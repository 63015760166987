import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from './useApi';
import { SET_MENUS } from '../constants/reduxActions';
import { API_METHOD_GET } from '../constants/api';
import { MENU_API } from '../constants/routes';

function useMenus(): void {
  const dispatch = useDispatch();
  const { handleApi } = useApi();

  useEffect(() => {
    fetchMenuGroups();
  }, []);

  async function fetchMenuGroups() {
    const { data } = await handleApi(API_METHOD_GET, MENU_API);
    dispatch({ type: SET_MENUS, payload: data.menuGroups });
  }
}

export default useMenus;
