import { useHistory } from 'react-router';

import useNewService from '../../hooks/useNewService';
import InstrumentCard from '../InstrumentCard';
import ServiceForm from '../ServiceForm';
import AdSection from '../AdSection';
import useGetTranslation from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import ReactToggle from 'react-toggle';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import style from '../../assets/scss/components/Toggle.module.scss';

function NewService(): JSX.Element {
  const {
    instrument,
    register,
    handleSubmit,
    handleProjectChange,
    errors,
    newProjectName,
    handleNewProjectNameChange,
    onPictureChange,
    onAttachmentChange,
    attachmentNr,
    pictureNr,
    project,
    showLeaveWarning,
    handleLastDate,
    handleNextDate,
    lastServiceDate,
    nextServiceDate,
    handleDeleteLastDate,
    handleDeleteNextDate,
    cropUploadImage,
    handleCancelUploadCrop,
    handleFinishUploadCrop,
    uploadCropperRef,
    handleActive,
    isActive,
    handleNewTunerNameChange,
    handleTunerChange,
    newTunerName,
    tuner,
  } = useNewService();
  const { translations } = useGetTranslation();
  const history = useHistory();
  useSetHeader(() => {
    history.goBack();
  }, translations.serviceNewTitle);
  const { custom, flex, activeText, activeContainer } = convertKeysToCamelCase(style);

  return (
    <main>
      <AdSection>
        <div className={flex}>
          <InstrumentCard {...instrument} isSimple={true} />
          <div className={activeContainer}>
            <p className={activeText}>{translations.active}</p>
            <ReactToggle
              className={custom}
              onChange={(e) => handleActive(e.target.checked ? 1 : 0)}
              checked={isActive === 1}
            />
          </div>
        </div>
        <ServiceForm
          register={register}
          handleSubmit={handleSubmit}
          onMatch={handleProjectChange}
          isEditing={true}
          errors={errors}
          newProjectName={newProjectName}
          handleNewProjectNameChange={handleNewProjectNameChange}
          onAttachmentChange={onAttachmentChange}
          onPictureChange={onPictureChange}
          pictureNr={pictureNr}
          attachmentNr={attachmentNr}
          project={project}
          instrument={instrument}
          showLeaveWarning={showLeaveWarning}
          handleLastDate={handleLastDate}
          handleNextDate={handleNextDate}
          lastServiceDate={lastServiceDate}
          nextServiceDate={nextServiceDate}
          handleDeleteLastDate={handleDeleteLastDate}
          handleDeleteNextDate={handleDeleteNextDate}
          uploadCropperRef={uploadCropperRef}
          uploadCropImage={cropUploadImage}
          handleUploadCrop={handleFinishUploadCrop}
          handleCancelUploadCrop={handleCancelUploadCrop}
          handleNewTunerNameChange={handleNewTunerNameChange}
          handleTunerMatch={handleTunerChange}
          newTunerName={newTunerName}
          tuner={tuner}
        />
      </AdSection>
    </main>
  );
}

export default NewService;
