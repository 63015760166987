import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useApi } from './useApi';
import { PROFESSIONS, REGISTER } from '../constants/routes';
import { API_METHOD_GET, API_METHOD_POST } from '../constants/api';
import useYupValidationResolver from './useYupValidationResolver';
import { getRegistrationValidationSchema } from '../helpers/validationSchemaHelper';
import { IRegistrationForm } from '../interfaces/components/Registration';
import { useSelector } from 'react-redux';
import { IReduxStore } from '../interfaces/IGeneral';
import useGetTranslations from './useGetTranslations';
import { ACTIVE_COMPANY_PROFESSION_TYPES, ACTIVE_PRIVATE_PROFESSION_TYPES } from '../constants/register';

function useRegistration() {
  const { handleApi } = useApi();
  const [isPrivate, setIsPrivate] = useState<boolean | undefined>(undefined);
  const [professionsTypes = [], setProfessionsTypes] = useState<Array<{ title: string; id: number }>>();
  const [filteredProfessionsTypes, setFilteredProfessionsTypes] = useState<Array<{ title: string; id: number }>>([]);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [selectedProfessionTypes = [], setSelectedProfessionTypes] = useState<Array<number>>();
  const { language } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslations();
  const [isRegisterModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  const resolver = useYupValidationResolver(getRegistrationValidationSchema());

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    control,
  } = useForm<IRegistrationForm>({ resolver });

  const filterProfessionTypes = (data: any) => {
    const activeProfessions = !isPrivate ? ACTIVE_PRIVATE_PROFESSION_TYPES : ACTIVE_COMPANY_PROFESSION_TYPES;
    const filteredProfessions = data.filter((profession: any) => activeProfessions.includes(profession.title));
    setFilteredProfessionsTypes(filteredProfessions);
  };

  async function fetchProfessions() {
    const { data, code, message } = await handleApi(API_METHOD_GET, PROFESSIONS);
    if (code !== 200) {
      toast.error(message);
      return;
    }
    filterProfessionTypes(data);
    setProfessionsTypes(data);
  }
  useEffect(() => {
    fetchProfessions();
  }, []);

  const setSignupType = (value: boolean) => {
    setIsPrivate(value);
    setSelectedProfessionTypes([]);
    filterProfessionTypes(professionsTypes);
  };

  const toggleSelectedProfessions = (id: number) => {
    if (isPrivate) {
      setSelectedProfessionTypes([id]);
      return;
    }
    const ids = selectedProfessionTypes.includes(id)
      ? selectedProfessionTypes.filter((element) => element != id)
      : [...selectedProfessionTypes, id];
    setSelectedProfessionTypes(ids);
  };

  const toggleTermsAccepted = () => {
    setIsTermsAccepted(!isTermsAccepted);
  };

  const handleRegisterModalOpen = (value: boolean) => {
    setRegisterModalOpen(value);
  };

  async function submitRegistration(formData: any) {
    let error = false;
    if (!isTermsAccepted) {
      setError('terms', { type: 'manual', message: 'Must accept terms' });
      error = true;
    }
    if (selectedProfessionTypes.length === 0) {
      setError('professionTypes', { type: 'manual', message: 'Must accept terms' });
      error = true;
    }
    if (error) {
      return;
    }
    const { message, code, data } = await handleApi(API_METHOD_POST, REGISTER, {
      ...formData,
      userType: isPrivate ? 'private' : 'company',
      professions: selectedProfessionTypes,
      languageCode: language,
    });

    window.scroll(0, 0);

    if (code === 200) {
      handleRegisterModalOpen(true);
      return;
    }

    if (message) {
      toast.error(message);
    }

    if (data) {
      Object.values(data).map((err: any) => {
        toast.error(translations[err] ? translations[err] : err);
      });
    }
  }

  return {
    isPrivate,
    setSignupType,
    professionsTypes: filteredProfessionsTypes,
    selectedProfessionTypes,
    toggleSelectedProfessions,
    isTermsAccepted,
    toggleTermsAccepted,
    register,
    errors,
    handleSubmitForm: handleSubmit(submitRegistration),
    reset,
    control,
    isRegisterModalOpen,
    handleRegisterModalOpen,
  };
}

export default useRegistration;
