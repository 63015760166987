import { ICategorySelect } from '../interfaces/components/Filter';

export const FilterFilter = {
  clientName: false,
  city: false,
  region: false,
  postcode: false,
  country: false,
  clients: true,
  instruments: false,
  myInstruments: true,
  noService: true,
  title: false,
  category: false,
  hasUnfinishedService: true,
  tuners: true,
};

export const CATEGORY_TYPES: ICategorySelect[] = [
  { code: '', name: 'All' },
  { code: 'grand', name: 'Grand' },
  { code: 'upright', name: 'Upright' },
  { code: 'other', name: 'Other' },
];
