import { GiWaterDrop, GiSoundWaves } from 'react-icons/gi';
import { RiTempColdLine } from 'react-icons/ri';

import style from '../assets/scss/components/LatestInfo.module.scss';
import Title from './Title';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { ILatestInfo } from '../interfaces/components/Instrument';
import useGetTranslations from '../hooks/useGetTranslations';
import useProfile from '../hooks/useProfile';

function LatestInfo(props: ILatestInfo): JSX.Element {
  const { row, rowCenter, icon, attribute, text } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { profile } = useProfile();
  const { temperature, humidity, frequency, register, isEditing } = props;

  return (
    <div>
      <Title title={translations.latestInfo} />
      <div className={`${row} ${rowCenter}`}>
        <GiSoundWaves className={icon} />
        {isEditing ? (
          <input
            type="text"
            name="frequency"
            ref={register}
            className={`primary ${attribute}`}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <p className={`bubble ${attribute}`}>{frequency}</p>
        )}
        <p className={text}>Hz</p>
      </div>
      <div className={`${row} ${rowCenter}`}>
        <GiWaterDrop className={icon} />
        {isEditing ? (
          <input
            type="text"
            name="humidity"
            ref={register}
            className={`primary ${attribute}`}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <p className={`bubble ${attribute}`}>{humidity}</p>
        )}
        <p className={text}>%</p>
      </div>
      <div className={`${row} ${rowCenter}`}>
        <RiTempColdLine className={icon} />
        {isEditing ? (
          <input
            type="text"
            name="temperature"
            ref={register}
            className={`primary ${attribute}`}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <p className={`bubble ${attribute}`}>{temperature}</p>
        )}
        <p className={text}>{profile?.temperatureUnit}</p>
      </div>
    </div>
  );
}

export default LatestInfo;
