import style from '../assets/scss/components/IdComponent.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';

function IdComponent(props: { id?: number; text?: string }): JSX.Element {
  const { wrapper } = convertKeysToCamelCase(style);
  const { id, text } = props;

  return (
    <div className={`${wrapper} h4`}>
      {text}: {id}
    </div>
  );
}

export default IdComponent;
