import React from 'react';

import ForgotPasswordForm from '../ForgotPasswordForm';
import AuthenticationTitle from '../AuthenticationTitle';
import useGeneral from '../../hooks/useGeneral';
import useGetTranslations from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import { LOGIN_PAGE } from '../../constants/routes';

function ForgotPassword(): JSX.Element {
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();

  useSetHeader(() => {
    handleRedirect(LOGIN_PAGE);
  }, translations.forgotPassword);

  return (
    <main>
      <section className="small">
        <AuthenticationTitle />
      </section>
      <section className="small">
        <ForgotPasswordForm />
      </section>
    </main>
  );
}

export default ForgotPassword;
