import { useRef } from 'react';
import { MdLocationPin, MdModeEditOutline } from 'react-icons/md';
import { BiUser, BiBell } from 'react-icons/bi';

import style from '../assets/scss/components/RemindersCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import noImage from '../assets/images/no-image.jpg';
import { IRemindersCard } from '../interfaces/components/Reminders';
import useGetTranslations from '../hooks/useGetTranslations';
import { professionTranslation } from '../utils/roleChecker';

function ReminderCard(props: IRemindersCard): JSX.Element {
  const {
    wrapper,
    image,
    title,
    description,
    clientData,
    descriptionContainer,
    logoContainer,
    logo,
    infoWrapper,
    contactWrapper,
    contactIconWrapper,
    wrapperSimple,
    imageContainer,
    logoMe,
    swapElements,
  } = convertKeysToCamelCase(style);

  const { isSimple, selectedImage, handleImageChange, isNew, reminder, onClick, isEditing, isOverForm } = props;
  const imageRef = useRef<HTMLImageElement>(null);
  const { translations } = useGetTranslations();

  //prettier-ignore
  return (
    <div className={isSimple ? wrapperSimple : wrapper}>
      <div
        className={infoWrapper}
        onClick={() => {
          if (onClick) onClick(reminder?.id);
        }}
      >
        <input type="file" name="file" id="file" accept="image/*" onChange={handleImageChange} disabled={!isEditing} />
        <label htmlFor="file" className={imageContainer}>
          <img
            src={!isSimple ? reminder?.thumbnail ? reminder.thumbnail : noImage : selectedImage ? selectedImage : noImage}
            className={image}
            ref={imageRef}
            onError={() => {
              if (imageRef && imageRef.current) imageRef.current.src = noImage;
            }}
          />
          {isEditing && <MdModeEditOutline className="image-edit-icon" />}
        </label>
        {!isNew && (
          <div className={descriptionContainer}>
            {isOverForm ? (
              <div>
                <p className={title}>{reminder?.brand}</p>
                <p className={description}>
                  {reminder?.model}
                  {reminder.model && reminder.category && ','} {reminder?.category}
                </p>
              </div>
            ) : (
              <div>
                <p className={title}>
                  {reminder?.brand}
                  {reminder.brand && reminder.model && ','} {reminder?.model}
                </p>
                <p className={description}>{reminder?.upcomingReminderDate}</p>
              </div>
            )}
            {reminder?.client ? (
              <div className={isOverForm ? swapElements : ''}>
                {(reminder.client.city !== '' || reminder.client.postcode !== '') &&  <div className={logoContainer}>
                  <MdLocationPin className={logo} />
                  <p className={clientData}>
                    {reminder?.client?.city}
                    {reminder.client.city && reminder.client.postcode && ', '}
                    {reminder?.client?.postcode}
                  </p>
                </div>}
                <div className={logoContainer}>
                  <BiUser className={logo} />
                  <p className={clientData}>{reminder?.client?.name}</p>
                </div>
              </div>
            ) : (
              <div className={logoContainer}>
                <BiUser className={logoMe} />
                <p>{translations[professionTranslation('noClient')]}</p>
              </div>
            )}
          </div>
        )}
      </div>
      {!isSimple && (
        <div className={contactWrapper}>
          <div className={contactIconWrapper}>
            <BiBell className={logo} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ReminderCard;
