import { TReduxState } from '../interfaces/IGeneral';

export const defaultOrderState = { orderByDirection: 'DESC', orderByField: 'created_at' };
export const initialState: TReduxState = {
  translations: {},
  language: 'en',
  promises: [],
  isLoading: false,
  myValue: false,
  countries: [],
  activeCountries: [],
  profile: null,
  menugroups: [],
  header: {
    onBack: undefined,
    title: '',
    showBack: false,
  },
  clients: [],
  instruments: [],
  instrumnetsClients: [],
  order: defaultOrderState,
  globalSearch: {},
  globalSearchTextValue: '',
  globalSearchOnBack: false,
  filter: {},
  actualFilter: {},
  customAds: [],
  pagination: { page: 1, perPage: 10 },
  shouldLeaveModal: false,
  attachmentPageSwitcher: 'image',
  instrumentServicesPageSwitcher: 0,
  clientServicesPageSwitcher: 0,
  projectListRefresh: false,
  intrumentsListRefresh: false,
  clientListRefresh: false,
  hamburgerRef: null,
  isSuggestionOpened: false,
  openAttachmentInstead: false,
  openProjectInstead: false,
  openReminderInstead: false,
  openServiceInstead: false,
  resetGlobalSearch: false,
  isActiveProjects: false,
  isActiveServices: false,
  instrumentServicesFilter: {},
  actualInstrumentServicesFilter: {},
  instrumentServicesOrder: defaultOrderState,
  brandListRefresh: false,
  tunerListRefresh: false,
};
