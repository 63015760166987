import { Prompt } from 'react-router-dom';

import AlertModal from './AlertModal';
import useLeaveModal from '../hooks/useLeaveModal';
import useGetTranslations from '../hooks/useGetTranslations';
import { ILeaveModalProps } from '../interfaces/IGeneral';

function LeaveModal(props: ILeaveModalProps): JSX.Element {
  const { canLeave } = props;
  const { onLeaveAttempt, isModalVisible, redirectToLocation, onCancel } = useLeaveModal();
  const { translations } = useGetTranslations();

  if (canLeave) {
    return <></>;
  }

  return (
    <div>
      <Prompt message={onLeaveAttempt} />
      <AlertModal
        onClose={onCancel}
        isOpen={isModalVisible}
        onLeftButtonClick={onCancel}
        onRightButtonClick={redirectToLocation}
        header={translations.leaveModalHeader}
        description={translations.leaveModalDescription}
        leftButton={translations.cancel}
        rightButton={translations.leaveModalGo}
      />
    </div>
  );
}

export default LeaveModal;
