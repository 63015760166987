import { useEffect, useState } from 'react';

import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import { INSTRUMENT_PROJECTS } from '../constants/routes';
import { ISimpleProject } from '../interfaces/components/Project';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxStore } from '../interfaces/IGeneral';
import { SET_PROJECT_LIST_REFRESH } from '../constants/reduxActions';
import { IUseChooseProject } from '../interfaces/components/Hooks';

function useChooseProject(instrumentId: number | undefined): IUseChooseProject {
  const [projects, setProjects] = useState<Array<ISimpleProject>>([]);
  const { projectListRefresh } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { handleApi } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProjects();
  }, [instrumentId, projectListRefresh]);

  async function fetchProjects() {
    if (!instrumentId) {
      return;
    }
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENT_PROJECTS.replace(':id', instrumentId.toString()));
    if (code !== 200) {
      return;
    }
    setProjects(data.data);
    dispatch({ type: SET_PROJECT_LIST_REFRESH, payload: false });
  }

  return { projects: projects.map((project) => ({ name: project.title, id: project.id })) };
}

export default useChooseProject;
