import { AiOutlinePlus } from 'react-icons/ai';

import BlueHeader from './BlueHeader';
import SortIcon from './SortIcon';
import style from '../assets/scss/components/InstrumentServicesHeader.module.scss';
import useGetTranslations from '../hooks/useGetTranslations';
import FilterIcon from './FilterIcon';
import useInstrumentServicesHeader from '../hooks/useInstrumentServicesHeader';
import { convertKeysToCamelCase } from '../helpers/baseHelper';

import ActiveFilterHeader from './ActiveFilterHeader';
import { IInstrumentServiceHeader } from '../interfaces/components/InstrumentServiceHeader';
import {
  RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER,
  SET_INSTRUMENT_SERVICES_ACTUAL_FILTER,
  UPDATE_INSTRUMENT_SERVICES_ACTUAL_FILTER,
} from '../constants/reduxActions';

function InstrumentServicesHeader({
  switcher,
  filters,
  actualFilter,
  hideFilters,
  hideSort,
}: IInstrumentServiceHeader): JSX.Element {
  const { wrapper, newWrapper } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { handleClick } = useInstrumentServicesHeader(switcher);

  return Object.keys(actualFilter).length === 0 ? (
    <BlueHeader
      element={
        <div className={wrapper}>
          <div className={newWrapper}>
            {switcher !== 2 && !hideSort && (
              <SortIcon
                isService={switcher === 0}
                isProject={switcher === 1}
                parentPage={switcher === 0 ? 'instrumentServices' : ''}
                field="title"
                title={{
                  ascending:
                    switcher === 0
                      ? translations.sortInstrumentProjectAscending
                      : translations.sortInstrumentServiceAscending,
                  descending:
                    switcher === 0
                      ? translations.sortInstrumentServiceDescending
                      : translations.sortInstrumentServiceDescending,
                }}
              />
            )}
            {switcher === 0 && !hideFilters && (
              <FilterIcon filters={filters} addActualFilterRedux={SET_INSTRUMENT_SERVICES_ACTUAL_FILTER} />
            )}
          </div>

          <div className={newWrapper} onClick={handleClick}>
            {switcher === 0 && <p>{translations.instrumentDetailHeaderNewService}</p>}
            {switcher === 1 && <p>{translations.instrumentNewProject}</p>}
            {switcher === 2 && <p>{translations.instrumentNewReminder}</p>}
            <AiOutlinePlus className="plus-circle" />
          </div>
        </div>
      }
    />
  ) : (
    <ActiveFilterHeader
      title={{
        ascending:
          switcher === 0 ? translations.sortInstrumentProjectAscending : translations.sortInstrumentServiceAscending,
        descending:
          switcher === 0 ? translations.sortInstrumentServiceDescending : translations.sortInstrumentServiceDescending,
      }}
      field="title"
      isService={switcher === 0}
      actualFilter={actualFilter}
      updateActualFilterRedux={UPDATE_INSTRUMENT_SERVICES_ACTUAL_FILTER}
      resetActualFilterRedux={RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER}
    />
  );
}

export default InstrumentServicesHeader;
