import React from 'react';
import Loader from 'react-loader-spinner';

import style from '../assets/scss/components/ReactLoader.module.scss';

const ReactLoader = (): JSX.Element => {
  const { wrapper } = style;

  return (
    <div className={wrapper}>
      <Loader {...{ type: 'Bars', color: '#304785', height: 100, width: 100 }} />
    </div>
  );
};

export default ReactLoader;
