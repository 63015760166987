import AttachmentHeader from '../AttachmentHeader';
import InstrumentCard from '../InstrumentCard';
import SwitcherComponent from '../SwitcherComponent';
import useAttachments from '../../hooks/useAttachments';
import AttachmentImages from '../AttachmentImages';
import AdSection from '../AdSection';
import Title from '../Title';
import AttachmentAttachments from '../AttachmentAttachments';
import useGetTranslations from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import UserIdComponent from '../UserIdComponent';
import AlertModal from '../AlertModal';
import LeaveModal from '../LeaveModal';

function EditAttachments(): JSX.Element {
  const {
    instrument,
    toggleEditing,
    selectedImages,
    onImageClick,
    imageModal,
    closeModal,
    selectedAttachments,
    onAttachmentClick,
    handleDelete,
    switcherPage,
    toggleSwitcher,
    type,
    handleUpload,
    projectFiles,
    isDeleteModalOpen,
    toggleDeleteModal,
    dataTotal,
    data,
    nextPage,
    shouldLeaveWarning,
    files,
  } = useAttachments();

  const { translations } = useGetTranslations();
  useSetHeader(() => {}, translations.attachmentEditTitle);

  //prettier-ignore
  return (
    <main>
      {shouldLeaveWarning && <LeaveModal />}
      <AttachmentHeader
        onChange={handleUpload}
        isEditing={true}
        toggleEditing={toggleEditing}
        handleDelete={toggleDeleteModal}
        switcherPage={switcherPage}
      />
      <AlertModal
        isOpen={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        header={translations.deleteModalTitle}
        description=""
        leftButton={translations.back}
        rightButton={translations.deleteSelected}
        onLeftButtonClick={toggleDeleteModal}
        onRightButtonClick={handleDelete}
      />
      <AdSection topPadding>
        <>
          {instrument?.id && <UserIdComponent isInstrument={true} clientId={instrument.id} />}
          {(type === 'instrument' || type === 'service' || type === 'project') && (
            <InstrumentCard isSimple={true} {...instrument} />
          )}
          {!projectFiles && (
            <SwitcherComponent
              switcherPage={switcherPage}
              toggleSwitcherPage={toggleSwitcher}
              titles={[
                instrument
                  ? `${translations.attachmentPictureTitle} ` + `(${instrument && instrument?.imagesCount})`
                  : translations.attachmentPictureTitle,
                instrument
                  ? `${translations.attachmentFileTitle} (${instrument && instrument?.filesCount})`
                  : translations.attachmentFileTitle,
              ]}
              elements={[
                <AttachmentImages
                  key="AttachmentImages"
                  isEditing={true}
                  files={type === 'instrument' ? data : files?.attachments.filter((f) => f.selectedType === 'image')}
                  total={dataTotal}
                  selectedImages={selectedImages}
                  onImageClick={onImageClick}
                  imageModal={imageModal}
                  closeModal={closeModal}
                  projectFiles={projectFiles}
                  nextPage={type === 'instrument' ? nextPage : undefined}
                />,
                <AttachmentAttachments
                  key="AttachmentAttachments"
                  isEditing={true}
                  files={type === 'instrument' ? data : files?.attachments.filter((f) => f.selectedType !== 'image')}
                  total={dataTotal}
                  selectedAttachments={selectedAttachments}
                  onAttachmentClick={onAttachmentClick}
                  projectFiles={projectFiles}
                  nextPage={type === 'instrument' ? nextPage : undefined}
                />,
              ]}
            />
          )}
          {projectFiles && (
            <SwitcherComponent
              switcherPage={switcherPage}
              toggleSwitcherPage={toggleSwitcher}
              titles={[
                instrument
                  ? `${translations.attachmentPictureTitle} ` + `(${instrument && instrument?.imagesCount})`
                  : translations.attachmentPictureTitle,
                instrument
                  ? `${translations.attachmentFileTitle} (${instrument && instrument?.filesCount})`
                  : translations.attachmentFileTitle,
              ]}
              elements={[
                <div key="attachments-1">
                  {projectFiles.services.map(
                    (serviceFiles) =>
                      serviceFiles.attachments.filter((file) => file.selectedType === 'image').length > 0 && (
                        <div key={serviceFiles.id}>
                          <Title title={serviceFiles.title} />
                          <AttachmentImages
                            key={serviceFiles.id}
                            isEditing={true}
                            files={serviceFiles.attachments.filter((file) => file.selectedType === 'image')}
                            selectedImages={selectedImages}
                            onImageClick={onImageClick}
                            imageModal={imageModal}
                            closeModal={closeModal}
                            projectFiles={projectFiles}
                          />
                        </div>
                      )
                  )}
                </div>,
                <div key="attachments-2">
                  {projectFiles.services.map(
                    (serviceFiles) =>
                      serviceFiles.attachments.filter((file) => file.selectedType === 'file').length > 0 && (
                        <div key={serviceFiles.id}>
                          <Title title={serviceFiles.title} />
                          <AttachmentAttachments
                            key={serviceFiles.id}
                            isEditing={true}
                            files={serviceFiles.attachments.filter((file) => file.selectedType === 'file')}
                            selectedAttachments={selectedAttachments}
                            onAttachmentClick={onAttachmentClick}
                            projectFiles={projectFiles}
                          />
                        </div>
                      )
                  )}
                </div>,
              ]}
            />
          )}
        </>
      </AdSection>
    </main>
  );
}

export default EditAttachments;
