import { IKeyString } from '../interfaces/IGeneral';

export function transformTranslations(data?: IKeyString): IKeyString {
  const newTranslations: IKeyString = {};
  if (data) {
    Object.keys(data).forEach((key) => {
      newTranslations[toCamelCase(key)] = data[key];
    });
  }

  return newTranslations;
}

export function toCamelCase(string: string): string {
  return string.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
}
