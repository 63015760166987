import style from '../assets/scss/components/UserIdComponent.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslation from '../hooks/useGetTranslations';
import { professionTranslation } from '../utils/roleChecker';

function UserIdComponent(props: { clientId?: number; isInstrument?: boolean }): JSX.Element {
  const { wrapper } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslation();
  const { clientId, isInstrument } = props;

  return (
    <div className={`${wrapper} h4`}>
      {isInstrument ? translations.instrumentId : translations[professionTranslation('clientId')]}:{' '}
      {clientId ? clientId : ''}
    </div>
  );
}

export default UserIdComponent;
