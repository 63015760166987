import ProfileCard from '../ProfileCard';
import CurrencyLanguageForm from '../CurrencyLanguageForm';
import EditHeader from '../EditHeader';
import useCurrencyLanguage from '../../hooks/useCurrencyLanguage';
import useGetTranslations from '../../hooks/useGetTranslations';
import useGeneral from '../../hooks/useGeneral';
import AdSection from '../AdSection';
import useSetHeader from '../../hooks/useSetHeader';
import { PROFILE } from '../../constants/routes';
import { useSelector } from 'react-redux';
import { IReduxStore } from '../../interfaces/IGeneral';

function Currency(): JSX.Element {
  const {
    onDownloadPictures,
    onDownloadFiles,
    isEditing,
    toggleEditing,
    editProfile,
    updateEditProfile,
    onSubmit,
    onDownloadUserDb,
  } = useCurrencyLanguage();

  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();
  const x = isEditing ? translations.currencyEditTitle : translations.currencyTitle;
  const { profile } = useSelector((store: IReduxStore) => store.defaultReducer);

  useSetHeader(() => {
    !isEditing ? handleRedirect(PROFILE) : toggleEditing();
  }, x);

  return (
    <main>
      <EditHeader
        isEditing={isEditing}
        toggleEditing={toggleEditing}
        handleDelete={toggleEditing}
        isWhiteBackground
        deleteButtonText={''}
      />

      <AdSection>
        <ProfileCard clientId={profile?.id} />

        <CurrencyLanguageForm
          onDownloadFiles={onDownloadFiles}
          onDownloadPictures={onDownloadPictures}
          isEditing={isEditing}
          profile={editProfile}
          onChange={updateEditProfile}
          onSubmit={onSubmit}
          onDownloadUserDb={onDownloadUserDb}
        />
      </AdSection>
    </main>
  );
}

export default Currency;
