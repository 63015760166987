import { Redirect, Route, useLocation } from 'react-router-dom';

import { LOGIN_PAGE } from '../constants/routes';
import { TOKEN_DATA_KEY } from '../constants/login';
import { IPrivateRoute } from '../interfaces/components/PrivateRoute';
import { SET_LANGUAGE } from '../constants/reduxActions';
import { useDispatch } from 'react-redux';

function PrivateRoute(props: IPrivateRoute): JSX.Element {
  const { element: Component, path, ...rest } = props;
  const token = localStorage.getItem(TOKEN_DATA_KEY);

  const { search } = useLocation();
  const dispatch = useDispatch();
  if (search.length > 1) {
    const lang = search.substring(1).split('=');
    if (lang && lang.length) {
      if (lang[0] === 'lang') {
        dispatch({ type: SET_LANGUAGE, payload: lang[1] });
        if (token) {
          return <Redirect to={path.split('?')[0]} />;
        }
      }
    }
  }

  if (!token) return <Redirect to={LOGIN_PAGE} />;

  return <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />;
}

export default PrivateRoute;
