import { useSelector } from 'react-redux';

import { IGetCountries } from '../interfaces/components/Hooks';
import { IReduxStore } from '../interfaces/IGeneral';

function useGetCountries(): IGetCountries {
  const { countries, activeCountries } = useSelector((store: IReduxStore) => store.defaultReducer);

  return { countries, activeCountries };
}

export default useGetCountries;
