import { useParams } from 'react-router';

import useGeneral from './useGeneral';
import { NEW_PROJECT_PAGE, NEW_REMINDER_PAGE, NEW_SERVICE_PAGE } from '../constants/routes';

function useInstrumentServicesHeader(switcher: number) {
  const { handleRedirect } = useGeneral();
  const { id } = useParams<{ id: string }>();

  const handleClick = () => {
    if (switcher === 2) {
      handleRedirect(NEW_REMINDER_PAGE.replace(':id', id));
    }
    if (switcher === 1) {
      handleRedirect(NEW_PROJECT_PAGE.replace(':id', id));
    }
    if (switcher === 0) {
      handleRedirect(NEW_SERVICE_PAGE.replace(':id', id));
    }
  };

  return { handleClick };
}

export default useInstrumentServicesHeader;
