import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DOWNLOAD_USER_DB, PROFILE_CURRENCY_LANGUAGE } from '../constants/routes';
import { DOWNLOAD_FILES, DOWNLOAD_IMAGE } from '../constants/routes';
import { API_METHOD_GET, API_METHOD_POST } from '../constants/api';
import { useApi } from './useApi';
import useProfile from './useProfile';
import { ApiResponseTemplate, ISimpleProfile } from '../interfaces/IGeneral';
import { SET_PROFILE } from '../constants/reduxActions';
import { useDispatch } from 'react-redux';
import { IUseCurrencyLanguage } from '../interfaces/components/Hooks';
import { toCamelCase } from '../helpers/baseHelper';
import useGetTranslations from './useGetTranslations';

function useCurrencyLanguage(): IUseCurrencyLanguage {
  const { handleApi } = useApi();
  const dispatch = useDispatch();
  const { translations } = useGetTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const { profile } = useProfile();
  const [editProfile, setEditProfile] = useState<ISimpleProfile | null>(profile);

  useEffect(() => {
    setEditProfile(profile);
  }, [profile]);

  useEffect(() => {
    if (!isEditing) {
      setEditProfile(profile);
    }
  }, [profile, isEditing]);

  async function onDownloadPictures() {
    const { code, data, message } = await handleApi(API_METHOD_GET, DOWNLOAD_IMAGE);
    if (code !== 200) {
      toast.error(message);
      return;
    }
    window.location.assign(data);
  }
  async function onDownloadFiles() {
    const { code, data, message } = await handleApi(API_METHOD_GET, DOWNLOAD_FILES);
    if (code !== 200) {
      toast.error(message);
      return;
    }
    window.location.assign(data);
  }

  async function onDownloadUserDb() {
    const { code, message } = await handleApi(API_METHOD_GET, DOWNLOAD_USER_DB);
    if (code === 200) {
      toast.success(translations[toCamelCase(message.replace('api.', ''))]);
      return;
    }
  }

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  //prettier-ignore
  const updateEditProfile = useCallback(
    (property: keyof ISimpleProfile, value: ISimpleProfile[keyof ISimpleProfile]) => {
      if (editProfile) {
        setEditProfile({
          ...editProfile,
          [property]: value,
        });
      }
    },
    [editProfile, setEditProfile]
  );

  //prettier-ignore
  const onSubmit = async () => {
    try {
      const { data: newProfile } = await handleApi<ApiResponseTemplate<ISimpleProfile>>(
        API_METHOD_POST,
        PROFILE_CURRENCY_LANGUAGE,
        {
          currency: editProfile?.currency,
          languageCode: editProfile?.languageCode,
          temperatureUnit: editProfile?.temperatureUnit,
          lengthUnit: editProfile?.lengthUnit,
        }
      );
      dispatch({ type: SET_PROFILE, payload: newProfile });
      setIsEditing(false);
      window.scroll(0, 0);
    } catch {}
  };

  return {
    onDownloadFiles,
    onDownloadPictures,
    onDownloadUserDb,
    isEditing,
    editProfile,
    updateEditProfile,
    toggleEditing,
    onSubmit,
  };
}

export default useCurrencyLanguage;
