import { useEffect, useRef } from 'react';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineMenu } from 'react-icons/ai';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IHamburgerBase } from '../interfaces/components/HamburgerBase';

import style from '../assets/scss/components/HamburgerBase.module.scss';
import useScrollBlock from '../hooks/useScrollBlock';
import { SET_HAMBURGER_REF } from '../constants/reduxActions';
import { useDispatch } from 'react-redux';

function HamburgerBase(props: IHamburgerBase): JSX.Element {
  const {
    header,
    title,
    toggleOpener,
    burgerOpened,
    burgerClosed,
    content,
    blurOpened,
    blurClosed,
    contentMenu,
    wrapper,
    noScroll,
  } = convertKeysToCamelCase(style);
  const { childrenContent, headerTitle, needsOpenerIcon, isMenu, isOpen, toggleOpen, onBlurClick, disableScroll } =
    props;
  const { allowScroll, blockScroll } = useScrollBlock();
  const hamburgerBaseRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      blockScroll();
      return;
    }

    allowScroll();
  }, [isOpen]);

  useEffect(() => {
    isOpen
      ? dispatch({ type: SET_HAMBURGER_REF, payload: hamburgerBaseRef.current })
      : dispatch({ type: SET_HAMBURGER_REF, payload: null });
  }, [isOpen]);

  return (
    <div className={wrapper}>
      <div
        className={isOpen ? blurOpened : blurClosed}
        onClick={() => {
          if (onBlurClick) {
            onBlurClick();
            return;
          }
          if (toggleOpen) {
            toggleOpen();
          }
        }}
      />
      <div className={isOpen ? burgerOpened : burgerClosed} ref={hamburgerBaseRef}>
        <div className={`small ${header}`}>
          <p className={title}>{headerTitle}</p>
          <IoMdClose size={30} color="white" onClick={toggleOpen} />
        </div>
        <div className={`${isMenu ? contentMenu : `small ${content}`}  ${disableScroll && noScroll}`}>
          {childrenContent}
        </div>
      </div>
      {!isOpen && needsOpenerIcon && (
        <div className={toggleOpener}>
          <AiOutlineMenu color="white" size={30} onClick={toggleOpen} />
        </div>
      )}
    </div>
  );
}

export default HamburgerBase;
