import { useParams } from 'react-router';

import InstrumentCard from '../InstrumentCard';
import useNewProject from '../../hooks/useNewProject';
import ProjectForm from '../ProjectForm';
import AdSection from '../AdSection';
import { INSTRUMENT_SERVICES } from '../../constants/routes';
import useGetTranslations from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import useGeneral from '../../hooks/useGeneral';

function NewProject(): JSX.Element {
  const {
    instrument,
    register,
    handleSubmit,
    errors,
    handleStartingDate,
    startingDate,
    showLeaveWarning,
    handleDeleteDate,
    endDate,
    handleDeleteEndDate,
    handleEndDate,
    tuner,
    newTunerName,
    handleTunerMatch,
    handleNewTunerNameChange,
  } = useNewProject();
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();
  const { id } = useParams<{ id: string }>();
  useSetHeader(() => {
    handleRedirect(INSTRUMENT_SERVICES.replace(':id', id));
  }, translations.projectNewTitle);

  return (
    <main>
      <AdSection>
        <InstrumentCard isSimple={true} {...instrument} />
        <ProjectForm
          register={register}
          handleSubmit={handleSubmit}
          isEditing={true}
          shouldLeaveWarning={showLeaveWarning}
          errors={errors}
          handleStartingDate={handleStartingDate}
          startingDateOriginalValue={startingDate}
          handleDeleteDate={handleDeleteDate}
          endingDateOriginalValue={endDate}
          handleDeleteEndingDate={handleDeleteEndDate}
          handleEndingDate={handleEndDate}
          handleNewTunerNameChange={handleNewTunerNameChange}
          handleTunerMatch={handleTunerMatch}
          newTunerName={newTunerName}
          tuner={tuner}
        />
      </AdSection>
    </main>
  );
}

export default NewProject;
