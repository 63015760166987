import { useSelector } from 'react-redux';

import { IReduxStore } from '../interfaces/IGeneral';
import { IUseFooter } from '../interfaces/components/Hooks';
import { useMemo } from 'react';

function useFooter(): IUseFooter {
  const { menugroups } = useSelector((store: IReduxStore) => store.defaultReducer);

  const [privacy, terms, refund, contact] = useMemo(() => {
    const footer = menugroups.find((menu) => menu.name === 'footer');
    const privacy = footer?.menus.find((menu) => menu.name === 'footer_privacy');
    const terms = footer?.menus.find((menu) => menu.name === 'footer_terms');
    const refund = footer?.menus.find((menu) => menu.name === 'footer_refund');
    const contacts = menugroups.find((menu) => menu.name === 'contact');
    const contact = contacts?.menus.find((menu) => menu.name === 'contact_us_title');

    return [privacy, terms, refund, contact];
  }, [menugroups]);

  return { privacy, terms, refund, contact };
}
export default useFooter;
