import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/ServiceIconInfo.module.scss';
import { AiFillInfoCircle, AiOutlineClockCircle } from 'react-icons/ai';
import useGetTranslations from '../hooks/useGetTranslations';
import completed from '../assets/images/completed.svg';
import scheduled from '../assets/images/scheduled.svg';
import { BsLink } from 'react-icons/bs';
import { ReactComponent as UnfinishedService } from '../assets/images/scheduled.svg';

interface ServiceIconInfoProps {
  active: boolean;
  hideInfoIcon?: boolean;
}

export const ServiceIconInfo = ({ active, hideInfoIcon }: ServiceIconInfoProps) => {
  const { wrapper, infoContainer, infoImage, infoDescription, infoDescriptionRight, infoRed, infoIcon, linkIcon } =
    convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  return (
    <div className={wrapper}>
      {!hideInfoIcon && <AiFillInfoCircle className={infoIcon} />}
      <div>
        {!active ? (
          <>
            <div className={infoContainer}>
              <img src={scheduled} alt="scheduled_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.scheduledInfoDescription}</p>
            </div>
            <div className={infoContainer}>
              <img src={completed} alt="completed_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.completedInfoDescription}</p>
            </div>
            <div className={infoContainer}>
              <UnfinishedService className={infoRed} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}> {translations.lateScheduledInfoDescription}</p>
            </div>
          </>
        ) : (
          <>
            <div className={infoContainer}>
              <img src={scheduled} alt="scheduled_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.scheduledInfoDescriptionActive}</p>
            </div>
            <div className={infoContainer}>
              <AiOutlineClockCircle className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.dateTimeInfoDescriptionActive}</p>
            </div>
          </>
        )}
        <div className={infoContainer}>
          <BsLink className={linkIcon} />
          <p className={infoDescription}>= </p>
          <p className={infoDescriptionRight}>{translations.projectIconDescription}</p>
        </div>
      </div>
    </div>
  );
};
