import React, { useState, useEffect, createRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Resizer from 'react-image-file-resizer';

import { useApi } from './useApi';
import { API_METHOD_GET, API_METHOD_DELETE, API_METHOD_POST } from '../constants/api';
import {
  FETCH_INSTRUMENT,
  INSTRUMENT_FILES,
  DELETE_FILE,
  UPLOAD_ATTACHMENT,
  SERVICE_DETAIL_API,
  PROJECT_DETAILS,
  ATTACHMENTS_PAGE,
  EDIT_ATTACHMENTS_PAGE,
  INSTRUMENT_MAIN_GALLERY,
} from '../constants/routes';
import { IFiles, IAttachment, IProjectFiles } from '../interfaces/components/Attachments';
import { IReduxStore } from '../interfaces/IGeneral';
import { IUseAttachments } from '../interfaces/components/Hooks';
import useMySubscriptionPlan from './useMySubscriptionPlan';
import useGeneral from './useGeneral';
import { ReactCropperElement } from 'react-cropper';
import { SET_ATTACHMENTS_PAGE_SWITCHER, SET_PAGINATION } from '../constants/reduxActions';
import usePagination from './usePagination';
import { nanoid } from 'nanoid';
import { getBase64 } from '../helpers/baseHelper';

function useAttachments(): IUseAttachments {
  const [instrument, setInstrument] = useState();
  const [files, setFiles] = useState<IFiles>();
  const [mainGalleryData, setMainGalleryData] = useState<IAttachment[]>([]);
  const [mainGalleryTotal, setMainGalleryTotal] = useState<number>(1);
  const [shouldLeaveWarning, setShouldLeaveWarning] = useState<boolean>(true);
  const [selectedImages, setSelectedImages] = useState<Array<IAttachment>>([]);
  const [selectedAttachments, setSelectedAttachments] = useState<Array<IAttachment>>([]);
  const [imageModal, setImageModal] = useState<IAttachment>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [projectFiles, setProjectFiles] = useState<IProjectFiles>();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const { handleApi } = useApi();
  const { id, type } = useParams<{ id: string; type: string }>();
  const { imagesLeft, filesLeft, showPlanExceededToast, refresh } = useMySubscriptionPlan();
  const { promises, attachmentPageSwitcher, pagination } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { handleRedirect } = useGeneral();
  const [cropImage, setCropImage] = useState<string>('');
  const cropperRef = createRef<ReactCropperElement>();
  const dispatch = useDispatch();
  const { paginate } = usePagination();

  useEffect(() => {
    if (type === 'instrument') {
      refreshMainGalleryData();
      return;
    }
    if (type === 'service') fetchServiceInstrument();
    if (type === 'project') fetchProjectInstrument();
    fetchFiles();
  }, []);

  useEffect(() => {
    if (shouldUpdate)
      Promise.all(promises).then(() => {
        fetchFiles();
        if (type === 'instrument') {
          refreshMainGalleryData();
        }
      });
    setShouldUpdate(false);
  }, [shouldUpdate]);

  useEffect(() => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: 1 } });
    setMainGalleryData([]);
  }, [attachmentPageSwitcher]);

  useEffect(() => {
    if (type === 'instrument') {
      refreshMainGalleryData();
      return;
    }
  }, [pagination.page, attachmentPageSwitcher]);

  const refreshMainGalleryData = () => {
    attachmentPageSwitcher === 'image' ? fetchMainGalleryImages() : fetchMainGalleryFiles();
    fetchInstrument();
  };

  async function fetchInstrument() {
    const { data } = await handleApi(API_METHOD_GET, FETCH_INSTRUMENT.replace(':id', id));
    setInstrument(data);
  }

  async function fetchServiceInstrument() {
    const { data } = await handleApi(API_METHOD_GET, SERVICE_DETAIL_API.replace(':id', id));
    setInstrument({
      ...data.instrument.data,
      client: data.instrument.client,
      clientId: data.instrument.client?.id,
      imagesCount: data.imagesCount,
      filesCount: data.filesCount,
    });
  }

  async function fetchProjectInstrument() {
    const { data } = await handleApi(API_METHOD_GET, PROJECT_DETAILS.replace(':id', id));
    setInstrument({
      ...data.instrument,
      brand: data.instrument.brand.name,
      client: data.client,
      clientId: data.client?.id,
      imagesCount: data.imagesCount,
      filesCount: data.filesCount,
    });
  }

  async function fetchFiles() {
    const { data } = await handleApi(API_METHOD_GET, INSTRUMENT_FILES.replace(':id', id).replace(':type', type));
    if (type === 'project') setProjectFiles(data);
    else setFiles(data);
  }

  //prettier-ignore
  async function fetchMainGalleryImages() {
    const { data } = await handleApi(
      API_METHOD_GET,
      INSTRUMENT_MAIN_GALLERY.replace(':id', id).replace(':type', 'image') + `?page=${pagination.page}&perPage=20`
    );
    paginate(setMainGalleryData, data.data);
    setMainGalleryTotal(data.total)
  }

  //prettier-ignore
  async function fetchMainGalleryFiles() {
    const { data } = await handleApi(
      API_METHOD_GET,
      INSTRUMENT_MAIN_GALLERY.replace(':id', id).replace(':type', 'file') + `?page=${pagination.page}&perPage=20`
    );
    paginate(setMainGalleryData, data.data);
    setMainGalleryTotal(data.total);
  }

  const toggleEditing = () => {
    if (window.location.href.includes('/edit')) {
      handleRedirect(ATTACHMENTS_PAGE.replace(':id', String(id)).replace(':type', String(type)));
      return;
    }
    handleRedirect(EDIT_ATTACHMENTS_PAGE.replace(':id', String(id)).replace(':type', String(type)));
  };

  const toggleSwitcher = (index: number) => {
    dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: index === 0 ? 'image' : 'file' });
    setMainGalleryData([]);
  };

  const toggleSelectedImage = (image: IAttachment) => {
    if (selectedImages?.find((img) => image.id === img.id))
      setSelectedImages(selectedImages.filter((img) => img.id !== image.id));
    else setSelectedImages((old) => (old ? [...old, image] : [image]));
  };

  const onImageClick = (image: IAttachment) => {
    if (window.location.href.includes('/edit')) {
      toggleSelectedImage(image);
      return;
    }
    setImageModal(image);
    setIsModalOpen(true);
  };

  const toggleSelectedAttachments = (image: IAttachment) => {
    if (selectedAttachments?.find((img) => image.id === img.id))
      setSelectedAttachments(selectedAttachments.filter((img) => img.id !== image.id));
    else setSelectedAttachments((old) => (old ? [...old, image] : [image]));
  };

  const onAttachmentClick = (image: IAttachment) => {
    toggleSelectedAttachments(image);
  };

  const closeImageModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    await handleApi(API_METHOD_DELETE, DELETE_FILE, {
      file_ids: selectedAttachments.concat(selectedImages).map((element) => element.id),
    });
    setShouldLeaveWarning(false);
    history.go(-1);
    setSelectedAttachments([]);
    setSelectedImages([]);
    setShouldUpdate(true);
    setDeleteModalOpen(false);
    refresh();
  };

  //prettier-ignore
  const isPlanLimitExceeded = 
    (amountOfFiles: number) => {
      if (attachmentPageSwitcher === 'image') {
        return amountOfFiles > imagesLeft;
      }
      return amountOfFiles > filesLeft;
    };

  /*prettier-ignore*/
  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1020,
        1020,
        'JPEG',
        200,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'base64'
      );
    });

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const amountOfFiles = files.length;
      // Prevent adding files if limit exceeded
      if (isPlanLimitExceeded(amountOfFiles)) return showPlanExceededToast();
      for (let i = 0; i < amountOfFiles; i++) {
        if (attachmentPageSwitcher === 'file') {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onload = () => {
            onLoadProfile(reader.result, files[i].name, i === amountOfFiles - 1);
          };
        } else {
          if (files.length === 1) {
            getBase64(files[i], handleCropImage);
            return;
          }
          const image = await resizeFile(files[i]);
          onLoadProfile(image as any, `resized${nanoid()}.png`, i === amountOfFiles - 1);
        }
      }
    }

    if (type === 'instrument') {
      refreshMainGalleryData();
      return;
    }
  };

  const handleCropImage = (fileString: string | ArrayBuffer | null, name: string) => {
    name;
    if (typeof fileString === 'string') {
      setCropImage(fileString);
    }
  };

  const handleCancelCrop = () => {
    setCropImage('');
  };

  const handleFinishCrop = async () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const croppedImageDataURL = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      const byteString = atob(croppedImageDataURL.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: 'image/png' });

      // Create a File object from the Blob
      const file = new File([blob], 'cropped_image.png', { type: 'image/png' });

      const resized = await resizeFile(file);
      onLoadProfile(resized as any, `${nanoid()}}.png`, true);
      setCropImage('');
    }
  };

  const onLoadProfile = async (fileString: string | ArrayBuffer | null, name: string, shouldRefreshData?: boolean) => {
    await handleApi(API_METHOD_POST, UPLOAD_ATTACHMENT.replace(':id', id).replace(':type', type), {
      name: name,
      content: fileString,
      type: attachmentPageSwitcher,
    });
    if (!shouldUpdate) setShouldUpdate(true);
    refresh();
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: 1 } });

    if (shouldRefreshData) {
      if (type === 'instrument') {
        refreshMainGalleryData();
        return;
      }

      if (type === 'service') {
        fetchServiceInstrument();
        return;
      }
    }
  };

  const handleDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  const nextPage = () => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: pagination.page + 1 } });
  };

  return {
    files,
    shouldLeaveWarning,
    instrument,
    toggleEditing,
    selectedImages,
    onImageClick,
    imageModal: { image: imageModal, isOpen: isModalOpen },
    closeModal: closeImageModal,
    selectedAttachments,
    onAttachmentClick,
    handleDelete,
    switcherPage: attachmentPageSwitcher === 'image' ? 0 : 1,
    toggleSwitcher,
    type,
    handleUpload,
    projectFiles,
    isDeleteModalOpen,
    toggleDeleteModal: handleDeleteModal,
    cropperRef,
    cropImage,
    handleFinishCrop,
    data: mainGalleryData,
    dataTotal: mainGalleryTotal,
    nextPage,
    handleCancelCrop,
  };
}

export default useAttachments;
