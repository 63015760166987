import { useEffect } from 'react';
import InstrumentCard from '../InstrumentCard';
import InstrumentForm from '../InstrumentForm';
import useInstrument from '../../hooks/useInstrument';
import useGetTranslations from '../../hooks/useGetTranslations';
import useGeneral from '../../hooks/useGeneral';
import AdSection from '../AdSection';
import useSetHeader from '../../hooks/useSetHeader';
import { HOME_PAGE } from '../../constants/routes';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import { ImageCropper } from '../ImageCropper';

function NewInstrument(): JSX.Element {
  const {
    handleImageChange,
    uploadImage,
    register,
    handleSubmitForm,
    selectedType,
    setSelectedType,
    errors,
    onAttachmentChange,
    onPictureChange,
    handleSuggestionChange,
    handleDeleteSelected,
    selectedClient,
    attachmentNr,
    pictureNr,
    canLeave,
    cropImage,
    handleFinishCrop,
    cropperRef,
    handleCancelCrop,
    handleDeleteProfileImage,
    cropUploadImage,
    handleCancelUploadCrop,
    handleFinishUploadCrop,
    uploadCropperRef,
    handleNewBrandNameChange,
    newBrandName,
    handleBrandChange,
  } = useInstrument();
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();
  const { noInstrumentsLeft, showPlanExceededToast } = useMySubscriptionPlan();

  useEffect(() => {
    if (noInstrumentsLeft) {
      showPlanExceededToast();
    }
  }, [noInstrumentsLeft, showPlanExceededToast]);

  useSetHeader(() => {
    handleRedirect(HOME_PAGE);
  }, translations.newInstrumentTitle);

  return (
    <main>
      <AdSection>
        <InstrumentCard
          isSimple={true}
          handleImageChange={handleImageChange}
          handleProfileImageDelete={handleDeleteProfileImage}
          uploadImage={uploadImage}
          dateInsteadModel
          date={null}
        />
        <>
          {cropImage !== '' && (
            <ImageCropper
              cropperRef={cropperRef}
              cropImage={cropImage}
              handleFinishCrop={handleFinishCrop}
              cropText={translations.crop}
              cancelText={translations.cancel}
              handleCancel={handleCancelCrop}
            />
          )}
        </>

        <InstrumentForm
          register={register}
          handleSubmitForm={handleSubmitForm}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          errors={errors}
          onAttachmentChange={onAttachmentChange}
          onPictureChange={onPictureChange}
          onSuggestionChange={handleSuggestionChange}
          selectedClient={selectedClient}
          onDeleteClick={handleDeleteSelected}
          pictureNr={pictureNr}
          attachmentNr={attachmentNr}
          disableSave={noInstrumentsLeft}
          canLeave={canLeave}
          createMode
          uploadCropperRef={uploadCropperRef}
          uploadCropImage={cropUploadImage}
          handleUploadCrop={handleFinishUploadCrop}
          handleCancelUploadCrop={handleCancelUploadCrop}
          handleNewBrandNameChange={handleNewBrandNameChange}
          onMatch={handleBrandChange}
          newBrandName={newBrandName}
        />
      </AdSection>
    </main>
  );
}

export default NewInstrument;
