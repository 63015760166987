import { LOG_OUT } from '../constants/routes';

import { IUseLogout } from '../interfaces/components/Hooks';
import useGeneral from './useGeneral';

function useLogout(): IUseLogout {
  const { handleRedirect } = useGeneral();

  const logOut = () => {
    handleRedirect(LOG_OUT);
    window.location.reload();
  };

  return { logOut };
}

export default useLogout;
