import { useCallback, useEffect, useMemo } from 'react';

import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import { CURRENT_PLAN } from '../constants/routes';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../redux';
import { SET_PLAN_DETAILS } from '../constants/reduxActions';
import { ICurrentPlan } from '../interfaces/components/Plans';
import { ApiResponseTemplate } from '../interfaces/IGeneral';
import { toast } from 'react-toastify';
import LimitExceededNotification, { limitExceededOptions } from '../components/LimitExceededNotification';
import { getPreviousDay } from '../helpers/date';

function useMySubscriptionPlan() {
  const { handleApi } = useApi();
  const subscription = useAppSelector((s) => s.planDetailsReducer);
  const dispatch = useDispatch();

  const fetchPlan = useCallback(async () => {
    const { data } = await handleApi<ApiResponseTemplate<ICurrentPlan>>(API_METHOD_GET, CURRENT_PLAN);
    if (data) {
      dispatch({ type: SET_PLAN_DETAILS, payload: data });
    }
  }, [dispatch]);

  const noInstrumentsLeft = useMemo(() => !!subscription && subscription.resourcesLeft.instrument <= 0, [subscription]);
  const noClientsLeft = useMemo(() => !!subscription && subscription.resourcesLeft.client <= 0, [subscription]);
  const filesLeft = useMemo(() => subscription?.resourcesLeft.file || 0, [subscription]);
  const imagesLeft = useMemo(() => subscription?.resourcesLeft.image || 0, [subscription]);

  useEffect(() => {
    fetchPlan();
  }, []);

  const showPlanExceededToast = useCallback(() => {
    toast.error(<LimitExceededNotification />, limitExceededOptions);
  }, []);

  const canCreateNewClient = () => {
    if (subscription) {
      if (subscription.resourcesLeft.client === 0) {
        return false;
      }
      if (
        new Date(subscription.subscriptionEndAtOriginal.split(' ')[0].replace(/-/g, '/')) < getPreviousDay(new Date())
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const canCreateNewInstrument = () => {
    if (subscription) {
      if (subscription.resourcesLeft.instrument === 0) {
        return false;
      }
      if (
        new Date(subscription.subscriptionEndAtOriginal.split(' ')[0].replace(/-/g, '/')) < getPreviousDay(new Date())
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  return {
    subscription,
    refresh: fetchPlan,
    noInstrumentsLeft,
    noClientsLeft,
    filesLeft,
    imagesLeft,
    showPlanExceededToast,
    canCreateNewClient,
    canCreateNewInstrument,
  };
}

export default useMySubscriptionPlan;
