import { useSelector } from 'react-redux';

import style from '../assets/scss/components/PlanTitle.module.scss';
import { IReduxStore } from '../interfaces/IGeneral';
import { convertKeysToCamelCase, toCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import { useCallback } from 'react';

function PlanTitle(): JSX.Element {
  const { wrapper, title, line } = convertKeysToCamelCase(style);
  const { menugroups, profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslations();
  const menu = menugroups.find((menu) => menu.name === 'plan');
  const takeTour = menu?.menus[0];

  const planTextHelper = useCallback(() => {
    switch (profile?.userType) {
      case 'private':
        return translations.privatePackages;
      case 'company':
        return translations.companyPackages;
      default:
        return translations.planPackages;
    }
  }, [profile?.userType, translations]);

  return (
    <div>
      <section className="small">
        <div className={wrapper}>
          <p className={title}>{planTextHelper()}</p>
          <button
            className="mixed"
            onClick={() => {
              window.open(takeTour?.url, '_self');
            }}
          >
            {translations[toCamelCase(takeTour?.name)]}
          </button>
        </div>
      </section>
      <div className={line} />
    </div>
  );
}

export default PlanTitle;
