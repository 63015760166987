import { Link } from 'react-router-dom';
import { ToastOptions } from 'react-toastify/dist/types';
import useGetTranslations from '../hooks/useGetTranslations';
import { PLANS } from '../constants/routes';

export const limitExceededOptions: ToastOptions = {
  toastId: 'limit-exceeded',
  autoClose: 10000,
};

const LimitExceededNotification = (): JSX.Element => {
  const { translations } = useGetTranslations();

  return Object.keys(translations).length > 0 ? (
    <div>
      {translations.planLimitExceededNotification} <Link to={PLANS}>{translations.mySubscriptionTitle}</Link>.
    </div>
  ) : (
    <div>
      <p>You reached the limit of your plan.</p>
    </div>
  );
};

export default LimitExceededNotification;
