import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useApi } from './useApi';
import { API_METHOD_POST } from '../constants/api';
import { FORGOT_PASSWORD_API } from '../constants/routes';
import useYupValidationResolver from './useYupValidationResolver';
import { getForgotPasswordValidationSchema } from '../helpers/validationSchemaHelper';
import { IUseForgotPassword } from '../interfaces/components/Hooks';
import useGetTranslations from './useGetTranslations';

function useForgotPassword(): IUseForgotPassword {
  const resolver = useYupValidationResolver(getForgotPasswordValidationSchema());
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver });
  const { translations } = useGetTranslations();
  const { handleApi } = useApi();

  async function sendData(formData: any) {
    const { code } = await handleApi(API_METHOD_POST, FORGOT_PASSWORD_API, formData);
    if (code === 200) {
      toast.success(translations.resetPasswordEmailSent);
      return;
    }
  }

  return { register, errors, handleSubmitForm: handleSubmit(sendData), reset };
}

export default useForgotPassword;
