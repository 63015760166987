import Select from 'react-select';
import { Controller } from 'react-hook-form';

import style from '../assets/scss/components/CountryDropdown.module.scss';
import { ICountry, ICountryDropdown } from '../interfaces/components/Hooks';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import { countryDropdownStyle, countryDropdownStyleWithWrap } from '../constants/countryDropdownStyle';

function CountryDropdown(props: ICountryDropdown): JSX.Element {
  const { control, countries, placeholder, title, disabled, textWithWrap } = props;
  const { text, inputContainer } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  return (
    <div className={inputContainer}>
      {title && (
        <label className={text} htmlFor="country">
          {translations.myAccountFormCountry} *
        </label>
      )}
      <Controller
        control={control}
        name="country"
        id="country"
        render={({ onChange, value, name, ref }) => (
          <Select
            ref={ref}
            classNamePrefix="react-select"
            className="react-select"
            options={countries}
            name={name}
            isDisabled={disabled}
            placeholder={placeholder}
            value={countries.find((country: ICountry) => country.label === value)}
            onChange={(country: ICountry | null) => onChange(country?.label)}
            styles={textWithWrap ? countryDropdownStyleWithWrap : countryDropdownStyle}
          />
        )}
      />
    </div>
  );
}
export default CountryDropdown;
