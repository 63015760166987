import { BsCheck } from 'react-icons/bs';

import style from '../assets/scss/components/FilterElement.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useFilterElement from '../hooks/useFilterElement';
import SuggestionSearch from './SuggestionSearch';
import useGetTranslation from '../hooks/useGetTranslations';
import { CATEGORY_TYPES } from '../constants/filter';
import { ICategorySelect } from '../interfaces/components/Filter';
import { IFilterElement } from '../interfaces/components/FilterElement';
import { useRef } from 'react';
import { ADD_FILTER, RESET_ACTUAL_FILTER, RESET_FILTER, SET_FILTER } from '../constants/reduxActions';

function FilterElement({
  name,
  data,
  field,
  isNumber,
  isSelect,
  needsSpiltAtValues,
  addFilterRedux = ADD_FILTER,
  setFilterRedux = SET_FILTER,
  resetActualFilterRedux = RESET_ACTUAL_FILTER,
  resetFilterRedux = RESET_FILTER,
}: IFilterElement): JSX.Element {
  const { isSelected, toggleSelected, onMatch, handleCategoryChange, category } = useFilterElement(
    field,
    isNumber,
    addFilterRedux,
    setFilterRedux,
    resetActualFilterRedux,
    // eslint-disable-next-line prettier/prettier
    resetFilterRedux
  );
  const { radio, icon, title, searchContainer, line, inpContainer, iconInactive, categoryContainer, spacer, flex } =
    convertKeysToCamelCase(style);
  const { translations } = useGetTranslation();
  const suggestionSearchParentRef = useRef<HTMLDivElement>(null);

  return isSelect ? (
    <>
      <div>
        <p className={title}>{name}</p>
        <div className={flex}>
          <div className={spacer} />
          <select className={`${categoryContainer} bubble-no-p`} onChange={handleCategoryChange} value={category}>
            {CATEGORY_TYPES.map((category: ICategorySelect) => (
              <option key={category.code} value={category.code}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  ) : (
    <div ref={suggestionSearchParentRef}>
      <p className={title}>{name}</p>
      <div className={`radio ${radio}`}>
        <div className="radio-container">
          <BsCheck
            className={`${!isSelected ? icon : iconInactive} radio-item ${!isSelected && 'radio-item-selected'}`}
            onClick={() => {
              toggleSelected(false);
              onMatch();
            }}
          />
        </div>
        <p>{translations.all}</p>
      </div>
      <div className={inpContainer}>
        <div
          className={`radio ${radio}`}
          onClick={() => {
            toggleSelected(true);
          }}
        >
          <div className="radio-container">
            <BsCheck
              className={`${isSelected ? icon : iconInactive} radio-item ${isSelected && 'radio-item-selected'}`}
            />
          </div>
        </div>
        {data && (
          <div className={searchContainer}>
            <SuggestionSearch
              onMatch={onMatch}
              suggestions={data}
              placeholder={translations.filterPlaceholder}
              topPosition={suggestionSearchParentRef.current?.offsetTop}
              isFilter
              customRef={suggestionSearchParentRef}
              needsSpiltAtValues={needsSpiltAtValues}
            />
          </div>
        )}
      </div>

      <hr className={line} />
    </div>
  );
}

export default FilterElement;
