import Modal from './Modal';
import style from '../assets/scss/components/DeleteAccountModal.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IAlertModal } from '../interfaces/components/AlertModal';

function AlertModal(props: IAlertModal): JSX.Element {
  const { isOpen, header, description, leftButton, rightButton, onLeftButtonClick, onRightButtonClick, onClose } =
    props;
  const { btnContainer, wrapper, title, content } = convertKeysToCamelCase(style);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      content={
        <div className={wrapper}>
          <p className={title}>{header}</p>
          <p className={content}>{description}</p>
          <div className={btnContainer}>
            <button className="primary" onClick={onLeftButtonClick} type="button">
              {leftButton}
            </button>
            <button className="blue" onClick={onRightButtonClick} type="button">
              {rightButton}
            </button>
          </div>
        </div>
      }
    />
  );
}

export default AlertModal;
