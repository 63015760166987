import { useState } from 'react';

import useGeneral from './useGeneral';
import { useHistory } from 'react-router';
import { Location } from 'history';

function useLeaveModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [location, setLocation] = useState<string>('');
  const { handleRedirect } = useGeneral();
  const history = useHistory<{ from?: string }>();

  const onLeaveAttempt = (newLocation: Location) => {
    if (location) {
      return true;
    }
    setLocation(newLocation.pathname);
    setIsModalVisible(!isModalVisible);
    return false;
  };

  const onCancel = () => {
    setLocation('');
    setIsModalVisible(!isModalVisible);
  };

  const redirectToLocation = () => {
    if (location && history.location?.state?.from !== location) {
      handleRedirect(location);
    } else {
      history.goBack();
    }
  };

  return { onLeaveAttempt, isModalVisible, redirectToLocation, onCancel };
}

export default useLeaveModal;
