import style from '../assets/scss/components/ChooseProject.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import SuggestionSearch from './SuggestionSearch';
import useChooseProject from '../hooks/useChooseProject';
import { IChooseProject } from '../interfaces/components/Project';
import useGetTranslation from '../hooks/useGetTranslations';

function ChooseProject(props: IChooseProject): JSX.Element {
  const { row, text, line, inpContainer, gold, noSpace, input, inp } = convertKeysToCamelCase(style);
  const { onMatch, instrumentId, isEditing, projectId, newProjectName, handleNewProjectNameChange, project } = props;
  const { projects } = useChooseProject(instrumentId);
  const { translations } = useGetTranslation();

  return (
    <div>
      {isEditing ? (
        <>
          <p className={`bubble ${inp}`}>{project?.name}</p>
          <>
            <div className={row}>
              <p className={text}>{translations.projectChoose}</p>
              <div className={inpContainer}>
                <SuggestionSearch onMatch={onMatch} suggestions={projects} />
              </div>
            </div>
            <div className={`${row} ${noSpace}`}>
              <p className={`${text} ${gold}`}>{translations.or}</p>
              <div className={line} />
            </div>
            <div className={row}>
              <p className={text}>{translations.projectAddNew}</p>
              <div className={inpContainer}>
                <input
                  type="text"
                  className={`primary ${input}`}
                  value={newProjectName}
                  onChange={handleNewProjectNameChange}
                  placeholder={translations.beShort}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                />
              </div>
            </div>
          </>
        </>
      ) : (
        <p className={`bubble ${inp}`}>{projects.find((prj) => prj.id === projectId)?.name}</p>
      )}
    </div>
  );
}

export default ChooseProject;
