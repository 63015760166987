export const SET_STATE = 'SET_STATE';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_PROMISE = 'SET_PROMISE';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_MENUS = 'SET_MENUS';
export const SET_HEADER = 'SET_HEADER';
export const SET_CLIENTS = 'SET_CLIENTS';
export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_INSTRUMENT = 'ADD_INSTRUMENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const SET_INSTRUMENTS = 'SET_INSTRUMENTS';
export const SET_INSTRUMENTS_CLIENTS = 'SET_INSTRUMENTS_CLIENTS';
export const SET_ORDER = 'SET_ORDER';
export const RESET_ORDER = 'RESET_ORDER';
export const SET_GLOBAL_SEARCH = 'SET_GLOBAL_SEARCH';
export const SET_GLOBAL_SEARCH_TEXT_VALUE = 'SET_GLOBAL_SEARCH_TEXT_VALUE';
export const SET_GLOBAL_SEARCH_ON_BACK = 'SET_GLOBAL_SEARCH_ON_BACK';
export const ADD_FILTER = 'ADD_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const SET_FILTER = 'SET_FILTER';
export const ADD_ACTUAL_FILTER = 'ADD_ACTUAL_FILTER';
export const RESET_ACTUAL_FILTER = 'RESET_ACTUAL_FILTER';
export const UPDATE_ACTUAL_FILTER = 'UPDATE_ACTUAL_FILTER';
export const SET_CUSTOM_ADS = 'SET_CUSTOM_ADS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SHOULD_LEAVE = 'SET_SHOULD_LEAVE';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_ACTIVE_COUNTRIES = 'SET_ACTIVE_COUNTRIES';
export const SET_PLAN_DETAILS = 'SET_PLAN_DETAILS';
export const SET_ATTACHMENTS_PAGE_SWITCHER = 'SET_ATTACHMENTS_PAGE_SWITCHER';
export const SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER = 'SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER';
export const SET_CLIENT_SERVICES_PAGE_SWITCHER = 'SET_CLIENT_SERVICES_PAGE_SWITCHER';
export const SET_PROJECT_LIST_REFRESH = 'SET_PROJECT_LIST_REFRESH';
export const SET_BRAND_LIST_REFRESH = 'SET_BRAND_LIST_REFRESH';
export const SET_TUNER_LIST_REFRESH = 'SET_TUNER_LIST_REFRESH';
export const SET_INTRUMENT_LIST_REFRESH = 'SET_INTRUMENT_LIST_REFRESH';
export const SET_CLIENT_LIST_REFRESH = 'SET_CLIENT_LIST_REFRESH';
export const SET_HAMBURGER_REF = 'SET_HAMBURGER_REF';
export const SET_SUGGESTION_OPENED = 'SET_SUGGESTION_OPENED';
export const SET_OPEN_ATTACHMENT_INSTEAD = 'SET_OPEN_ATTACHMENT_INSTEAD';
export const SET_OPEN_NEW_SERVICE_INSTEAD = 'SET_OPEN_NEW_SERVICE_INSTEAD';
export const SET_OPEN_NEW_PROJECT_INSTEAD = 'SET_OPEN_NEW_PROJECT_INSTEAD';
export const SET_OPEN_NEW_REMINDER_INSTEAD = 'SET_OPEN_NEW_REMINDER_INSTEAD';
export const SET_RESET_GLOBAL_SEARCH = 'SET_RESET_GLOBAL_SEARCH';
export const SET_ACTIVE_SERVICES = 'SET_ACTIVE_SERVICES';
export const SET_ACTIVE_PROJECTS = 'SET_ACTIVE_PROJECTS';
export const SET_INSTRUMENT_SERVICES_FILTER = 'SET_INSTRUMENT_SERVICES_FILTER';
export const SET_INSTRUMENT_SERVICES_ORDER = 'SET_INSTRUMENT_SERVICES_ORDER';
export const ADD_INSTRUMENT_SERVICES_FILTER = 'ADD_INSTRUMENT_SERVICES_FILTER';
export const SET_INSTRUMENT_SERVICES_ACTUAL_FILTER = 'SET_INSTRUMENT_SERVICES_ACTUAL_FILTER';
export const UPDATE_INSTRUMENT_SERVICES_ACTUAL_FILTER = 'UPDATE_INSTRUMENT_SERVICES_ACTUAL_FILTER';
export const RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER = 'RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER';
export const RESET_INSTRUMENT_SERVICES_FILTER = 'RESET_INSTRUMENT_SERVICES_FILTER';
