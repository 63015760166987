import ClientsCard from '../ClientsCard';
import ClientsEditForm from '../ClientsEditForm';
import useEditClients from '../../hooks/useEditClients';
import EditHeader from '../EditHeader';
import AdSection from '../AdSection';
import useGetTranslations from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import UserIdComponent from '../UserIdComponent';
import useClientsCard from '../../hooks/useClientsCard';
import { MenuBar } from '../MenuBar';
import { MdPiano } from 'react-icons/md';
import { GiAutoRepair } from 'react-icons/gi';
import { BiCalendar } from 'react-icons/bi';
import { ImAttachment } from 'react-icons/im';
import { BsCameraFill } from 'react-icons/bs';
import {
  ADD_ACTUAL_FILTER,
  ADD_FILTER,
  SET_ATTACHMENTS_PAGE_SWITCHER,
  SET_CLIENT_SERVICES_PAGE_SWITCHER,
  SET_OPEN_ATTACHMENT_INSTEAD,
} from '../../constants/reduxActions';
import {
  CLIENTS_SERVICES,
  ATTACHMENTS_PAGE,
  HOME_PAGE,
  INSTRUMENT_DETAILS,
  NEW_INSTRUMENT,
  EDIT_CLIENT,
} from '../../constants/routes';
import { useDispatch } from 'react-redux';
import useGeneral from '../../hooks/useGeneral';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import { toast } from 'react-toastify';
import LimitExceededNotification, { limitExceededOptions } from '../LimitExceededNotification';
import style from '../../assets/scss/pages/ClientDetails.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import { professionChecker, professionTranslation } from '../../utils/roleChecker';
import { useMemo, useState } from 'react';
import AlertModal from '../AlertModal';

function ClientDetails(): JSX.Element {
  const {
    handleAddressClick,
    client,
    selectedImage,
    handleImageChange,
    onDelete,
    selectedInstruments,
    handleInstrumentClick,
    toggleEditing,
  } = useEditClients();
  const dispatch = useDispatch();
  const { handleRedirect } = useGeneral();
  const { canCreateNewInstrument } = useMySubscriptionPlan();
  const { handleEmailOpen, handlePhoneOpen, handleSMSOpen } = useClientsCard();
  const { translations } = useGetTranslations();
  const { instrumentCount } = convertKeysToCamelCase(style);
  const isTuner = useMemo(() => professionChecker(), []);
  const [isModalOpen, setModalOpen] = useState(false);

  useSetHeader(() => {}, translations[professionTranslation('clientTitle')]);

  return (
    <main>
      <EditHeader
        isEditing={false}
        toggleEditing={toggleEditing}
        handleDelete={onDelete}
        deleteTitle={translations.deleteClientDescription}
        deleteButtonText={translations.deleteClient}
      />
      <AdSection topPadding>
        <UserIdComponent clientId={client?.id} />
        <ClientsCard
          isSimple={true}
          client={client}
          selectedImage={selectedImage}
          handleImageChange={handleImageChange}
          isEditing={false}
        />
        <>
          {isTuner && (
            <MenuBar
              items={[
                {
                  icon: (
                    <>
                      <MdPiano />
                      {selectedInstruments.length > 1 && (
                        <p className={instrumentCount}>{`(${selectedInstruments.length})`}</p>
                      )}
                    </>
                  ),
                  onClick: () => {
                    if (!client) {
                      return;
                    }
                    if (selectedInstruments.length === 0) {
                      setModalOpen(true);
                      return;
                    }
                    if (selectedInstruments.length === 1) {
                      handleRedirect(INSTRUMENT_DETAILS.replace(':id', selectedInstruments[0].id.toString()));
                      return;
                    }

                    dispatch({
                      type: ADD_FILTER,
                      payload: { clients: { id: client.id, name: `${client.firstName} ${client.lastName}` } },
                    });
                    dispatch({ type: ADD_ACTUAL_FILTER });
                    handleRedirect(HOME_PAGE);
                  },
                },
                {
                  icon: <GiAutoRepair />,
                  onClick: () => {
                    if (!client) {
                      return;
                    }
                    if (selectedInstruments.length === 0) {
                      return;
                    }
                    dispatch({ type: SET_CLIENT_SERVICES_PAGE_SWITCHER, payload: 0 });
                    handleRedirect(CLIENTS_SERVICES.replace(':id', client.id.toString()));
                  },
                },
                {
                  icon: <BiCalendar />,
                  onClick: () => {
                    if (!client) {
                      return;
                    }
                    if (selectedInstruments.length === 0) {
                      return;
                    }
                    dispatch({ type: SET_CLIENT_SERVICES_PAGE_SWITCHER, payload: 2 });
                    handleRedirect(CLIENTS_SERVICES.replace(':id', client.id.toString()));
                  },
                },
                {
                  icon: <BsCameraFill />,
                  onClick: () => {
                    if (!client) {
                      return;
                    }
                    if (selectedInstruments.length === 1) {
                      dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'image' });
                      //prettier-ignore
                      handleRedirect(
                        // eslint-disable-next-line prettier/prettier
                        ATTACHMENTS_PAGE.replace(':id', selectedInstruments[0].id.toString()).replace(
                          ':type',
                          'instrument'
                        )
                      );
                      return;
                    }
                    if (selectedInstruments.length > 1) {
                      //hanlde here the redirect to files & images. prevent default action
                      dispatch({
                        type: ADD_FILTER,
                        payload: { clients: { id: client.id, name: `${client.firstName} ${client.lastName}` } },
                      });
                      dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'image' });
                      dispatch({ type: ADD_ACTUAL_FILTER });
                      dispatch({ type: SET_OPEN_ATTACHMENT_INSTEAD, payload: true });
                      handleRedirect(HOME_PAGE);
                      return;
                    }
                  },
                },
                {
                  icon: <ImAttachment />,
                  onClick: () => {
                    if (!client) {
                      return;
                    }
                    if (selectedInstruments.length === 1) {
                      //hanlde here the redirect to files & images. prevent default action
                      dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'file' });
                      //prettier-ignore
                      handleRedirect(
                        // eslint-disable-next-line prettier/prettier
                        ATTACHMENTS_PAGE.replace(':id', selectedInstruments[0].id.toString()).replace(
                          ':type',
                          'instrument'
                        )
                      );
                      return;
                    }
                    if (selectedInstruments.length > 1) {
                      //hanlde here the redirect to files & images. prevent default action
                      dispatch({
                        type: ADD_FILTER,
                        payload: { clients: { id: client.id, name: `${client.firstName} ${client.lastName}` } },
                      });
                      dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'file' });
                      dispatch({ type: ADD_ACTUAL_FILTER });
                      dispatch({ type: SET_OPEN_ATTACHMENT_INSTEAD, payload: true });
                      handleRedirect(HOME_PAGE);
                      return;
                    }
                  },
                },
              ]}
            />
          )}
        </>
        <AlertModal
          isOpen={isModalOpen ?? false}
          onClose={() => {
            setModalOpen(false);
          }}
          header={translations.newInstrumentQuestionTitle}
          description={translations.newInstrumentQuestionDescription}
          leftButton={translations.newInstrumentQuestionLeftButton}
          rightButton={translations.newInstrumentQuestionRightButton}
          onLeftButtonClick={() => {
            if (!client) {
              return;
            }

            setModalOpen(false);
            handleRedirect(EDIT_CLIENT.replace(':id', client.id.toString()));
          }}
          onRightButtonClick={() => {
            if (!client) {
              return;
            }

            if (!canCreateNewInstrument()) {
              toast.error(<LimitExceededNotification />, limitExceededOptions);
              return;
            }
            setModalOpen(false);
            handleRedirect(NEW_INSTRUMENT + `?clientId=${client.id}`);
          }}
        />
        <ClientsEditForm
          handleAddressClick={handleAddressClick}
          client={client}
          selectedInstruments={selectedInstruments}
          handleInstrumentClick={handleInstrumentClick}
          handleEmailOpen={handleEmailOpen}
          handlePhoneOpen={handlePhoneOpen}
          handleSmsOpen={handleSMSOpen}
        />
      </AdSection>
    </main>
  );
}

export default ClientDetails;
