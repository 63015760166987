import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SET_HEADER } from '../constants/reduxActions';

function useSetHeader(onBack: () => void, title: string, forcedBack?: boolean, showBack?: boolean): void {
  const dispatch = useDispatch();

  useEffect(() => {
    if (title)
      dispatch({
        type: SET_HEADER,
        payload: {
          onBack: onBack,
          title: title,
          showBack: showBack ?? true,
          forcedBack: forcedBack ? forcedBack : false,
        },
      });

    return backToNormal;
  }, [title, showBack]);

  const backToNormal = () => {
    dispatch({
      type: SET_HEADER,
      payload: { onBack, title, showBack: false, forcedBack: forcedBack ? forcedBack : false },
    });
  };
}

export default useSetHeader;
