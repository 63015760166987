import { GiSandsOfTime } from 'react-icons/gi';
import { IoLogoUsd } from 'react-icons/io';

import style from '../assets/scss/components/ClientsProjectDetails.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import IClientsProjectDetails from '../interfaces/ProjectDetails';
import useProfile from '../hooks/useProfile';
import completed from '../assets/images/completed.svg';
import scheduled from '../assets/images/scheduled.svg';

function InstrumentsProjectDetails(props: IClientsProjectDetails): JSX.Element {
  const { project, showInstrumentData } = props;
  const {
    wrapper,
    description,
    row,
    box,
    icon,
    text,
    instrumentCategory,
    title,
    imageRow,
    dateIcon,
    dateText,
    dateSection,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { profile } = useProfile();

  return (
    <div className={wrapper}>
      <div className={description}>
        {showInstrumentData && (
          <span className={title}>
            {project.instrument.brand}
            {project.instrument.model ? `, ${project.instrument.model}` : ''}{' '}
            <span className={instrumentCategory}>{project.instrument.category}</span>
          </span>
        )}
        <p>{project.title}</p>
        <div className={dateSection}>
          <div className={imageRow}>
            <img src={scheduled} alt="" className={dateIcon} /> <p className={dateText}>{project.endingDate ?? '-'}</p>
          </div>
          <div className={imageRow}>
            <img src={completed} alt="" className={dateIcon} />{' '}
            <p className={dateText}>{project.startingDate ?? '-'}</p>
          </div>
        </div>
      </div>
      <div className={`${description} ${box}`}>
        <div className={row}>
          <IoLogoUsd className={icon} />
          <p className={`bubble ${text}`}>{project.sumPrice}</p>
          <p>{profile?.currency}</p>
        </div>
        <div className={row}>
          <GiSandsOfTime className={icon} />
          <p className={`bubble ${text}`}>{project.sumDuration}</p>
          <p>{translations.projectCardHours}</p>
        </div>
      </div>
    </div>
  );
}

export default InstrumentsProjectDetails;
