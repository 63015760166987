import style from '../assets/scss/components/Footer.module.scss';
import { convertKeysToCamelCase, toCamelCase } from '../helpers/baseHelper';
import paymentLogo from '../assets/images/visa_mastercard.png';
import stripeLogo from '../assets/images/stripe_logo.jpg';
import useFooter from '../hooks/useFooter';
import useGetTranslations from '../hooks/useGetTranslations';
import { LOGIN_PAGE, PROFILE } from '../constants/routes';
import useGeneral from '../hooks/useGeneral';
import { FooterProps } from '../interfaces/components/Footer';

function Footer(props: FooterProps): JSX.Element {
  const { fromBurger } = props;
  const {
    wrapper,
    container,
    imagePayment,
    imageStripe,
    leftContainer,
    text,
    wrapperNoMargin,
    marginDiv,
    imageContainer,
    linkWrapper,
    contactLink,
  } = convertKeysToCamelCase(style);
  const { privacy, terms, refund, contact } = useFooter();
  const { translations } = useGetTranslations();
  const { isAtRoute } = useGeneral();
  const primary = isAtRoute(LOGIN_PAGE) || fromBurger;

  if (!fromBurger && !isAtRoute(LOGIN_PAGE) && !isAtRoute(PROFILE)) {
    return <div className={marginDiv} />;
  }

  return (
    <footer>
      <section className={primary ? `small ${wrapper} ${wrapperNoMargin}` : `small ${wrapper}`}>
        <div className={container}>
          <div className={linkWrapper}>
            <a className={text} href={privacy?.url} target="_blank" rel="noreferrer">
              {translations[toCamelCase(privacy?.name)]}
            </a>
            <a className={text} href={terms?.url} target="_blank" rel="noreferrer">
              {translations[toCamelCase(terms?.name)]}
            </a>
            <a className={text} href={refund?.url} target="_blank" rel="noreferrer">
              {translations[toCamelCase(refund?.name)]}
            </a>
          </div>
          <p>{translations.copyright}</p>
        </div>
        <div className={`${container} ${leftContainer}`}>
          <a className={contactLink} href={contact?.url} target="_blank" rel="noreferrer">
            {translations[toCamelCase(contact?.name)]}
          </a>
          <div className={imageContainer}>
            <img src={stripeLogo} className={imageStripe} />
            <img src={paymentLogo} className={imagePayment} />
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
