import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RESET_ORDER, SET_ORDER, SET_PAGINATION } from '../constants/reduxActions';

function useSortIcon(title?: string) {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ORDER });
    };
  }, []);

  const toggleVisible = useCallback(() => {
    setIsVisible((prev) => !prev);
  }, [setIsVisible]);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleTitleAscend = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: title, orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleTitleDescend = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: title, orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleNewest = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'created_at', orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleOldest = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'created_at', orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleNewestDate = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'date', orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleOldestDate = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'date', orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleNewestReminderDate = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'upcoming_reminder_date', orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleOldestReminderDate = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'upcoming_reminder_date', orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleLastAsc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'last_date', orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleLastDesc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'last_date', orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleNextAsc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'next_date', orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleNextDesc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'next_date', orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleProjectDateAsc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'starting_date', orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleProjectDateDesc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'starting_date', orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleProjectEndingDateAsc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'ending_date', orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleProjectEndingDateDesc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'ending_date', orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleScheduledDateAsc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'scheduledDate', orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleScheduledDateDesc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'scheduledDate', orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleTunerAsc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'tuners.id', orderByDirection: 'ASC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  const handleTunerDesc = () => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'tuners.id', orderByDirection: 'DESC' } });
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });

    toggleVisible();
  };

  return {
    handleNextDesc,
    handleNextAsc,
    handleLastDesc,
    handleLastAsc,
    isVisible,
    toggleVisible,
    handleTitleAscend,
    handleTitleDescend,
    handleOldest,
    handleNewest,
    handleProjectDateAsc,
    handleProjectDateDesc,
    handleProjectEndingDateAsc,
    handleProjectEndingDateDesc,
    handleOldestDate,
    handleNewestDate,
    handleOldestReminderDate,
    handleNewestReminderDate,
    handleScheduledDateAsc,
    handleScheduledDateDesc,
    handleTunerAsc,
    handleTunerDesc,
    close,
  };
}

export default useSortIcon;
