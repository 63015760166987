import style from '../assets/scss/components/InstrumentDetailsForm.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import Title from './Title';
import LatestInfo from './LatestInfo';
import TimeAndAmount from './TimeAndAmount';
import { IInstrumentDetail } from '../interfaces/components/Instrument';
import useGetTranslations from '../hooks/useGetTranslations';
import useProfile from '../hooks/useProfile';

function InstrumentDetailForm(instrument: IInstrumentDetail): JSX.Element {
  const { row, attributePrimary, attributeIndented, line, bolded } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { profile } = useProfile();

  return (
    <form>
      <div className={line} />
      <div className={row}>
        <p className={`${attributeIndented} ${bolded}`}>{translations.instrumentDetailFormRoom}</p>
        <p className={`bubble-instrument`}>{instrument?.room}</p>
      </div>
      <div className={row}>
        <p className={attributeIndented}>{translations.instrumentDetailFormSize}</p>
        <p className="bubble">{instrument?.size === 0 ? '-' : instrument.size}</p>
        <p>{profile?.lengthUnit}</p>
      </div>
      <div className={row}>
        <p className={attributeIndented}>{translations.instrumentDetailFormColor}</p>
        <p className="bubble-instrument">{instrument?.color}</p>
      </div>
      <div className={row}>
        <p className={attributeIndented}>{translations.instrumentDetailFormSerial}</p>
        <p className="bubble-instrument">{instrument?.serialNumber}</p>
      </div>
      <div className={row}>
        <p className={attributeIndented}>{translations.instrumentDetailFormAge}</p>
        <p className="bubble-instrument">{instrument?.age}</p>
      </div>
      <div className={row}>
        <p className={attributeIndented}>{translations.instrumentDetailFormDateOfPurchase}</p>
        <p className="bubble-instrument">{instrument?.dateOfPurchase}</p>
      </div>
      <div className={row}>
        <p className={attributeIndented}>{translations.instrumentDetailFormWarranty}</p>
        <p className="bubble-instrument">{instrument?.warranty}</p>
      </div>
      <Title title={translations.instrumentDetailFormServiceTitle} />
      <div className={row}>
        <p className={attributeIndented}>{translations.instrumentDetailFormLast}</p>
        <p className="bubble-date">{instrument?.latestService?.lastServiceDate}</p>
      </div>
      <div className={row}>
        <p className={`${attributeIndented} ${attributePrimary}`}>{translations.instrumentDetailFormNext}</p>
        <p className="bubble-date">{instrument?.latestService?.nextServiceDate}</p>
      </div>
      {instrument?.latestService?.nextReminderAt && (
        <div className={row}>
          <p className={attributeIndented}>{translations.instrumentDetailFormReminder}</p>
          <p className="bubble-date">{instrument?.latestService?.nextReminderAt}</p>
        </div>
      )}
      <LatestInfo
        temperature={instrument?.latestService?.temperature}
        frequency={instrument?.latestService?.frequency}
        humidity={instrument?.latestService?.humidity}
      />
      <TimeAndAmount
        price={instrument.priceTotal}
        time={instrument.durationTotal}
        timeUnit={translations.timeAndAmountDays}
      />
      <Title title={translations.instrumentDetailNoteTitle} />
      <textarea disabled rows={8} value={instrument.note} />
      <div className="last-element" />
    </form>
  );
}

export default InstrumentDetailForm;
