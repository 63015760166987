import React from 'react';
import style from '../assets/scss/components/LanguageBar.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useLanguage from '../hooks/useLanguage';
import { LANGUAGES } from '../constants/languages';

function LanguageBar(): JSX.Element {
  const { wrapper, group, flag, active } = convertKeysToCamelCase(style);
  const { handleLanguage, language } = useLanguage();

  return (
    <div className={wrapper}>
      {LANGUAGES.map((languageItem) => {
        return (
          <div
            key={languageItem.code}
            className={languageItem.code === language ? active : group}
            onClick={() => handleLanguage(languageItem.code)}
          >
            <img src={languageItem.image} alt={languageItem.code} className={flag} />
            <p>{languageItem.text}</p>
          </div>
        );
      })}
    </div>
  );
}

export default LanguageBar;
