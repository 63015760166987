import Plan from './Plan';
import useStripeWebCheckout from '../hooks/useStripeWebCheckout';
import { IFetchPlans, IPlan } from '../interfaces/components/Plans';

function FetchPlans(props: IFetchPlans): JSX.Element {
  const { plans } = props;
  const { onSubscribePlan } = useStripeWebCheckout();

  return (
    <div>
      {plans.map((plan: IPlan) => (
        <Plan key={plan.id} plan={plan} onChooseClick={onSubscribePlan} />
      ))}
    </div>
  );
}

export default FetchPlans;
