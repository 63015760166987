import { ISuggestion } from '../interfaces/components/SuggestionSearch';
import { IReactSelectOptions } from '../interfaces/IGeneral';

export const suggestionArrayToSelectArray = (data: ISuggestion[]): IReactSelectOptions[] => {
  return data.map((element: ISuggestion) => {
    return { label: element.name, value: element.id };
  });
};

export const selectArrayToSuggestionArray = (data: IReactSelectOptions[]): ISuggestion[] => {
  return data.map((element: IReactSelectOptions) => {
    return { name: element.label, id: element.value };
  });
};

export const suggestionToSelect = (data: ISuggestion): IReactSelectOptions => {
  return { label: data.name, value: data.id };
};

export const selectToSuggestion = (data: IReactSelectOptions | null): ISuggestion | undefined => {
  if (data === null) {
    return undefined;
  }
  return { name: data.label, id: data.value };
};
