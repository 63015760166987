import { useDispatch, useSelector } from 'react-redux';
import { MdArrowBackIosNew } from 'react-icons/md';

import Title from './Title';
import style from '../assets/scss/components/Header.module.scss';
import { convertKeysToCamelCase, toCamelCase } from '../helpers/baseHelper';
import pianoImage from '../assets/images/piano_small.webp';
import useGetTranslations from '../hooks/useGetTranslations';
import { IReduxStore } from '../interfaces/IGeneral';
import useHeader from '../hooks/useHeader';
import Footer from './Footer';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MenuPoints from './MenuPoints';
import { TOKEN_DATA_KEY } from '../constants/login';
import HamburgerBase from './HamburgerBase';
import { PROFILE } from '../constants/routes';
import { RESET_ORDER, SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, UPDATE_ACTUAL_FILTER } from '../constants/reduxActions';

function Header(): JSX.Element {
  const { header, actualFilter } = useSelector((store: IReduxStore) => store.defaultReducer);
  const {
    wrapper,
    logo,
    section,
    logoBack,
    modal,
    btnContainer,
    btn,
    tabletView,
    headerLeft,
    burgerMenuPoints,
    burgerMenuPoint,
    title,
    headerMenu,
    horizontalLine,
    line,
    footerWrapper,
    burgerMenuPointMobile,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { handleRedirect, isOpen, toggleOpen, menus } = useHeader();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem(TOKEN_DATA_KEY);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <header className={wrapper}>
      <section className={tabletView}>
        <div className={burgerMenuPoints}>
          {menus.map((menu, index) => (
            <div key={menu.url + menu.name} className={burgerMenuPoint}>
              <a className={burgerMenuPoint} href={menu.url} target="_blank" rel="noreferrer">
                <p>{translations[toCamelCase(menu.name)]}</p>{' '}
              </a>
              {menus.length !== index + 1 && <p className={horizontalLine}>|</p>}
            </div>
          ))}
        </div>
        <div className={line} />
        <div className={headerMenu}>
          <div className={headerLeft}>
            {header.showBack ? (
              <MdArrowBackIosNew
                className={logoBack}
                onClick={() => {
                  if (window.location.href.includes('/my-subscription')) {
                    handleRedirect(PROFILE);
                    return;
                  }
                  if (
                    window.location.href.includes('/instrument-services/') ||
                    window.location.href.includes('/client-services/')
                  ) {
                    const updatedFilters = actualFilter;
                    delete updatedFilters['title'];
                    if (window.location.href.includes('/client-services/')) {
                      delete updatedFilters['instruments'];
                    }
                    dispatch({ type: UPDATE_ACTUAL_FILTER, payload: updatedFilters });
                    dispatch({ type: RESET_ORDER });
                  }
                  if (window.location.href.includes('/services/')) {
                    // because of the small menu on service detail (reminder back)
                    dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 0 });
                  }
                  if (window.location.href.includes('/projects/')) {
                    dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 1 });
                  }
                  history.goBack();
                }}
              />
            ) : (
              <img src={pianoImage} className={logo} />
            )}
            <p className={title}>{header.showBack ? header.title : translations.title}</p>
          </div>
          {token && (
            <div>
              <MenuPoints />
            </div>
          )}
        </div>
      </section>

      <section className={`small ${section}`}>
        {header.showBack ? (
          <MdArrowBackIosNew
            className={logoBack}
            onClick={() => {
              if (window.location.href.includes('/my-subscription')) {
                handleRedirect(PROFILE);
                return;
              }
              if (
                window.location.href.includes('/instrument-services/') ||
                window.location.href.includes('/client-services/')
              ) {
                const updatedFilters = actualFilter;
                delete updatedFilters['title'];
                if (window.location.href.includes('/client-services/')) {
                  delete updatedFilters['instruments'];
                }
                dispatch({ type: UPDATE_ACTUAL_FILTER, payload: updatedFilters });
                dispatch({ type: RESET_ORDER });
              }
              if (window.location.href.includes('/services/')) {
                dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 0 });
              }
              if (window.location.href.includes('/projects/')) {
                dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 1 });
              }
              history.goBack();
            }}
          />
        ) : (
          <img src={pianoImage} className={logo} />
        )}
        <p>{header.showBack ? header.title : translations.title}</p>
        <HamburgerBase
          childrenContent={
            <div className={modal}>
              <section className="small">
                <div className={btnContainer}>
                  {menus.map((menu) => (
                    <div className={btn} key={menu.url + menu.name}>
                      <a className={burgerMenuPointMobile} href={menu.url} target="_blank" rel="noreferrer">
                        <Title title={translations[toCamelCase(menu.name)]} alignCenter tapAnimation />
                      </a>
                    </div>
                  ))}
                </div>
              </section>
              <div className={footerWrapper}>
                <Footer fromBurger />
              </div>
            </div>
          }
          headerTitle={translations.burgerTitleMenu}
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          needsOpenerIcon
          isMenu
        />
      </section>
    </header>
  );
}

export default Header;
