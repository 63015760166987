import { useRef } from 'react';
import { IoIosSearch } from 'react-icons/io';

import style from '../assets/scss/components/SearchInput.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { ISearchInput } from '../interfaces/components/SearchInput';

function SearchInput(props: ISearchInput): JSX.Element {
  const { onChange, isSuggestion, value, placeholder } = props;
  const { searchBarContainer, searchBarMargin, searchBarIcon, searchBar } = convertKeysToCamelCase(style);

  const inputRef = useRef<HTMLInputElement>(null);

  const addFocus = () => {
    inputRef?.current?.focus();
  };

  return (
    <div className={`${searchBarContainer} ${!isSuggestion && searchBarMargin}`}>
      <IoIosSearch className={searchBarIcon} onClick={addFocus} />
      <input
        id="search"
        name="search"
        type="text"
        className={searchBar}
        placeholder={placeholder}
        ref={inputRef}
        onChange={onChange}
        value={value && value}
        autoComplete="off"
      />
    </div>
  );
}

export default SearchInput;
