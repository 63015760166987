import { useSelector } from 'react-redux';

import style from '../assets/scss/components/AdSection.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IReduxStore } from '../interfaces/IGeneral';
import PaginationElements from '../components/PaginationElements';
import BottomAd from './BottomAd';
import { IAdSection } from '../interfaces/components/AdSection';

function AdSection(props: IAdSection): JSX.Element {
  const { children, length, topPadding, noPadding } = props;
  const { wrapper, adWrapper, childWrapper, paginations, pageWrapper, spacer, noSidePadding } =
    convertKeysToCamelCase(style);
  const { customAds } = useSelector((store: IReduxStore) => store.defaultReducer);
  const ad = customAds.find((ad) => ad.position === 'right');

  return (
    <section className={`small ${pageWrapper} ${noPadding && noSidePadding}`}>
      <div className={topPadding ? spacer : ''} />
      <div className={wrapper}>
        <div className={childWrapper}>
          {children}
          {length && length > 0 ? (
            <div className={paginations}>
              <PaginationElements length={length} />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={adWrapper} dangerouslySetInnerHTML={{ __html: ad?.content ? ad.content : '' }} />
      </div>
      <BottomAd />
    </section>
  );
}

export default AdSection;
