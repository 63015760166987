import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import { GET_CLIENTS } from '../constants/routes';
import { CLIENT_DETAILS } from '../constants/routes';
import useGeneral from './useGeneral';
import { FilterFilter as ClientsFilter } from '../constants/filter';
import { IUseClientsList } from '../interfaces/components/Hooks';
import { IReduxStore, IFilter } from '../interfaces/IGeneral';
import NoActiveSubscriptionNotification, {
  noActiveSubscriptionOptions,
} from '../components/NoActiveSubscriptionNotification';
import { toast } from 'react-toastify';
import { SET_PAGINATION } from '../constants/reduxActions';
import { IClient } from '../interfaces/components/Clients';
import usePagination from './usePagination';

function useClientsList(): IUseClientsList {
  const { actualFilter, order, pagination } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [total, setTotal] = useState<number>(0);
  const [clients, setClients] = useState<IClient[]>([]);
  const [allClients, setAllClients] = useState<IClient[]>([]);
  const { handleApi } = useApi();
  const dispatch = useDispatch();
  const { handleRedirect } = useGeneral();
  const { paginate } = usePagination();

  useEffect(() => {
    fetchAllClients();
  }, []);

  useEffect(() => {
    fetchClients();
  }, [order, actualFilter]);

  useEffect(() => {
    fetchClientsWithPagination();
  }, [pagination]);

  const getTotal = async () => {
    const { data, code } = await handleApi(API_METHOD_GET, GET_CLIENTS);
    if (code !== 200) {
      return;
    }
    return data.total;
  };

  async function fetchAllClients() {
    const total = await getTotal();
    const URL = GET_CLIENTS + `?page=1&perPage=${total}`;

    const { data } = await handleApi(API_METHOD_GET, URL);

    setAllClients(data.data);
  }

  async function fetchClients() {
    const keys = Object.keys(actualFilter) as Array<keyof IFilter>;
    // prettier-ignore
    const answers = keys.map(
      (key: keyof IFilter) => `&${[key]}=${ClientsFilter[key] ? actualFilter[key]?.id : actualFilter[key]?.name}`
    ).join('');
    const x = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=1&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, GET_CLIENTS + x);
    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }
    setClients(data.data);
    setTotal(data.total);
  }

  const fetchClientsWithPagination = async () => {
    const keys = Object.keys(actualFilter) as Array<keyof IFilter>;
    // prettier-ignore
    const answers = keys.map(
      (key: keyof IFilter) => `&${[key]}=${ClientsFilter[key] ? actualFilter[key]?.id : actualFilter[key]?.name}`
    ).join('');
    const x = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, GET_CLIENTS + x);
    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }
    paginate(setClients, data.data);
    setTotal(data.total);
  };

  const nextPage = () => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: pagination.page + 1 } });
  };

  const handleOnClick = (id?: number) => {
    if (id) handleRedirect(CLIENT_DETAILS.replace(':id', id.toString()));
  };

  const showNoActiveSubscriptionToast = useCallback(() => {
    toast.error(<NoActiveSubscriptionNotification />, noActiveSubscriptionOptions);
  }, []);

  return { clients, allClients, handleOnClick, total, nextPage };
}

export default useClientsList;
