import style from '../assets/scss/components/MySubscriptionDetails.module.scss';
import { capitalize, convertKeysToCamelCase } from '../helpers/baseHelper';
import useMySubscriptionDetails from '../hooks/useMySubscriptionDetails';
import AlertModal from './AlertModal';
import useGeneral from '../hooks/useGeneral';
import { PLANS } from '../constants/routes';
import useGetTranslations from '../hooks/useGetTranslations';
import useStripeWebCheckout from '../hooks/useStripeWebCheckout';
import { toCamelCase } from '../transforms/baseTransformer';

function MySubscriptionDetails(): JSX.Element {
  const { wrapper, line, title, btnContainer, btn, indent, price, noMargin, centerText, horizontalLine } =
    convertKeysToCamelCase(style);
  const { isCancelModalOpen, toggleCancelModal, cancelSubscription, subscription } = useMySubscriptionDetails();
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();
  const { changePaymentMethod } = useStripeWebCheckout();

  let subPrice = '-';
  if (
    subscription?.priceIntervalCount &&
    subscription.priceInterval &&
    subscription.isRecurring &&
    subscription?.paymentDetails.paid !== null
  ) {
    subPrice = `${subscription?.paymentDetails.paid} ${subscription?.paymentDetails.currency === 'usd' ? '$' : '€'}/${
      translations[subscription?.priceInterval]
    }`;
  }

  if (!subscription?.isRecurring && subscription?.paymentDetails.paid !== null) {
    subPrice = `${subscription?.paymentDetails.paid} ${subscription?.paymentDetails.currency === 'usd' ? '$' : '€'}`;
  }

  return (
    <div className={wrapper}>
      <AlertModal
        isOpen={isCancelModalOpen}
        onClose={toggleCancelModal}
        header={translations.mySubscriptionDetailsAlertHeader}
        description={translations.mySubscriptionDetailsAlertDescription}
        leftButton={translations.back}
        rightButton={translations.cancelSubscription}
        onLeftButtonClick={toggleCancelModal}
        onRightButtonClick={cancelSubscription}
      />
      <p className={title}>{translations.mySubscriptionDetailsSubscriptionTitle}</p>

      <div className={horizontalLine} />

      <div className={line}>
        <p className={`bubble ${noMargin}`}>
          {subscription?.name ? translations[toCamelCase(subscription.name)] ?? subscription.name : '-'}
        </p>
        <p className={`bubble ${centerText}`}>
          {subscription?.priceInterval ? translations[subscription.priceInterval] : '-'}
        </p>
      </div>
      <div className={`${line} ${indent}`}>
        <p>{translations.mySubscriptionDetailsRenews ? translations.mySubscriptionDetailsRenews : '-'}</p>
        <p className="bubble-date">
          {subscription?.paymentDetails.nextPayment ? subscription.paymentDetails.nextPayment : '-'}
        </p>
      </div>
      <div className={indent}>
        <p className={price}>
          {translations.price}: {subPrice}
        </p>
        <p>
          {translations.mySubscriptionLastPayment}:{' '}
          {subscription?.paymentDetails.lastPayment ? subscription.paymentDetails.lastPayment : '-'}
        </p>
        <p>
          {translations.mySubscriptionNextPayment}:{' '}
          {subscription?.paymentDetails.nextPayment ? subscription.paymentDetails.nextPayment : '-'}
        </p>
        <p>
          {translations.mySubscriptionPaymentMethod}:{' '}
          {subscription?.paymentDetails.other.cardBrand
            ? capitalize(subscription?.paymentDetails.other.cardBrand)
            : '-'}
        </p>
      </div>
      <div className={btnContainer}>
        <button
          className={`primary ${btn}`}
          onClick={() => {
            handleRedirect(PLANS);
          }}
        >
          {translations.mySubscriptionDetailsChangePlan}
        </button>
        {subscription?.isBaseSubscription === false && (
          <button className={`primary ${btn}`} onClick={changePaymentMethod}>
            {translations.changePaymentMethod}
          </button>
        )}
        {subscription?.paymentDetails.nextPayment && (
          <button className={`secondary ${btn}`} onClick={toggleCancelModal}>
            {translations.mySubscriptionDetailsCancelSubscription}
          </button>
        )}
      </div>
    </div>
  );
}

export default MySubscriptionDetails;
