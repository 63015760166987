import { useHistory, useLocation } from 'react-router-dom';

import { IUseGeneral } from '../interfaces/IGeneral';
import { RefObject } from 'react';
import { ReactCropperElement } from 'react-cropper';
import Resizer from 'react-image-file-resizer';

function useGeneral(): IUseGeneral {
  const history = useHistory();
  const location = useLocation();

  const handleRedirect = (url: string) => {
    history.push({
      pathname: url,
      state: {
        from: location.pathname,
      },
    });
    window.scrollTo(0, 0);
  };

  const refresh = () => {
    history.go(0);
  };

  const goBack = () => {
    history.goBack();
  };

  const isAtRoute = (url: string) => {
    const activePath = location?.pathname.substring(location?.pathname.lastIndexOf('/'));
    return activePath === url;
  };

  const containsRoute = (route: string[]) => {
    for (let i = 0; i < route.length; i++) {
      if (!location?.pathname.includes(route[i])) {
        return false;
      }
    }
    return true;
  };

  const removeSearchParams = () => {
    history.replace({
      search: '',
    });
  };

  const finishCrop = async (cropperRef: RefObject<ReactCropperElement>): Promise<File | null> => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const croppedImageDataURL = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      const byteString = atob(croppedImageDataURL.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: 'image/png' });

      // Create a File object from the Blob
      const file = new File([blob], 'cropped_image.png', { type: 'image/png' });

      const resized = await resizeFile(file);

      return resized;
    }

    return null;
  };

  /*prettier-ignore*/
  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1020,
        1020,
        'JPEG',
        200,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'base64'
      );
    });

  return { handleRedirect, refresh, goBack, isAtRoute, containsRoute, removeSearchParams, finishCrop, resizeFile };
}

export default useGeneral;
