import { useCallback } from 'react';
import { API_METHOD_POST } from '../constants/api';
import { toast } from 'react-toastify';
import { useApi } from './useApi';
import {
  MY_SUBSCRIPTION,
  PAYMENT_CHANGE_METHOD,
  PAYMENT_SUBSCRIBE,
  SUBSCRIPTIONS_CHANGE_PAYMENT_METHOD_STATUS,
  SUBSCRIPTIONS_PAYMENT_STATUS,
} from '../constants/routes';
import useGetTranslations from './useGetTranslations';
import { buildUrl } from '../helpers/baseHelper';

interface PaymentIntentResponse {
  data: {
    checkoutUrl: string;
  };
  message: string;
  code: number;
}

enum PaymentStatus {
  SUCCESS = 'success',
  CANCELED = 'canceled',
}

//prettier-ignore
const useStripeWebCheckout = () => {
  const { handleApi } = useApi();
  const { translations } = useGetTranslations();

  const changePaymentMethod = useCallback(async () => {
    try {
      const currentOrigin = window.location.origin;
      const {
        data: { checkoutUrl },
      } = await handleApi<PaymentIntentResponse>(API_METHOD_POST, PAYMENT_CHANGE_METHOD, {
        successUrl: buildUrl(
          currentOrigin,
          MY_SUBSCRIPTION,
          new URLSearchParams({ [SUBSCRIPTIONS_CHANGE_PAYMENT_METHOD_STATUS]: PaymentStatus.SUCCESS })
        ),
        cancelUrl: buildUrl(
          currentOrigin,
          MY_SUBSCRIPTION,
          new URLSearchParams({ [SUBSCRIPTIONS_CHANGE_PAYMENT_METHOD_STATUS]: PaymentStatus.CANCELED })
        ),
      });
      window.location.href = checkoutUrl;
    } catch (e) {
      toast.error(translations.failedToStartPayment);
    }
  }, [handleApi, translations]);

  const onSubscribePlan = useCallback(
    async (planId: string) => {
      try {
        const currentOrigin = window.location.origin;
        const data = await handleApi<PaymentIntentResponse>(API_METHOD_POST, PAYMENT_SUBSCRIBE, {
          planId,
          successUrl: buildUrl(
            currentOrigin,
            MY_SUBSCRIPTION,
            new URLSearchParams({ [SUBSCRIPTIONS_PAYMENT_STATUS]: PaymentStatus.SUCCESS })
          ),
          cancelUrl: buildUrl(
            currentOrigin,
            MY_SUBSCRIPTION,
            new URLSearchParams({ [SUBSCRIPTIONS_PAYMENT_STATUS]: PaymentStatus.CANCELED })
          ),
        });

        if (data.message && data.code == 415) {
          // toast.error(data.message);
          return;
        }

        if (data.message === 'api.desired_and_ongoing_subscription_currency_mismatch' && data.code == 403) {
          return;
        }

        window.location.href = data.data.checkoutUrl;
      } catch (e) {
        toast.error(translations.failedToStartPayment);
      }
    },
    [handleApi, translations]
  );

  return { onSubscribePlan, changePaymentMethod };
};

export default useStripeWebCheckout;
export { PaymentStatus };
