import { MdAttachMoney, MdTimer } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';

import Title from './Title';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/TimeAndAmount.module.scss';
import { ITimeAndAmount } from '../interfaces/components/TimeAndAmount';
import useGetTranslations from '../hooks/useGetTranslations';
import useProfile from '../hooks/useProfile';

function TimeAndAmount(props: ITimeAndAmount): JSX.Element {
  const { price, time, register, isEditing, timeUnit, needsTotal } = props;
  const { row, rowCenter, attribute, editAttribute, icon, iconContainer, currencyContainer } =
    convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { profile } = useProfile();

  return (
    <div>
      <Title title={translations.timeAndAmountTitle} />
      <div className={needsTotal ? rowCenter : row}>
        <div className={iconContainer}>
          <MdAttachMoney className={icon} />
          {needsTotal && translations.total}
        </div>
        {isEditing ? (
          <NumericFormat
            value={price === null ? price : Number(price)}
            getInputRef={register}
            thousandSeparator=" "
            decimalSeparator="."
            name="price"
            className={`primary bubble ${editAttribute}`}
            onKeyPress={(e: any) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <NumericFormat
            value={price === null ? '-' : price}
            thousandSeparator=" "
            decimalSeparator="."
            name="price"
            className={`primary bubble ${attribute}`}
            disabled
          />
        )}
        <div className={currencyContainer}>
          <p>{profile?.currency}</p>
        </div>
      </div>
      <div className={needsTotal ? rowCenter : row}>
        <div className={iconContainer}>
          <MdTimer className={icon} />
          {needsTotal && translations.total}
        </div>
        {isEditing ? (
          <NumericFormat
            value={time === null ? time : Number(time)}
            getInputRef={register}
            thousandSeparator=" "
            decimalSeparator="."
            name="duration"
            className={`primary bubble ${editAttribute}`}
            onKeyPress={(e: any) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <NumericFormat
            value={time === null ? '-' : time}
            thousandSeparator=" "
            decimalSeparator="."
            name="duration"
            className={`primary bubble ${attribute}`}
            disabled
          />
        )}
        <div className={currencyContainer}>
          <p>{timeUnit}</p>
        </div>
      </div>
    </div>
  );
}

export default TimeAndAmount;
