import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from './useApi';
import { SET_CUSTOM_ADS } from '../constants/reduxActions';
import { API_METHOD_GET } from '../constants/api';
import { CUSTOM_ADS } from '../constants/routes';

function useCustomAds(): void {
  const { handleApi } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAds();
  }, []);

  async function fetchAds() {
    const { data } = await handleApi(API_METHOD_GET, CUSTOM_ADS);
    dispatch({ type: SET_CUSTOM_ADS, payload: data });
  }
}

export default useCustomAds;
