import React from 'react';

import RegistrationForm from '../RegistrationForm';
import AuthenticationTitle from '../AuthenticationTitle';
import useSetHeader from '../../hooks/useSetHeader';
import useGeneral from '../../hooks/useGeneral';
import { LOGIN_PAGE } from '../../constants/routes';
import useGetTranslations from '../../hooks/useGetTranslations';
import LanguageBar from '../LanguageBar';

function Registration(): JSX.Element {
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();

  useSetHeader(() => {
    handleRedirect(LOGIN_PAGE);
  }, translations.loginSignUp);

  return (
    <main>
      <section className="small">
        <LanguageBar />
      </section>
      <section className="small">
        <AuthenticationTitle />
      </section>
      <section className="small">
        <RegistrationForm />
      </section>
    </main>
  );
}

export default Registration;
