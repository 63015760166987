import RemindersForm from '../RemindersForm';
import EditHeader from '../EditHeader';
import AdSection from '../AdSection';
import useReminderDetails from '../../hooks/useReminderDetails';
import RemindersCard from '../RemindersCard';
import useGetTranslations from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import IdComponent from '../IdComponent';
import useProfile from '../../hooks/useProfile';

function EditReminder(): JSX.Element {
  const {
    reminder,
    toggleEditing,
    register,
    handleSubmit,
    errors,
    handleDelete,
    handleReminderDateValue,
    reminderDateValue,
    handleReminderRepeatValue,
    reminderRepeat,
    handleSendEmail,
    isSendEmail,
    handleDeleteReminderValue,
    saveTemplate,
    shouldLeaveWarning,
  } = useReminderDetails();

  const { translations } = useGetTranslations();
  const { profile } = useProfile();

  useSetHeader(() => {}, translations.reminderEditTitle);

  return (
    <main>
      <EditHeader
        isEditing
        toggleEditing={toggleEditing}
        handleDelete={handleDelete}
        deleteTitle={translations.deleteReminderDescription}
        deleteButtonText={translations.deleteReminder}
      />
      <AdSection topPadding>
        <>
          <IdComponent id={reminder?.id} text={translations.reminderId} />
          {reminder && (
            <RemindersCard
              isSimple
              isEditing={false}
              isNew={false}
              selectedImage={reminder.instrument.data.thumbnail}
              isOverForm
              reminder={{
                client: reminder.instrument.client?.data,
                id: reminder.id,
                brand: reminder.instrument.data.brand,
                model: reminder.instrument.data.model,
                category: reminder.instrument.data.category,
                thumbnail: reminder.instrument.data.thumbnail,
                upcomingReminderDate: '',
              }}
            />
          )}
          <RemindersForm
            reminder={reminder}
            isEditing
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            isSendMail={isSendEmail}
            handleSendEmail={handleSendEmail}
            reminderDateValue={reminderDateValue}
            handleReminderDateValue={handleReminderDateValue}
            showLeaveWarning={shouldLeaveWarning}
            handleReminderRepeatValue={handleReminderRepeatValue}
            reminderRepeatValue={reminderRepeat}
            handleDeleteReminderDate={handleDeleteReminderValue}
            showSendEmail={
              !!reminder?.instrument?.client?.data && reminder?.instrument?.client.data.email !== '' ? true : false
            }
            saveReminderTemplate={saveTemplate}
            profile={profile}
          />
        </>
      </AdSection>
    </main>
  );
}

export default EditReminder;
