import pianoImage from '../assets/images/piano.jpg';
import style from '../assets/scss/components/AuthenticationTitle.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';

function AuthenticationTitle(): JSX.Element {
  const { wrapper, title, image } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  return (
    <div className={wrapper}>
      <img src={pianoImage} className={image} />
      <p className={`${title} h2`}>{translations.authenticationTitle}</p>
    </div>
  );
}

export default AuthenticationTitle;
