import { AiOutlinePlus } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';

import BlueaHeader from './BlueHeader';
import style from '../assets/scss/components/InstrumentDetailHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useInstrumentDetailHeader from '../hooks/useInstrumentDetailHeader';
import useGetTranslations from '../hooks/useGetTranslations';

function InstrumentDetailHeader(): JSX.Element {
  const { wrapper, icon, line, option } = convertKeysToCamelCase(style);
  const { redirectToEdit, redirectToService } = useInstrumentDetailHeader();
  const { translations } = useGetTranslations();

  return (
    <BlueaHeader
      element={
        <div className={wrapper}>
          <div className={option}>
            <p onClick={redirectToService}>{translations.instrumentDetailHeaderNewService}</p>
            <AiOutlinePlus className="plus-circle" onClick={redirectToService} />
          </div>
          <div className={line} />
          <div className={option}>
            <p onClick={redirectToEdit}>{translations.edit}</p>
            <MdModeEditOutline className={icon} onClick={redirectToEdit} />
          </div>
        </div>
      }
    />
  );
}

export default InstrumentDetailHeader;
