import style from '../assets/scss/components/ClientsInstruments.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import detach from '../assets/images/detach.svg';
import attach from '../assets/images/attach.svg';
import useGetTranslations from '../hooks/useGetTranslations';
import { IInstrumentClient } from '../interfaces/components/Instrument';

function InstrumentClient(props: IInstrumentClient): JSX.Element {
  const { iconPart, textPart, icon, line, instrumentContainer } = convertKeysToCamelCase(style);
  const { isEditing, selectedClient, onDeleteClick } = props;
  const { translations } = useGetTranslations();

  return (
    <div>
      <div className={instrumentContainer}>
        <div className={iconPart}>
          <div className={icon} />
        </div>
      </div>
      <div className={instrumentContainer}>
        {selectedClient && (
          <>
            <div className={iconPart}>
              <hr className={line} />
              <img
                src={isEditing ? detach : attach}
                className={icon}
                onClick={() => {
                  if (onDeleteClick) onDeleteClick(selectedClient.id);
                }}
              />
              <hr className={line} />
            </div>
            <div className={textPart}>
              <p>
                {selectedClient.firstName +
                  ' ' +
                  selectedClient.lastName +
                  (selectedClient.city !== '' ? ', ' + selectedClient.city : '') +
                  ', ' +
                  translations.generalId +
                  ': ' +
                  selectedClient.id}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default InstrumentClient;
