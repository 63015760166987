import { BiTime } from 'react-icons/bi';
import { GoSync } from 'react-icons/go';

import CollapseElement from './CollapseElement';
import style from '../assets/scss/components/Plan.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import planImage from '../assets/images/plan_image.png';
import { ICouponForm, IPlanElement } from '../interfaces/components/Plans';
import useGetTranslations from '../hooks/useGetTranslations';
import { toCamelCase } from '../transforms/baseTransformer';
import { useState } from 'react';
import Modal from './Modal';
import SimpleInput from './SimpleInput';
import useYupValidationResolver from '../hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { getCouponValidationSchema } from '../helpers/validationSchemaHelper';
import { API_METHOD_POST } from '../constants/api';
import { useApi } from '../hooks/useApi';
import { COUPON_API } from '../constants/routes';

function Plan(props: IPlanElement): JSX.Element {
  const {
    wrapper,
    row,
    imageContainer,
    image,
    title,
    price,
    user,
    half,
    collapseContainer,
    btnContainer,
    timeIcon,
    syncIcon,
    textContainer,
    timeDescription,
    syncDescription,
    line,
    bubble,
    bubbleContainer,
    shortLine,
    longLine,
    lineBigPadding,
    additionalInfo,
    content,
    infoRow,
    couponButton,
    couponModalWrapper,
    couponModalTitle,
    couponModalButtonContainer,
  } = convertKeysToCamelCase(style);

  const { translations } = useGetTranslations();
  const { plan, onChooseClick } = props;
  const {
    name,
    description,
    price: priceValue,
    isRecurring,
    priceInterval,
    priceIntervalCount,
    nrClients,
    nrInstruments,
    nrImages,
    nrFiles,
    stripeId,
    description2,
    currency,
  } = plan;
  const [isModalOpen, setModalOpen] = useState(false);
  const resolver = useYupValidationResolver(getCouponValidationSchema());
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICouponForm>({ resolver });
  const { handleApi } = useApi();

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const applyCoupon = async (formData: ICouponForm) => {
    const { coupon } = formData;

    const { code } = await handleApi(API_METHOD_POST, COUPON_API, {
      promotionCode: coupon,
    });

    if (code !== 200) {
      return;
    }

    toggleModal();
    onChooseClick(stripeId);
  };

  return (
    <div className={wrapper}>
      <Modal
        isOpen={isModalOpen}
        onClose={toggleModal}
        content={
          <div className={couponModalWrapper}>
            <p className={couponModalTitle}>{translations.couponModalTitle}</p>
            <SimpleInput
              label={translations.couponModalDescription}
              isRequired
              reg={register}
              errors={errors}
              id="coupon"
              type="text"
              errorText={translations.couponModalError}
            />
            <div className={couponModalButtonContainer}>
              <button className="primary" onClick={toggleModal} type="button">
                {translations.couponModalLeftButton}
              </button>
              <button className="blue" onClick={handleSubmit(applyCoupon)} type="submit">
                {translations.couponModalRightButton}
              </button>
            </div>
          </div>
        }
      />
      <div className={row}>
        <div className={imageContainer}>
          <img src={planImage} className={image} />
        </div>
        <div className={textContainer}>
          <div>
            <div className={row}>
              <p className={title}>{translations[toCamelCase(name)] ?? name}</p>
              <p className={price}>
                {priceValue} {currency === 'usd' ? '$' : '€'}
              </p>
            </div>
            <p className={user}>{translations[toCamelCase(description)] ?? description}</p>
          </div>
          <div>
            <div className={row}>
              <BiTime className={timeIcon} />
              <p className={timeDescription}>
                {priceIntervalCount} {translations[priceInterval]}
              </p>
            </div>
            <div className={row}>
              <GoSync className={syncIcon} />
              <p className={syncDescription}>{isRecurring ? translations.recurring : translations.nonRecurring}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={line} />
      <CollapseElement
        notBoldHeader
        collapseElement={
          <div>
            <div className={row}>
              <div className={bubbleContainer}>
                <div className={shortLine} />
                <div className={bubble} />
                <div className={longLine} />
              </div>
              <div className={`${row} ${content}`}>
                <div className={collapseContainer}>
                  <div className={infoRow}>
                    <p className={half}>{translations.planClients}</p>
                    <p className={half}>{nrClients}</p>
                  </div>
                  <div className={infoRow}>
                    <p className={half}>{translations.planInstruments}</p>
                    <p className={half}>{nrInstruments}</p>
                  </div>
                  <div className={infoRow}>
                    <p className={half}>{translations.planFiles}</p>
                    <div className={`${row} ${half}`}>
                      <p>{nrFiles}</p>
                      <p className={additionalInfo}>{translations.plansFileDescription}</p>
                    </div>
                  </div>
                  <div className={infoRow}>
                    <p className={half}>{translations.planImages}</p>
                    <div className={`${row} ${half}`}>
                      <p>{nrImages}</p>
                      <p className={additionalInfo}>{translations.plansImageDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={lineBigPadding} />
            <div className={btnContainer}>
              <button
                className={`primary ${couponButton}`}
                onClick={() => {
                  toggleModal();
                }}
              >
                {translations.couponButton}
              </button>
              <button
                className="blue"
                onClick={() => {
                  onChooseClick(stripeId);
                }}
              >
                {translations.planChoose}
              </button>
            </div>
          </div>
        }
        headerElement={description2 ? translations[toCamelCase(description2)] ?? description2 : ''}
        defaultVisible={true}
      />
    </div>
  );
}
export default Plan;
