import { useParams } from 'react-router';

import Title from './Title';
import LatestInfo from './LatestInfo';
import TimeAndAmount from './TimeAndAmount';
import AttachmentIcons from './AttachmentIcons';
import ServiceDate from './ServiceDate';
import ChooseProject from './ChooseProject';
import { IServiceForm } from '../interfaces/components/Service';
import useGetTranslation from '../hooks/useGetTranslations';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/ServiceForm.module.scss';
import ChooseTuner from './ChooseTuner';
import { useMemo } from 'react';
import { professionChecker } from '../utils/roleChecker';

function ServiceDetailForm(props: IServiceForm): JSX.Element {
  const {
    register,
    handleSubmit,
    onMatch,
    service,
    errors,
    newProjectName,
    handleNewProjectNameChange,
    handleLastDate,
    handleNextDate,
    project,
    lastServiceDate,
    nextServiceDate,
    handleDeleteLastDate,
    handleDeleteNextDate,
    instrument,
    handleNewTunerNameChange,
    handleTunerMatch,
    newTunerName,
    tuner,
  } = props;
  const { indented, inp } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslation();
  const { id } = useParams<{ id: string }>();
  const isTuner = useMemo(() => professionChecker(), []);

  return (
    <form onSubmit={handleSubmit}>
      <Title title={translations.serviceFormTitleTitle} />
      <div className={indented}>
        <p className={`bubble ${inp}`}>{service?.title}</p>
      </div>
      <Title title={translations.serviceFormTitleDescription} />
      <div className={indented}>
        <textarea rows={4} value={service?.description ? service.description : '-'} disabled />
      </div>
      {isTuner && (
        <>
          <Title title={translations.serviceFormTitleTuner} />
          <div className={indented}>
            <ChooseTuner
              isEditing={false}
              onMatch={handleTunerMatch}
              tuner={tuner}
              handleNewTunerChange={handleNewTunerNameChange}
              newTunerName={newTunerName}
            />
          </div>
        </>
      )}
      <Title title={translations.serviceFormTitleProject} />
      <div className={indented}>
        <ChooseProject
          onMatch={onMatch}
          isEditing={false}
          instrumentId={service?.instrumentId ? service.instrumentId : instrument?.id}
          projectId={service?.projectId}
          project={project}
          newProjectName={newProjectName}
          handleNewProjectNameChange={handleNewProjectNameChange}
          service={service}
        />
      </div>
      <ServiceDate
        errors={errors}
        register={register}
        isEditing={false}
        lastDate={service?.lastDate}
        nextDate={service?.nextDate}
        lastDateOriginalValue={lastServiceDate}
        nextDateOriginalValue={nextServiceDate}
        dateTimeInfo={service?.dateTimeInfo}
        handleLastValue={handleLastDate}
        handleNextValue={handleNextDate}
        handleDeleteLastDate={handleDeleteLastDate}
        handleDeleteNextDate={handleDeleteNextDate}
      />
      <LatestInfo
        isEditing={false}
        register={register}
        temperature={service?.temperature}
        humidity={service?.humidity}
        frequency={service?.frequency}
      />
      <TimeAndAmount
        price={service?.price}
        time={service?.duration}
        isEditing={false}
        register={register}
        timeUnit={translations.timeAmountHours}
      />
      <Title title={translations.serviceFormTitleAttachments} />
      <div className={indented}>
        <AttachmentIcons
          pictureNr={service?.imagesCount}
          attachmentNr={service?.filesCount}
          instrumentId={Number(id)}
          type="service"
        />
      </div>
      <Title title={translations.serviceFormTitleNote} />
      <div className={indented}>
        <textarea rows={8} ref={register} name="note" value={service?.note} disabled />
      </div>
      <div className="last-element" />
    </form>
  );
}

export default ServiceDetailForm;
