//PAGES
export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const REGISTRATION = '/registration';
export const PROFILE = '/profile';
export const MY_ACCOUNT = '/profile/my-account';
export const MY_SUBSCRIPTION = '/profile/my-subscription';
export const PLANS = '/profile/plans';
export const CURRENCY_LANGUAGE = '/profile/currency-language';
export const INSTRUMENT_DETAILS = '/instrument/:id';
export const NEW_INSTRUMENT = '/instrument/new';
export const EDIT_INSTRUMENT = '/instrument/edit/:id';
export const ATTACHMENTS_PAGE = '/attachments/:id/:type';
export const EDIT_ATTACHMENTS_PAGE = '/attachments/:id/:type/edit';
export const CLIENTS_PAGE = '/clients';
export const NEW_CLIENT_PAGE = '/clients/new';
export const EDIT_CLIENT = '/clients/edit/:id';
export const CLIENT_DETAILS = '/clients/:id';
export const REMINDERS_PAGE = '/reminders';
export const INSTRUMENT_SERVICES = '/instrument-services/:id';
export const REMINDER_DETAILS = '/reminders/:id';
export const EDIT_REMINDER = '/reminders/edit/:id';
export const NEW_PROJECT_PAGE = '/projects/new/:id';
export const SERVICES_PAGE = '/services';
export const PROJECTS_PAGE = '/projects';
export const NEW_SERVICE_PAGE = '/services/new/:id';
export const NEW_SERVICE_PAGE_WITH_PROJECT = '/services/new/:id/project/:projectId';
export const SERVICE_DETAIL = '/services/:id';
export const EDIT_SERVICE = '/services/edit/:id';
export const PROJECT_DETAIL = '/projects/:id';
export const EDIT_PROJECT = '/projects/edit/:id';
export const CLIENTS_SERVICES = '/client-services/:id';
export const LOG_OUT = '/logout';
export const NEW_REMINDER_PAGE = '/reminders/new/:id';

//ENDPOINTS
export const LOGIN_API = 'v1/oauth/token';
export const TRANSLATIONS_API = 'v1/translations';
export const PROFESSIONS = 'v1/professions';
export const REGISTER = 'v1/register';
export const FORGOT_PASSWORD_API = 'v1/forgot-password';
export const RESET_PASSWORD_API = 'v1/reset-password';
export const MENU_API = 'v1/menus';
export const MY_ACCOUNT_API = 'v1/profile';
export const UPLOAD_IMAGE = 'v1/profile/update-profile-picture';
export const DOWNLOAD_IMAGE = 'v1/profile/download-images';
export const DOWNLOAD_FILES = 'v1/profile/download-files';
export const DOWNLOAD_USER_DB = 'v1/profile/download-instrument-data';
export const FETCH_PLANS = 'v1/plans';
export const CURRENT_PLAN = 'v1/plans/current';
export const DELETE_CURRENT_PLAN = 'v1/plans/current/cancel';
export const PROFILE_CURRENCY_LANGUAGE = 'v1/profile/currency-unit-language';
export const INSTRUMENTS = 'v1/instruments';
export const FETCH_INSTRUMENT = 'v1/instruments/:id';
export const INSTRUMENT_FILES = 'v1/files/load-attachments/:id/:type';
export const INSTRUMENT_MAIN_GALLERY = 'v1/files/load-attachments-main/:id/:type';
export const DELETE_FILE = 'v1/files/delete-attachments';
export const GET_CLIENTS = 'v1/clients';
export const GET_CLIENT = 'v1/clients/:id';
export const REMINDERS = 'v1/reminders';
export const GET_REMINDERS_TEMPLATE = 'v1/reminders/get-reminder-template';
export const SAVE_REMINDERS_TEMPLATE = '/v1/reminders/save-reminder-template';
export const REMINDER_DETAIL_API = 'v1/reminders/instrument/:id';
export const SINGLE_REMINDER = 'v1/reminders/:id';
export const PROJECTS_LIST = 'v1/projects';
export const ACTIVE_PROJECTS_LIST = 'v1/projects/active';
export const SERVICES_LIST = 'v1/services';
export const ACTIVE_SERVICES_LIST = 'v1/services/active';
export const NEW_PROJECT = 'v1/projects';
export const NEW_BRAND = 'v1/brands';
export const PROJECT_DETAILS = 'v1/projects/:id';
export const SERVICE_DETAIL_API = 'v1/services/:id';
export const SERVICE_ACTIVATE_API = 'v1/services/:id/activate';
export const SERVICE_DEACTIVATE_API = 'v1/services/:id/deactivate';
export const INSTRUMENT_PROJECTS = 'v1/projects/instrument/:id';
export const BRANDS = 'v1/brands';
export const INSTRUMENT_SERVICES_API = 'v1/services/instrument/:id';
export const CLIENT_SERVICES_API = 'v1/services/client/:id';
export const CLIENT_PROJECTS_API = 'v1/projects/client/:id';
export const CLIENT_REMINDERS_API = 'v1/reminders/client/:id';
export const GLOBAL_SEARCH = 'v1/global-search';
export const CUSTOM_ADS = 'v1/custom-ads';
export const SUBSCRIBE_FRAME = 'https://tuningremindernew.web-staging.eu/subscribe?email=:email&plan_id=:stripe_id';
export const COUNTRY_LIST = 'v1/countries';
export const COUNTRY_LIST_ACTIVE = 'v1/countries/active';
export const UPLOAD_ATTACHMENT = 'v1/files/:type/:id/upload-attachment';
export const PAYMENT_SUBSCRIBE = '/v1/create-payment-intent';
export const PAYMENT_CHANGE_METHOD = '/v1/create-payment-method-update-intent';
export const LANGUAGE = 'v1/language';
export const TUNERS = 'v1/tuners';
export const COUPON_API = 'v1/profile/promotion-code';

// Page Query Params
export const SUBSCRIPTIONS_PAYMENT_STATUS = 'paymentStatus';
export const SUBSCRIPTIONS_CHANGE_PAYMENT_METHOD_STATUS = 'paymentMethodChangeStatus';
