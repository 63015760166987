import { useDispatch, useSelector } from 'react-redux';

import { IReduxStore } from '../interfaces/IGeneral';
import NewHeader from './NewHeader';
import ProjectCard from './ProjectCard';
import ServiceCard from './ServiceCard';
import ClientsCard from './ClientsCard';
import { CLIENT_DETAILS, PROJECT_DETAIL, REMINDER_DETAILS, SERVICE_DETAIL } from '../constants/routes';
import InstrumentCard from './InstrumentCard';
import useGetTranslations from '../hooks/useGetTranslations';
import useGeneral from '../hooks/useGeneral';
import style from '../assets/scss/components/GlobalSearchElements.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { ServiceCardTypes } from '../constants/ServiceCard';
import { ProjectCardTypes } from '../constants/projects';
import Adsection from './AdSection';
import ReminderDetailCard from './ReminderDetailCard';
import { SET_GLOBAL_SEARCH_ON_BACK } from '../constants/reduxActions';
import { professionTranslation } from '../utils/roleChecker';

function GlobalSearchElement(): JSX.Element {
  const { globalSearch } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslations();
  const { handleRedirect } = useGeneral();
  const dispatch = useDispatch();
  const { content } = convertKeysToCamelCase(style);
  const showInstrument = globalSearch?.instruments ? globalSearch?.instruments.length > 0 : false;
  const showClients = globalSearch?.clients ? globalSearch?.clients.length > 0 : false;
  const showReminders = globalSearch?.reminders ? globalSearch?.reminders.length > 0 : false;
  const showServices = globalSearch?.services ? globalSearch?.services.length > 0 : false;
  const showProjects = globalSearch?.projects ? globalSearch?.projects.length > 0 : false;

  return (
    <Adsection length={10}>
      <div className={content}>
        {showInstrument && (
          <>
            <NewHeader leftTitle={translations.homePageLeftTitle} rightTitle="" />
            <div className={content}>
              {globalSearch?.instruments?.map((instr) => (
                <InstrumentCard {...instr} isSimple={false} key={'i' + instr.id} fromGlobalSearch />
              ))}
            </div>
          </>
        )}
        {showClients && (
          <>
            <NewHeader leftTitle={translations[professionTranslation('clientPageLeftTitle')]} rightTitle="" />
            <div className={content}>
              {globalSearch?.clients?.map((client) => (
                <ClientsCard
                  client={client}
                  isSimple={false}
                  key={'c' + client.id}
                  onClick={() => {
                    handleRedirect(CLIENT_DETAILS.replace(':id', client.id.toString()));
                    dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: true });
                  }}
                />
              ))}
            </div>
          </>
        )}
        {showReminders && (
          <>
            <NewHeader leftTitle={translations.remindersLeftTitle} rightTitle="" />
            <div className={content}>
              {globalSearch?.reminders?.map((reminder) => (
                <ReminderDetailCard
                  key={'r' + reminder.id}
                  onClick={() => {
                    handleRedirect(REMINDER_DETAILS.replace(':id', reminder.id.toString()));
                    dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: true });
                  }}
                  reminder={{ ...reminder }}
                />
              ))}
            </div>
          </>
        )}
        {showServices && (
          <>
            <NewHeader leftTitle={translations.servicesTitle} rightTitle="" />
            <div className={content}>
              {globalSearch?.services?.map((service) => (
                <ServiceCard
                  key={'s' + service.id}
                  service={service}
                  type={ServiceCardTypes.INSTRUMENT_SERVICE}
                  onClick={() => {
                    handleRedirect(SERVICE_DETAIL.replace(':id', service.id.toString()));
                    dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: true });
                  }}
                  disableActivate
                  showInstrumentData
                />
              ))}
            </div>
          </>
        )}
        {showProjects && (
          <>
            <NewHeader leftTitle={translations.projectsTitle} rightTitle="" />
            <div className={content}>
              {globalSearch?.projects?.map((project) => (
                <>
                  {project.instrument && (
                    <ProjectCard
                      key={'p' + project.id}
                      project={project}
                      type={ProjectCardTypes.CLIENT_PROJECT}
                      onClick={() => {
                        handleRedirect(PROJECT_DETAIL.replace(':id', project.id.toString()));
                        dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: true });
                      }}
                      showInstrumentData={false}
                    />
                  )}
                </>
              ))}
            </div>
          </>
        )}
      </div>
    </Adsection>
  );
}

export default GlobalSearchElement;
