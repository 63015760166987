import { BsCheck } from 'react-icons/bs';

import { convertKeysToCamelCase, toCamelCase } from '../helpers/baseHelper';
import SimpleInput from './SimpleInput';
import style from '../assets/scss/components/MyAccountForm.module.scss';
import CollapseElement from './CollapseElement';
import useGetTranslations from '../hooks/useGetTranslations';
import { IProfession } from '../interfaces/components/Profession';
import CountryDropdown from './CountryDropdown';
import useGetCountries from '../hooks/useGetCountries';
import LeaveModal from './LeaveModal';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useState } from 'react';
import AlertModal from './AlertModal';

//prettier-ignore
function MyAccountForm(props: any): JSX.Element {
  const {
    wrapper,
    infoContainer,
    infoImage,
    infoDescription,
    submit,
    submitContainer,
    line,
    subtitle,
    countryDropdownContainer,
    indented,
    row,
    option,
    textWrap,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { countries, activeCountries } = useGetCountries();
  const {
    professionsTypes,
    register,
    handleSubmit,
    isEditing,
    isPrivate,
    toggleIsPrivate,
    profile,
    toggleSelectedProfessions,
    selectedProfessionTypes,
    errors,
    control,
  } = props;

  const [isTypeModalVisible, setTypeModalVisible] = useState(false);

  const professions = professionsTypes
    .map((profession: IProfession) => {
      if (selectedProfessionTypes.includes(profession.id)) {
        return translations[toCamelCase(profession.title)];
      }
    })
    .filter((professionId: number) => professionId !== undefined);

  return (
    <div className={wrapper}>
      <form onSubmit={handleSubmit}>
        {isEditing && <LeaveModal />}
        {(profile.userType === 'private') !== isPrivate && (
          <AlertModal
            onClose={() => {
              toggleIsPrivate();
              setTypeModalVisible(false);
            }}
            isOpen={isTypeModalVisible}
            onLeftButtonClick={() => {
              toggleIsPrivate();
              setTypeModalVisible(false);
            }}
            onRightButtonClick={() => setTypeModalVisible(false)}
            header={translations.leaveModalHeader}
            description={translations.changeProfileTypeAlert}
            leftButton={translations.cancel}
            rightButton={translations.leaveModalGo}
          />
        )}
        {isEditing && (
          <>
            <div className={line} />
            <p className={subtitle}>{translations.myAccountFormPrivatePersonOrCompany}</p>
            <div className={row}>
              <div
                className="radio"
                onClick={() => {
                  toggleIsPrivate();
                  setTypeModalVisible(true);
                }}
              >
                <p className={option}>{translations.myAccountFormPrivatePerson}</p>
                <div className="radio-container">
                  <BsCheck className={`radio-item ${isPrivate && 'radio-item-selected'}`} />
                </div>
              </div>
              <div
                className="radio"
                onClick={() => {
                  toggleIsPrivate();
                  setTypeModalVisible(true);
                }}
              >
                <p className={option}>{translations.myAccountFormCompany}</p>
                <div className="radio-container">
                  <BsCheck className={`radio-item ${!isPrivate && 'radio-item-selected'}`} />
                </div>
              </div>
            </div>
            {isEditing && (
              <div className={infoContainer}>
                <AiFillInfoCircle className={infoImage} />
                <p className={infoDescription}>{translations.changeProfileTypeText}</p>
              </div>
            )}
          </>
        )}
        <CollapseElement
          headerElement={translations.myAccountFormHeaderMyAccount}
          defaultVisible={true}
          collapseElement={
            <div className={indented}>
              <SimpleInput
                label={translations.myAccountFormFirstName}
                isRequired
                reg={register}
                errors={errors}
                id="firstName"
                type="text"
                errorText={translations.mandatoryField}
                disabled={!isEditing}
              />
              <SimpleInput
                label={translations.myAccountFormLastName}
                isRequired
                reg={register}
                errors={errors}
                id="lastName"
                type="text"
                errorText={translations.mandatoryField}
                disabled={!isEditing}
              />
              {!isPrivate && (
                <SimpleInput
                  label={translations.myAccountFormCompanyName}
                  reg={register}
                  errors={errors}
                  id="companyName"
                  type="text"
                  errorText={translations.mandatoryField}
                  disabled={!isEditing}
                />
              )}
            </div>
          }
        />
        <CollapseElement
          headerElement={translations.myAccountFormHeaderInvoiceData}
          defaultVisible={true}
          collapseElement={
            <div className={indented}>
              {!isPrivate && (
                <SimpleInput
                  label={translations.myAccountFormCompanyTaxNumber}
                  reg={register}
                  errors={errors}
                  id="companyTaxNumber"
                  type="text"
                  errorText={translations.mandatoryField}
                  disabled={!isEditing}
                />
              )}
              <SimpleInput
                label={translations.myAccountFormAddress}
                isRequired
                reg={register}
                errors={errors}
                id="address1"
                type="text"
                errorText={translations.mandatoryField}
                disabled={!isEditing}
              />
              <SimpleInput
                label={translations.myAccountFormAddress2}
                reg={register}
                errors={errors}
                id="address2"
                type="text"
                errorText={translations.sameAddress}
                disabled={!isEditing}
              />
              <SimpleInput
                label={translations.myAccountFormCity}
                isRequired
                reg={register}
                errors={errors}
                id="city"
                type="text"
                errorText={translations.mandatoryField}
                disabled={!isEditing}
              />
              <div className={countryDropdownContainer}>
                <CountryDropdown
                  control={control}
                  countries={isPrivate ? activeCountries : countries}
                  placeholder={translations.countryDropdownPlaceHolder}
                  disabled={!isEditing}
                  title
                />
              </div>
              <SimpleInput
                label={translations.myAccountFormRegion}
                isRequired
                reg={register}
                errors={errors}
                id="region"
                type="text"
                errorText={translations.mandatoryField}
                disabled={!isEditing}
              />
              <SimpleInput
                label={translations.myAccountFormPostalCode}
                isRequired
                reg={register}
                errors={errors}
                id="postcode"
                type="text"
                errorText={translations.mandatoryField}
                disabled={!isEditing}
              />
            </div>
          }
        />
        <CollapseElement
          headerElement={translations.myAccountFormHeaderRegisteredAs}
          defaultVisible={true}
          collapseElement={
            <div className={indented}>
              {isEditing &&
                professionsTypes?.map(
                  (profession: IProfession) =>
                    (
                      <div
                        key={profession.id}
                        className="radio"
                        onClick={() => {
                          toggleSelectedProfessions(profession.id);
                        }}
                      >
                        <p>{translations[toCamelCase(profession.title)]}</p>
                        <div className="radio-container">
                          <BsCheck
                            className={`radio-item ${
                              selectedProfessionTypes.includes(profession.id) && 'radio-item-selected'
                            }`}
                          />
                        </div>
                      </div>
                    )
                )}
              {!isEditing && (
                <div>
                  <p>{professions.join(', ')}</p>
                </div>
              )}
            </div>
          }
        />
        <CollapseElement
          headerElement={translations.myAccountFormHeaderLoginInfo}
          defaultVisible={true}
          collapseElement={
            <div className={indented}>
              <p className={textWrap}>
                {translations.myAccountFormEmail}: {profile.email}
              </p>
              <div className={infoContainer}>
                <AiFillInfoCircle className={infoImage} />
                <p className={infoDescription}>{translations.myAccountFormEmailInfo}</p>
              </div>
              {isEditing && (
                <>
                  <SimpleInput
                    label={translations.myAccountFormPassword}
                    reg={register}
                    errors={errors}
                    id="password"
                    type="password"
                    errorText={translations.registrationPassword}
                  />
                  <SimpleInput
                    label={translations.myAccountFormPasswordRepeat}
                    reg={register}
                    errors={errors}
                    id="passwordConfirmation"
                    type="password"
                    errorText={translations.registrationPasswordConfirmation}
                  />
                </>
              )}
            </div>
          }
        />
        {isEditing && (
          <div className={submitContainer}>
            <button type="submit" className={`${submit} primary`}>
              {translations.save}
            </button>
          </div>
        )}
        <div className="last-element" />
      </form>
    </div>
  );
}

export default MyAccountForm;
