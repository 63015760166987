import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import style from '../assets/scss/components/ProfileRow.module.scss';
import AlertModal from './AlertModal';
import useLogout from '../hooks/useLogout';
import { LogOutProps } from '../interfaces/components/LogOutPopup';

function LogOutPopup(props: LogOutProps): JSX.Element {
  const { toggleModal, isVisible } = props;
  const { translations } = useGetTranslations();
  const { wrapper } = convertKeysToCamelCase(style);
  const { logOut } = useLogout();

  return (
    <div className={wrapper} onClick={toggleModal}>
      <AlertModal
        onClose={toggleModal}
        isOpen={isVisible}
        onLeftButtonClick={toggleModal}
        onRightButtonClick={logOut}
        header={translations.logoutTitle}
        description=""
        leftButton={translations.cancel}
        rightButton={translations.logOutTitle}
      />
    </div>
  );
}

export default LogOutPopup;
