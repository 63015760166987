import Cropper from 'react-cropper';
import style from '../assets/scss/components/ImageCropper.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { useEffect, useRef } from 'react';

export interface IImageCropper {
  cropperRef: any;
  cropImage: string;
  handleFinishCrop: () => void;
  handleCancel: () => void;
  cropText: string;
  cancelText: string;
}

export const ImageCropper = ({
  cropperRef,
  cropImage,
  handleFinishCrop,
  cropText,
  handleCancel,
  cancelText,
}: IImageCropper) => {
  const { wrapper, cropButton, buttonContainer } = convertKeysToCamelCase(style);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // special request from the client: please scroll until the crop buttons are visible
    if (cropImage !== '' && parentRef && parentRef !== null && parentRef.current !== null) {
      window.scrollTo({ top: parentRef.current.offsetTop - 200 });
    }
  }, [cropImage, parentRef]);

  return (
    <div className={wrapper} ref={parentRef}>
      <Cropper
        ref={cropperRef}
        initialAspectRatio={1}
        src={cropImage}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        style={{ height: 300 }}
        background={false}
        responsive={true}
        autoCropArea={1}
        cropBoxResizable={false}
        dragMode="move"
        checkOrientation={false}
      />
      <div className={buttonContainer}>
        <button type="button" onClick={handleCancel} className={`${cropButton} secondary`}>
          {cancelText}
        </button>
        <button type="button" onClick={handleFinishCrop} className={`${cropButton} primary`}>
          {cropText}
        </button>
      </div>
    </div>
  );
};
