import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IUseFilterIcon } from '../interfaces/components/Hooks';
import { IReduxStore } from '../interfaces/IGeneral';
import useGetClients from './useGetClients';
import useGetInstruments from './useGetInstruments';

function useFilterIcon(addActualFilterRedux: string, resetFilterRedux: string): IUseFilterIcon {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const { clients, instruments } = useSelector((store: IReduxStore) => store.defaultReducer);
  useGetInstruments();
  useGetClients();

  useEffect(() => {
    return () => {
      dispatch({ type: resetFilterRedux });
    };
  }, []);

  const toggleVisible = useCallback(() => {
    setIsVisible((prev) => !prev);
  }, [setIsVisible]);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const onSave = useCallback(() => {
    dispatch({ type: addActualFilterRedux });
    toggleVisible();
  }, [toggleVisible, dispatch]);

  return { isVisible, toggleVisible, clients, instruments, onSave, close };
}

export default useFilterIcon;
