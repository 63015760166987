import style from '../assets/scss/components/ForgotPasswordForm.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import SimpleInput from './SimpleInput';
import useGetTranslation from '../hooks/useGetTranslations';
import useResetPassword from '../hooks/useResetPassword';

function ResetPasswordForm(): JSX.Element {
  const { submitContainer } = convertKeysToCamelCase(style);
  const { register, handleSubmitForm, errors } = useResetPassword();
  const { translations } = useGetTranslation();

  return (
    <form onSubmit={handleSubmitForm}>
      <SimpleInput
        type="text"
        id="email"
        label={translations.loginEmail}
        isRequired
        reg={register}
        errors={errors}
        errorText={translations.mandatoryField}
      />
      <SimpleInput
        type="password"
        id="password"
        label={translations.myAccountFormPassword}
        isRequired
        reg={register}
        errors={errors}
        errorText={
          errors.password?.message === 'passwordLength'
            ? translations.registrationPassword
            : translations.mandatoryField
        }
      />
      <SimpleInput
        type="password"
        id="passwordConfirmation"
        label={translations.myAccountFormPasswordRepeat}
        isRequired
        reg={register}
        errors={errors}
        errorText={translations.registrationPasswordConfirmation}
      />
      <div className={submitContainer}>
        <button type="submit" className="primary">
          {translations.save}
        </button>
      </div>
    </form>
  );
}

export default ResetPasswordForm;
