import useGetTranslations from '../hooks/useGetTranslations';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/ClientServicesDetails.module.scss';
import { IInstrumentServicesDetails } from '../interfaces/components/IntrumentServicesDetails';
import { BsLink } from 'react-icons/bs';

function ClientServicesDetails(props: IInstrumentServicesDetails): JSX.Element {
  const { service, showProjectIcon } = props;
  const { translations } = useGetTranslations();
  const {
    wrapper,
    description,
    title,
    box,
    text,
    row,
    textBlue,
    flex,
    subTitle,
    instrumentCategory,
    linkContainer,
    linkIcon,
  } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <div className={description}>
        <div className={row}>
          <div>
            <span className={title}>
              {service?.instrument?.brand}
              {service?.instrument?.model ? `, ${service.instrument.model}` : ''}{' '}
              <span className={instrumentCategory}>{service?.instrument?.category}</span>
            </span>
          </div>
        </div>
        <p className={subTitle}>{service.title}</p>
        <div className={box}>
          <div className={row}>
            <p className={text}>{translations.serviceCardLast}:</p>
            <p>{service.lastDate}</p>
          </div>
          <div className={flex}>
            <div className={row}>
              <p className={textBlue}>{translations.serviceCardNext}:</p>
              <p>{service.nextDate}</p>
            </div>
            {showProjectIcon && (
              <div className={linkContainer}>
                <BsLink className={linkIcon} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientServicesDetails;
