import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/MenuBar.module.scss';
import { ReactNode } from 'react';
import {
  RESET_ACTUAL_FILTER,
  RESET_ORDER,
  SET_GLOBAL_SEARCH,
  SET_RESET_GLOBAL_SEARCH,
} from '../constants/reduxActions';
import { useDispatch } from 'react-redux';

interface IMenuItem {
  icon: ReactNode;
  onClick: () => void;
}

export interface IMenuBar {
  items: IMenuItem[];
}

export const MenuBar = ({ items }: IMenuBar) => {
  const { wrapper, menuItem } = convertKeysToCamelCase(style);
  const dispatch = useDispatch();

  return (
    <div className={wrapper}>
      {items.map((item, index) => {
        const { icon, onClick } = item;

        return (
          <span
            key={index}
            className={menuItem}
            onClick={() => {
              dispatch({ type: RESET_ACTUAL_FILTER });
              dispatch({ type: RESET_ORDER });
              dispatch({ type: SET_GLOBAL_SEARCH, payload: '' });
              dispatch({ type: SET_RESET_GLOBAL_SEARCH, payload: true });
              onClick();
            }}
          >
            {icon}
          </span>
        );
      })}
    </div>
  );
};
