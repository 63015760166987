import { IUseProfileMenu } from '../interfaces/components/Hooks';
import { useState } from 'react';

function useProfileMenu(): IUseProfileMenu {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisible = () => {
    setIsVisible(!isVisible);
  };

  return { isVisible, handleVisible };
}

export default useProfileMenu;
