import PlansHeader from '../PlansHeader';
import PlanTitle from '../PlanTitle';
import FetchPlans from '../FetchPlans';
import AdSection from '../AdSection';
import usePlans from '../../hooks/usePlans';
import useGetTranslations from '../../hooks/useGetTranslations';
import useGeneral from '../../hooks/useGeneral';
import useSetHeader from '../../hooks/useSetHeader';
import { MY_SUBSCRIPTION } from '../../constants/routes';

function Plans(): JSX.Element {
  const { plans, onChange } = usePlans();
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();

  useSetHeader(() => {
    handleRedirect(MY_SUBSCRIPTION);
  }, translations.plansTitle);

  return (
    <main>
      <PlansHeader onChange={onChange} />
      <PlanTitle />
      <AdSection>
        <FetchPlans plans={plans} />
      </AdSection>
    </main>
  );
}

export default Plans;
