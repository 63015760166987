import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from './useApi';
import { TOKEN_DATA_KEY } from '../constants/login';
import { API_METHOD_GET } from '../constants/api';
import { SET_INSTRUMENTS, SET_INSTRUMENTS_CLIENTS } from '../constants/reduxActions';
import { INSTRUMENTS } from '../constants/routes';
import { IReduxStore } from '../interfaces/IGeneral';
import { IInstrument } from '../interfaces/components/Instrument';

function useGetInstruments(): void {
  const dispatch = useDispatch();
  const { handleApi } = useApi();
  const { intrumentsListRefresh } = useSelector((store: IReduxStore) => store.defaultReducer);

  useEffect(() => {
    getAllInstruments();
  }, [intrumentsListRefresh]);

  const getTotal = async () => {
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENTS);
    if (code !== 200) {
      return;
    }
    return data.total;
  };

  const getAllInstruments = async () => {
    if (!localStorage.getItem(TOKEN_DATA_KEY)) {
      return;
    }

    const total = await getTotal();
    const URL = INSTRUMENTS + `?page=1&perPage=${total}`;

    const { data, code } = await handleApi(API_METHOD_GET, URL);
    if (code !== 200) {
      return;
    }
    dispatch({ type: SET_INSTRUMENTS, payload: data.data });

    const instrumentsWithClient = data.data.filter((instrument: IInstrument) => {
      return instrument.client;
    });

    dispatch({
      type: SET_INSTRUMENTS_CLIENTS,
      payload: instrumentsWithClient.map((instrument: IInstrument) => {
        return instrument.client;
      }),
    });
  };
}

export default useGetInstruments;
