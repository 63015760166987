import style from '../assets/scss/components/ServiceProjectsHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import { IServiceProjectsHeader } from '../interfaces/components/ServicesProjects';
import { SERVICES_PAGE, PROJECTS_PAGE } from '../constants/routes';
import useGeneral from '../hooks/useGeneral';
import Toggle from './Toggle';

function ServiceProjectsHeader(props: IServiceProjectsHeader): JSX.Element {
  const { wrapper, horizontal, line, title, titleSelected, activeText, activeWrapper, categoryWrapper } =
    convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { handleRedirect } = useGeneral();
  const { isServices, onToggleChecked, toggleChecked } = props;

  return (
    <div>
      <div className={wrapper}>
        <div className={categoryWrapper}>
          <p
            className={isServices ? titleSelected : title}
            onClick={() => {
              handleRedirect(SERVICES_PAGE);
            }}
          >
            {translations.servicesTitle}
          </p>
          <div className={horizontal} />
          <p
            className={isServices ? title : titleSelected}
            onClick={() => {
              handleRedirect(PROJECTS_PAGE);
            }}
          >
            {translations.projectsTitle}
          </p>
        </div>
        <div className={activeWrapper}>
          <p className={activeText}>{translations.active}</p>
          <Toggle checked={toggleChecked} onChange={onToggleChecked} />
        </div>
      </div>
      <div className={line} />
    </div>
  );
}

export default ServiceProjectsHeader;
