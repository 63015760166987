import { useRef } from 'react';

import { IUseScrollBlock } from '../interfaces/components/Hooks';

const useScrollBlock = (): IUseScrollBlock => {
  const scroll = useRef(false);

  const blockScroll = () => {
    if (typeof document === 'undefined') return;

    const html = document.documentElement;
    const { body } = document;

    if (!body || !body.style || scroll.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue('padding-right')) || 0;

    html.style.position = 'relative';
    body.style.position = 'relative';
    html.style.height = '100%';
    html.style.overflow = 'hidden';
    body.style.overflow = 'hidden';
    html.style.height = '100%';
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
    html.style.overscrollBehavior = 'none';

    scroll.current = true;
  };

  const allowScroll = () => {
    if (typeof document === 'undefined') return;

    const html = document.documentElement;
    const { body } = document;

    if (!body || !body.style || !scroll.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';
    body.style.maxHeight = '';
    body.style.height = '';

    scroll.current = false;
  };

  return { blockScroll, allowScroll };
};

export default useScrollBlock;
