import usFlag from '../assets/images/us.png';
import deFlag from '../assets/images/de.png';
import frFlag from '../assets/images/fr.png';
import itFlag from '../assets/images/it.png';
import plFlag from '../assets/images/pl.png';
import esFlag from '../assets/images/es.png';

export const LANGUAGE_TYPES = [
  {
    code: 'en',
    name: 'English',
  },
  { code: 'de', name: 'Deutsch' },
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'es',
    name: 'Español',
  },
  { code: 'it', name: 'Italian' },
  { code: 'pl', name: 'Polish' },
];

export const LANGUAGES = [
  {
    code: 'en',
    text: 'EN',
    image: usFlag,
  },
  { code: 'de', text: 'DE', image: deFlag },
  { code: 'fr', text: 'FR', image: frFlag },
  { code: 'es', text: 'ES', image: esFlag },
  { code: 'it', text: 'IT', image: itFlag },
  { code: 'pl', text: 'PL', image: plFlag },
];
