import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import useYupValidationResolver from './useYupValidationResolver';
import { IReduxStore, Value } from '../interfaces/IGeneral';
import { useApi } from './useApi';
import { API_METHOD_POST } from '../constants/api';
import { INSTRUMENT_SERVICES, NEW_PROJECT, PROJECT_DETAIL, TUNERS } from '../constants/routes';
import { IUseNewProject } from '../interfaces/components/Hooks';
import useGeneral from './useGeneral';
import { getNewProjectValidationSchema } from '../helpers/validationSchemaHelper';
import { toast } from 'react-toastify';
import useGetTranslations from './useGetTranslations';
import { formatDate } from '../utils/date';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';
import { toCamelCase } from '../helpers/baseHelper';

function useNewProject(): IUseNewProject {
  const urlParts = window.location.href.split('/');
  const id = urlParts[urlParts.length - 1];
  const [startingDate, setStartingDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [showLeaveWarning, setShowLeaveWarning] = useState<boolean>(true);
  const [newTunerName, setNewTunerName] = useState<string>('');
  const [tuner, setTuner] = useState<ISuggestion>({ id: -1, name: '' });
  const { instruments } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { handleApi } = useApi();
  const { translations } = useGetTranslations();
  const instrument = instruments.find((instrument) => instrument.id.toString() === id);
  const history = useHistory();
  const resolver = useYupValidationResolver(getNewProjectValidationSchema());
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });
  const { handleRedirect } = useGeneral();

  async function onSubmit(form: any) {
    if (startingDate && endDate && new Date(endDate) <= new Date(startingDate)) {
      toast.error(translations.endingDateError);
      return;
    }

    let tunerIdOnSubmit = -1;
    let tunerNameOnSubmit = '';

    if (newTunerName !== '') {
      const { data, code } = await handleApi(API_METHOD_POST, TUNERS, {
        name: newTunerName,
      });

      if (Number(code) >= 400) {
        toast.error(translations[toCamelCase(data.name.replace('api.', ''))] ?? data.name);
        return;
      }

      tunerIdOnSubmit = data.id;
      tunerNameOnSubmit = data.name;
    }

    if (newTunerName === '') {
      tunerIdOnSubmit = tuner.id;
      tunerNameOnSubmit = tuner.name;
    }

    const { code, data } = await handleApi(API_METHOD_POST, NEW_PROJECT, {
      ...form,
      instrument_id: Number(id),
      starting_date: startingDate !== '' ? startingDate : null,
      ending_date: endDate !== '' ? endDate : null,
      tuner_id: tunerIdOnSubmit === -1 ? null : tunerIdOnSubmit,
      tuner: tunerNameOnSubmit === '' ? null : tunerNameOnSubmit,
    });
    if (code === 200) {
      setShowLeaveWarning(false);
      if (instrument && instrument.id) {
        toast.success(translations.savedSuccessfully);
        history.goBack();
        history.replace(INSTRUMENT_SERVICES.replace(':id', String(instrument.id)));
        setTimeout(() => {
          handleRedirect(PROJECT_DETAIL.replace(':id', String(data.id)));
        }, 100);
      }
      return;
    }

    if (data) {
      Object.values(data).map((err: any) => {
        toast.error(err);
      });
    }
  }

  const handleStartingDate = (value: Value) => {
    if (value instanceof Date) {
      setStartingDate(formatDate(value));
    }
  };

  const handleEndDate = (value: Value) => {
    if (value instanceof Date) {
      setEndDate(formatDate(value));
    }
  };

  const handleDeleteDate = () => {
    setStartingDate('');
  };

  const handleDeleteEndDate = () => {
    setEndDate('');
  };

  const handleNewTunerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTunerName(e.target.value);
  };

  const handleTunerMatch = (tuner: ISuggestion | undefined) => {
    tuner && setTuner(tuner);
  };

  return {
    instrument,
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    handleStartingDate,
    startingDate,
    showLeaveWarning,
    handleDeleteDate,
    endDate,
    handleDeleteEndDate,
    handleEndDate,
    tuner,
    newTunerName,
    handleNewTunerNameChange,
    handleTunerMatch,
  };
}

export default useNewProject;
