import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IProjectCard } from '../interfaces/components/Project';
import style from '../assets/scss/components/ProjectCard.module.scss';
import ClientsProjectDetails from './ClientsProjectDetails';
import { ProjectCardTypes } from '../constants/projects';
import InstrumentsProjectDetails from './InstrumentsProjectDetails';

function ProjectCard(props: IProjectCard): JSX.Element {
  const { project, onClick, type, showInstrumentData = true, endData } = props;
  const { wrapper, bubble, greenBubble, bubbleContainer, shortLine, longLine, bubbleOut, right } =
    convertKeysToCamelCase(style);

  return (
    <div
      className={wrapper}
      onClick={() => {
        onClick();
      }}
    >
      <div className={bubbleContainer}>
        <div className={shortLine} />
        <div className={bubbleOut}>
          <div className={project?.isActive ? greenBubble : bubble} />
        </div>
        <div className={longLine} />
      </div>
      <div className={right}>
        {type === ProjectCardTypes.CLIENT_PROJECT && <ClientsProjectDetails project={project} />}
        {type === ProjectCardTypes.INSTRUMENT_PROJECT && (
          <InstrumentsProjectDetails project={project} showInstrumentData={showInstrumentData} endData={endData} />
        )}
      </div>
    </div>
  );
}

export default ProjectCard;
