import { useSelector } from 'react-redux';

import { MY_ACCOUNT, MY_SUBSCRIPTION, CURRENCY_LANGUAGE, LOG_OUT } from '../constants/routes';
import ProfileRow from './ProfileRow';
import useGetTranslations from '../hooks/useGetTranslations';
import { IReduxStore } from '../interfaces/IGeneral';
import LogOutPopup from './LogOutPopup';
import useProfileMenu from '../hooks/useProfileMenu';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/ProfileMenu.module.scss';

function ProfileMenu(): JSX.Element {
  const { translations } = useGetTranslations();
  const { menugroups } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { isVisible, handleVisible } = useProfileMenu();
  const { wrapper } = convertKeysToCamelCase(style);
  const contacts = menugroups.find((menu) => menu.name === 'contact');

  return (
    <div className={wrapper}>
      <ProfileRow text={translations.myAccountTitle} to={MY_ACCOUNT} showLine />
      <ProfileRow text={translations.mySubscriptionTitle} to={MY_SUBSCRIPTION} showLine />
      <ProfileRow text={translations.currencyTitle} to={CURRENCY_LANGUAGE} showLine />
      <ProfileRow
        text={translations.contactUsTitle}
        to={contacts?.menus.find((menuPoint) => menuPoint.name === 'contact_us_title')?.url as string}
        showLine
        navigate
      />
      <ProfileRow text={translations.logOutTitle} to={LOG_OUT} showLine={false} onClick={handleVisible} />
      {isVisible && <LogOutPopup isVisible={isVisible} toggleModal={handleVisible} />}
    </div>
  );
}

export default ProfileMenu;
