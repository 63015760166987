import style from '../assets/scss/components/ChooseBrand.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import SuggestionSearch from './SuggestionSearch';
import useGetTranslation from '../hooks/useGetTranslations';
import useChooseBrands from '../hooks/useChooseBrands';
import { IChooseBrand } from '../interfaces/components/Instrument';

const ChooseBrand = (props: IChooseBrand): JSX.Element => {
  const { row, text, line, inpContainer, gold, noSpace, input, inp } = convertKeysToCamelCase(style);
  const { onMatch, isEditing, brand, handleNewBrandNameChange, newBrandName } = props;
  const { brands } = useChooseBrands();
  const { translations } = useGetTranslation();

  return (
    <div>
      {isEditing ? (
        <>
          <p className={`bubble ${inp}`}>{brand?.name}</p>
          <>
            <div className={row}>
              <p className={text}>{translations.brandChoose}</p>
              <div className={inpContainer}>
                <SuggestionSearch onMatch={onMatch} suggestions={brands} placeholder={translations.changeClient} />
              </div>
            </div>
            <div className={`${row} ${noSpace}`}>
              <p className={`${text} ${gold}`}>{translations.or}</p>
              <div className={line} />
            </div>
            <div className={row}>
              <p className={text}>{translations.brandAddNew}</p>
              <div className={inpContainer}>
                <input
                  type="text"
                  className={`primary ${input}`}
                  value={newBrandName}
                  onChange={handleNewBrandNameChange}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                />
              </div>
            </div>
          </>
        </>
      ) : (
        <p className={`bubble ${inp}`}>{brand?.name}</p>
      )}
    </div>
  );
};

export default ChooseBrand;
