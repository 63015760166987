import style from '../assets/scss/components/BlueHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IBlueHeader } from '../interfaces/components/BlueHeader';

function BlueHeader(props: IBlueHeader): JSX.Element {
  const { wrapper, row, whiteWrapper } = convertKeysToCamelCase(style);
  const { element, isWhiteBackground } = props;

  return (
    <div className={isWhiteBackground ? whiteWrapper : wrapper}>
      <section className={`${row} small`}>{element}</section>
    </div>
  );
}

export default BlueHeader;
