import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADD_FILTER } from '../constants/reduxActions';
import { IInstrument } from '../interfaces/components/Instrument';
import { IReduxStore } from '../interfaces/IGeneral';

function useMyInstrumentsFilter() {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const { instruments, hamburgerRef } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [myInstruments, setMyInstruments] = useState<IInstrument[]>([]);

  useEffect(() => {
    getMyInstruments();
  }, [instruments]);

  useEffect(() => {
    if (hamburgerRef === null) {
      setToggle(false);
    }
  }, [hamburgerRef]);

  const handleToggle = () => {
    dispatch({ type: ADD_FILTER, payload: { myInstruments: !toggle } });
    setToggle(!toggle);
  };

  const getMyInstruments = () => {
    const result: IInstrument[] = [];
    instruments.map((instrument) => {
      instrument.client === null && result.push(instrument);
    });
    setMyInstruments(result);
  };

  return { toggle, myInstruments, handleToggle };
}

export default useMyInstrumentsFilter;
