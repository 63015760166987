import { createRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Resizer from 'react-image-file-resizer';

import { API_METHOD_GET, API_METHOD_PUT, API_METHOD_DELETE } from '../constants/api';
import { useApi } from './useApi';
import { GET_CLIENT, CLIENTS_PAGE, INSTRUMENT_DETAILS, CLIENT_DETAILS, EDIT_CLIENT } from '../constants/routes';
import useGeneral from './useGeneral';
import { IClient } from '../interfaces/components/Clients';
import { IReduxStore } from '../interfaces/IGeneral';
import useYupValidationResolver from './useYupValidationResolver';
import { getClientValidationSchema, getCompanyClientValidationSchema } from '../helpers/validationSchemaHelper';
import { getBase64 } from '../helpers/baseHelper';
import { IInstrument } from '../interfaces/components/Instrument';
import { IUseEditClients } from '../interfaces/components/Hooks';
import { toast } from 'react-toastify';
import useGetTranslations from './useGetTranslations';
import { useHistory } from 'react-router';
import { SET_CLIENT_LIST_REFRESH } from '../constants/reduxActions';
import { ReactCropperElement } from 'react-cropper';
import { nanoid } from 'nanoid';

function useEditClients(): IUseEditClients {
  const urlParts = window.location.href.split('/');
  const id = urlParts[urlParts.length - 1];
  const { instruments } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [client, setClient] = useState<IClient>();
  const [showLeaveWarning, setShowLeaveWarning] = useState<boolean>(true);
  const [deleteProfilePicture, setDeleteProfilePicture] = useState<boolean>(false);
  const [selectedInstruments, setSelectedInstruments] = useState<Array<IInstrument>>([]);
  const [selectedImage, setSelectedImage] = useState<{ file: string; name: string }>();
  const [clientType, setClientType] = useState<'private' | 'company'>('private');
  const { handleApi } = useApi();
  const { handleRedirect, goBack } = useGeneral();
  const { clients } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslations();
  const history = useHistory();
  const dispatch = useDispatch();
  //prettier-ignore
  const resolver = useYupValidationResolver(
    clientType !== 'company' ? getClientValidationSchema() : getCompanyClientValidationSchema()
  );
  const [cropImage, setCropImage] = useState<string>('');
  const cropperRef = createRef<ReactCropperElement>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setClientValue,
    control,
    getValues,
  } = useForm<IClient>({ resolver });

  useEffect(() => {
    fetchClient();
  }, [clients]);

  useEffect(() => {
    if (client) {
      setClientValue('firstName', client.firstName);
      setClientValue('lastName', client.lastName);
      setClientValue('street', client.street);
      setClientValue('city', client.city);
      setClientValue('region', client.region);
      setClientValue('country', client.country);
      setClientValue('phone1', client.phone1);
      setClientValue('phone2', client.phone2);
      setClientValue('nick', client.nick);
      setClientValue('postcode', client.postcode);
      setClientValue('email', client.email);
      setClientValue('note', client.note);
      setClientValue('type', client.type);
      setClientType(client.type as 'private' | 'company');
    }
  }, [client]);

  async function fetchClient() {
    const { data } = await handleApi(API_METHOD_GET, GET_CLIENT.replace(':id', id));
    if (data) {
      setClient(data);
      setSelectedImage({ file: data.thumbnail, name: '' });
      setSelectedInstruments(data.instruments);
    }
  }
  const onConvertReady = (fileString: string | ArrayBuffer | null, name: string) => {
    setSelectedImage({ file: fileString as string, name });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      // getBase64(file, onConvertReady);
      getBase64(file, handleCropImage);
    }
  };

  const handleCropImage = (fileString: string | ArrayBuffer | null, name: string) => {
    name;
    if (typeof fileString === 'string') {
      setCropImage(fileString);
    }
  };

  const handleCancelCrop = () => {
    setCropImage('');
  };

  const handleFinishCrop = async () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const croppedImageDataURL = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      const byteString = atob(croppedImageDataURL.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: 'image/png' });

      // Create a File object from the Blob
      const file = new File([blob], 'cropped_image.png', { type: 'image/png' });

      const resized = await resizeFile(file);
      setDeleteProfilePicture(false);
      onConvertReady(resized as any, `${nanoid()}}.png`);
      setCropImage('');
    }
  };

  /*prettier-ignore*/
  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1020,
        1020,
        'JPEG',
        200,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'base64'
      );
    });

  const handleAddressClick = () => {
    if (client?.region) {
      //prettier-ignore
      const searchAddress = new URLSearchParams(
        `${client?.street} ${client?.city} ${client.region} ${client?.country}`
      ).toString();
      window.open('http://maps.apple.com/?address=' + searchAddress, '_blank');
      return;
    }
    const searchAddress = new URLSearchParams(`${client?.street} ${client?.city} ${client?.country}`).toString();
    window.open('http://maps.apple.com/?address=' + searchAddress, '_blank');
  };

  const onMatchInstrument = (dat: any) => {
    if (dat && !selectedInstruments.find((instr) => instr.id === dat.id)) {
      const intst = instruments.find((inst) => inst.id === dat.id);
      if (intst) setSelectedInstruments([...selectedInstruments, intst]);
    }
  };

  async function onSubmit(formData: any) {
    let form = {
      ...formData,
      instrumentIds: selectedInstruments.map((instr) => instr.id),
      country: getValues('country') === null ? '' : getValues('country'),
      deleteThumbnail: deleteProfilePicture ? '1' : '0',
      type: clientType,
      lastName: clientType === 'company' ? '' : formData.lastName,
    };
    if (selectedImage?.name)
      form = {
        ...form,
        thumbnail: selectedImage?.file,
        thumbnail_name: selectedImage?.name,
      };
    const { code } = await handleApi(API_METHOD_PUT, GET_CLIENT.replace(':id', id), form);
    if (code === 200) {
      toast.success(translations.savedSuccessfully);
    }
    dispatch({ type: SET_CLIENT_LIST_REFRESH, payload: true });
    setShowLeaveWarning(false);
    goBack();
  }

  async function onDelete() {
    setShowLeaveWarning(false);
    history.go(-3);
    await handleApi(API_METHOD_DELETE, GET_CLIENT.replace(':id', id));
    setTimeout(() => {
      handleRedirect(CLIENTS_PAGE);
    }, 100);
  }

  const handleDeleteSelected = (id: number) => {
    setSelectedInstruments([...selectedInstruments.filter((instrument) => instrument.id !== id)]);
  };

  const handleInstrumentClick = (id: number) => {
    handleRedirect(INSTRUMENT_DETAILS.replace(':id', id.toString()));
  };

  const toggleEditing = () => {
    if (window.location.href.includes('/edit/')) {
      handleRedirect(CLIENT_DETAILS.replace(':id', String(id)));
      return;
    }
    handleRedirect(EDIT_CLIENT.replace(':id', String(id)));
  };

  const handleDeleteProfilePicture = () => {
    setDeleteProfilePicture(true);
    setSelectedImage({ file: '', name: '' });
  };

  const handleClientType = (newType: string) => {
    setClientType(newType as 'private' | 'company');
  };

  return {
    handleAddressClick,
    client,
    showLeaveWarning,
    toggleEditing,
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    selectedImage: selectedImage?.file,
    handleImageChange,
    onDelete,
    selectedInstruments,
    onMatchInstrument,
    handleDeleteSelected,
    control,
    handleInstrumentClick,
    handleDeleteProfilePicture,
    cropImage,
    cropperRef,
    handleFinishCrop,
    handleCancelCrop,
    clientType,
    handleClientType,
  };
}

export default useEditClients;
