import RemindersForm from '../RemindersForm';
import EditHeader from '../EditHeader';
import AdSection from '../AdSection';
import useReminderDetails from '../../hooks/useReminderDetails';
import RemindersCard from '../RemindersCard';
import useGetTranslations from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import IdComponent from '../IdComponent';
import { MenuBar } from '../MenuBar';
import { MdPiano } from 'react-icons/md';
import { CLIENT_DETAILS, INSTRUMENT_DETAILS, NEW_CLIENT_PAGE } from '../../constants/routes';
import { BsFillPersonFill } from 'react-icons/bs';
import LimitExceededNotification, { limitExceededOptions } from '../LimitExceededNotification';
import { toast } from 'react-toastify';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import useGeneral from '../../hooks/useGeneral';
import useProfile from '../../hooks/useProfile';

function ReminderDetails(): JSX.Element {
  const {
    reminder,
    toggleEditing,
    register,
    handleSubmit,
    errors,
    handleDelete,
    handleReminderDateValue,
    reminderDateValue,
    handleReminderRepeatValue,
    reminderRepeat,
    handleSendEmail,
    isSendEmail,
    handleDeleteReminderValue,
    saveTemplate,
  } = useReminderDetails();
  const { canCreateNewClient } = useMySubscriptionPlan();
  const { handleRedirect } = useGeneral();
  const { profile } = useProfile();
  const { translations } = useGetTranslations();

  useSetHeader(() => {}, translations.reminderDetailTitle);

  return (
    <main>
      <EditHeader
        isEditing={false}
        toggleEditing={toggleEditing}
        handleDelete={handleDelete}
        deleteTitle={translations.deleteReminderDescription}
        deleteButtonText={translations.deleteReminder}
      />
      <AdSection topPadding>
        <>
          <IdComponent id={reminder?.id} text={translations.reminderId} />
          {reminder && (
            <RemindersCard
              isSimple={true}
              isEditing={false}
              isNew={false}
              selectedImage={reminder.instrument.data.thumbnail}
              isOverForm
              reminder={{
                client: reminder.instrument.client?.data,
                id: reminder.id,
                brand: reminder.instrument.data.brand,
                model: reminder.instrument.data.model,
                category: reminder.instrument.data.category,
                thumbnail: reminder.instrument.data.thumbnail,
                upcomingReminderDate: '',
              }}
            />
          )}
          <MenuBar
            items={[
              {
                icon: <MdPiano />,
                onClick: () => {
                  if (!reminder?.instrument.data.id) {
                    return;
                  }
                  handleRedirect(INSTRUMENT_DETAILS.replace(':id', reminder?.instrument.data.id.toString()));
                },
              },
              {
                icon: <BsFillPersonFill />,
                onClick: () => {
                  if (!reminder) {
                    return;
                  }

                  if (!reminder.instrument.client.data || reminder.instrument.client.data === null) {
                    if (!canCreateNewClient()) {
                      toast.error(<LimitExceededNotification />, limitExceededOptions);
                      return;
                    }
                    handleRedirect(NEW_CLIENT_PAGE + `?instrumentId=${reminder.instrument.data.id}`);
                    return;
                  } else {
                    handleRedirect(CLIENT_DETAILS.replace(':id', reminder.instrument.client.data.id.toString()));
                  }
                },
              },
            ]}
          />
          <RemindersForm
            reminder={reminder}
            isEditing={false}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            isSendMail={isSendEmail}
            handleSendEmail={handleSendEmail}
            reminderDateValue={reminderDateValue}
            handleReminderDateValue={handleReminderDateValue}
            showLeaveWarning={true}
            handleReminderRepeatValue={handleReminderRepeatValue}
            reminderRepeatValue={reminderRepeat}
            handleDeleteReminderDate={handleDeleteReminderValue}
            showSendEmail={
              !!reminder?.instrument?.client?.data && reminder?.instrument?.client?.data.email ? true : false
            }
            saveReminderTemplate={saveTemplate}
            profile={profile}
          />
        </>
      </AdSection>
    </main>
  );
}

export default ReminderDetails;
