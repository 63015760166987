import style from '../assets/scss/components/LoginBottom.module.scss';
import { REGISTRATION } from '../constants/routes';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGeneral from '../hooks/useGeneral';
import useGetTranslations from '../hooks/useGetTranslations';

function LoginBottom(): JSX.Element {
  const { handleRedirect } = useGeneral();
  const { wrapper, sign } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  return (
    <div className={wrapper}>
      <p>{translations.loginNewExperience}</p>
      <p
        className={sign}
        onClick={() => {
          handleRedirect(REGISTRATION);
        }}
      >
        {translations.loginSignUp}
      </p>
    </div>
  );
}

export default LoginBottom;
