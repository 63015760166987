import ProfileCard from '../ProfileCard';
import MySubscriptionDetails from '../MySubscriptionDetails';
import useGetTranslations from '../../hooks/useGetTranslations';
import useGeneral from '../../hooks/useGeneral';
import AdSection from '../AdSection';
import useSetHeader from '../../hooks/useSetHeader';
import { PROFILE } from '../../constants/routes';
import { useSelector } from 'react-redux';
import { IReduxStore } from '../../interfaces/IGeneral';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import style from '../../assets/scss/components/MyAccountHeader.module.scss';

function MySubscription(): JSX.Element {
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();
  const { profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { spacer } = convertKeysToCamelCase(style);

  useSetHeader(() => {
    handleRedirect(PROFILE);
  }, translations.mySubscriptionTitle);

  return (
    <main>
      <div className={spacer} />
      <AdSection>
        <ProfileCard clientId={profile?.id} />

        <MySubscriptionDetails />
      </AdSection>
    </main>
  );
}

export default MySubscription;
