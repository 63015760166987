import { MdModeEditOutline } from 'react-icons/md';
import { BsTrash } from 'react-icons/bs';

import BlueHeader from './BlueHeader';
import DeleteModal from './DeleteModal';
import style from '../assets/scss/components/EditHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IEditHeader } from '../interfaces/components/Headers';
import useEditHeader from '../hooks/useEditHeader';
import useGetTranslations from '../hooks/useGetTranslations';

function ClientEditHeader(props: IEditHeader): JSX.Element {
  const { isEditing, toggleEditing, handleDelete, deleteTitle, isWhiteBackground, deleteButtonText } = props;
  const { row, wrapper, trash, icon } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { isVisible, toggleVisible } = useEditHeader();

  return (
    <div>
      <BlueHeader
        isWhiteBackground={isWhiteBackground}
        element={
          <div className={wrapper}>
            {!isEditing && (
              <div className={row} onClick={toggleEditing}>
                <p>{translations.edit}</p>
                <MdModeEditOutline className={icon} />
              </div>
            )}
            {isEditing && deleteButtonText?.length > 0 && (
              <div className={`${row} ${trash}`} onClick={deleteTitle ? toggleVisible : handleDelete}>
                <p>{deleteButtonText}</p>
                <BsTrash className={icon} />
              </div>
            )}
          </div>
        }
      />
      {deleteTitle && (
        <DeleteModal
          isVisible={isVisible}
          toggleVisible={toggleVisible}
          handleDelete={handleDelete}
          description={deleteTitle}
        />
      )}
    </div>
  );
}

export default ClientEditHeader;
