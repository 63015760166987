import { BsCameraFill } from 'react-icons/bs';
import { ImAttachment } from 'react-icons/im';
import style from '../assets/scss/components/AttachmentIcons.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IAttachmentIcons } from '../interfaces/components/Instrument';
import { ATTACHMENTS_PAGE } from '../constants/routes';
import useGeneral from '../hooks/useGeneral';
import { useDispatch } from 'react-redux';
import { SET_ATTACHMENTS_PAGE_SWITCHER } from '../constants/reduxActions';
import { ImageCropper } from './ImageCropper';

function AttachmentIcons(props: IAttachmentIcons): JSX.Element {
  const { row, rowIndented, icon, iconAttach } = convertKeysToCamelCase(style);
  const {
    onPictureChange,
    onAttachmentChange,
    pictureNr,
    attachmentNr,
    instrumentId,
    type,
    cropperRef,
    cropImage,
    handleFinishCrop,
    handleCancelCrop,
    cancelText,
    cropText,
  } = props;
  const { handleRedirect } = useGeneral();
  const dispatch = useDispatch();

  const handleClickOnPhotos = () => {
    if (!onPictureChange && instrumentId && type) {
      dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'image' });
      handleRedirect(ATTACHMENTS_PAGE.replace(':id', instrumentId.toString()).replace(':type', type));
    }
  };

  const handleClickOnAttachments = () => {
    if (!onPictureChange && instrumentId && type) {
      dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'file' });
      handleRedirect(ATTACHMENTS_PAGE.replace(':id', instrumentId.toString()).replace(':type', type));
    }
  };

  //prettier-ignore
  return (
    <div>
      <div className={`${row} ${rowIndented}`}>
        {onPictureChange && (
          <input id="image" name="image" type="file" onChange={onPictureChange} accept="image/*" multiple />
        )}
        <label htmlFor="image">
          <BsCameraFill className={icon} onClick={handleClickOnPhotos} />
        </label>
        {pictureNr !== undefined && <p>{`( ${pictureNr} )`}</p>}
        {onAttachmentChange && (
          <input id="attachment" name="attachment" type="file" onChange={onAttachmentChange} multiple />
        )}
        <label htmlFor="attachment">
          <ImAttachment className={`${icon} ${iconAttach}`} onClick={handleClickOnAttachments} />
        </label>
        {attachmentNr !== undefined && <p>{`( ${attachmentNr} )`}</p>}
      </div>
      <>
        {cropImage !== '' &&
          cropperRef &&
          cropImage &&
          handleFinishCrop &&
          cropText &&
          cancelText &&
          handleCancelCrop && (
          <ImageCropper
            cropperRef={cropperRef}
            cropImage={cropImage}
            handleFinishCrop={handleFinishCrop}
            cropText={cropText}
            cancelText={cancelText}
            handleCancel={handleCancelCrop}
          />
        )}
      </>
    </div>
  );
}

export default AttachmentIcons;
