import { BsFilterSquare, BsFilterSquareFill } from 'react-icons/bs';

import useSortIcon from '../hooks/useSortIcon';
import Title from './Title';
import useGetTranslations from '../hooks/useGetTranslations';
import { ISortIcon } from '../interfaces/components/GreyModal';
import style from '../assets/scss/components/SortIcon.module.scss';
import HamburgerBase from './HamburgerBase';

function SortIcon(props: ISortIcon): JSX.Element {
  const { title, field, isService, isProject, isInactive, parentPage } = props;
  const {
    isVisible,
    toggleVisible,
    handleNewest,
    handleOldest,
    handleTitleAscend,
    handleTitleDescend,
    handleLastAsc,
    handleLastDesc,
    handleNextAsc,
    handleNextDesc,
    handleProjectDateAsc,
    handleProjectDateDesc,
    handleNewestDate,
    handleOldestDate,
    handleNewestReminderDate,
    handleOldestReminderDate,
    handleProjectEndingDateAsc,
    handleProjectEndingDateDesc,
    handleScheduledDateAsc,
    handleScheduledDateDesc,
    handleTunerAsc,
    handleTunerDesc,
  } = useSortIcon(field);
  const { translations } = useGetTranslations();
  const { wrapper, content, link, inactive } = style;

  // TODO: refactor: simplify the whole component and logic

  return (
    <div className={wrapper}>
      {isInactive ? (
        <BsFilterSquare className={`filter-icon ${inactive}`} color="#808080" />
      ) : isVisible ? (
        <BsFilterSquareFill className="filter-icon" color="#172B4D" onClick={toggleVisible} />
      ) : (
        <BsFilterSquare className="filter-icon" color="#172B4D" onClick={toggleVisible} />
      )}
      <div className={content}>
        <HamburgerBase
          childrenContent={
            <div>
              {title && (
                <>
                  <div onClick={handleTitleAscend} className={link}>
                    <Title title={title.ascending} alignCenter />
                  </div>
                  <div onClick={handleTitleDescend} className={link}>
                    <Title title={title.descending} alignCenter />
                  </div>
                </>
              )}

              {parentPage === 'serviceList' && (
                <>
                  <div onClick={handleOldestDate} className={link}>
                    <Title title={translations.sortOldest} alignCenter />
                  </div>
                  <div onClick={handleNewestDate} className={link}>
                    <Title title={translations.sortNewest} alignCenter />
                  </div>
                  <div onClick={handleScheduledDateAsc} className={link}>
                    <Title title={translations.sortScheduledOldest} alignCenter />
                  </div>
                  <div onClick={handleScheduledDateDesc} className={link}>
                    <Title title={translations.sortScheduledNewest} alignCenter />
                  </div>
                </>
              )}

              {parentPage === 'instrumentServices' && (
                <>
                  <div onClick={handleTunerAsc} className={link}>
                    <Title title={translations.sortTunerAsc} alignCenter />
                  </div>
                  <div onClick={handleTunerDesc} className={link}>
                    <Title title={translations.sortTunerDesc} alignCenter />
                  </div>
                </>
              )}

              {parentPage === 'activeServiceList' && (
                <>
                  <div onClick={handleLastAsc} className={link}>
                    <Title title={translations.sortLastDateAscending} alignCenter />
                  </div>
                  <div onClick={handleLastDesc} className={link}>
                    <Title title={translations.sortLastDateDescending} alignCenter />
                  </div>
                  <div onClick={handleNextAsc} className={link}>
                    <Title title={translations.sortNextDateAscending} alignCenter />
                  </div>
                  <div onClick={handleNextDesc} className={link}>
                    <Title title={translations.sortNextDateDescending} alignCenter />
                  </div>
                </>
              )}

              {parentPage === 'projectList' && (
                <>
                  <div onClick={handleProjectEndingDateAsc} className={link}>
                    <Title title={translations.sortEndingDateAscending} alignCenter />
                  </div>
                  <div onClick={handleProjectEndingDateDesc} className={link}>
                    <Title title={translations.sortEndingDateDescending} alignCenter />
                  </div>
                  <div onClick={handleProjectDateAsc} className={link}>
                    <Title title={translations.sortStartingDateAscending} alignCenter />
                  </div>
                  <div onClick={handleProjectDateDesc} className={link}>
                    <Title title={translations.sortStartingDateDescending} alignCenter />
                  </div>
                </>
              )}

              {parentPage === 'activeProjectList' && (
                <>
                  <div onClick={handleProjectEndingDateAsc} className={link}>
                    <Title title={translations.sortEndingDateAscending} alignCenter />
                  </div>
                  <div onClick={handleProjectEndingDateDesc} className={link}>
                    <Title title={translations.sortEndingDateDescending} alignCenter />
                  </div>
                  <div onClick={handleProjectDateAsc} className={link}>
                    <Title title={translations.sortStartingDateAscending} alignCenter />
                  </div>
                  <div onClick={handleProjectDateDesc} className={link}>
                    <Title title={translations.sortStartingDateDescending} alignCenter />
                  </div>
                </>
              )}

              {parentPage === 'reminderList' && (
                <>
                  <div onClick={handleOldestReminderDate} className={link}>
                    <Title title={translations.sortOldest} alignCenter />
                  </div>
                  <div onClick={handleNewestReminderDate} className={link}>
                    <Title title={translations.sortNewest} alignCenter />
                  </div>
                </>
              )}

              {parentPage === 'intrumentList' && (
                <>
                  <div onClick={handleOldest} className={link}>
                    <Title title={translations.sortOldest} alignCenter />
                  </div>
                  <div onClick={handleNewest} className={link}>
                    <Title title={translations.sortNewest} alignCenter />
                  </div>
                </>
              )}

              {parentPage === 'clientList' && (
                <>
                  <div onClick={handleOldest} className={link}>
                    <Title title={translations.sortOldest} alignCenter />
                  </div>
                  <div onClick={handleNewest} className={link}>
                    <Title title={translations.sortNewest} alignCenter />
                  </div>
                </>
              )}

              {!isService ? (
                isProject && (
                  <>
                    <div onClick={handleProjectDateAsc} className={link}>
                      <Title title={translations.sortLastDateAscending} alignCenter />
                    </div>
                    <div onClick={handleProjectDateDesc} className={link}>
                      <Title title={translations.sortLastDateDescending} alignCenter />
                    </div>
                  </>
                )
              ) : (
                <>
                  <div onClick={handleLastAsc} className={link}>
                    <Title title={translations.sortLastDateAscending} alignCenter />
                  </div>
                  <div onClick={handleLastDesc} className={link}>
                    <Title title={translations.sortLastDateDescending} alignCenter />
                  </div>
                  <div onClick={handleNextAsc} className={link}>
                    <Title title={translations.sortNextDateAscending} alignCenter />
                  </div>
                  <div onClick={handleNextDesc} className={link}>
                    <Title title={translations.sortNextDateDescending} alignCenter />
                  </div>
                </>
              )}
            </div>
          }
          headerTitle={translations.sortTitle}
          isOpen={isVisible}
          toggleOpen={toggleVisible}
          disableScroll
        />
      </div>
    </div>
  );
}

export default SortIcon;
