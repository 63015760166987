import { useParams } from 'react-router';

import RemindersForm from '../RemindersForm';
import AdSection from '../AdSection';
import useNewReminder from '../../hooks/useNewReminder';
import useSetHeader from '../../hooks/useSetHeader';
import { INSTRUMENT_SERVICES } from '../../constants/routes';
import useGeneral from '../../hooks/useGeneral';
import useGetTranslations from '../../hooks/useGetTranslations';
import InstrumentCard from '../InstrumentCard';
import { useSelector } from 'react-redux';
import { IReduxStore } from '../../interfaces/IGeneral';
import useProfile from '../../hooks/useProfile';

function NewReminderPage(): JSX.Element {
  const {
    register,
    handleSubmit,
    errors,
    isSendEmail,
    handleSendEmail,
    saveTemplate,
    showLeaveWarning,
    handleReminderDateValue,
    reminderDateValue,
    handleReminderRepeatValue,
    reminderRepeat,
    handleDeleteReminderValue,
  } = useNewReminder();
  const { translations } = useGetTranslations();
  const { handleRedirect } = useGeneral();
  const { id } = useParams<{ id: string }>();
  const { instruments } = useSelector((store: IReduxStore) => store.defaultReducer);
  const instrument = instruments && instruments.find((instr) => instr.id.toString() === id);
  const { profile } = useProfile();
  useSetHeader(() => {
    handleRedirect(INSTRUMENT_SERVICES.replace(':id', id));
  }, translations.reminderNewTitle);

  return (
    <main>
      <AdSection>
        <InstrumentCard isSimple={true} {...instrument} />
        <RemindersForm
          register={register}
          handleSubmit={handleSubmit}
          isEditing={true}
          showLeaveWarning={showLeaveWarning}
          errors={errors}
          showSendEmail={instrument && instrument.client && instrument.client.email !== '' ? true : false}
          isSendMail={isSendEmail}
          handleSendEmail={handleSendEmail}
          saveReminderTemplate={saveTemplate}
          reminderDateValue={reminderDateValue}
          handleReminderDateValue={handleReminderDateValue}
          handleReminderRepeatValue={handleReminderRepeatValue}
          reminderRepeatValue={reminderRepeat}
          handleDeleteReminderDate={handleDeleteReminderValue}
          profile={profile}
        />
      </AdSection>
    </main>
  );
}

export default NewReminderPage;
