import { BsCheck } from 'react-icons/bs';
import style from '../assets/scss/components/AttachmentImages.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IAttachmentImages } from '../interfaces/components/Attachments';
import Modal from './Modal';
import useMobile from '../hooks/useMobile';
import InfiniteScroll from 'react-infinite-scroll-component';
import { paddingForInfiniteScroll } from '../constants/infiniteScrollStyle';
import PaginationElements from './PaginationElements';

function AttachmentImages(props: IAttachmentImages): JSX.Element {
  const { wrapper, image, imageContainer, logo, logoSelected, logoUnselected, modalImage, grid } =
    convertKeysToCamelCase(style);
  const { isEditing, files, selectedImages, onImageClick, imageModal, closeModal, nextPage, total } = props;
  const { isMobile } = useMobile();

  return (
    <div className={wrapper}>
      <Modal
        isOpen={imageModal?.isOpen ? true : false}
        onClose={closeModal}
        content={<img src={imageModal?.image?.url} className={modalImage} />}
        noContent
      />
      {nextPage ? (
        <>
          {isMobile ? (
            <InfiniteScroll
              dataLength={total ?? 1}
              next={nextPage}
              hasMore={true}
              loader={<></>}
              style={paddingForInfiniteScroll}
              className={grid}
            >
              {files?.map((attachment) => (
                <div
                  className={imageContainer}
                  key={attachment.id}
                  onClick={() => {
                    onImageClick(attachment);
                  }}
                >
                  <img src={attachment.url} className={image} />
                  {isEditing && (
                    <BsCheck
                      className={` ${
                        selectedImages?.find((img) => img.id === attachment.id) ? logoSelected : logoUnselected
                      } ${logo}`}
                    />
                  )}
                </div>
              ))}
            </InfiniteScroll>
          ) : (
            <>
              {files?.map((attachment) => (
                <div
                  className={imageContainer}
                  key={attachment.id}
                  onClick={() => {
                    onImageClick(attachment);
                  }}
                >
                  <img src={attachment.url} className={image} />
                  {isEditing && (
                    <BsCheck
                      className={` ${
                        selectedImages?.find((img) => img.id === attachment.id) ? logoSelected : logoUnselected
                      } ${logo}`}
                    />
                  )}
                </div>
              ))}
              {!!total && <PaginationElements length={total} perPage={20} />}
            </>
          )}
        </>
      ) : (
        files?.map((attachment) => (
          <div
            className={imageContainer}
            key={attachment.id}
            onClick={() => {
              onImageClick(attachment);
            }}
          >
            <img src={attachment.url} className={image} />
            {isEditing && (
              <BsCheck
                className={` ${
                  selectedImages?.find((img) => img.id === attachment.id) ? logoSelected : logoUnselected
                } ${logo}`}
              />
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default AttachmentImages;
