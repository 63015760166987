import Title from './Title';
import style from '../assets/scss/components/ServiceDate.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IServiceDate } from '../interfaces/components/Service';
import useGetTranslations from '../hooks/useGetTranslations';
import { MdAutoDelete, MdDateRange } from 'react-icons/md';
import { AiFillInfoCircle, AiOutlineClockCircle } from 'react-icons/ai';
import AlertModal from './AlertModal';
import DatePicker from 'react-date-picker';
import { useRef } from 'react';

function ServiceDate(props: IServiceDate): JSX.Element {
  const {
    row,
    nextText,
    errorText,
    deleteIcon,
    inputWrapper,
    infoImage,
    infoDescription,
    infoContainer,
    clockInfo,
    clockInfoIcon,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const {
    register,
    isEditing,
    lastDate,
    nextDate,
    errors,
    lastDateOriginalValue,
    nextDateOriginalValue,
    dateTimeInfo,
    handleLastValue,
    handleNextValue,
    handleDeleteLastDate,
    handleDeleteNextDate,
    isModalOpen,
    toggleModal,
    handleModalConfirm,
  } = props;
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleScrollToDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div>
      <AlertModal
        isOpen={isModalOpen ?? false}
        onClose={() => {
          if (toggleModal) {
            toggleModal();
          }
        }}
        header={translations.serviceCompletedAlertTitle}
        description={translations.serviceCompletedAlertDescription}
        leftButton={translations.serviceCompletedAlertCancel}
        rightButton={translations.serviceCompletedAlertConfirm}
        onLeftButtonClick={() => {
          if (toggleModal) {
            toggleModal();
          }
        }}
        onRightButtonClick={() => {
          if (handleModalConfirm) {
            handleModalConfirm();
          }
        }}
      />
      <Title title={translations.serviceDateDateTitle} />
      {isEditing && (
        <div className={row}>
          <div className={infoContainer}>
            <AiFillInfoCircle className={infoImage} />
            <p className={infoDescription}>{translations.serviceDateInfo}</p>
          </div>
        </div>
      )}
      {errors?.lastDate && <p className={errorText}>{translations.mandatoryField}</p>}
      <div className={row}>
        <p>{translations.serviceDateLastService}</p>
        {isEditing ? (
          <div className={`${row} ${inputWrapper}`} ref={datePickerRef}>
            <p className="bubble-date">{lastDateOriginalValue}</p>
            <DatePicker
              onChange={handleLastValue}
              calendarIcon={<MdDateRange className={deleteIcon} onClick={handleScrollToDatePicker} />}
              clearIcon={<MdAutoDelete className={deleteIcon} onClick={handleDeleteLastDate} />}
              value={lastDateOriginalValue}
              format="dd/MM/yyyy"
            />
          </div>
        ) : (
          <p className="bubble-date">{lastDate}</p>
        )}
      </div>
      <div className={row}>
        <p className={nextText}>{translations.serviceDateNextService}</p>
        {isEditing ? (
          <div className={`${row} ${inputWrapper}`}>
            <p className="bubble-date">{nextDateOriginalValue}</p>
            <DatePicker
              onChange={handleNextValue}
              calendarIcon={<MdDateRange className={deleteIcon} onClick={handleScrollToDatePicker} />}
              clearIcon={<MdAutoDelete className={deleteIcon} onClick={handleDeleteNextDate} />}
              value={nextDateOriginalValue}
              format="dd/MM/yyyy"
            />
          </div>
        ) : (
          <p className="bubble-date">{nextDate}</p>
        )}
      </div>
      <div className={clockInfo}>
        <AiOutlineClockCircle className={clockInfoIcon} />
        {dateTimeInfo === null || (dateTimeInfo && dateTimeInfo.length === 0) ? (
          <p className="bubble">{dateTimeInfo}</p>
        ) : (
          <input
            className="primary"
            type="text"
            ref={register}
            id="date_time_info"
            name="date_time_info"
            disabled={!isEditing}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ServiceDate;
