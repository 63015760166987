import { useSelector } from 'react-redux';

import HomeSearchHeader from '../HomeSearchHeader';
import NewHeader from '../NewHeader';
import FilterElement from '../FilterElement';
import RemindersCard from '../RemindersCard';
import useRemindersList from '../../hooks/useRemindersList';
import GlobalSearchElement from '../GlobalSearchElements';
import useGetTranslations from '../../hooks/useGetTranslations';
import AdSection from '../AdSection';
import { IReduxStore } from '../../interfaces/IGeneral';
import { filterDuplicateNames } from '../../helpers/baseHelper';
import useMobile from '../../hooks/useMobile';
import { paddingForInfiniteScroll } from '../../constants/infiniteScrollStyle';
import InfiniteScroll from 'react-infinite-scroll-component';

function Reminders(): JSX.Element {
  const { reminders, remindersClients, remindersInstruments, total, onClick, fetchNextPage } = useRemindersList();
  const { translations } = useGetTranslations();
  const { isMobile } = useMobile();
  const { globalSearch } = useSelector((store: IReduxStore) => store.defaultReducer);

  //prettier-ignore
  return (
    <main>
      <HomeSearchHeader
        sortField="brand"
        parentPage="reminderList"
        sortTitle={{
          ascending: translations.sortRemindersAscending,
          descending: translations.sortRemindersDescending,
        }}
        filters={
          <>
            <FilterElement
              name={translations.byInstrument}
              data={filterDuplicateNames(
                remindersInstruments.map((instrument) => ({
                  id: instrument.id,
                  name: instrument.brand,
                }))
              )}
              field="instruments"
              isNumber={false}
            />
            <FilterElement
              name={translations.byClients}
              data={filterDuplicateNames(
                remindersClients.map((cli) => ({ id: cli.id, name: `${cli.firstName} ${cli.lastName}, ID: ${cli.id}` }))
              )}
              field="clients"
              isNumber={true}
            />
          </>
        }
      />
      {Object.keys(globalSearch).length > 0 ? (
        <GlobalSearchElement />
      ) : (
        <>
          <NewHeader leftTitle={translations.remindersLeftTitle} rightTitle="" />
          <AdSection length={total} noPadding>
            {isMobile ? (
              <InfiniteScroll
                dataLength={reminders.length}
                next={fetchNextPage}
                hasMore={true}
                loader={<></>}
                style={paddingForInfiniteScroll}
              >
                {reminders.map((reminder) => {
                  return (
                    <RemindersCard
                      key={reminder.id}
                      reminder={reminder}
                      isNew={false}
                      selectedImage=""
                      isEditing={false}
                      isSimple={false}
                      onClick={onClick}
                      isOverForm={false}
                    />
                  );
                })}
              </InfiniteScroll>
            ) : (
              reminders.map((reminder) => (
                <RemindersCard
                  key={reminder.id}
                  reminder={reminder}
                  isNew={false}
                  selectedImage=""
                  isEditing={false}
                  isSimple={false}
                  onClick={onClick}
                  isOverForm={false}
                />
              ))
            )}
          </AdSection>
        </>
      )}
    </main>
  );
}

export default Reminders;
