import { AiOutlineClose } from 'react-icons/ai';

import style from '../assets/scss/components/Modal.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IModal } from '../interfaces/components/Modal';
import { useEffect } from 'react';

function Modal(props: IModal): JSX.Element | null {
  const { isOpen, onClose, content, noContent } = props;
  const { modal, back, icon, modalNocontent } = convertKeysToCamelCase(style);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={back} onClick={noContent ? onClose : () => {}}>
      <div className={!noContent ? modal : modalNocontent}>
        {!noContent && <AiOutlineClose className={icon} onClick={onClose} />}
        {content}
      </div>
    </div>
  );
}

export default Modal;
