import { useParams } from 'react-router-dom';

import { EDIT_INSTRUMENT, NEW_SERVICE_PAGE } from '../constants/routes';
import useGeneral from './useGeneral';
import { IUseInstrumentDetailHeader } from '../interfaces/components/Hooks';

function useInstrumentDetailHeader(): IUseInstrumentDetailHeader {
  const { handleRedirect } = useGeneral();
  const { id } = useParams<{ id: string }>();

  const redirectToEdit = () => {
    handleRedirect(EDIT_INSTRUMENT.replace(':id', id));
  };

  const redirectToService = () => {
    handleRedirect(NEW_SERVICE_PAGE.replace(':id', id));
  };

  return { redirectToEdit, redirectToService };
}

export default useInstrumentDetailHeader;
