import { AnyAction } from 'redux';

import { SET_PLAN_DETAILS } from '../constants/reduxActions';
import { ICurrentPlan } from '../interfaces/components/Plans';

type PlanDetailsReducerState = ICurrentPlan | null;
const planDetailsReducer = (state: PlanDetailsReducerState = null, action: AnyAction): PlanDetailsReducerState => {
  const { type, payload } = action;

  switch (type) {
    case SET_PLAN_DETAILS: {
      return {
        ...payload,
      };
    }
    default:
      return state;
  }
};

export default planDetailsReducer;
