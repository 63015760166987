import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IUseHeader } from '../interfaces/components/Hooks';
import { IBurgerMenuItem, IReduxStore } from '../interfaces/IGeneral';

function useHeader(): IUseHeader {
  const { menugroups } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [isOpen, setIsOpen] = useState(false);
  const [menus, setMenus] = useState<IBurgerMenuItem[]>([]);
  setMenus;
  useEffect(() => {
    handleMenus();
  }, [menugroups]);

  useEffect(() => {
    close();
  }, [window.location.pathname]);

  const handleRedirect = (to: string): void => {
    window.open(to, '_self');
  };

  const handleMenus = () => {
    menugroups.length > 0 && setMenus(menugroups.filter((menu) => menu.name === 'burger')[0].menus);
  };

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return { handleRedirect, isOpen, toggleOpen, close, menus };
}

export default useHeader;
