import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { useApi } from './useApi';
import useGeneral from './useGeneral';
import { API_METHOD_GET, API_METHOD_DELETE, API_METHOD_PUT, API_METHOD_POST } from '../constants/api';
import {
  EDIT_REMINDER,
  GET_REMINDERS_TEMPLATE,
  REMINDER_DETAILS,
  SAVE_REMINDERS_TEMPLATE,
  SINGLE_REMINDER,
} from '../constants/routes';
import { IReminderDetail, IReminderTemplate } from '../interfaces/components/Reminders';
import { IUseReminderDetails } from '../interfaces/components/Hooks';
import useYupValidationResolver from './useYupValidationResolver';
import { getReminderDetailsSchema } from '../helpers/validationSchemaHelper';
import useGetTranslations from './useGetTranslations';
import useGetInstruments from './useGetInstruments';
import { ApiResponseTemplate, Value } from '../interfaces/IGeneral';
import { formatDate } from '../utils/date';

function useReminderDetails(): IUseReminderDetails {
  const urlParts = window.location.href.split('/');
  const id = urlParts[urlParts.length - 1];
  const [reminder, setReminder] = useState<IReminderDetail>();
  const [shouldLeaveWarning, setShouldLeaveWarning] = useState<boolean>(true);
  const [reminderDateValue, setReminderDateValue] = useState<string>(new Date().toISOString().slice(0, 10));
  const [reminderRepeat, setReminderRepeat] = useState<string>('');
  const [isSendEmail, setIsSendEmail] = useState<boolean>(false);
  const { handleApi } = useApi();
  const resolver = useYupValidationResolver(getReminderDetailsSchema());
  const { translations } = useGetTranslations();
  const history = useHistory();
  useGetInstruments();
  const {
    register,
    handleSubmit,
    setValue: setReminderValue,
    formState: { errors },
    getValues,
  } = useForm<IReminderDetail>({ resolver });
  const { handleRedirect, goBack } = useGeneral();

  useEffect(() => {
    fetchReminder();
  }, []);

  useEffect(() => {
    if (reminder?.date) {
      setReminderDateValue(new Date(reminder?.dateOriginalFormat).toISOString().slice(0, 10));
    }
    if (reminder?.repeatInDays) {
      setReminderRepeat(reminder.repeatInDays.toString());
    }
    if (reminder?.sendMailToClient) {
      setIsSendEmail(reminder.sendMailToClient === 1 ? true : false);
    }
    if (reminder) {
      setReminderValue('title', reminder?.title);
      setReminderValue('subject', reminder?.subject);
      setReminderValue('text', reminder?.text);
      setReminderValue('date', reminder?.date);
      setReminderValue('repeat_in_days', reminder?.repeatInDays);
      if (reminder?.date) {
        setReminderDateValue(new Date(reminder?.dateOriginalFormat).toISOString().slice(0, 10));
      }
      if (reminder?.repeatInDays) {
        setReminderRepeat(reminder.repeatInDays.toString());
      }
      if (reminder?.sendMailToClient) {
        setIsSendEmail(reminder.sendMailToClient === 1 ? true : false);
      }
    }
  }, [reminder]);

  async function fetchReminder() {
    const { data } = await handleApi(API_METHOD_GET, SINGLE_REMINDER.replace(':id', id));
    setReminder(data);
  }

  async function handleDelete() {
    await handleApi(API_METHOD_DELETE, SINGLE_REMINDER.replace(':id', id));
    if (reminder?.instrument) {
      setShouldLeaveWarning(false);
      history.go(-2);
    }
  }

  async function onSubmit(formData: any) {
    if (/^\d+$/.test(reminderRepeat) === false && reminderRepeat !== '') {
      toast.error(translations.repeatDaysError);
      return;
    }
    if (reminderDateValue === '') {
      toast.error(translations.dateMissing);
      return;
    }
    const { data } = await handleApi(API_METHOD_PUT, SINGLE_REMINDER.replace(':id', id), {
      ...formData,
      instrument_id: reminder?.instrument.data.id ? reminder.instrument.data.id : null,
      date: reminderDateValue,
      repeat_in_days: reminderRepeat !== '' ? reminderRepeat : null,
      send_mail_to_client: isSendEmail ? 1 : 0,
    });
    setReminder(data);
    setShouldLeaveWarning(false);
    goBack();
    toast.success(translations.savedSuccessfully);
  }
  const saveTemplate = useCallback(async () => {
    const template = getValues('text');
    const subject = getValues('subject');
    try {
      await handleApi(API_METHOD_POST, SAVE_REMINDERS_TEMPLATE, { template, subject });
      toast.success(translations.reminderTemplateSaveSuccess);
    } catch {
      toast.error(translations.reminderTemplateSaveFail);
    }
  }, [translations]);

  const getTemplate = useCallback(async () => {
    try {
      const { data } = await handleApi<ApiResponseTemplate<IReminderTemplate>>(API_METHOD_GET, GET_REMINDERS_TEMPLATE);
      if (data) {
        const { template, subject } = data;
        if (getValues('text') === '') {
          setReminderValue('text', template);
        }
        if (getValues('subject') === '') {
          setReminderValue('subject', subject);
        }
      }
    } catch {}
  }, []);

  useEffect(() => {
    getTemplate();
  }, [getTemplate]);

  const toggleEditing = () => {
    if (window.location.href.includes('/edit/')) {
      handleRedirect(REMINDER_DETAILS.replace(':id', String(id)));
      return;
    }
    handleRedirect(EDIT_REMINDER.replace(':id', String(id)));
  };

  const handleReminderDateValue = (value: Value) => {
    if (value instanceof Date) {
      setReminderDateValue(formatDate(value));
    }
  };

  const handleReminderRepeatValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReminderRepeat(e.target.value);
  };

  const handleSendEmail = () => {
    setIsSendEmail(!isSendEmail);
  };

  const handleDeleteReminderValue = () => {
    setReminderDateValue('');
  };

  return {
    reminder,
    shouldLeaveWarning,
    toggleEditing,
    handleDelete,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    reminderDateValue,
    handleReminderDateValue,
    reminderRepeat,
    handleReminderRepeatValue,
    isSendEmail,
    handleSendEmail,
    handleDeleteReminderValue,
    saveTemplate,
  };
}

export default useReminderDetails;
