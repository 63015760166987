import style from '../../assets/scss/components/info/InstrumentServiceIconInfo.module.scss';
import { AiFillInfoCircle } from 'react-icons/ai';
import completed from '../../assets/images/completed.svg';
import scheduled from '../../assets/images/scheduled.svg';
import { BsLink } from 'react-icons/bs';
import useGetTranslations from '../../hooks/useGetTranslations';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import { IoLogoUsd } from 'react-icons/io';
import { GiSandsOfTime } from 'react-icons/gi';

interface InfoProps {
  type: number;
}

export const InstrumentServiceIconInfo = ({ type }: InfoProps) => {
  const {
    wrapper,
    infoContainer,
    infoImage,
    infoDescription,
    infoDescriptionRight,
    infoImageGreen,
    infoIcon,
    linkIcon,
    infoContent,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  return (
    <div className={wrapper}>
      <AiFillInfoCircle className={infoIcon} />
      <div className={infoContent}>
        {type === 0 && (
          <>
            <div className={infoContainer}>
              <img src={scheduled} alt="scheduled_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.instrumentScheduledInfoDescription}</p>
            </div>
            <div className={infoContainer}>
              <img src={completed} alt="completed_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.instrumentCompletedInfoDescription}</p>
            </div>
            <div className={infoContainer}>
              <BsLink className={linkIcon} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.instrumentProjectIconDescription}</p>
            </div>
          </>
        )}
        {type === 1 && (
          <>
            <p className={infoDescription}>{translations.activeStatusProjectInfo}</p>
            <div className={infoContainer} />
            <div className={infoContainer}>
              <img src={scheduled} alt="scheduled_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.instrumentProjectScheduledInfoDescription}</p>
            </div>
            <div className={infoContainer}>
              <img src={completed} alt="completed_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.instrumentProjectCompletedInfoDescription}</p>
            </div>
            <div className={infoContainer}>
              <IoLogoUsd className={infoImageGreen} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.projectCostInfo}</p>
            </div>
            <div className={infoContainer}>
              <GiSandsOfTime className={infoImageGreen} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.projectTimeInfo}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
