import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from './useApi';
import {
  SET_GLOBAL_SEARCH,
  SET_GLOBAL_SEARCH_ON_BACK,
  SET_GLOBAL_SEARCH_TEXT_VALUE,
  SET_RESET_GLOBAL_SEARCH,
} from '../constants/reduxActions';
import { API_METHOD_GET } from '../constants/api';
import { GLOBAL_SEARCH } from '../constants/routes';
import { IReduxStore } from '../interfaces/IGeneral';
import { IUseGlobalSearch } from '../interfaces/components/Hooks';

function useGlobalSearch(): IUseGlobalSearch {
  //prettier-ignore
  const { globalSearchTextValue, globalSearchOnBack, resetGlobalSearch } = useSelector(
    (store: IReduxStore) => store.defaultReducer
  );
  const [value, setValue] = useState(globalSearchTextValue);
  const [, setShouldUpdate] = useState(true);
  const { handleApi } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    if (resetGlobalSearch) {
      dispatch({ type: SET_RESET_GLOBAL_SEARCH, payload: false });
      dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: false });
      setValue('');
      return;
    }

    if (globalSearchOnBack) {
      if (resetGlobalSearch) {
        dispatch({ type: SET_RESET_GLOBAL_SEARCH, payload: false });
        dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: false });
        setValue('');
        return;
      }
      dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: false });
      fetchSearch(value);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      fetchSearch(value);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [value, resetGlobalSearch]);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(() => e.target.value);
  };

  async function fetchSearch(value: string) {
    if (value && value.length > 2) {
      const { data } = await handleApi(API_METHOD_GET, GLOBAL_SEARCH + '?searchPhrase=' + value, {}, false);

      dispatch({ type: SET_GLOBAL_SEARCH, payload: data });
      dispatch({ type: SET_GLOBAL_SEARCH_TEXT_VALUE, payload: value });
    } else {
      dispatch({ type: SET_GLOBAL_SEARCH, payload: {} });
      dispatch({ type: SET_GLOBAL_SEARCH_TEXT_VALUE, payload: '' });
    }
    setShouldUpdate(true);
  }

  return { value, onValueChange };
}
export default useGlobalSearch;
