import { RefObject, useRef } from 'react';
import { MdLocationPin, MdModeEditOutline, MdDeleteOutline } from 'react-icons/md';
import { BiUser } from 'react-icons/bi';
import { BsFillImageFill } from 'react-icons/bs';

import {
  INSTRUMENT_DETAILS,
  ATTACHMENTS_PAGE,
  NEW_SERVICE_PAGE,
  NEW_PROJECT_PAGE,
  NEW_REMINDER_PAGE,
} from '../constants/routes';
import style from '../assets/scss/components/InstrumentCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IInstrumentCard } from '../interfaces/components/Instrument';
import useGeneral from '../hooks/useGeneral';
import noImage from '../assets/images/no-image.jpg';
import { FaCheckCircle, FaUser, FaBan } from 'react-icons/fa';
import { MdNotificationsNone } from 'react-icons/md';
import { BsLink } from 'react-icons/bs';
import useGetTranslations from '../hooks/useGetTranslations';
import {
  SET_GLOBAL_SEARCH_ON_BACK,
  SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER,
  SET_OPEN_ATTACHMENT_INSTEAD,
  SET_OPEN_NEW_PROJECT_INSTEAD,
  SET_OPEN_NEW_REMINDER_INSTEAD,
  SET_OPEN_NEW_SERVICE_INSTEAD,
} from '../constants/reduxActions';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxStore } from '../interfaces/IGeneral';
import { professionTranslation } from '../utils/roleChecker';

function InstrumentCard(props: IInstrumentCard): JSX.Element {
  const {
    wrapper,
    imageRow,
    image,
    title,
    type,
    logo,
    lighter,
    descriptionContainer,
    iconContainer,
    edge,
    imageIcon,
    line,
    redIcon,
    notificationIcon,
    linkIcon,
    separator,
    project,
    imageWrapper,
    removeBottomMargin,
    bottomInfoContainer,
    removeMargin,
    redText,
    flex,
    textSeparator,
    cardContent,
    serviceDoneIconStyle,
    serviceDoneIconStyleWrapper,
    banIconStyle,
  } = convertKeysToCamelCase(style);
  const {
    brand,
    model,
    client,
    thumbnail,
    isSimple,
    id,
    handleImageChange,
    handleProfileImageDelete,
    uploadImage,
    to,
    category,
    isServiceCard,
    hasProjects,
    hasReminders,
    serviceType,
    fromGlobalSearch,
    dateInsteadModel,
    date,
    endDate,
    noMarginBottom,
    noMargin,
    serviceDoneIcon,
    serialNumber,
    room,
  } = props;
  const { handleRedirect } = useGeneral();
  const image1Ref = useRef(null);
  const image2Ref = useRef(null);
  const { translations } = useGetTranslations();
  const dispatch = useDispatch();
  //prettier-ignore
  const { openAttachmentInstead, openProjectInstead, openReminderInstead, openServiceInstead } = useSelector(
    (store: IReduxStore) => store.defaultReducer
  );

  const onError = (ref: RefObject<HTMLImageElement>) => {
    if (ref && ref.current) ref.current.src = noImage;
  };

  const nameBuilder = (name?: string, firstName?: string, lastName?: string) => {
    if (name) {
      return name;
    }

    if (firstName && lastName && lastName !== null) {
      return `${firstName} ${lastName}`;
    }

    if (firstName) {
      return firstName;
    }

    return '';
  };

  return (
    <div
      className={`${wrapper} ${!isSimple && edge} ${noMarginBottom && removeBottomMargin} ${noMargin && removeMargin}`}
    >
      <div
        className={cardContent}
        onClick={() => {
          if (openAttachmentInstead && id) {
            handleRedirect(ATTACHMENTS_PAGE.replace(':id', id.toString()).replace(':type', 'instrument'));
            dispatch({ type: SET_OPEN_ATTACHMENT_INSTEAD, payload: false });
            return;
          }
          if (openServiceInstead && id) {
            handleRedirect(NEW_SERVICE_PAGE.replace(':id', id.toString()));
            dispatch({ type: SET_OPEN_NEW_SERVICE_INSTEAD, payload: false });
            return;
          }
          if (openProjectInstead && id) {
            handleRedirect(NEW_PROJECT_PAGE.replace(':id', id.toString()));
            dispatch({ type: SET_OPEN_NEW_PROJECT_INSTEAD, payload: false });
            return;
          }
          if (openReminderInstead && id) {
            handleRedirect(NEW_REMINDER_PAGE.replace(':id', id.toString()));
            dispatch({ type: SET_OPEN_NEW_REMINDER_INSTEAD, payload: false });
            return;
          }
          if (to) {
            if (serviceType === 'service') {
              dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 0 });
              handleRedirect(to);
              return;
            }
            dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 1 });
            handleRedirect(to);
          } else if (id && !isSimple) {
            handleRedirect(INSTRUMENT_DETAILS.replace(':id', id.toString()));
          }

          if (fromGlobalSearch) {
            dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: true });
          }
        }}
      >
        <div className={imageRow}>
          {handleImageChange ? (
            <div className={imageWrapper}>
              <input id="file" name="file" type="file" accept="image/*" onChange={handleImageChange} />
              <label className={image} htmlFor="file">
                {uploadImage ? (
                  <img
                    src={uploadImage}
                    className={image}
                    ref={image2Ref}
                    onError={() => {
                      onError(image2Ref);
                    }}
                  />
                ) : (
                  <BsFillImageFill className={imageIcon} />
                )}
                <MdModeEditOutline className="image-edit-icon" />
              </label>
              <MdDeleteOutline onClick={handleProfileImageDelete} className="image-delete-icon" />
            </div>
          ) : (
            <img
              src={thumbnail ?? noImage}
              className={image}
              onError={() => {
                onError(image1Ref);
              }}
              ref={image1Ref}
            />
          )}

          <div className={descriptionContainer}>
            <div>
              <p className={title}>{brand}</p>
              {serviceType === 'project' ? (
                <div className={flex}>
                  <p className={type}>{date && date !== null ? date : ''}</p>
                  {endDate && endDate !== null && <p className={textSeparator}>:</p>}
                  <p className={redText}>{endDate && endDate !== null ? endDate : ''}</p>
                </div>
              ) : (
                <p className={type}>
                  {dateInsteadModel
                    ? date && date !== null
                      ? date
                      : ''
                    : `${model} ${category && translations[category]}`}
                </p>
              )}
            </div>
            <div>
              {client?.region && (
                <div className={imageRow}>
                  <MdLocationPin className={logo} />
                  <p className={lighter}>
                    {client?.city}
                    {client.city && client.postcode && ', '} {client?.postcode}
                  </p>
                </div>
              )}
              {client?.name ? (
                <div className={imageRow}>
                  <BiUser className={logo} />
                  <p className={lighter}>{nameBuilder(client.name, client.firstName, client.lastName)}</p>
                </div>
              ) : client?.firstName ? (
                <div className={imageRow}>
                  <BiUser className={logo} />
                  <p className={lighter}>{nameBuilder(client.name, client.firstName, client.lastName)}</p>
                </div>
              ) : (
                <div className={imageRow}>
                  <FaUser className={redIcon} />
                  <p className={lighter}>{translations[professionTranslation('noClient')]}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {isServiceCard ? (
          !isSimple && serviceDoneIcon && date === null ? (
            <div className={serviceDoneIconStyleWrapper}>
              <FaBan className={banIconStyle} />
              <FaCheckCircle className={serviceDoneIconStyle} />
            </div>
          ) : (
            <div className={serviceDoneIconStyleWrapper}>
              <FaCheckCircle className={serviceDoneIconStyle} />
            </div>
          )
        ) : (
          <></>
        )}
      </div>
      {!isSimple && !isServiceCard && (room || serialNumber) && translations && (
        <>
          <div className={line} />
          <div className={bottomInfoContainer}>
            <div>{room && `${translations.instrumentCardRoom}: ${room}`}</div>
            <div>{serialNumber && `${translations.instrumentCardSerial}: ${serialNumber}`}</div>
          </div>
        </>
      )}
      {!isSimple && isServiceCard && (
        <>
          {(hasProjects || hasReminders) && <div className={line} />}
          <div className={iconContainer}>
            {hasProjects && (
              <>
                <BsLink className={linkIcon} />
                <p className={project}>project</p>
              </>
            )}
            {hasProjects && hasReminders && <div className={separator}>|</div>}
            {hasReminders && <MdNotificationsNone className={notificationIcon} />}
          </div>
        </>
      )}
    </div>
  );
}

export default InstrumentCard;
