import style from '../assets/scss/components/LoginForm.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useLogin from '../hooks/useLogin';
import useGetTranslations from '../hooks/useGetTranslations';
import SimpleInput from './SimpleInput';

function LoginForm(): JSX.Element {
  const { buttonContainer, forgotPassword, loginWrapper } = convertKeysToCamelCase(style);
  const { handleForgotPassword, register, handleSubmitForm, errors } = useLogin();
  const { translations } = useGetTranslations();

  return (
    <form onSubmit={handleSubmitForm}>
      <div className={loginWrapper}>
        <SimpleInput
          type="text"
          id="email"
          label={translations.loginEmail}
          isRequired
          reg={register}
          errors={errors}
          errorText={translations.mandatoryField}
        />
        <SimpleInput
          type="password"
          id="password"
          label={translations.loginPassword}
          isRequired
          reg={register}
          errors={errors}
          errorText={translations.mandatoryField}
        />
      </div>
      <div className={buttonContainer}>
        <p className={`${forgotPassword} h4`} onClick={handleForgotPassword}>
          {translations.loginForgotPassword}
        </p>
        <button className="primary" type="submit">
          {translations.loginLogin}
        </button>
      </div>
    </form>
  );
}

export default LoginForm;
