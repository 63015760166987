import Modal from './Modal';
import style from '../assets/scss/components/DeleteAccountModal.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGeneral from '../hooks/useGeneral';
import { LOGIN_PAGE } from '../constants/routes';
import useGetTranslations from '../hooks/useGetTranslations';

interface RegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function RegisterModal(props: RegisterModalProps): JSX.Element {
  const { isOpen, onClose } = props;
  const { btnContainer, wrapper, title, content } = convertKeysToCamelCase(style);
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        handleRedirect(LOGIN_PAGE);
      }}
      content={
        <div className={wrapper}>
          <p className={title}>{translations.registerModalTitle}</p>
          <p className={content}>{translations.registerModalDescription}</p>
          <div className={btnContainer}>
            <button
              className="blue"
              onClick={() => {
                onClose();
                handleRedirect(LOGIN_PAGE);
              }}
              type="button"
            >
              {translations.leaveModalGo}
            </button>
          </div>
        </div>
      }
    />
  );
}

export default RegisterModal;
