import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { API_METHOD_GET } from '../constants/api';
import { SET_ACTIVE_COUNTRIES, SET_COUNTRIES } from '../constants/reduxActions';
import { COUNTRY_LIST, COUNTRY_LIST_ACTIVE } from '../constants/routes';
import { ICountry, ICountryApi, IUseCountries } from '../interfaces/components/Hooks';
import { useApi } from './useApi';

function useCountries(): IUseCountries {
  const { handleApi } = useApi();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const dispatch = useDispatch();

  async function getCountries() {
    const { data } = await handleApi(API_METHOD_GET, COUNTRY_LIST);
    const countryList: ICountry[] = [];
    data.map((country: ICountryApi) => {
      countryList.push({ value: country.code, label: country.native_name });
    });
    setCountries(countryList);
    dispatch({ type: SET_COUNTRIES, payload: countryList });
  }

  async function getActiveCountries() {
    const { data } = await handleApi(API_METHOD_GET, COUNTRY_LIST_ACTIVE);
    const countryList: ICountry[] = [];
    data.map((country: ICountryApi) => {
      countryList.push({ value: country.code, label: country.native_name });
    });
    setCountries(countryList);
    dispatch({ type: SET_ACTIVE_COUNTRIES, payload: countryList });
  }

  useEffect(() => {
    getCountries();
    getActiveCountries();
  }, []);

  return { countries };
}

export default useCountries;
