import { BiCalendar } from 'react-icons/bi';
import { RiUserSettingsLine } from 'react-icons/ri';
import { BsFillPeopleFill } from 'react-icons/bs';
import { GiAutoRepair } from 'react-icons/gi';
import { MdPiano } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import style from '../assets/scss/components/MenuPoints.module.scss';
import {
  RESET_ACTUAL_FILTER,
  RESET_ORDER,
  SET_GLOBAL_SEARCH,
  SET_GLOBAL_SEARCH_TEXT_VALUE,
  SET_PAGINATION,
} from '../constants/reduxActions';
import { CLIENTS_PAGE, HOME_PAGE, PROFILE, PROJECTS_PAGE, REMINDERS_PAGE, SERVICES_PAGE } from '../constants/routes';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGeneral from '../hooks/useGeneral';

function MenuPoints(): JSX.Element {
  const { wrapper, leftIcons, iconContainer, icon, iconContainerActive, bottomArea } = convertKeysToCamelCase(style);
  const { handleRedirect, containsRoute, isAtRoute } = useGeneral();
  const dispatch = useDispatch();

  return (
    <section className={wrapper}>
      <div className={leftIcons}>
        <div
          className={
            containsRoute(['/instrument/']) || isAtRoute(HOME_PAGE) || containsRoute(['/attachments/', '/instrument'])
              ? `${iconContainer} ${iconContainerActive}`
              : iconContainer
          }
        >
          <MdPiano
            className={icon}
            size={30}
            onClick={() => {
              dispatch({ type: SET_GLOBAL_SEARCH, payload: {} });
              dispatch({ type: SET_GLOBAL_SEARCH_TEXT_VALUE, payload: '' });
              dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });
              dispatch({ type: RESET_ORDER });
              dispatch({ type: RESET_ACTUAL_FILTER });
              handleRedirect(HOME_PAGE);
            }}
          />
        </div>
        <div className={containsRoute([CLIENTS_PAGE]) ? `${iconContainer} ${iconContainerActive}` : iconContainer}>
          <BsFillPeopleFill
            className={icon}
            size={30}
            onClick={() => {
              dispatch({ type: SET_GLOBAL_SEARCH, payload: {} });
              dispatch({ type: SET_GLOBAL_SEARCH_TEXT_VALUE, payload: '' });
              dispatch({ type: RESET_ACTUAL_FILTER });
              dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });
              dispatch({ type: RESET_ORDER });
              handleRedirect(CLIENTS_PAGE);
            }}
          />
        </div>
        <div
          className={
            containsRoute([SERVICES_PAGE]) ||
            containsRoute(['-services/']) ||
            containsRoute([PROJECTS_PAGE]) ||
            containsRoute(['-projects/']) ||
            containsRoute(['/attachments/', '/service']) ||
            containsRoute(['/attachments/', '/project'])
              ? `${iconContainer} ${iconContainerActive}`
              : iconContainer
          }
        >
          <GiAutoRepair
            className={icon}
            size={30}
            onClick={() => {
              dispatch({ type: SET_GLOBAL_SEARCH, payload: {} });
              dispatch({ type: SET_GLOBAL_SEARCH_TEXT_VALUE, payload: '' });
              dispatch({ type: RESET_ACTUAL_FILTER });
              dispatch({ type: RESET_ORDER });
              dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });
              handleRedirect(SERVICES_PAGE);
            }}
          />
        </div>
        <div className={containsRoute([REMINDERS_PAGE]) ? `${iconContainer} ${iconContainerActive}` : iconContainer}>
          <BiCalendar
            className={icon}
            size={30}
            onClick={() => {
              dispatch({ type: SET_GLOBAL_SEARCH, payload: {} });
              dispatch({ type: SET_GLOBAL_SEARCH_TEXT_VALUE, payload: '' });
              dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });
              dispatch({ type: RESET_ORDER });
              dispatch({ type: RESET_ACTUAL_FILTER });
              handleRedirect(REMINDERS_PAGE);
            }}
          />
        </div>
        <div className={containsRoute([PROFILE]) ? `${iconContainer} ${iconContainerActive}` : iconContainer}>
          <RiUserSettingsLine
            className={icon}
            size={30}
            onClick={() => {
              dispatch({ type: SET_GLOBAL_SEARCH, payload: {} });
              dispatch({ type: SET_GLOBAL_SEARCH_TEXT_VALUE, payload: '' });
              dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });
              dispatch({ type: RESET_ORDER });
              handleRedirect(PROFILE);
            }}
          />
        </div>
      </div>
      <div className={bottomArea} />
    </section>
  );
}

export default MenuPoints;
