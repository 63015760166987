export const ACTIVE_PRIVATE_PROFESSION_TYPES = [
  // 'piano_tuner',
  'piano_owner',
  'piano_teacher',
  'piano_artist',
  'private_other',
];

export const ACTIVE_COMPANY_PROFESSION_TYPES = [
  'piano_tuner',
  'salesperson_marketing',
  'piano_dealer',
  'piano_workshop',
  'manufacturer',
  'other',
];
