import useGeneral from './useGeneral';
import { HOME_PAGE, NEW_PROJECT_PAGE, NEW_REMINDER_PAGE, NEW_SERVICE_PAGE } from '../constants/routes';
import {
  ADD_ACTUAL_FILTER,
  ADD_FILTER,
  RESET_ORDER,
  SET_OPEN_NEW_PROJECT_INSTEAD,
  SET_OPEN_NEW_REMINDER_INSTEAD,
  SET_OPEN_NEW_SERVICE_INSTEAD,
} from '../constants/reduxActions';
import { useDispatch } from 'react-redux';
import { IClient } from '../interfaces/components/Clients';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';

function useClientServicesHeader(switcher: number, client: IClient | undefined, instruments: ISuggestion[]) {
  const { handleRedirect } = useGeneral();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (!client) {
      return;
    }
    dispatch({ type: RESET_ORDER });
    switch (switcher) {
      case 0: {
        if (instruments.length === 1) {
          handleRedirect(NEW_SERVICE_PAGE.replace(':id', instruments[0].id.toString()));
          return;
        }
        dispatch({
          type: ADD_FILTER,
          payload: { clients: { id: client.id, name: `${client.firstName} ${client.lastName}` } },
        });
        dispatch({ type: ADD_ACTUAL_FILTER });
        dispatch({ type: SET_OPEN_NEW_SERVICE_INSTEAD, payload: true });
        handleRedirect(HOME_PAGE);
        return;
      }
      case 1: {
        if (instruments.length === 1) {
          handleRedirect(NEW_PROJECT_PAGE.replace(':id', instruments[0].id.toString()));
          return;
        }
        dispatch({
          type: ADD_FILTER,
          payload: { clients: { id: client.id, name: `${client.firstName} ${client.lastName}` } },
        });
        dispatch({ type: ADD_ACTUAL_FILTER });
        dispatch({ type: SET_OPEN_NEW_PROJECT_INSTEAD, payload: true });
        handleRedirect(HOME_PAGE);

        return;
      }
      case 2: {
        if (instruments.length === 1) {
          handleRedirect(NEW_REMINDER_PAGE.replace(':id', instruments[0].id.toString()));
          return;
        }
        dispatch({
          type: ADD_FILTER,
          payload: { clients: { id: client.id, name: `${client.firstName} ${client.lastName}` } },
        });
        dispatch({ type: ADD_ACTUAL_FILTER });
        dispatch({ type: SET_OPEN_NEW_REMINDER_INSTEAD, payload: true });
        handleRedirect(HOME_PAGE);

        return;
      }
      default:
        return;
    }
  };

  return { handleClick };
}

export default useClientServicesHeader;
