import SearchInput from './SearchInput';
import BlueaHeader from './BlueHeader';
import { IPlansHeader } from '../interfaces/components/Plans';

function PlansHeader(props: IPlansHeader): JSX.Element {
  const { onChange } = props;

  return <BlueaHeader element={<SearchInput onChange={onChange} />}></BlueaHeader>;
}

export default PlansHeader;
