import useFilterIcon from '../hooks/useFilterIcon';
import style from '../assets/scss/components/FilterIcon.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslation from '../hooks/useGetTranslations';
import HamburgerBase from './HamburgerBase';
import { IFilterIcon } from '../interfaces/components/Filter';
import { IReduxStore } from '../interfaces/IGeneral';
import { useSelector } from 'react-redux';
import { ADD_ACTUAL_FILTER, RESET_FILTER } from '../constants/reduxActions';

function FilterIcon({
  filters,
  isInactive,
  addActualFilterRedux = ADD_ACTUAL_FILTER,
  resetFilterRedux = RESET_FILTER,
}: IFilterIcon): JSX.Element {
  const { isVisible, toggleVisible, onSave } = useFilterIcon(addActualFilterRedux, resetFilterRedux);
  const { btnContainer, spacer, inactive, noSpace } = convertKeysToCamelCase(style);
  const { isSuggestionOpened } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslation();

  return (
    <div>
      {isInactive ? (
        <button className={`mixed ${inactive}`}>{translations.homeSearchHeaderFilter}</button>
      ) : (
        <button className="mixed" onClick={toggleVisible}>
          {translations.homeSearchHeaderFilter}
        </button>
      )}
      <HamburgerBase
        childrenContent={
          <div>
            {filters}
            <div className={btnContainer}>
              <button type="button" className="primary" onClick={onSave}>
                {translations.save}
              </button>
            </div>
            <div className={isSuggestionOpened ? spacer : noSpace} />
          </div>
        }
        headerTitle={translations.homeSearchHeaderFilter}
        isOpen={isVisible}
        toggleOpen={toggleVisible}
        onBlurClick={onSave}
      />
    </div>
  );
}

export default FilterIcon;
