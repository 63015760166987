import React, { createRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';

import { IReduxStore, Value } from '../interfaces/IGeneral';
import { useApi } from './useApi';
import { API_METHOD_GET, API_METHOD_POST } from '../constants/api';
import { SERVICES_LIST, NEW_PROJECT, PROJECT_DETAILS, SERVICE_DETAIL, TUNERS } from '../constants/routes';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';
import useYupValidationResolver from './useYupValidationResolver';
import { getBase64, multiUploadFilesAndCheckLimit, toCamelCase } from '../helpers/baseHelper';
import { SET_PAGINATION } from '../constants/reduxActions';
import { getServiceValidationSchema } from '../helpers/validationSchemaHelper';
import useMySubscriptionPlan from './useMySubscriptionPlan';
import useGetTranslations from './useGetTranslations';
import { toast } from 'react-toastify';
import useGetInstruments from './useGetInstruments';
import { ReactCropperElement } from 'react-cropper';
import useGeneral from './useGeneral';
import { nanoid } from 'nanoid';
import { formatDate } from '../utils/date';

function useNewService() {
  useGetInstruments();
  const [project, setProject] = useState<ISuggestion>();
  const [uploadImages, setUploadImages] = useState<Array<{ content: string; name: string }>>([]);
  const [uploadAttachments, setUploadAttachments] = useState<Array<{ content: string; name: string }>>([]);
  const [newProjectName, setNewProjecName] = useState<string>('');
  const [showLeaveWarning, setShowLeaveWarning] = useState(true);
  const [lastServiceDate, setLastServiceDate] = useState<string>();
  const [nextServiceDate, setNextServiceDate] = useState<string>();
  const [isActive, setIsActive] = useState<number>(0);
  const [newTunerName, setNewTunerName] = useState<string>('');
  const [tuner, setTuner] = useState<ISuggestion>({ id: -1, name: '' });

  const { instruments, pagination } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const dispatch = useDispatch();
  const instrument = instruments && instruments.find((instr) => instr.id.toString() === id);
  const { imagesLeft, filesLeft, showPlanExceededToast } = useMySubscriptionPlan();
  const { handleApi } = useApi();
  const { translations } = useGetTranslations();
  const resolver = useYupValidationResolver(getServiceValidationSchema());
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });
  const [cropUploadImage, setCropUploadImage] = useState<string>(''); // single uploaded image on attachments cropped
  const uploadCropperRef = createRef<ReactCropperElement>();
  const { resizeFile, finishCrop } = useGeneral();

  useEffect(() => {
    if (projectId) {
      getProjectById(projectId);
    }
  }, []);

  async function getProjectById(id: string) {
    const { data } = await handleApi(API_METHOD_GET, PROJECT_DETAILS.replace(':id', id));
    const projectById: ISuggestion = {
      id: data.id,
      name: data.title,
    };
    setProject(projectById);
  }

  async function onSubmit(form: any) {
    let projectIdOnSubmit: number | null = null;

    if (lastServiceDate && nextServiceDate && new Date(nextServiceDate) <= new Date(lastServiceDate)) {
      toast.error(translations.nextDateError);
      return;
    }

    if (newProjectName !== '' && instrument) {
      const { data } = await handleApi(API_METHOD_POST, NEW_PROJECT, {
        instrument_id: instrument.id,
        title: newProjectName,
      });
      projectIdOnSubmit = data.id;
    }

    if (newProjectName === '') {
      projectIdOnSubmit = project?.id ? project.id : null;
    }

    let tunerIdOnSubmit = -1;
    let tunerNameOnSubmit = '';

    if (newTunerName !== '') {
      const { data, code } = await handleApi(API_METHOD_POST, TUNERS, {
        name: newTunerName,
      });

      if (Number(code) >= 400) {
        toast.error(translations[toCamelCase(data.name.replace('api.', ''))] ?? data.name);
        return;
      }

      tunerIdOnSubmit = data.id;
      tunerNameOnSubmit = data.name;
    }

    if (newTunerName === '') {
      tunerIdOnSubmit = tuner.id;
      tunerNameOnSubmit = tuner.name;
    }

    const { data, code } = await handleApi(API_METHOD_POST, SERVICES_LIST, {
      ...form,
      price: form.price !== '' ? Number(form.price.replace(/\s/g, '')) : form.price,
      duration: form.duration !== '' ? Number(form.duration.replace(/\s/g, '')) : form.duration,
      project_id: projectIdOnSubmit,
      instrument_id: id,
      images: uploadImages,
      files: uploadAttachments,
      last_date: lastServiceDate,
      next_date: nextServiceDate,
      isActive,
      tuner_id: tunerIdOnSubmit === -1 ? null : tunerIdOnSubmit,
      tuner: tunerNameOnSubmit === '' ? null : tunerNameOnSubmit,
    });

    if (code === 200) {
      window.scrollTo(0, 0);
      toast.success(translations.savedSuccessfully);
      setShowLeaveWarning(false);
      dispatch({ type: SET_PAGINATION, payload: pagination });
      data && history.replace(SERVICE_DETAIL.replace(':id', data.id));
      return;
    }

    if (data) {
      Object.values(data).map((err: any) => {
        toast.error(err);
      });
    }
  }

  const handleNewProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewProjecName(e.target.value);
  };

  const handleProjectChange = (dat?: ISuggestion) => {
    setProject(dat);
  };

  const handleUploadCropImage = (fileString: string | ArrayBuffer | null, name: string) => {
    name;
    if (typeof fileString === 'string') {
      setCropUploadImage(fileString);
    }
  };
  const handleCancelUploadCrop = () => {
    setCropUploadImage('');
  };

  const handleFinishUploadCrop = async () => {
    const resized = await finishCrop(uploadCropperRef);

    if (resized) {
      onLoadPictures(resized as any, `${nanoid()}}.png`);
      setCropUploadImage('');
    }
  };

  const onPictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const amountOfFiles = files.length;
      // Prevent adding files if limit exceeded
      if (amountOfFiles > imagesLeft) return showPlanExceededToast();
      for (let i = 0; i < amountOfFiles; i++) {
        if (files.length === 1) {
          getBase64(files[i], handleUploadCropImage);
          return;
        }
        const image = await resizeFile(files[i]);
        onLoadPictures(image as any, `resized${nanoid()}.png`);
      }
    }
  };

  const onAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    multiUploadFilesAndCheckLimit(files, onLoadAttachments, filesLeft, showPlanExceededToast);
  };

  const onLoadPictures = (fileString: string | ArrayBuffer | null, name: string) => {
    if (fileString)
      setUploadImages((old) => {
        return old
          ? [...old, { content: fileString as string, name: name }]
          : [{ content: fileString as string, name: name }];
      });
  };

  const onLoadAttachments = (fileString: string | ArrayBuffer | null, name: string) => {
    if (fileString)
      setUploadAttachments((old) => {
        return old
          ? [...old, { content: fileString as string, name: name }]
          : [{ content: fileString as string, name: name }];
      });
  };

  const handleLastDate = (value: Value) => {
    if (value instanceof Date) {
      setLastServiceDate(formatDate(value));
    }
  };

  const handleNextDate = (value: Value) => {
    if (value instanceof Date) {
      setNextServiceDate(formatDate(value));
    }
  };

  const handleDeleteLastDate = () => {
    setLastServiceDate('');
  };

  const handleDeleteNextDate = () => {
    setNextServiceDate('');
  };

  const handleActive = (value: number) => {
    setIsActive(value);
  };

  const handleNewTunerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTunerName(e.target.value);
  };

  const handleTunerChange = (tuner: ISuggestion | undefined) => {
    tuner && setTuner(tuner);
  };

  return {
    instrument,
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    handleProjectChange,
    project,
    onPictureChange,
    onAttachmentChange,
    uploadAttachments,
    uploadImages,
    newProjectName,
    handleNewProjectNameChange,
    pictureNr: uploadImages.length,
    attachmentNr: uploadAttachments.length,
    showLeaveWarning,
    lastServiceDate,
    nextServiceDate,
    handleLastDate,
    handleNextDate,
    handleDeleteLastDate,
    handleDeleteNextDate,
    cropUploadImage,
    uploadCropperRef,
    handleCancelUploadCrop,
    handleFinishUploadCrop,
    isActive,
    handleActive,
    handleNewTunerNameChange,
    newTunerName,
    handleTunerChange,
    tuner,
  };
}

export default useNewService;
