import ProfileCard from '../ProfileCard';
import MyAccountHeader from '../MyAccountHeader';
import AdSection from '../AdSection';
import MyAccountForm from '../MyAccountForm';
import useMyAccount from '../../hooks/useMyAccount';
import useGetTranslations from '../../hooks/useGetTranslations';
import useGeneral from '../../hooks/useGeneral';
import useSetHeader from '../../hooks/useSetHeader';
import { PROFILE } from '../../constants/routes';
import useProfileCard from '../../hooks/useProfileCard';

function MyAccount(): JSX.Element {
  const {
    professionsTypes,
    register,
    handleSubmit,
    isEditing,
    isPrivate,
    toggleIsPrivate,
    toggleEditing,
    toggleSelectedProfessions,
    selectedProfessionTypes,
    deleteAccount,
    toggleModal,
    isModalVisible,
    errors,
    control,
    profile,
  } = useMyAccount();
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();
  const title = isEditing ? translations.myAccountEditTitle : translations.myAccountTitle;
  const { onImageChange, uploadImage } = useProfileCard();

  useSetHeader(() => {
    isEditing ? toggleEditing() : handleRedirect(PROFILE);
  }, title);

  if (profile === null) {
    return <></>;
  }

  return (
    <main>
      <section className="small">
        <MyAccountHeader
          toggleEditing={toggleEditing}
          isEditing={isEditing}
          deleteAccount={deleteAccount}
          toggleModal={toggleModal}
          isModalVisible={isModalVisible}
        />
      </section>
      <AdSection>
        <ProfileCard
          isEditing={isEditing}
          handleImageChange={onImageChange}
          uploadImage={uploadImage}
          clientId={profile?.id}
        />
        <MyAccountForm
          professionsTypes={professionsTypes}
          register={register}
          handleSubmit={handleSubmit}
          isEditing={isEditing}
          isPrivate={isPrivate}
          toggleIsPrivate={toggleIsPrivate}
          profile={profile}
          toggleSelectedProfessions={toggleSelectedProfessions}
          selectedProfessionTypes={selectedProfessionTypes}
          errors={errors}
          control={control}
        />
      </AdSection>
    </main>
  );
}

export default MyAccount;
