import { BsCheck } from 'react-icons/bs';

import style from '../assets/scss/components/RegistrationForm.module.scss';
import Title from './Title';
import SimpleInput from './SimpleInput';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useRegistration from '../hooks/useRegistration';
import useGetTranslations from '../hooks/useGetTranslations';
import CountryDropdown from './CountryDropdown';
import useGetCountries from '../hooks/useGetCountries';
import { toCamelCase } from '../transforms/baseTransformer';
import { AiFillInfoCircle } from 'react-icons/ai';
import RegisterModal from './RegisterModal';

function RegistrationForm(): JSX.Element {
  const {
    submit,
    submitContainer,
    termsContainer,
    errorMessage,
    termsText,
    subtitle,
    line,
    countryDropdownContainer,
    formWrapper,
    dropdownWrapper,
    radio,
    emptyPage,
    infoContainer,
    infoDescription,
    infoImage,
  } = convertKeysToCamelCase(style);
  const {
    setSignupType,
    isPrivate,
    professionsTypes,
    toggleSelectedProfessions,
    selectedProfessionTypes,
    isTermsAccepted,
    toggleTermsAccepted,
    register,
    handleSubmitForm,
    errors,
    control,
    handleRegisterModalOpen,
    isRegisterModalOpen,
  } = useRegistration();
  const { countries, activeCountries } = useGetCountries();
  const { translations } = useGetTranslations();

  const clientTypeTextSelector = () => {
    switch (isPrivate) {
      case true: {
        return translations.registerPrivate;
      }
      case false: {
        return translations.registerCompany;
      }
      default: {
        return translations.registerSelectAccountType;
      }
    }
  };

  //prettier-ignore
  return (
    <div>
      <RegisterModal isOpen={isRegisterModalOpen} onClose={() => handleRegisterModalOpen(false)} />
      <form onSubmit={handleSubmitForm}>
        <div className={`${formWrapper} ${isPrivate === undefined && emptyPage}`}>
          <Title title={translations.loginSignUp} />
          <p className={subtitle}>{translations.registrationFormSignUpDescription}</p>
          <div
            className={`radio ${radio}`}
            onClick={() => {
              setSignupType(true);
            }}
          >
            <p>{translations.registrationFormPrivatePerson}</p>
            <div className="radio-container">
              <BsCheck className={`radio-item ${isPrivate && 'radio-item-selected'}`} />
            </div>
          </div>
          <div
            className={`radio ${radio}`}
            onClick={() => {
              setSignupType(false);
            }}
          >
            <p>{translations.registrationFormCompany}</p>
            <div className="radio-container">
              <BsCheck className={`radio-item ${isPrivate === false && 'radio-item-selected'}`} />
            </div>
          </div>
          <div className={infoContainer}>
            <AiFillInfoCircle className={infoImage} />
            <p className={infoDescription}>{clientTypeTextSelector()}</p>
          </div>
        </div>
        {isPrivate !== undefined && (
          <>
            <div className={formWrapper}>
              <div className={line} />
              {!isPrivate && (
                <SimpleInput
                  reg={register}
                  type="text"
                  errorText={translations.mandatoryField}
                  errors={errors}
                  id="companyName"
                  label={translations.myAccountFormCompanyName}
                />
              )}
              <SimpleInput
                type="text"
                reg={register}
                errorText={translations.mandatoryField}
                errors={errors}
                id="firstName"
                label={translations.myAccountFormFirstName}
                isRequired
              />
              <SimpleInput
                type="text"
                reg={register}
                errorText={translations.mandatoryField}
                errors={errors}
                id="lastName"
                label={translations.myAccountFormLastName}
                isRequired
              />

              <Title title={translations.myAccountFormHeaderInvoiceData} />
              {!isPrivate && (
                <SimpleInput
                  type="text"
                  reg={register}
                  errorText={translations.mandatoryField}
                  errors={errors}
                  id="companyTaxNumber"
                  label={translations.myAccountFormCompanyTaxNumber}
                />
              )}

              <SimpleInput
                type="text"
                reg={register}
                errorText={translations.mandatoryField}
                errors={errors}
                id="address1"
                isRequired
                label={translations.myAccountFormAddress}
              />
              <SimpleInput
                type="text"
                reg={register}
                errorText={translations.sameAddress}
                errors={errors}
                id="address2"
                label={translations.myAccountFormAddress2}
              />

              <SimpleInput
                type="text"
                reg={register}
                errorText={translations.mandatoryField}
                errors={errors}
                id="city"
                isRequired
                label={translations.myAccountFormCity}
              />
              {isPrivate && (
                <div className={infoContainer}>
                  <AiFillInfoCircle className={infoImage} />
                  <p className={infoDescription}>{translations.registerCountryInfo}</p>
                </div>
              )}
            </div>
            <div className={dropdownWrapper}>
              <div className={countryDropdownContainer}>
                <CountryDropdown
                  control={control}
                  countries={isPrivate ? activeCountries : countries}
                  placeholder={translations.countryDropdownPlaceholder}
                  title
                />
              </div>
            </div>

            <div className={formWrapper}>
              <SimpleInput
                type="text"
                reg={register}
                errorText={translations.mandatoryField}
                errors={errors}
                id="region"
                isRequired
                label={translations.myAccountFormRegion}
              />
              <SimpleInput
                type="text"
                reg={register}
                errorText={translations.mandatoryField}
                errors={errors}
                id="postcode"
                isRequired
                label={translations.myAccountFormPostalCode}
              />

              <Title title={translations.myAccountFormHeaderRegisteredAs} />
              {errors.professionTypes && <p className={errorMessage}>{translations.mandatoryField}</p>}
              {professionsTypes &&
                professionsTypes?.map(
                  (profession) =>
                    (
                      <div
                        key={profession.id}
                        className="radio"
                        onClick={() => {
                          toggleSelectedProfessions(profession.id);
                        }}
                      >
                        <p>{translations[toCamelCase(profession.title)]}</p>
                        <div className="radio-container">
                          <BsCheck
                            className={`radio-item ${
                              selectedProfessionTypes.includes(profession.id) && 'radio-item-selected'
                            }`}
                          />
                        </div>
                      </div>
                    )
                )}
              <Title title={translations.myAccountFormHeaderLoginInfo} />
              <SimpleInput
                type="email"
                reg={register}
                errorText={translations.mandatoryField}
                errors={errors}
                id="email"
                isRequired
                label={translations.registrationFormEmailAddress}
              />

              <SimpleInput
                type="password"
                reg={register}
                errorText={translations.registrationPassword}
                errors={errors}
                id="password"
                isRequired
                placeHolder={translations.registrationPassword}
                label={translations.myAccountFormPassword}
              />

              <SimpleInput
                type="password"
                reg={register}
                errorText={translations.registrationPasswordConfirmation}
                errors={errors}
                id="passwordConfirmation"
                isRequired
                placeHolder={translations.registrationPassword}
                label={translations.myAccountFormPasswordRepeat}
              />

              <div className={line} />

              {errors.terms && <p className={errorMessage}>{translations.mandatoryField}</p>}
              <div className={termsContainer}>
                <BsCheck
                  onClick={toggleTermsAccepted}
                  className={`radio-item ${isTermsAccepted && 'radio-item-selected'}`}
                />
                <a
                  href="https://pianoservice.app/TermsConditions/"
                  target="_blank"
                  rel="noreferrer"
                  className={termsText}
                >
                  <p>{translations.registrationFormTerms}</p>
                </a>
              </div>

              <div className={submitContainer}>
                <button type="submit" className={`${submit} primary`}>
                  {translations.save}
                </button>
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default RegistrationForm;
