import style from '../assets/scss/components/SimpleInput.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { ISimpleInput } from '../interfaces/components/SimpleInput';

function SimpleInput(props: ISimpleInput): JSX.Element {
  const { errorText, reg, errors, id, label, isRequired, type, placeHolder, disabled } = props;
  const { inputContainer, errorMessage, text } = convertKeysToCamelCase(style);

  return (
    <div className={inputContainer}>
      <label className={text} htmlFor={id}>{`${label}${isRequired ? ' *' : ''}`}</label>
      {errors[id] && <p className={errorMessage}>{errorText}</p>}
      <input
        type={type}
        id={id}
        name={id}
        className="primary"
        ref={reg}
        placeholder={placeHolder ? placeHolder : ''}
        disabled={disabled}
        onKeyPress={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
      />
    </div>
  );
}

export default SimpleInput;
