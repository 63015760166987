import style from '../assets/scss/components/SwitcherComponent.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { ISwitcherComponent } from '../interfaces/components/SwitcherComponent';

function SwitcherComponent(props: ISwitcherComponent): JSX.Element {
  const { row, title, line, lineSelected, lineUnselected, titleSelected, titleUnselected, content, titleSmall } =
    convertKeysToCamelCase(style);
  const { titles, elements, switcherPage, toggleSwitcherPage } = props;

  return (
    <div>
      <div className={row}>
        {titles.map((header, index) => (
          <p
            key={index}
            className={`${title} ${switcherPage === index ? titleSelected : titleUnselected} ${
              titles.length > 2 && titleSmall
            }`}
            onClick={() => {
              toggleSwitcherPage(index);
            }}
          >
            {header}
          </p>
        ))}
      </div>
      <div className={row}>
        {titles.map((_, index) => (
          <div key={index} className={`${line} ${switcherPage === index ? lineSelected : lineUnselected} `} />
        ))}
      </div>
      <div className={content}>{elements[switcherPage]}</div>
    </div>
  );
}

export default SwitcherComponent;
