import { AnyAction } from 'redux';

import { defaultOrderState, initialState } from './initialState';
import {
  SET_IS_LOADING,
  SET_PROMISE,
  SET_STATE,
  SET_TRANSLATIONS,
  SET_PROFILE,
  SET_MENUS,
  SET_HEADER,
  SET_CLIENTS,
  ADD_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  SET_INSTRUMENTS,
  SET_ORDER,
  SET_GLOBAL_SEARCH,
  ADD_FILTER,
  RESET_FILTER,
  ADD_ACTUAL_FILTER,
  RESET_ACTUAL_FILTER,
  UPDATE_ACTUAL_FILTER,
  SET_FILTER,
  SET_CUSTOM_ADS,
  SET_PAGINATION,
  SET_LANGUAGE,
  SET_SHOULD_LEAVE,
  SET_COUNTRIES,
  ADD_INSTRUMENT,
  RESET_ORDER,
  SET_ATTACHMENTS_PAGE_SWITCHER,
  SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER,
  SET_CLIENT_SERVICES_PAGE_SWITCHER,
  SET_PROJECT_LIST_REFRESH,
  SET_INTRUMENT_LIST_REFRESH,
  SET_HAMBURGER_REF,
  SET_GLOBAL_SEARCH_TEXT_VALUE,
  SET_INSTRUMENTS_CLIENTS,
  SET_GLOBAL_SEARCH_ON_BACK,
  SET_SUGGESTION_OPENED,
  SET_CLIENT_LIST_REFRESH,
  SET_OPEN_ATTACHMENT_INSTEAD,
  SET_RESET_GLOBAL_SEARCH,
  SET_OPEN_NEW_SERVICE_INSTEAD,
  SET_OPEN_NEW_PROJECT_INSTEAD,
  SET_OPEN_NEW_REMINDER_INSTEAD,
  SET_ACTIVE_PROJECTS,
  SET_ACTIVE_SERVICES,
  SET_INSTRUMENT_SERVICES_FILTER,
  SET_INSTRUMENT_SERVICES_ORDER,
  ADD_INSTRUMENT_SERVICES_FILTER,
  SET_INSTRUMENT_SERVICES_ACTUAL_FILTER,
  RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER,
  UPDATE_INSTRUMENT_SERVICES_ACTUAL_FILTER,
  RESET_INSTRUMENT_SERVICES_FILTER,
  SET_ACTIVE_COUNTRIES,
  SET_BRAND_LIST_REFRESH,
  SET_TUNER_LIST_REFRESH,
} from '../constants/reduxActions';
import { TReduxState } from '../interfaces/IGeneral';

// eslint-disable-next-line
function redux(state = initialState, action: AnyAction): TReduxState {
  const { type, payload } = action;

  switch (type) {
    case SET_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        language: payload,
      };
    }
    case SET_TRANSLATIONS: {
      return {
        ...state,
        translations: payload,
      };
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case SET_PROMISE: {
      return {
        ...state,
        promises: [...state.promises, payload],
      };
    }
    case 'UPDATE_VALUE': {
      return {
        ...state,
        myValue: payload,
      };
    }
    case SET_PROFILE: {
      return {
        ...state,
        profile: { ...state.profile, ...payload },
      };
    }
    case SET_MENUS: {
      return {
        ...state,
        menugroups: [...state.menugroups, ...payload],
      };
    }
    case SET_HEADER: {
      return {
        ...state,
        header: { ...state.header, ...payload },
      };
    }
    case SET_CLIENTS: {
      return {
        ...state,
        clients: [...payload],
      };
    }
    case ADD_CLIENT: {
      return {
        ...state,
        clients: [...state.clients, payload],
      };
    }
    case UPDATE_CLIENT: {
      return {
        ...state,
        clients: [...state.clients.filter((client) => client.id !== payload.id), payload],
      };
    }
    case DELETE_CLIENT: {
      return {
        ...state,
        clients: [...state.clients.filter((client) => client.id !== payload.id)],
      };
    }
    case SET_INSTRUMENTS: {
      return {
        ...state,
        instruments: [...payload],
      };
    }
    case ADD_INSTRUMENT: {
      return {
        ...state,
        instruments: [payload, ...state.instruments],
      };
    }
    case SET_ORDER: {
      return { ...state, order: { ...payload } };
    }
    case RESET_ORDER: {
      return { ...state, order: defaultOrderState };
    }
    case SET_GLOBAL_SEARCH: {
      return { ...state, globalSearch: payload };
    }
    case SET_GLOBAL_SEARCH_TEXT_VALUE: {
      return { ...state, globalSearchTextValue: payload };
    }
    case SET_GLOBAL_SEARCH_ON_BACK: {
      return { ...state, globalSearchOnBack: payload };
    }
    case ADD_FILTER: {
      return { ...state, filter: { ...state.filter, ...payload } };
    }
    case RESET_FILTER: {
      return { ...state, filter: {} };
    }
    case SET_FILTER: {
      return { ...state, filter: payload };
    }
    case ADD_ACTUAL_FILTER: {
      return { ...state, actualFilter: state.filter };
    }
    case RESET_ACTUAL_FILTER: {
      return { ...state, actualFilter: {} };
    }
    case UPDATE_ACTUAL_FILTER: {
      return { ...state, actualFilter: { ...payload } };
    }
    case SET_CUSTOM_ADS: {
      return { ...state, customAds: [...payload] };
    }
    case SET_PAGINATION: {
      return { ...state, pagination: { ...payload } };
    }
    case SET_SHOULD_LEAVE: {
      return { ...state, shouldLeaveModal: payload };
    }
    case SET_COUNTRIES: {
      return { ...state, countries: payload };
    }
    case SET_ACTIVE_COUNTRIES: {
      return { ...state, activeCountries: payload };
    }
    case SET_ATTACHMENTS_PAGE_SWITCHER: {
      return { ...state, attachmentPageSwitcher: payload };
    }
    case SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER: {
      return { ...state, instrumentServicesPageSwitcher: payload };
    }
    case SET_CLIENT_SERVICES_PAGE_SWITCHER: {
      return { ...state, clientServicesPageSwitcher: payload };
    }
    case SET_PROJECT_LIST_REFRESH: {
      return { ...state, projectListRefresh: payload };
    }
    case SET_BRAND_LIST_REFRESH: {
      return { ...state, brandListRefresh: payload };
    }
    case SET_TUNER_LIST_REFRESH: {
      return { ...state, tunerListRefresh: payload };
    }
    case SET_INTRUMENT_LIST_REFRESH: {
      return { ...state, intrumentsListRefresh: payload };
    }
    case SET_CLIENT_LIST_REFRESH: {
      return { ...state, clientListRefresh: payload };
    }
    case SET_HAMBURGER_REF: {
      return { ...state, hamburgerRef: payload };
    }
    case SET_INSTRUMENTS_CLIENTS: {
      return { ...state, instrumnetsClients: payload };
    }
    case SET_SUGGESTION_OPENED: {
      return { ...state, isSuggestionOpened: payload };
    }
    case SET_OPEN_ATTACHMENT_INSTEAD: {
      return { ...state, openAttachmentInstead: payload };
    }
    case SET_OPEN_NEW_SERVICE_INSTEAD: {
      return { ...state, openServiceInstead: payload };
    }
    case SET_OPEN_NEW_PROJECT_INSTEAD: {
      return { ...state, openProjectInstead: payload };
    }
    case SET_OPEN_NEW_REMINDER_INSTEAD: {
      return { ...state, openReminderInstead: payload };
    }
    case SET_RESET_GLOBAL_SEARCH: {
      return { ...state, resetGlobalSearch: payload };
    }
    case SET_ACTIVE_PROJECTS: {
      return { ...state, isActiveProjects: payload };
    }
    case SET_ACTIVE_SERVICES: {
      return { ...state, isActiveServices: payload };
    }
    case SET_INSTRUMENT_SERVICES_FILTER: {
      return { ...state, instrumentServicesFilter: payload };
    }
    case SET_INSTRUMENT_SERVICES_ORDER: {
      return { ...state, instrumentServicesOrder: payload };
    }
    case ADD_INSTRUMENT_SERVICES_FILTER: {
      return { ...state, instrumentServicesFilter: { ...state.instrumentServicesFilter, ...payload } };
    }
    case SET_INSTRUMENT_SERVICES_ACTUAL_FILTER: {
      return { ...state, actualInstrumentServicesFilter: state.instrumentServicesFilter };
    }
    case RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER: {
      return { ...state, actualInstrumentServicesFilter: {} };
    }
    case RESET_INSTRUMENT_SERVICES_FILTER: {
      return { ...state, instrumentServicesFilter: {} };
    }
    case UPDATE_INSTRUMENT_SERVICES_ACTUAL_FILTER: {
      return { ...state, actualInstrumentServicesFilter: { ...payload } };
    }
    default:
      return state;
  }
}

export default redux;
