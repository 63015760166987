import { useSelector } from 'react-redux';

import { IReduxStore } from '../interfaces/IGeneral';
import style from '../assets/scss/components/BottomAd.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';

function BottomAd(): JSX.Element {
  const { wrapper } = convertKeysToCamelCase(style);
  const { customAds } = useSelector((store: IReduxStore) => store.defaultReducer);
  const ad = customAds.find((ad) => ad.position === 'bottom');

  return <div className={wrapper} dangerouslySetInnerHTML={{ __html: ad?.content ? ad.content : '' }} />;
}

export default BottomAd;
