import { GiSandsOfTime } from 'react-icons/gi';
import { IoLogoUsd } from 'react-icons/io';

import style from '../assets/scss/components/ClientsProjectDetails.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import IClientsProjectDetails from '../interfaces/ProjectDetails';
import useProfile from '../hooks/useProfile';

function ClientsProjectDetails(props: IClientsProjectDetails): JSX.Element {
  const { project } = props;
  const { wrapper, description, title, date, row, box, icon, text, instrumentCategory, noMargin } =
    convertKeysToCamelCase(style);
  const { profile } = useProfile();
  const { translations } = useGetTranslations();

  return (
    <div className={wrapper}>
      <div className={description}>
        <div className={`${row} ${noMargin}`}>
          <span className={title}>
            {project.instrument.brand}
            {project.instrument.model ? `, ${project.instrument.model}` : ''}{' '}
            <span className={instrumentCategory}>{project.instrument.category}</span>
          </span>
        </div>
        <p>{project.title}</p>
        <p className={date}>{project.startingDate}</p>
      </div>
      <div className={`${description} ${box}`}>
        <div className={row}>
          <IoLogoUsd className={icon} />
          <p className={`bubble ${text}`}>{project.sumPrice}</p>
          <p>{profile?.currency}</p>
        </div>
        <div className={row}>
          <GiSandsOfTime className={icon} />
          <p className={`bubble ${text}`}>{project.sumDuration}</p>
          <p>{translations.projectCardHours}</p>
        </div>
      </div>
    </div>
  );
}

export default ClientsProjectDetails;
