import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useApi } from './useApi';
import { API_METHOD_POST } from '../constants/api';
import { LOGIN_PAGE, RESET_PASSWORD_API } from '../constants/routes';
import useYupValidationResolver from './useYupValidationResolver';
import { getResetPasswordValidationSchema } from '../helpers/validationSchemaHelper';
import { IUseForgotPassword } from '../interfaces/components/Hooks';
import useGeneral from './useGeneral';
import useGetTranslations from './useGetTranslations';
import { toCamelCase } from '../helpers/baseHelper';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SET_LANGUAGE } from '../constants/reduxActions';

function useResetPassword(): IUseForgotPassword {
  const resolver = useYupValidationResolver(getResetPasswordValidationSchema());
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver });
  const { handleApi } = useApi();
  const { translations } = useGetTranslations();
  const { handleRedirect } = useGeneral();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [resetToken, setResetToken] = useState<string>('');

  useEffect(() => {
    if (search.length === 0) {
      return;
    }
    const searchParams = search.substring(1).split('&');

    if (searchParams.length < 2) {
      return;
    }
    const token = searchParams[0].split('=');
    const language = searchParams[1].split('=');
    setResetToken(token[1]);
    dispatch({ type: SET_LANGUAGE, payload: language[1] });
  }, []);

  async function sendData(formData: any) {
    const { code, message } = await handleApi(API_METHOD_POST, RESET_PASSWORD_API, {
      ...formData,
      token: resetToken,
    });
    if (code === 200) {
      toast.success(translations.passwordResetSuccessfully);
      handleRedirect(LOGIN_PAGE);
      return;
    }
    toast.error(translations[toCamelCase(message)]);
    window.scrollTo(0, 0);
  }

  return { register, errors, handleSubmitForm: handleSubmit(sendData), reset };
}

export default useResetPassword;
