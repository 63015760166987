import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';

import useYupValidationResolver from './useYupValidationResolver';
import { getReminderDetailsSchema } from '../helpers/validationSchemaHelper';
import { SET_PAGINATION, SET_SHOULD_LEAVE } from '../constants/reduxActions';
import { API_METHOD_GET, API_METHOD_POST } from '../constants/api';
import {
  REMINDERS,
  INSTRUMENT_SERVICES,
  SAVE_REMINDERS_TEMPLATE,
  GET_REMINDERS_TEMPLATE,
  REMINDER_DETAILS,
} from '../constants/routes';
import { useApi } from './useApi';
import useGeneral from './useGeneral';
import { IReminderDetail, IReminderTemplate } from '../interfaces/components/Reminders';
import { useCallback, useEffect, useState } from 'react';
import useGetTranslations from './useGetTranslations';
import { toast } from 'react-toastify';
import { ApiResponseTemplate, IReduxStore, Value } from '../interfaces/IGeneral';
import useGetInstruments from './useGetInstruments';
import { formatDate } from '../utils/date';

function useNewReminder() {
  const { id } = useParams<{ id: string }>();
  const { translations } = useGetTranslations();
  const resolver = useYupValidationResolver(getReminderDetailsSchema());
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IReminderDetail>({ resolver });
  const { handleApi } = useApi();
  const dispatch = useDispatch();
  const { handleRedirect } = useGeneral();
  const { pagination } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [isSendEmail, setIsSendEmail] = useState<boolean>(false);
  const [showLeaveWarning, setShowLeaveWarning] = useState<boolean>(true);
  const [reminderDateValue, setReminderDateValue] = useState<string>('');
  const [reminderRepeat, setReminderRepeat] = useState<string>('');
  const history = useHistory();
  useGetInstruments();

  const onSubmit = async (formData: any) => {
    if (reminderDateValue === '') {
      toast.error(translations.dateMissing);
      return;
    }
    if (/^\d+$/.test(reminderRepeat) === false && reminderRepeat !== '') {
      toast.error(translations.repeatDaysError);
      return;
    }
    const { data, code } = await handleApi(API_METHOD_POST, REMINDERS, {
      ...formData,
      instrument_id: id,
      send_mail_to_client: isSendEmail ? 1 : 0,
      date: reminderDateValue,
      repeat_in_days: reminderRepeat !== '' ? reminderRepeat : null,
    });
    if (code !== 200) {
      return;
    }
    dispatch({ type: SET_SHOULD_LEAVE, payload: true });
    dispatch({ type: SET_PAGINATION, payload: pagination });
    setShowLeaveWarning(false);
    toast.success(translations.savedSuccessfully);
    history.goBack();
    history.replace(INSTRUMENT_SERVICES.replace(':id', String(id)));
    setTimeout(() => {
      handleRedirect(REMINDER_DETAILS.replace(':id', data.id));
    }, 100);
  };

  const saveTemplate = useCallback(async () => {
    const template = getValues('text');
    const subject = getValues('subject');
    try {
      await handleApi(API_METHOD_POST, SAVE_REMINDERS_TEMPLATE, { template, subject });
      toast.success(translations.reminderTemplateSaveSuccess);
    } catch {
      toast.error(translations.reminderTemplateSaveFail);
    }
  }, [translations]);

  const getTemplate = useCallback(async () => {
    try {
      const { data } = await handleApi<ApiResponseTemplate<IReminderTemplate>>(API_METHOD_GET, GET_REMINDERS_TEMPLATE);
      if (data) {
        const { template, subject } = data;
        if (getValues('text') === '') {
          setValue('text', template);
        }
        if (getValues('subject') === '') {
          setValue('subject', subject);
        }
      }
    } catch {}
  }, []);

  useEffect(() => {
    getTemplate();
  }, [getTemplate]);

  const onBack = () => {
    handleRedirect(INSTRUMENT_SERVICES.replace(':id', id));
  };

  const handleSendEmail = () => {
    setIsSendEmail(!isSendEmail);
  };

  const handleReminderDateValue = (value: Value) => {
    if (value instanceof Date) {
      setReminderDateValue(formatDate(value));
    }
  };

  const handleReminderRepeatValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReminderRepeat(e.target.value);
  };

  const handleDeleteReminderValue = () => {
    setReminderDateValue('');
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    isSendEmail,
    handleSendEmail,
    onBack,
    saveTemplate,
    showLeaveWarning,
    handleReminderDateValue,
    reminderDateValue,
    reminderRepeat,
    handleReminderRepeatValue,
    handleDeleteReminderValue,
  };
}

export default useNewReminder;
