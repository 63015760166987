import { useEffect, useState } from 'react';
import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import { BRANDS } from '../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxStore } from '../interfaces/IGeneral';
import { SET_BRAND_LIST_REFRESH } from '../constants/reduxActions';
import { IUseChooseBrands } from '../interfaces/components/Hooks';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';

function useChooseProject(): IUseChooseBrands {
  const [brands, setBrands] = useState<ISuggestion[]>([]);
  const { brandListRefresh } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { handleApi } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchBrands();
  }, [brandListRefresh]);

  async function fetchBrands() {
    const { data, code } = await handleApi(API_METHOD_GET, BRANDS);
    if (code !== 200) {
      return;
    }
    setBrands(data);
    dispatch({ type: SET_BRAND_LIST_REFRESH, payload: false });
  }

  return { brands };
}

export default useChooseProject;
