import { useEffect, useState } from 'react';

import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import { FETCH_PLANS } from '../constants/routes';
import { IPlans, IPlan } from '../interfaces/components/Plans';
import { IUsePlans } from '../interfaces/components/Hooks';

function usePlans(): IUsePlans {
  const { handleApi } = useApi();
  const [plans = [], setPlans] = useState<IPlans>();
  const [search, setSearch] = useState('');

  useEffect(() => {
    getPlans();
  }, []);

  async function getPlans() {
    const { data } = await handleApi(API_METHOD_GET, FETCH_PLANS);
    setPlans(data);
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const contains = (plan: IPlan): boolean => {
    return plan.description.includes(search) || plan.name.includes(search) || plan.price.toString().includes(search);
  };

  return { plans: plans.filter((plan) => contains(plan)), onChange };
}

export default usePlans;
