import { useState, useEffect, useCallback } from 'react';
// import _ from 'lodash';

import { API_METHOD_GET } from '../constants/api';
import { useApi } from './useApi';
import { REMINDERS, INSTRUMENT_SERVICES } from '../constants/routes';
import { IReminderInstruments, IRemindersInstrumnets } from '../interfaces/components/Reminders';
import useGeneral from './useGeneral';
import { useDispatch, useSelector } from 'react-redux';
import { IFilter, IReduxStore } from '../interfaces/IGeneral';
import { FilterFilter as ClientsFilter } from '../constants/filter';
import { SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, SET_ORDER, SET_PAGINATION } from '../constants/reduxActions';
import NoActiveSubscriptionNotification, {
  noActiveSubscriptionOptions,
} from '../components/NoActiveSubscriptionNotification';
import { toast } from 'react-toastify';
import { IClient } from '../interfaces/components/Clients';
import usePagination from './usePagination';

function useRemindersList() {
  const { actualFilter, order, clients, pagination } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [reminders, setReminders] = useState<IReminderInstruments[]>([]);
  const [remindersInstruments, setRemindersInstruments] = useState<IRemindersInstrumnets[]>([]);
  const [remindersClients, setRemindersClients] = useState<IClient[]>([]);
  const { handleRedirect } = useGeneral();
  const [total, setTotal] = useState<number>(0);
  const dispatch = useDispatch();
  const { paginate } = usePagination();
  const { handleApi } = useApi();

  useEffect(() => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'upcoming_reminder_date', orderByDirection: 'ASC' } });
  }, []);

  useEffect(() => {
    if (clients.length > 0) {
      getRemindersInstruments();
    }
  }, [clients]);

  useEffect(() => {
    if (order.orderByField === 'created_at') {
      dispatch({ type: SET_ORDER, payload: { orderByField: 'upcoming_reminder_date', orderByDirection: 'ASC' } });
      return;
    }
    if (order.orderByField !== 'created_at') {
      fetchReminders();
    }
  }, [order, actualFilter]);

  useEffect(() => {
    if (order.orderByField !== 'created_at') {
      fetchRemindersWithPagination();
    }
  }, [pagination.page]);

  // prettier-ignore
  const fetchReminders = async () => {
    const keys = Object.keys(actualFilter) as Array<keyof IFilter>;
    const answers = keys.map(
      (key: keyof IFilter) => `&${[key]}=${ClientsFilter[key] ? actualFilter[key]?.id : actualFilter[key]?.name}`
    ).join('');

    const queryParams = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=1&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, REMINDERS + queryParams);

    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }
    setReminders(data.data);
    setTotal(data.total);
  };

  // prettier-ignore
  const fetchRemindersWithPagination = async () => {
    const keys = Object.keys(actualFilter) as Array<keyof IFilter>;
    const answers = keys.map(
      (key: keyof IFilter) => `&${[key]}=${ClientsFilter[key] ? actualFilter[key]?.id : actualFilter[key]?.name}`
    ).join('');

    const queryParams = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, REMINDERS + queryParams);

    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }
    paginate(setReminders, data.data);
    setTotal(data.total);
  };

  const fetchNextPage = () => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: pagination.page + 1 } });
  };

  const getRemindersInstruments = async () => {
    const URL = REMINDERS + `?page=1&perPage=99999`;

    const { data, code } = await handleApi(API_METHOD_GET, URL);
    if (code !== 200) {
      return;
    }

    //prettier-ignore
    setRemindersInstruments(
      data.data.map((reminder: IReminderInstruments) => {
        return {
          id: reminder.id,
          brand: reminder.brand,
          model: reminder.model,
          category: reminder.category,
        };
      })
    );

    const allRemindersWithClient = data.data.filter((reminder: IReminderInstruments) => {
      return reminder.client;
    });

    const allRemindersClients: IClient[] = [];

    allRemindersWithClient.map((reminder: IReminderInstruments) => {
      const found = clients.find((client) => reminder.client.id === client.id);
      if (found) {
        allRemindersClients.push(found);
      }
    });

    setRemindersClients(allRemindersClients);
  };

  const onClick = (id: number) => {
    dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 2 });
    handleRedirect(INSTRUMENT_SERVICES.replace(':id', id.toString()));
  };

  const showNoActiveSubscriptionToast = useCallback(() => {
    toast.error(<NoActiveSubscriptionNotification />, noActiveSubscriptionOptions);
  }, []);

  return { reminders, remindersInstruments, remindersClients, onClick, total, fetchNextPage };
}

export default useRemindersList;
