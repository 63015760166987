import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import { TRANSLATIONS_API } from '../constants/routes';
import { SET_IS_LOADING, SET_TRANSLATIONS } from '../constants/reduxActions';
import { transformTranslations } from '../transforms/baseTransformer';
import { IReduxStore } from '../interfaces/IGeneral';

function useTranslations(): void {
  const { handleApi } = useApi();
  const { promises, language } = useSelector((store: IReduxStore) => store.defaultReducer);
  const dispatch = useDispatch();

  async function getTranslations() {
    const { data } = await handleApi(API_METHOD_GET, TRANSLATIONS_API + '?language=' + language);
    const transformedData = transformTranslations(data);

    dispatch({ type: SET_TRANSLATIONS, payload: transformedData });
  }

  useEffect(() => {
    getTranslations();
  }, [language]);

  useEffect(() => {
    if (promises.length) {
      Promise.all(promises).then(() => dispatch({ type: SET_IS_LOADING, payload: false }));
    }
  }, [promises]);
}

export default useTranslations;
