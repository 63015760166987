import { useDispatch, useSelector } from 'react-redux';
import { SET_LANGUAGE } from '../constants/reduxActions';
import { IUseLanguage } from '../interfaces/components/Hooks';
import { IReduxStore } from '../interfaces/IGeneral';

function useLanguage(): IUseLanguage {
  const dispatch = useDispatch();
  const { language } = useSelector((store: IReduxStore) => store.defaultReducer);

  const handleLanguage = (newLanguage: string) => {
    dispatch({ type: SET_LANGUAGE, payload: newLanguage });
  };

  return { language, handleLanguage };
}

export default useLanguage;
