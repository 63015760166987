import style from '../assets/scss/components/ClientsInstruments.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import detach from '../assets/images/detach.svg';
import attach from '../assets/images/attach.svg';
import useGetTranslations from '../hooks/useGetTranslations';
import { IClientsInstrument } from '../interfaces/components/Clients';
import { AiFillInfoCircle } from 'react-icons/ai';

function ClientsInstruments(props: IClientsInstrument): JSX.Element {
  const {
    iconPart,
    textPart,
    icon,
    line,
    instrumentContainer,
    infoContainer,
    infoDescription,
    infoImage,
    cursorPointer,
  } = convertKeysToCamelCase(style);
  const { isEditing, selectedInstruments, onDeleteClick, onClick } = props;
  const { translations } = useGetTranslations();

  return (
    <div>
      {!isEditing && selectedInstruments.length > 0 && (
        <div className={infoContainer}>
          <AiFillInfoCircle className={infoImage} />
          <p className={infoDescription}>{translations.clientsInstrumentsInfo}</p>
        </div>
      )}
      {isEditing && (
        <div className={instrumentContainer}>
          <div className={iconPart}>
            <div className={icon} />
          </div>
        </div>
      )}
      {selectedInstruments.length > 0 &&
        selectedInstruments.map((instrument) => (
          <div className={instrumentContainer} key={instrument.id}>
            <div className={iconPart}>
              <hr className={line} />
              <img
                src={isEditing ? detach : attach}
                className={icon}
                onClick={() => {
                  if (onDeleteClick) onDeleteClick(instrument.id);
                }}
              />
              <hr className={line} />
            </div>
            <div
              className={`${textPart} ${!isEditing && cursorPointer}`}
              onClick={() => {
                if (onClick) onClick(instrument.id);
              }}
            >
              <p>
                {instrument.brand + ', ' + instrument.category + ', ' + translations.generalId + ': ' + instrument.id}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
}

export default ClientsInstruments;
