import { IUseClientsCard } from '../interfaces/components/Hooks';

function useClientsCard(): IUseClientsCard {
  const handleSMSOpen = (phone?: string) => {
    window.open(`sms://${phone?.replaceAll(' ', '')}`, '_self');
  };

  const handlePhoneOpen = (phone?: string) => {
    window.open(`tel://${phone?.replaceAll(' ', '')}`, '_self');
  };

  const handleEmailOpen = (email?: string) => {
    window.open(`mailto:${email}`, '_self');
  };

  return { handleSMSOpen, handlePhoneOpen, handleEmailOpen };
}

export default useClientsCard;
