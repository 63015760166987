import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useApi } from './useApi';
import useYupValidationResolver from './useYupValidationResolver';
import { getNewProjectValidationSchema } from '../helpers/validationSchemaHelper';
import { API_METHOD_DELETE, API_METHOD_GET, API_METHOD_POST, API_METHOD_PUT } from '../constants/api';
import { IDetailProject } from '../interfaces/components/Project';
import useGeneral from './useGeneral';
import { EDIT_PROJECT, PROJECT_DETAIL, PROJECT_DETAILS, TUNERS } from '../constants/routes';
import useGetTranslations from './useGetTranslations';
import useGetInstruments from './useGetInstruments';
import { IReduxStore, Value } from '../interfaces/IGeneral';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ORDER } from '../constants/reduxActions';
import { formatDate } from '../utils/date';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';
import { toCamelCase } from '../helpers/baseHelper';

function useProjectServices() {
  const urlParts = window.location.href.split('/');
  const id = urlParts[urlParts.length - 1];
  const [shouldLeaveWarning, setShouldLeaveWarning] = useState<boolean>(true);
  const [startingDate, setStartingDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [projectService, setProjectService] = useState<IDetailProject>();
  const { translations } = useGetTranslations();
  const resolver = useYupValidationResolver(getNewProjectValidationSchema());
  const { order } = useSelector((store: IReduxStore) => store.defaultReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setProjectValue,
  } = useForm<IDetailProject>({ resolver });
  const { handleApi } = useApi();
  const { handleRedirect, goBack } = useGeneral();
  useGetInstruments();
  const dispatch = useDispatch();
  const [newTunerName, setNewTunerName] = useState<string>('');
  const [tuner, setTuner] = useState<ISuggestion>({ id: -1, name: '' });

  useEffect(() => {
    dispatch({ type: SET_ORDER, payload: { orderByField: 'last_date', orderByDirection: 'DESC' } });
  }, []);

  useEffect(() => {
    fetchProjectDetails();
  }, [order]);

  useEffect(() => {
    if (projectService) {
      setProjectValue('title', projectService?.title);
      setProjectValue('description', projectService?.description);
      setProjectValue('starting_date', projectService?.startingDate);
      setProjectValue('ending_date', projectService?.endingDate);
      if (projectService?.startingDate) {
        setStartingDate(new Date(projectService?.startingDateOriginalFormat).toISOString().slice(0, 10));
      }
      if (projectService?.endingDate) {
        setEndDate(new Date(projectService?.endingDateOriginalFormat).toISOString().slice(0, 10));
      }
    }
  }, [projectService]);

  async function fetchProjectDetails() {
    if (order.orderByField === 'created_at') {
      return;
    }
    //prettier-ignore
    const { data } = await handleApi(
      API_METHOD_GET,
      PROJECT_DETAILS.replace(':id', id) +
        `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}`
    );
    setProjectService(data);
    if (data.tuner !== null) {
      setTuner({ id: data.tuner.id, name: data.tuner.name });
    }
  }

  async function onSubmit(form: IDetailProject) {
    if (startingDate && endDate && new Date(endDate) <= new Date(startingDate)) {
      toast.error(translations.endingDateError);
      return;
    }

    let tunerIdOnSubmit = -1;
    let tunerNameOnSubmit = '';

    if (newTunerName !== '') {
      const { data, code } = await handleApi(API_METHOD_POST, TUNERS, {
        name: newTunerName,
      });

      if (Number(code) >= 400) {
        toast.error(translations[toCamelCase(data.name.replace('api.', ''))] ?? data.name);
        return;
      }

      tunerIdOnSubmit = data.id;
      tunerNameOnSubmit = data.name;
    }

    if (newTunerName === '') {
      tunerIdOnSubmit = tuner.id;
      tunerNameOnSubmit = tuner.name;
    }

    const { data, code } = await handleApi(API_METHOD_PUT, PROJECT_DETAILS.replace(':id', id), {
      ...form,
      instrumentId: projectService?.instrumentId,
      starting_date: startingDate !== '' ? startingDate : null,
      ending_date: endDate !== '' ? endDate : null,
      tuner_id: tunerIdOnSubmit === -1 ? null : tunerIdOnSubmit,
      tuner: tunerNameOnSubmit === '' ? null : tunerNameOnSubmit,
    });

    if (code === 200) {
      window.scrollTo(0, 0);
      setShouldLeaveWarning(false);
      goBack();
      toast.success(translations.savedSuccessfully);
      await fetchProjectDetails();
      return;
    }

    if (data) {
      Object.values(data).map((err: any) => {
        toast.error(err);
      });
    }
  }

  const toggleEditing = () => {
    if (window.location.href.includes('/edit/')) {
      handleRedirect(PROJECT_DETAIL.replace(':id', String(id)));
      return;
    }
    handleRedirect(EDIT_PROJECT.replace(':id', String(id)));
  };

  async function handleDelete() {
    await handleApi(API_METHOD_DELETE, PROJECT_DETAILS.replace(':id', id));
    if (projectService?.instrumentId) {
      setShouldLeaveWarning(false);
      history.go(-2);
    }
  }
  const handleStartingDate = (value: Value) => {
    if (value instanceof Date) {
      setStartingDate(formatDate(value));
    }
  };

  const handleEndDate = (value: Value) => {
    if (value instanceof Date) {
      setEndDate(formatDate(value));
    }
  };

  const handleDeleteDate = () => {
    setStartingDate('');
  };

  const handleDeleteEndDate = () => {
    setEndDate('');
  };

  const handleNewTunerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTunerName(e.target.value);
    setProjectValue('tuner', e.target.value);
  };

  const handleTunerChange = (tuner: ISuggestion | undefined) => {
    tuner && setTuner(tuner);
    setProjectValue('tuner', tuner?.name);
  };

  return {
    shouldLeaveWarning,
    projectService,
    toggleEditing,
    handleSubmit: handleSubmit(onSubmit),
    register,
    errors,
    handleDelete,
    handleStartingDate,
    startingDate,
    handleDeleteDate,
    fetchProjectDetails,
    endDate,
    handleEndDate,
    handleDeleteEndDate,
    tuner,
    newTunerName,
    handleTunerChange,
    handleNewTunerNameChange,
  };
}

export default useProjectServices;
