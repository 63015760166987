import { IServiceCard, ISimpleService } from '../interfaces/components/Service';
import style from '../assets/scss/components/ServiceCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import InstrumentServicesDetails from './InstrumentServicesDetails';
import ClientServicesDetails from './ClientServicesDetails';
import { ServiceCardTypes } from '../constants/ServiceCard';
import { API_METHOD_POST } from '../constants/api';
import { SERVICE_ACTIVATE_API, SERVICE_DEACTIVATE_API } from '../constants/routes';
import { useApi } from '../hooks/useApi';
import { toast } from 'react-toastify';
import useGetTranslations from '../hooks/useGetTranslations';

function ServiceCard(props: IServiceCard): JSX.Element {
  const { service, onClick, type, onActiveChangeCallback, disableActivate, showInstrumentData, showProjectIcon } =
    props;
  const { wrapper, bubble, bubbleOut, bubbleOutRed, bubbleContainer, shortLine, longLine, right, greenBubble } =
    convertKeysToCamelCase(style);
  const { handleApi } = useApi();
  const { translations } = useGetTranslations();

  const handleServiceActivate = async (service: ISimpleService) => {
    const currentEndpoint = service.isActive ? SERVICE_DEACTIVATE_API : SERVICE_ACTIVATE_API;
    const { data, code } = await handleApi(API_METHOD_POST, currentEndpoint.replace(':id', service.id.toString()));
    if (code === 200 && onActiveChangeCallback) {
      onActiveChangeCallback(data);
      return;
    }
    toast.error(translations.SomethingWentWong);
  };

  const checkPast = (date?: string) => {
    if (!date) return false;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() - 1);
    const providedDate = new Date(date);
    return providedDate < tomorrow;
  };

  return (
    <div className={wrapper}>
      <div className={bubbleContainer}>
        <div className={shortLine} />
        <div className={checkPast(service.nextDate) ? bubbleOutRed : bubbleOut}>
          <div
            className={service?.isActive ? greenBubble : bubble}
            onClick={() => (!disableActivate ? handleServiceActivate(service) : onClick(service.id))}
          />
        </div>
        <div className={longLine} />
      </div>
      <div
        className={right}
        onClick={() => {
          onClick(service.id);
        }}
      >
        {type === ServiceCardTypes.CLIENT_SERVICE && (
          <ClientServicesDetails service={service} showProjectIcon={showProjectIcon} />
        )}
        {type === ServiceCardTypes.INSTRUMENT_SERVICE && (
          <InstrumentServicesDetails
            service={service}
            showInstrumentData={showInstrumentData}
            showProjectIcon={showProjectIcon}
          />
        )}
      </div>
    </div>
  );
}

export default ServiceCard;
