import style from '../assets/scss/components/ActiveServiceCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import { BiUser } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import { ISimpleProject } from '../interfaces/components/Project';
import completed from '../assets/images/completed.svg';
import { ReactComponent as UnfinishedProject } from '../assets/images/completed.svg';
import { professionTranslation } from '../utils/roleChecker';

interface IActiveProjectCard {
  project: ISimpleProject;
  onClick: (id: number) => void;
}

const ActiveProjectCard = (props: IActiveProjectCard): JSX.Element => {
  const { project, onClick } = props;
  const {
    wrapper,
    bubble,
    bubbleOut,
    bubbleOutRed,
    bubbleContainer,
    shortLine,
    longLine,
    right,
    greenBubble,
    detailWrapper,
    description,
    title,
    box,
    flex,
    instrumentTitle,
    icon,
    rowCenter,
    dateTitle,
    logo,
    imageRow,
    redIcon,
    lighter,
    infoRed,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  const checkPast = (date?: string) => {
    if (!date) return false;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() - 1);
    const providedDate = new Date(date);
    return providedDate < tomorrow;
  };

  return (
    <div className={wrapper}>
      <div className={bubbleContainer}>
        <div className={shortLine} />
        <div className={checkPast(project.endingDate) ? bubbleOutRed : bubbleOut}>
          <div
            className={project?.isActive ? greenBubble : bubble}
            onClick={() => {
              onClick(project.id);
            }}
          >
            {project.endingDateOriginal &&
              project.endingDateOriginal !== null &&
              new Date(project.endingDateOriginal).getDate()}
          </div>
        </div>
        <div className={longLine} />
      </div>
      <div
        className={right}
        onClick={() => {
          onClick(project.id);
        }}
      >
        <div className={detailWrapper}>
          <div className={description}>
            <div className={rowCenter}>
              {project.endingDate && project.endingDate !== null ? (
                <p className={dateTitle}>
                  {project.endingDate.split(' ')[1]}, {project.endingDate.split(' ')[2]}
                </p>
              ) : (
                <p className={dateTitle}>{translations.noFinishDate}</p>
              )}
              {checkPast(project.endingDate) ? (
                <UnfinishedProject className={infoRed} />
              ) : (
                <img src={completed} alt="completed" className={icon} />
              )}
            </div>
            <p className={instrumentTitle}>
              {project.instrument?.brand}
              {project.instrument?.model ? `, ${project.instrument.model}` : ''}
            </p>
            <p className={title}>{project.title}</p>
            <div className={box}>
              <div className={flex}>
                {project.instrument?.client?.name ? (
                  <div className={imageRow}>
                    <BiUser className={logo} />
                    <p className={lighter}>{project.instrument?.client.name}</p>
                  </div>
                ) : (
                  <div className={imageRow}>
                    <FaUser className={redIcon} />
                    <p className={lighter}>{translations[professionTranslation('noClient')]}</p>
                  </div>
                )}
              </div>
              {project.instrument?.client?.region && (
                <div className={imageRow}>
                  <MdLocationPin className={logo} />
                  <p className={lighter}>
                    {project.instrument?.client?.city}
                    {project.instrument?.client.city && project.instrument?.client.postcode && ', '}{' '}
                    {project.instrument?.client?.postcode}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveProjectCard;
