import React from 'react';

import AuthenticationTitle from '../AuthenticationTitle';
import useGeneral from '../../hooks/useGeneral';
import useGetTranslations from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import { LOGIN_PAGE } from '../../constants/routes';
import ResetPasswordForm from '../ResetPasswordForm';

function ResetPassword(): JSX.Element {
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();

  useSetHeader(() => {
    handleRedirect(LOGIN_PAGE);
  }, translations.resetPassword);

  return (
    <main>
      <section className="small">
        <AuthenticationTitle />
      </section>
      <section className="small">
        <ResetPasswordForm />
      </section>
    </main>
  );
}

export default ResetPassword;
