import style from '../assets/scss/components/ReminderDetailCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import { IReminderDetailCard } from '../interfaces/components/Reminders';

function ReminderDetailCard(props: IReminderDetailCard): JSX.Element {
  const {
    wrapper,
    bubble,
    bubbleContainer,
    bubbleOut,
    shortLine,
    longLine,
    description,
    right,
    title,
    box,
    line,
    greyText,
    greenText,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { reminder, onClick } = props;

  return (
    <div
      className={wrapper}
      onClick={() => {
        onClick(reminder.id);
      }}
    >
      <div className={bubbleContainer}>
        <div className={shortLine} />
        <div className={bubbleOut}>
          <div className={bubble} />
        </div>
        <div className={longLine} />
      </div>
      <div className={right}>
        <div className={description}>
          <p className={title}>{reminder.title}</p>
          <div className={box}>
            <p>{reminder.date}</p>
            {reminder.nextDate && (
              <>
                <p className={greyText}>|</p>
                <p className={greenText}>{translations.next}:</p>
                <p>{reminder.nextDate}</p>
              </>
            )}
          </div>
        </div>
        <div className={line} />
      </div>
    </div>
  );
}

export default ReminderDetailCard;
