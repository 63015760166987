import { useSelector } from 'react-redux';

import BlueHeader from './BlueHeader';
import SortIcon from './SortIcon';
import FilterIcon from './FilterIcon';
import ActiveFilterHeader from './ActiveFilterHeader';
import { IReduxStore } from '../interfaces/IGeneral';
import GlobalSearch from './GlobalSearch';
import { IHomeSearchHeader } from '../interfaces/components/HomeSearchHeader';

function HomeSearchHeader(props: IHomeSearchHeader): JSX.Element {
  const {
    hideSerachbar,
    hideFilters,
    hideSort,
    filters,
    sortTitle,
    sortField,
    isService,
    parentPage,
    passAllSortToActiveFilter,
  } = props;
  const { actualFilter, globalSearch } = useSelector((store: IReduxStore) => store.defaultReducer);

  return Object.keys(actualFilter).length === 0 ? (
    <BlueHeader
      element={
        <>
          {!hideSort && (
            <SortIcon
              title={sortTitle}
              field={sortField}
              isService={isService}
              isInactive={Object.keys(globalSearch).length !== 0}
              parentPage={parentPage}
            />
          )}
          {!hideFilters && <FilterIcon filters={filters} isInactive={Object.keys(globalSearch).length !== 0} />}
          {!hideSerachbar && <GlobalSearch />}
        </>
      }
    />
  ) : (
    <ActiveFilterHeader
      title={sortTitle}
      field={sortField}
      isService={isService}
      parentPage={passAllSortToActiveFilter ? parentPage : undefined}
      actualFilter={actualFilter}
    />
  );
}

export default HomeSearchHeader;
