import { AiOutlinePlus } from 'react-icons/ai';
import { toast } from 'react-toastify';

import style from '../assets/scss/components/NewHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGeneral from '../hooks/useGeneral';
import useMySubscriptionPlan from '../hooks/useMySubscriptionPlan';
import { INewHeader } from '../interfaces/components/Headers';
import LimitExceededNotification, { limitExceededOptions } from './LimitExceededNotification';
import { getPreviousDay } from '../helpers/date';

function HomeAddHeader(props: INewHeader): JSX.Element {
  const { wrapper, title, line, link } = convertKeysToCamelCase(style);
  const { handleRedirect } = useGeneral();
  const { leftTitle, rightTitle, to, type } = props;
  const { subscription } = useMySubscriptionPlan();

  const canCreateNewResource = (): boolean => {
    if (subscription) {
      if (type === 'instrument' && subscription.resourcesLeft.instrument === 0) {
        return false;
      }
      if (type === 'client' && subscription.resourcesLeft.client === 0) {
        return false;
      }
      if (
        new Date(subscription.subscriptionEndAtOriginal.split(' ')[0].replace(/-/g, '/')) < getPreviousDay(new Date())
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <div>
      <section className="small">
        <div className={wrapper}>
          <p className={title}>{leftTitle}</p>{' '}
          {to && (
            <div
              className={`${wrapper} ${link}`}
              onClick={() => {
                subscription && canCreateNewResource()
                  ? handleRedirect(to)
                  : toast.error(<LimitExceededNotification />, limitExceededOptions);
              }}
            >
              <p>{rightTitle}</p>
              <AiOutlinePlus className="plus-circle" />
            </div>
          )}
        </div>
        <div className={line} />
      </section>
    </div>
  );
}

export default HomeAddHeader;
