import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/ServiceIconInfo.module.scss';
import { AiFillInfoCircle } from 'react-icons/ai';
import useGetTranslations from '../hooks/useGetTranslations';
import completed from '../assets/images/completed.svg';
import project_start from '../assets/images/project_start.svg';
import { ReactComponent as UnfinishedProject } from '../assets/images/completed.svg';

interface ServiceIconInfoProps {
  active: boolean;
}

export const ProjectIconInfo = ({ active }: ServiceIconInfoProps) => {
  const { wrapper, infoContainer, infoImage, infoRed, infoDescription, infoDescriptionRight, infoIcon } =
    convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  return (
    <div className={wrapper}>
      <AiFillInfoCircle className={infoIcon} />
      <div>
        {!active ? (
          <>
            <div className={infoContainer}>
              <img src={project_start} alt="project_start_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.projectStartDateInfoDescription}</p>
            </div>
            <div className={infoContainer}>
              <img src={completed} alt="completed_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.projectEndDateInfoDescription}</p>
            </div>
          </>
        ) : (
          <>
            <div className={infoContainer}>
              <img src={completed} alt="completed_date" className={infoImage} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.activeProjectEndDateInfoDescription}</p>
            </div>
            <div className={infoContainer}>
              <UnfinishedProject className={infoRed} />
              <p className={infoDescription}>= </p>
              <p className={infoDescriptionRight}>{translations.activeProjectEndDateLateInfoDescription}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
