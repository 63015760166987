import { useEffect } from 'react';

import { TOKEN_DATA_KEY } from '../../constants/login';
import { LOGIN_PAGE } from '../../constants/routes';
import useGeneral from '../../hooks/useGeneral';

function Logout(): JSX.Element {
  const { handleRedirect } = useGeneral();

  useEffect(() => {
    localStorage.removeItem(TOKEN_DATA_KEY);
    handleRedirect(LOGIN_PAGE);
  }, []);

  return <div />;
}

export default Logout;
