import LoginForm from '../LoginForm';
import AuthenticationTitle from '../AuthenticationTitle';
import LoginBottom from '../LoginBottom';
import LanguageBar from '../LanguageBar';

function LoginPage(): JSX.Element {
  return (
    <main>
      <section className="small">
        <LanguageBar />
      </section>
      <section className="small">
        <AuthenticationTitle />
      </section>
      <section className="small">
        <LoginForm />
      </section>
      <section>
        <LoginBottom />
      </section>
    </main>
  );
}

export default LoginPage;
