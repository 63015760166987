import Select from 'react-select';
import _ from 'lodash';

import useSuggestionSearch from '../hooks/useSuggestionSearch';
import { ISuggestionSearch } from '../interfaces/components/SuggestionSearch';
import { suggestionArrayToSelectArray, selectToSuggestion } from '../transforms/suggestionTransformer';
import { filterDropdownStyle, countryDropdownStyle } from '../constants/countryDropdownStyle';
import { IReactSelectOptions, IReduxStore } from '../interfaces/IGeneral';
import { useDispatch, useSelector } from 'react-redux';
import { smoothScrollTo } from '../utils/scrollTo';
import { useRef } from 'react';
import { SET_SUGGESTION_OPENED } from '../constants/reduxActions';

/* eslint-disable */
const SuggestionSearch = (props: ISuggestionSearch): JSX.Element => {
  const { suggestions, placeholder, isFilter, customRef } = props;
  const { value, handleOnClick } = useSuggestionSearch(props);
  const { hamburgerRef, isSuggestionOpened } = useSelector((store: IReduxStore) => store.defaultReducer);
  const dispatch = useDispatch();
  const selectRef = useRef<any>(null);
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  if (!iOS) {
    return (
      <>
        <Select
          className="react-select"
          options={suggestionArrayToSelectArray(
            _.uniqBy(
              suggestions.sort((a, b) => a.name.localeCompare(b.name)),
              'id'
            )
          )}
          name={'suggestionSerach'}
          placeholder={placeholder}
          value={value}
          onChange={(value: IReactSelectOptions | null) => {
            handleOnClick(selectToSuggestion(value));
            if (hamburgerRef) {
              hamburgerRef.style.overflowY = 'visible';
            }
          }}
          styles={
            _.uniqBy(suggestions, 'id').length <= 5
              ? {
                  ...countryDropdownStyle,
                  menuList: (base) =>
                    ({
                      ...base,
                      overflowY: 'none',
                      overscrollBehavior: 'none',
                    } as any),
                }
              : countryDropdownStyle
          }
          maxMenuHeight={180}
          captureMenuScroll
          menuShouldBlockScroll={isFilter}
          onFocus={() => {
            if (hamburgerRef && customRef && isFilter) {
              smoothScrollTo({
                ref: customRef,
                duration: 500,
                parentRef: hamburgerRef,
              });
              dispatch({ type: SET_SUGGESTION_OPENED, payload: true });

              hamburgerRef.style.overflowY = 'hidden';
            }
          }}
          onBlur={() => {
            if (hamburgerRef) {
              dispatch({ type: SET_SUGGESTION_OPENED, payload: false });
              hamburgerRef.style.overflowY = 'visible';
            }
          }}
        />
      </>
    );
  }

  return !isFilter ? (
    <>
      <Select
        className="react-select"
        options={suggestionArrayToSelectArray(
          _.uniqBy(
            suggestions.sort((a, b) => a.name.localeCompare(b.name)),
            'id'
          )
        )}
        name={'suggestionSerach'}
        placeholder={placeholder}
        value={value}
        ref={selectRef}
        onChange={(value: IReactSelectOptions | null) => {
          handleOnClick(selectToSuggestion(value));
        }}
        styles={countryDropdownStyle}
        maxMenuHeight={180}
        menuShouldBlockScroll={isFilter}
      />
    </>
  ) : (
    <div
      onClick={() => {
        if (!isFilter) {
          dispatch({ type: SET_SUGGESTION_OPENED, payload: true });
          selectRef.current.focus();
          selectRef.current.openMenu('first');
          selectRef.current.controlRef.parentElement.style.pointerEvents = 'all';
        }

        if (hamburgerRef && selectRef.current && !isSuggestionOpened) {
          dispatch({ type: SET_SUGGESTION_OPENED, payload: true });
          const fakeInput = document.createElement('input');
          fakeInput.setAttribute('type', 'text');
          fakeInput.style.position = 'absolute';
          fakeInput.style.opacity = '0';
          fakeInput.style.height = '0';
          fakeInput.style.fontSize = '16px'; // disable auto zoom
          fakeInput.readOnly = true;
          document.body.prepend(fakeInput);
          fakeInput.focus();

          isFilter &&
            customRef &&
            smoothScrollTo({
              ref: customRef,
              duration: 150,
              parentRef: hamburgerRef,
              onFinished: () => {
                selectRef.current.focus();
                selectRef.current.openMenu('first');
                selectRef.current.controlRef.parentElement.style.pointerEvents = 'all';
                fakeInput.remove();
              },
            });
        }
      }}
    >
      <Select
        className="react-select"
        options={suggestionArrayToSelectArray(
          _.uniqBy(
            suggestions.sort((a, b) => a.name.localeCompare(b.name)),
            'id'
          )
        )}
        name={'suggestionSerach'}
        placeholder={placeholder}
        value={value}
        ref={selectRef}
        onChange={(value: IReactSelectOptions | null) => {
          handleOnClick(selectToSuggestion(value));
          if (hamburgerRef) {
            hamburgerRef.style.overflowY = 'visible';
          }
        }}
        styles={
          _.uniqBy(suggestions, 'id').length <= 5
            ? {
                ...filterDropdownStyle,
                menuList: (base) =>
                  ({
                    ...base,
                    overflowY: 'none',
                    overscrollBehavior: 'none',
                  } as any),
              }
            : filterDropdownStyle
        }
        maxMenuHeight={180}
        captureMenuScroll
        menuShouldBlockScroll={isFilter}
        onFocus={() => {
          if (hamburgerRef) {
            dispatch({ type: SET_SUGGESTION_OPENED, payload: true });
            hamburgerRef.style.overflowY = 'hidden';
          }
        }}
        onBlur={() => {
          if (hamburgerRef && selectRef) {
            dispatch({ type: SET_SUGGESTION_OPENED, payload: false });
            hamburgerRef.style.overflowY = 'visible';
            selectRef.current.controlRef.parentElement.style.pointerEvents = 'none';
          }
        }}
      />
    </div>
  );
};

export default SuggestionSearch;
