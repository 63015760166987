import { BsCheck } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import Title from './Title';
import style from '../assets/scss/components/InstrumentForm.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import AttachmentIcons from './AttachmentIcons';
import SuggestionSearch from './SuggestionSearch';
import { PIANO_TYPES } from '../constants/instruments';
import { IInstrumentForm } from '../interfaces/components/Instrument';
import { IReduxStore } from '../interfaces/IGeneral';
import useGetTranslations from '../hooks/useGetTranslations';
import { AiFillInfoCircle } from 'react-icons/ai';
import InstrumentClient from './InstrumentClient';
import LeaveModal from './LeaveModal';
import useProfile from '../hooks/useProfile';
import ChooseBrand from './ChooseBrand';
import { professionChecker, professionTranslation } from '../utils/roleChecker';

function InstrumentForm(props: IInstrumentForm): JSX.Element {
  const {
    input,
    row,
    errorText,
    orange,
    radioButtonText,
    // greenText,
    // flexContainer,
    infoImage,
    infoDescription,
    infoContainer,
    indented,
    sizeRow,
  } = convertKeysToCamelCase(style);
  const { clients } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslations();
  const { profile } = useProfile();
  const {
    register,
    handleSubmitForm,
    selectedType,
    setSelectedType,
    errors,
    onAttachmentChange,
    onPictureChange,
    instrument,
    onSuggestionChange,
    isEditing,
    selectedClient,
    onDeleteClick,
    attachmentNr,
    pictureNr,
    disableSave,
    canLeave,
    createMode,
    handleCancelUploadCrop,
    handleUploadCrop,
    uploadCropImage,
    uploadCropperRef,
    onMatch,
    brand,
    newBrandName,
    handleNewBrandNameChange,
  } = props;

  return (
    <form onSubmit={handleSubmitForm}>
      <LeaveModal canLeave={canLeave} />
      <Title title={translations[professionTranslation('instrumentFormClientTitle')]} />
      {!isEditing && (
        <div className={infoContainer}>
          <AiFillInfoCircle className={infoImage} />
          <p className={infoDescription}>
            {professionChecker() ? translations.instrumentFormTipToe : translations.instrumentFormTechnicianTipToe}
          </p>
        </div>
      )}
      {isEditing && (
        <div className={infoContainer}>
          <AiFillInfoCircle className={infoImage} />
          <p className={infoDescription}>
            {professionChecker()
              ? translations.instrumentFormEditClientTipToe
              : translations.instrumentFormEditTechnicianTipToe}
          </p>
        </div>
      )}
      <div className={indented}>
        <p className={orange}>
          {isEditing
            ? translations[professionTranslation('instrumentFormClientChange')]
            : translations[professionTranslation('instrumentFormClientDescription')]}
        </p>
        <SuggestionSearch
          onMatch={onSuggestionChange}
          suggestions={clients.map((client) => ({
            name: client.firstName + ' ' + client.lastName + ', ID: ' + client.id,
            id: client.id,
          }))}
          placeholder={translations.changeClient}
        />
        <InstrumentClient isEditing={true} selectedClient={selectedClient} onDeleteClick={onDeleteClick} />
      </div>
      <Title title={translations.instrumentFormCategoryTitle} />
      <div className={row}>
        {PIANO_TYPES.map((piano) => (
          <div
            className="radio radio-instrument"
            key={piano.id}
            onClick={() => {
              setSelectedType(piano.id);
            }}
          >
            <div className="radio-container">
              <BsCheck className={`radio-item ${piano.id === selectedType && 'radio-item-selected'}`} />
            </div>
            <p className={radioButtonText}>{translations[piano.name]}</p>
          </div>
        ))}
      </div>
      <Title title={translations.instrumentFormRoomTitle} />
      <div className={indented}>
        {errors.room && <p className={errorText}>{translations.instrumentFormRoomError}</p>}
        <input
          name="room"
          id="room"
          className={`primary ${input}`}
          ref={register}
          placeholder={translations.instrumentDetailFormRoomPlaceholder}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
      </div>
      <Title title={translations.instrumentFormBrandTitle} />
      <div className={indented}>
        <ChooseBrand
          isEditing={isEditing ?? true}
          onMatch={onMatch}
          brand={brand}
          handleNewBrandNameChange={handleNewBrandNameChange}
          newBrandName={newBrandName}
        />
        {errors.brand && <p className={errorText}>{translations.instrumentFormBrandError}</p>}
      </div>
      <Title title={translations.instrumentFormModelTitle} />
      <div className={indented}>
        <input
          name="model"
          id="model"
          className={`primary ${input}`}
          ref={register}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
      </div>
      <Title title={translations.instrumentFormSerialNumberTitle} />
      <div className={indented}>
        {errors.serial_number && <p className={errorText}>{translations.serialNumberError}</p>}
        <input
          name="serial_number"
          id="serial_number"
          className={`primary ${input}`}
          ref={register}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
      </div>
      <Title title={translations.instrumentFormAgeTitle} />
      <div className={indented}>
        <input
          name="age"
          id="age"
          className={`primary ${input}`}
          ref={register}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
      </div>
      {!professionChecker() && (
        <div className={infoContainer}>
          <AiFillInfoCircle className={infoImage} />
          <a className={infoDescription} href={'https://pianoservice.app/Contact/'} target="_blank" rel="noreferrer">
            {translations.pianoAgeHelp}
          </a>
        </div>
      )}
      <Title title={translations.instrumentFormDateOfPurchase} />
      <div className={indented}>
        <input
          name="dateOfPurchase"
          id="dateOfPurchase"
          className={`primary ${input}`}
          ref={register}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
      </div>
      {/* TODO {isEditing && (
        <div className={flexContainer}>
          <p>{translations.instrumentFormNeedHelp}</p>
          <p className={greenText}>{translations.instrumentFormOpenPianoAgeCalculator}</p>
        </div>
      )} */}
      {/* {!isEditing ? (
        <div
          className={row}
          onClick={() => {
            window.open('https://www.pianoagecalculator.com', '_blank');
          }}
        >
          <button className="grey" type="button">
            {translations.instrumentFormAgeCalculator}
          </button>
        </div>
      ) : (
        <>
          <div
            className={row}
            onClick={() => {
              window.open('https://www.pianoagecalculator.com', '_blank');
            }}
          >
            <button className="grey" type="button">
              {translations.instrumentFormAgeCalculatorWebVersion}
            </button>
          </div>
          <div
            className={row}
            onClick={() => {
              window.open('https://www.pianoagecalculator.com', '_blank');
            }}
          >
            <button className="grey" type="button">
              {translations.instrumentFormAgeCalculatorMobileApp}
            </button>
          </div>
        </>
      )} */}
      <Title title={translations.instrumentFormSizeTitle} />
      <div className={`${indented} ${sizeRow}`}>
        <input
          name="size"
          id="size"
          className={`primary ${input}`}
          ref={register}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
        <p>{profile?.lengthUnit}</p>
      </div>
      <Title title={translations.instrumentFormColorTitle} />
      <div className={indented}>
        <input
          name="color"
          id="color"
          className={`primary ${input}`}
          ref={register}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
      </div>
      <Title title={translations.instrumentFormWarrantyTitle} />
      <div className={indented}>
        {errors.warranty && <p className={errorText}>{translations.instrumentFormWarrantyError}</p>}
        <input
          name="warranty"
          id="warranty"
          className={`primary ${input}`}
          ref={register}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
      </div>
      <Title
        title={isEditing || createMode ? translations.editAttachments : translations.instrumentFormAttachmentTitle}
      />
      <AttachmentIcons
        onAttachmentChange={onAttachmentChange}
        onPictureChange={onPictureChange}
        instrumentId={instrument?.id}
        pictureNr={pictureNr}
        attachmentNr={attachmentNr}
        cropperRef={uploadCropperRef}
        cropImage={uploadCropImage}
        handleFinishCrop={handleUploadCrop}
        handleCancelCrop={handleCancelUploadCrop}
        cropText={translations.crop}
        cancelText={translations.cancel}
      />
      <Title title={translations.instrumentFormNoteTitle} />
      <textarea id="note" name="note" rows={8} ref={register} />
      <div className={row}>
        <button className="blue" type="submit" disabled={disableSave || false}>
          {translations.instrumentFormSaveInstrument}
        </button>
      </div>
      <div className="last-element" />
    </form>
  );
}

export default InstrumentForm;
