import { MdModeEditOutline } from 'react-icons/md';
import { BsTrash } from 'react-icons/bs';
import { useParams } from 'react-router-dom';

import BlueHeader from './BlueHeader';
import style from '../assets/scss/components/AttachmentHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IAttachmentHeader } from '../interfaces/components/Attachments';
import useGetTranslations from '../hooks/useGetTranslations';

function AttachmentHeader(props: IAttachmentHeader): JSX.Element {
  const { wrapper, row, deleteText, editText, deleteIcon, button, rightAlign } = convertKeysToCamelCase(style);
  const { isEditing, toggleEditing, handleDelete, switcherPage, onChange } = props;
  const { translations } = useGetTranslations();
  const { type } = useParams<{ type: string }>();

  return (
    <BlueHeader
      element={
        <div className={isEditing ? rightAlign : wrapper}>
          {type !== 'project' ? (
            !isEditing && (
              <button className={button}>
                <input
                  id="file"
                  onChange={onChange}
                  name="file"
                  type="file"
                  multiple
                  accept={switcherPage === 0 ? 'image/*' : ''}
                />
                <label htmlFor="file">
                  {switcherPage === 0 ? translations.attachmentUploadImage : translations.attachmentUploadFile}
                </label>
              </button>
            )
          ) : (
            <div />
          )}

          {!isEditing && (
            <div className={row} onClick={toggleEditing}>
              <p className={editText}>{translations.edit}</p>
              <MdModeEditOutline className="edit-icon" />
            </div>
          )}
          {isEditing && (
            <div className={row} onClick={handleDelete}>
              <p className={deleteText}>{translations.deleteSelected}</p>
              <BsTrash className={deleteIcon} />
            </div>
          )}
        </div>
      }
    />
  );
}

export default AttachmentHeader;
