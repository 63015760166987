import { useEffect, useState } from 'react';
import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import { TUNERS } from '../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxStore } from '../interfaces/IGeneral';
import { SET_TUNER_LIST_REFRESH } from '../constants/reduxActions';
import { IUseChooseTuners } from '../interfaces/components/Hooks';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';

function useChooseTuner(): IUseChooseTuners {
  const [tuners, setTuners] = useState<ISuggestion[]>([]);
  const { handleApi } = useApi();
  const { tunerListRefresh } = useSelector((store: IReduxStore) => store.defaultReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTuners();
  }, [tunerListRefresh]);

  async function fetchTuners() {
    const { data, code } = await handleApi(API_METHOD_GET, TUNERS);
    if (code !== 200) {
      return;
    }
    setTuners(data);
    dispatch({ type: SET_TUNER_LIST_REFRESH, payload: false });
  }

  return { tuners };
}

export default useChooseTuner;
