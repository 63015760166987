import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { IFilter } from '../interfaces/IGeneral';
import { IUseActiveFilterHeader } from '../interfaces/components/Hooks';

function useActiveFilterHeader(
  actualFilter: IFilter,
  resetActualFilterRedux: string,
  // eslint-disable-next-line prettier/prettier
  updateActualFilterRedux: string
): IUseActiveFilterHeader {
  const dispatch = useDispatch();

  const onReset = () => {
    dispatch({ type: resetActualFilterRedux });
  };

  //prettier-ignore
  const onDelete = useCallback(
    (key: keyof IFilter) => {
      const filter = actualFilter;
      delete filter[key];
      dispatch({ type: updateActualFilterRedux, payload: filter });
    },
    [actualFilter]
  );

  return { onReset, onDelete };
}

export default useActiveFilterHeader;
