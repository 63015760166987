import { TOKEN_DATA_KEY } from '../constants/login';
import MenuPoints from './MenuPoints';
import style from '../assets/scss/components/StickyFooter.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';

function StickyFooter(): JSX.Element {
  const { footer } = convertKeysToCamelCase(style);
  const token = localStorage.getItem(TOKEN_DATA_KEY);

  if (!token) {
    return <></>;
  }

  return (
    <footer>
      <div className={footer}>
        <MenuPoints />
      </div>
    </footer>
  );
}

export default StickyFooter;
