const formatDateForInputRange = (date: Date) => date.toISOString().split('T')[0];

const getPreviousDay = (date = new Date()) => {
  const previous = new Date(date.getTime());
  previous.setHours(0, 0, 0, 0);

  return previous;
};

export { formatDateForInputRange, getPreviousDay };
