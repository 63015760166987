import { useSelector } from 'react-redux';

import BlueHeader from './BlueHeader';
import SortIcon from './SortIcon';
import FilterIcon from './FilterIcon';
import ActiveFilterHeader from './ActiveFilterHeader';
import style from '../assets/scss/components/InstrumentServicesHeader.module.scss';
import { IReduxStore } from '../interfaces/IGeneral';
import { IClientServicesHeader } from '../interfaces/components/ClientServicesHeader';
import useGetTranslations from '../hooks/useGetTranslations';
import { AiOutlinePlus } from 'react-icons/ai';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useClientServicesHeader from '../hooks/useClientServicesHeader';

function ClientServicesHeader(props: IClientServicesHeader): JSX.Element {
  const { filters, sortTitle, sortField, switcherPage, client, instruments } = props;
  const { actualFilter } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslations();
  const { wrapper, newWrapper } = convertKeysToCamelCase(style);
  const { handleClick } = useClientServicesHeader(switcherPage, client, instruments);

  return Object.keys(actualFilter).length === 0 ? (
    <BlueHeader
      element={
        <div className={wrapper}>
          {switcherPage === 2 ? (
            <div className={newWrapper} />
          ) : (
            <div className={newWrapper}>
              <SortIcon
                title={sortTitle}
                field={sortField}
                isService={switcherPage === 0}
                isProject={switcherPage === 1}
                // isInactive={Object.keys(globalSearch).length !== 0}
              />
              <FilterIcon
                filters={filters}
                // isInactive={Object.keys(globalSearch).length !== 0}
              />
            </div>
          )}
          <div className={newWrapper} onClick={handleClick}>
            {switcherPage === 0 && <p>{translations.instrumentDetailHeaderNewService}</p>}
            {switcherPage === 1 && <p>{translations.instrumentNewProject}</p>}
            {switcherPage === 2 && <p>{translations.instrumentNewReminder}</p>}
            <AiOutlinePlus className="plus-circle" />
          </div>
        </div>
      }
    />
  ) : (
    <ActiveFilterHeader
      title={sortTitle}
      field={sortField}
      isService={switcherPage === 0}
      actualFilter={actualFilter}
    />
  );
}

export default ClientServicesHeader;
