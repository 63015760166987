import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IUseSuggestionSearch } from '../interfaces/components/Hooks';
import { ISuggestion, ISuggestionSearch } from '../interfaces/components/SuggestionSearch';
import { IReactSelectOptions, IReduxStore } from '../interfaces/IGeneral';
import { suggestionToSelect } from '../transforms/suggestionTransformer';

const useSuggestionSearch = (props: ISuggestionSearch): IUseSuggestionSearch => {
  const { onMatch, multiselect, defaultValue, needsSpiltAtValues } = props;
  const [value, setValue] = useState<IReactSelectOptions | null>();
  const { hamburgerRef } = useSelector((store: IReduxStore) => store.defaultReducer);

  useEffect(() => {
    if (defaultValue) {
      setValue(suggestionToSelect(defaultValue));
      onMatch(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    // On hamburger close the value by default is empty.
    if (hamburgerRef === null) {
      setValue(null);
    }
  }, [hamburgerRef]);

  const handleOnClick = (suggestion?: ISuggestion) => {
    if (suggestion) {
      if (needsSpiltAtValues && suggestion?.name) {
        const suggestionWithoutIdInName: ISuggestion = {
          ...suggestion,
          name: suggestion?.name.slice(0, suggestion?.name.lastIndexOf(',')),
        };
        onMatch(suggestionWithoutIdInName);
        setValue(multiselect ? null : suggestionToSelect(suggestion));
        return;
      }
      onMatch(suggestion);
      setValue(multiselect ? null : suggestionToSelect(suggestion));
    }
  };

  return { value, handleOnClick };
};

export default useSuggestionSearch;
