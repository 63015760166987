export const perPage = 10;

export const itemsOnPage = 10;

export const arrayPagination = (maxLength: number, currentLength: number): Array<number> => {
  const x = Array.from({ length: maxLength }, (_, i) => i + 1);
  if (x.length <= perPage) {
    return x;
  }
  let i = 1;
  const Arr = [];
  Arr.push(currentLength);
  let pushed = 1;
  while (pushed < perPage) {
    if (currentLength - i > 0) {
      Arr.push(currentLength - i);
      pushed = pushed + 1;
    }
    if (currentLength + i <= maxLength) {
      Arr.push(currentLength + i);
      pushed = pushed + 1;
    }
    i = i + 1;
  }
  return Arr.sort((a, b) => a - b);
};
