import { useEffect, useState } from 'react';
import useGetTranslations from '../hooks/useGetTranslations';
import AlertModal from './AlertModal';
import useProfile from '../hooks/useProfile';
import { capitalize } from '../helpers/baseHelper';

export const GuidePopup = () => {
  const isOpenLocalStorage = localStorage.getItem('guidePopup');
  const { profile } = useProfile();
  const [isOpen, setOpen] = useState(false);
  const { translations } = useGetTranslations();

  const closePopup = () => {
    localStorage.setItem('guidePopup', 'false');
    setOpen(false);
  };

  const handleRedirect = () => {
    closePopup();
  };

  useEffect(() => {
    if (translations[`guidePopupTitle${capitalize(profile?.userType)}`] === null) {
      setOpen(false);
      return;
    }

    if (profile !== null && isOpenLocalStorage !== 'false' && Object.keys(translations).length > 0) {
      setOpen(true);
    }
  }, [profile, isOpenLocalStorage, translations]);

  return isOpen ? (
    <AlertModal
      onClose={closePopup}
      isOpen={isOpen}
      onLeftButtonClick={closePopup}
      onRightButtonClick={handleRedirect}
      header={translations[`guidePopupTitle${capitalize(profile?.userType)}`]}
      description={translations[`guidePopupDescription${capitalize(profile?.userType)}`]}
      leftButton={translations[`guidePopupLeftButton${capitalize(profile?.userType)}`]}
      rightButton={translations[`guidePopupRightButton${capitalize(profile?.userType)}`]}
    />
  ) : null;
};
