import { BsCheck } from 'react-icons/bs';

import style from '../assets/scss/components/AttachmentAttachments.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IAttachmentAttachments, IAttachment } from '../interfaces/components/Attachments';
import useMobile from '../hooks/useMobile';
import InfiniteScroll from 'react-infinite-scroll-component';
import { paddingForInfiniteScroll } from '../constants/infiniteScrollStyle';
import PaginationElements from './PaginationElements';

function AttachmentAttachments(props: IAttachmentAttachments): JSX.Element {
  const { row, title, logo, logoSelected, logoUnselected, logoContainer, line } = convertKeysToCamelCase(style);
  const { isEditing, files, selectedAttachments, onAttachmentClick, nextPage, total } = props;
  const { isMobile } = useMobile();

  return (
    <div>
      {nextPage ? (
        <>
          {isMobile ? (
            <InfiniteScroll
              dataLength={total ?? 1}
              next={nextPage}
              hasMore={true}
              loader={<></>}
              style={paddingForInfiniteScroll}
            >
              {files?.map((file) => (
                <div key={file.id}>
                  <div
                    className={row}
                    onClick={() => {
                      if (onAttachmentClick) onAttachmentClick(file);
                    }}
                  >
                    <div className={logoContainer}>
                      {isEditing && (
                        <BsCheck
                          className={`${
                            selectedAttachments?.find((img: IAttachment) => img.id === file.id)
                              ? logoSelected
                              : logoUnselected
                          } ${logo}`}
                        />
                      )}
                    </div>
                    {!isEditing ? (
                      <a href={file.url} className={title}>
                        {file.name}
                      </a>
                    ) : (
                      <p className={title}>{file.name}</p>
                    )}
                  </div>
                  <div className={line} />
                </div>
              ))}
            </InfiniteScroll>
          ) : (
            <>
              {files?.map((file) => (
                <div key={file.id}>
                  <div
                    className={row}
                    onClick={() => {
                      if (onAttachmentClick) onAttachmentClick(file);
                    }}
                  >
                    <div className={logoContainer}>
                      {isEditing && (
                        <BsCheck
                          className={`${
                            selectedAttachments?.find((img: IAttachment) => img.id === file.id)
                              ? logoSelected
                              : logoUnselected
                          } ${logo}`}
                        />
                      )}
                    </div>
                    {!isEditing ? (
                      <a href={file.url} className={title}>
                        {file.name}
                      </a>
                    ) : (
                      <p className={title}>{file.name}</p>
                    )}
                  </div>
                  <div className={line} />
                </div>
              ))}
              {!!total && <PaginationElements length={total} perPage={20} />}
            </>
          )}
        </>
      ) : (
        files?.map((file: IAttachment) => (
          <div key={file.id}>
            <div
              className={row}
              onClick={() => {
                if (onAttachmentClick) onAttachmentClick(file);
              }}
            >
              <div className={logoContainer}>
                {isEditing && (
                  <BsCheck
                    className={`${
                      selectedAttachments?.find((img: IAttachment) => img.id === file.id)
                        ? logoSelected
                        : logoUnselected
                    } ${logo}`}
                  />
                )}
              </div>
              {!isEditing ? (
                <a href={file.url} className={title}>
                  {file.name}
                </a>
              ) : (
                <p className={title}>{file.name}</p>
              )}
            </div>
            <div className={line} />
          </div>
        ))
      )}
    </div>
  );
}

export default AttachmentAttachments;
