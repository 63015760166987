import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/Title.module.scss';
import { ITitle } from '../interfaces/components/Title';

function Title(props: ITitle): JSX.Element {
  const { wrapper, line, middle, center, centerLine, tap } = convertKeysToCamelCase(style);
  const { title, alignCenter, tapAnimation } = props;

  return (
    <div className={alignCenter ? '' : wrapper}>
      <div className={`${alignCenter ? center : middle}`}>
        <b className={`${tapAnimation ? tap : ''}`}>{title}</b>
      </div>

      <div className={alignCenter ? centerLine : line} />
    </div>
  );
}

export default Title;
