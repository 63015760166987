import { createRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import useYupValidationResolver from './useYupValidationResolver';
import { getServiceValidationSchema } from '../helpers/validationSchemaHelper';
import {
  EDIT_SERVICE,
  INSTRUMENT_PROJECTS,
  NEW_PROJECT,
  SERVICE_ACTIVATE_API,
  SERVICE_DEACTIVATE_API,
  SERVICE_DETAIL,
  SERVICE_DETAIL_API,
  TUNERS,
} from '../constants/routes';
import { useApi } from './useApi';
import useGeneral from './useGeneral';
import { SET_PROJECT_LIST_REFRESH } from '../constants/reduxActions';
import { getBase64, toCamelCase } from '../helpers/baseHelper';
import { IServiceDetail } from '../interfaces/components/Service';
import { API_METHOD_DELETE, API_METHOD_GET, API_METHOD_POST, API_METHOD_PUT } from '../constants/api';
import useGetTranslations from './useGetTranslations';
import { IDetailProject } from '../interfaces/components/Project';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';
import { useHistory } from 'react-router';
import { ReactCropperElement } from 'react-cropper';
import { nanoid } from 'nanoid';
import useMySubscriptionPlan from './useMySubscriptionPlan';
import { Value } from '../interfaces/IGeneral';
import { formatDate } from '../utils/date';

function useServiceDetail() {
  const urlParts = window.location.href.split('/');
  const id = urlParts[urlParts.length - 1];
  const [shouldLeaveWarning, setShouldLeaveWarning] = useState<boolean>(true);
  const [service, setService] = useState<IServiceDetail>();
  const [lastServiceDate, setLastServiceDate] = useState<string>();
  const [nextServiceDate, setNextServiceDate] = useState<string>();
  const [newProjectName, setNewProjectName] = useState<string>('');
  const [project, setProject] = useState<IDetailProject>();
  const [projects, setProjects] = useState<IDetailProject[]>();
  const [uploadImages, setUploadImages] = useState<Array<{ content: string; name: string }>>([]);
  const [uploadAttachments, setUploadAttachments] = useState<Array<{ content: string; name: string }>>([]);
  const [isActive, setIsActive] = useState<number>(0);
  const [isDateModalOpen, setDateModalOpen] = useState<boolean>(false);
  const [newTunerName, setNewTunerName] = useState<string>('');
  const [tuner, setTuner] = useState<ISuggestion>({ id: -1, name: '' });

  const { handleApi } = useApi();
  const dispatch = useDispatch();
  const { handleRedirect, goBack, resizeFile, finishCrop } = useGeneral();
  const { translations } = useGetTranslations();
  const history = useHistory();
  const resolver = useYupValidationResolver(getServiceValidationSchema());
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setServiceValue,
  } = useForm<IServiceDetail>({ resolver });
  const [cropUploadImage, setCropUploadImage] = useState<string>(''); // single uploaded image on attachments cropped
  const uploadCropperRef = createRef<ReactCropperElement>();
  const { imagesLeft, showPlanExceededToast } = useMySubscriptionPlan();

  useEffect(() => {
    fetchService();
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [service]);

  useEffect(() => {
    if (projects) {
      const serviceProject = projects.find((projectItem: IDetailProject) => {
        return projectItem.id === service?.projectId;
      });
      setProject(serviceProject);
    }
  }, [projects]);

  useEffect(() => {
    if (service) {
      setServiceValue('title', service.title);
      setServiceValue('description', service.description);
      setServiceValue('lastDate', service.lastDate);
      setServiceValue('nextDate', service.nextDate);
      setServiceValue('frequency', service.frequency);
      setServiceValue('humidity', service.humidity);
      setServiceValue('temperature', service.temperature);
      setServiceValue('price', service.price);
      setServiceValue('duration', service.duration);
      setServiceValue('note', service.note);
      setServiceValue('isActive', service.isActive);
      setServiceValue('date_time_info', service.dateTimeInfo);
      setIsActive(service.isActive);
      service.lastDateOriginalFormat &&
        setLastServiceDate(new Date(service.lastDateOriginalFormat).toISOString().slice(0, 10));
      service.nextDateOriginalFormat &&
        setNextServiceDate(new Date(service.nextDateOriginalFormat).toISOString().slice(0, 10));
    }
  }, [service]);

  async function fetchService() {
    const { data } = await handleApi(API_METHOD_GET, SERVICE_DETAIL_API.replace(':id', id));
    setService(data);
    if (data.tuner !== null) {
      setTuner({ id: data.tuner.id, name: data.tuner.name });
    }
  }

  async function fetchProjects() {
    if (service) {
      //prettier-ignore
      const { data } = await handleApi(
        API_METHOD_GET,
        INSTRUMENT_PROJECTS.replace(':id', String(service?.instrument.data.id))
      );
      setProjects(data.data);
    }
  }

  const toggleEditing = () => {
    if (window.location.href.includes('/edit/')) {
      handleRedirect(SERVICE_DETAIL.replace(':id', String(id)));
      return;
    }
    handleRedirect(EDIT_SERVICE.replace(':id', String(id)));
  };

  async function onSubmit(form: any) {
    if (lastServiceDate && nextServiceDate && new Date(nextServiceDate) <= new Date(lastServiceDate)) {
      toast.error(translations.nextDateError);
      return;
    }

    if (service && project) {
      service.projectId = project.id;
    }

    if (newProjectName !== '' && service) {
      const { data } = await handleApi(API_METHOD_POST, NEW_PROJECT, {
        instrument_id: service.instrument.data.id,
        title: newProjectName,
      });
      service.projectId = data.id;
      dispatch({ type: SET_PROJECT_LIST_REFRESH, payload: true });
    }

    if (service?.isActive !== undefined && isActive !== service?.isActive) {
      const currentEndpoint = isActive ? SERVICE_ACTIVATE_API : SERVICE_DEACTIVATE_API;
      await handleApi(API_METHOD_POST, currentEndpoint.replace(':id', service.id.toString()));
    }

    let tunerIdOnSubmit = -1;
    let tunerNameOnSubmit = '';

    if (newTunerName !== '') {
      const { data, code } = await handleApi(API_METHOD_POST, TUNERS, {
        name: newTunerName,
      });

      if (Number(code) >= 400) {
        toast.error(translations[toCamelCase(data.name.replace('api.', ''))] ?? data.name);
        return;
      }

      tunerIdOnSubmit = data.id;
      tunerNameOnSubmit = data.name;
    }

    if (newTunerName === '') {
      tunerIdOnSubmit = tuner.id;
      tunerNameOnSubmit = tuner.name;
    }

    const { data, code } = await handleApi(API_METHOD_PUT, SERVICE_DETAIL_API.replace(':id', id), {
      ...form,
      price: form.price !== '' ? Number(form.price.replace(/\s/g, '')) : form.price,
      duration: form.duration !== '' ? Number(form.duration.replace(/\s/g, '')) : form.duration,
      images: uploadImages,
      files: uploadAttachments,
      instrument_id: service?.instrumentId ? service.instrumentId : null,
      project_id: service?.projectId ? service.projectId : null,
      last_date: lastServiceDate,
      next_date: nextServiceDate,
      isActive,
      tuner_id: tunerIdOnSubmit === -1 ? null : tunerIdOnSubmit,
      tuner: tunerNameOnSubmit === '' ? null : tunerNameOnSubmit,
    });

    if (code === 200) {
      window.scrollTo(0, 0);
      toast.success(translations.savedSuccessfully);
      setService(data);
      setShouldLeaveWarning(false);
      goBack();
      return;
    }

    if (data) {
      Object.values(data).map((err: any) => {
        toast.error(err);
      });
    }
  }

  const handleUploadCropImage = (fileString: string | ArrayBuffer | null, name: string) => {
    name;
    if (typeof fileString === 'string') {
      setCropUploadImage(fileString);
    }
  };
  const handleCancelUploadCrop = () => {
    setCropUploadImage('');
  };

  const handleFinishUploadCrop = async () => {
    const resized = await finishCrop(uploadCropperRef);

    if (resized) {
      onLoadPictures(resized as any, `${nanoid()}}.png`);
      setCropUploadImage('');
    }
  };

  const onPictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const amountOfFiles = files.length;
      // Prevent adding files if limit exceeded
      if (amountOfFiles > imagesLeft) return showPlanExceededToast();
      for (let i = 0; i < amountOfFiles; i++) {
        if (files.length === 1) {
          getBase64(files[i], handleUploadCropImage);
          return;
        }
        const image = await resizeFile(files[i]);
        onLoadPictures(image as any, `resized${nanoid()}.png`);
      }
    }
  };

  const onAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        getBase64(files[i], onLoadAttachments);
      }
    }
  };
  const onLoadPictures = (fileString: string | ArrayBuffer | null, name: string) => {
    if (fileString)
      setUploadImages((old) => {
        return old
          ? [...old, { content: fileString as string, name: name }]
          : [{ content: fileString as string, name: name }];
      });
  };
  const onLoadAttachments = (fileString: string | ArrayBuffer | null, name: string) => {
    if (fileString)
      setUploadAttachments((old) => {
        return old
          ? [...old, { content: fileString as string, name: name }]
          : [{ content: fileString as string, name: name }];
      });
  };

  const handleLastDate = (value: Value) => {
    if (value === null) {
      // this handles that case, when the user has a scheduled date, and a completed date, and deletes the completed date.
      // this prevents the modal to pop up
      return;
    }
    if (value instanceof Date) {
      setLastServiceDate(formatDate(value));
    }
    nextServiceDate !== undefined && nextServiceDate !== '' && toggleDateModal();
  };

  const handleNextDate = (value: Value) => {
    if (value instanceof Date) {
      setNextServiceDate(formatDate(value));
    }
  };

  const handleDeleteLastDate = () => {
    setLastServiceDate('');
  };

  const handleDeleteNextDate = () => {
    setNextServiceDate('');
  };

  const handleExistingProjectChange = (project: ISuggestion | undefined) => {
    if (projects) {
      const updatedProject = projects.find((projectItem: IDetailProject) => {
        return projectItem.id === project?.id;
      });
      setProject(updatedProject);
    }
  };

  const handleNewProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewProjectName(e.target.value);
  };

  async function handleDelete() {
    await handleApi(API_METHOD_DELETE, SERVICE_DETAIL_API.replace(':id', id));
    if (service?.instrumentId) {
      setShouldLeaveWarning(false);
      history.go(-2);
    }
  }

  const handleServiceActivate = async () => {
    if (service === undefined) {
      return;
    }

    if (window.location.href.includes('/edit/')) {
      setIsActive(1 - isActive);
      return;
    }

    const currentEndpoint = service.isActive ? SERVICE_DEACTIVATE_API : SERVICE_ACTIVATE_API;
    const { data, code } = await handleApi(API_METHOD_POST, currentEndpoint.replace(':id', service.id.toString()));
    if (code === 200) {
      setService(data);
      return;
    }
    toast.error(translations.SomethingWentWong);
  };

  const toggleDateModal = () => {
    setDateModalOpen(!isDateModalOpen);
  };

  const confirmDateModal = () => {
    toggleDateModal();
    setNextServiceDate('');
  };

  const handleNewTunerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTunerName(e.target.value);
    setServiceValue('tuner', e.target.value);
  };

  const handleTunerChange = (tuner: ISuggestion | undefined) => {
    tuner && setTuner(tuner);
    setServiceValue('tuner', tuner?.name);
  };

  return {
    instrument: { ...service?.instrument.data, client: service?.instrument.client },
    service,
    toggleEditing,
    shouldLeaveWarning,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    onAttachmentChange,
    onPictureChange,
    handleDelete,
    pictureNr: uploadImages.length + (service?.imagesCount || 0),
    attachmentNr: uploadAttachments.length + (service?.filesCount || 0),
    lastServiceDate,
    nextServiceDate,
    handleLastDate,
    handleNextDate,
    handleDeleteLastDate,
    handleDeleteNextDate,
    newProjectName,
    handleNewProjectNameChange,
    handleExistingProjectChange,
    project,
    cropUploadImage,
    uploadCropperRef,
    handleCancelUploadCrop,
    handleFinishUploadCrop,
    handleServiceActivate,
    isActive,
    isDateModalOpen,
    toggleDateModal,
    confirmDateModal,
    tuner,
    newTunerName,
    handleTunerChange,
    handleNewTunerNameChange,
  };
}

export default useServiceDetail;
