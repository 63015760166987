import style from '../assets/scss/components/ForgotPasswordForm.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import SimpleInput from './SimpleInput';
import useForgotPassword from '../hooks/useForgotPassword';
import useGetTranslation from '../hooks/useGetTranslations';

function ForgotPasswordForm(): JSX.Element {
  const { submitContainer, formWrapper } = convertKeysToCamelCase(style);
  const { register, handleSubmitForm, errors } = useForgotPassword();
  const { translations } = useGetTranslation();

  return (
    <form onSubmit={handleSubmitForm}>
      <div className={formWrapper}>
        <SimpleInput
          type="text"
          id="email"
          label={translations.loginEmail}
          isRequired
          reg={register}
          errors={errors}
          errorText={translations.mandatoryField}
        />
        <div className={submitContainer}>
          <button type="submit" className="primary">
            {translations.forgotPasswordReset}
          </button>
        </div>
      </div>
    </form>
  );
}

export default ForgotPasswordForm;
