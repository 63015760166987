import AlertModal from './AlertModal';
import useGetTranslations from '../hooks/useGetTranslations';
import { IDeleteModal } from '../interfaces/components/DeleteModal';

function DeleteModal(props: IDeleteModal): JSX.Element {
  const { handleDelete, isVisible, toggleVisible, description } = props;
  const { translations } = useGetTranslations();

  return (
    <AlertModal
      onClose={toggleVisible}
      isOpen={isVisible}
      onLeftButtonClick={toggleVisible}
      onRightButtonClick={() => {
        handleDelete();
        toggleVisible();
      }}
      header={translations.deleteModalTitle}
      description={description}
      leftButton={translations.cancel}
      rightButton={translations.delete}
    />
  );
}

export default DeleteModal;
