import ProfileCard from '../ProfileCard';
import ProfileMenu from '../ProfileMenu';
import ProfileHeader from '../ProfileHeader';
import AdSection from '../AdSection';
import useProfileCard from '../../hooks/useProfileCard';
import { useSelector } from 'react-redux';
import { IReduxStore } from '../../interfaces/IGeneral';

function ProfileSettings(): JSX.Element {
  const { uploadImage } = useProfileCard();
  const { profile } = useSelector((store: IReduxStore) => store.defaultReducer);

  return (
    <main>
      <section className="small">
        <ProfileHeader />
      </section>
      <AdSection>
        <ProfileCard uploadImage={uploadImage} clientId={profile?.id} />
        <ProfileMenu />
      </AdSection>
    </main>
  );
}

export default ProfileSettings;
