import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/InstrumentServicesDetails.module.scss';
import { IInstrumentServicesDetails } from '../interfaces/components/IntrumentServicesDetails';
import { BsLink } from 'react-icons/bs';
import completed from '../assets/images/completed.svg';
import scheduled from '../assets/images/scheduled.svg';
import tuner from '../assets/images/tuner.svg';

function IntrumentServicesDetails(props: IInstrumentServicesDetails): JSX.Element {
  const { service, showInstrumentData, showProjectIcon } = props;
  const {
    wrapper,
    description,
    title,
    box,
    row,
    flex,
    linkIcon,
    linkContainer,
    instrumentTitle,
    redText,
    infoImage,
    tunerBox,
    tunerImage,
  } = convertKeysToCamelCase(style);

  const checkPast = (date?: string) => {
    if (!date) return false;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() - 1);
    const providedDate = new Date(date);
    return providedDate < tomorrow;
  };

  return (
    <div className={wrapper}>
      <div className={description}>
        {showInstrumentData && (
          <p className={instrumentTitle}>
            {service.instrument?.brand}
            {service.instrument?.model ? `, ${service.instrument.model}` : ''}
          </p>
        )}
        <p className={title}>{service.title}</p>
        <div className={box}>
          <div className={flex}>
            <div className={row}>
              <img src={scheduled} alt="scheduled_date" className={infoImage} />
              <p className={checkPast(service.nextDate) ? redText : ''}>{service.nextDate ? service.nextDate : '-'}</p>
            </div>
          </div>
          <div className={row}>
            <img src={completed} alt="completed_date" className={infoImage} />
            <p>{service.lastDate ? service.lastDate : '-'}</p>
          </div>
          {showProjectIcon && (
            <div className={linkContainer}>
              <BsLink className={linkIcon} />
            </div>
          )}
        </div>
        {service.tuner && service.tuner !== null && (
          <div className={`${box} ${tunerBox}`}>
            <div className={row}>
              <img src={tuner} alt="tuner" className={tunerImage} />
              <p>{service.tuner.name}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default IntrumentServicesDetails;
