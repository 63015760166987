import Title from './Title';
import LatestInfo from './LatestInfo';
import TimeAndAmount from './TimeAndAmount';
import AttachmentIcons from './AttachmentIcons';
import ServiceDate from './ServiceDate';
import ChooseProject from './ChooseProject';
import { IServiceForm } from '../interfaces/components/Service';
import style from '../assets/scss/components/ServiceForm.module.scss';
import useGetTranslation from '../hooks/useGetTranslations';
import LeaveModal from './LeaveModal';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import ChooseTuner from './ChooseTuner';
import { useMemo } from 'react';
import { professionChecker } from '../utils/roleChecker';

function ServiceForm(props: IServiceForm): JSX.Element {
  const {
    register,
    handleSubmit,
    onMatch,
    service,
    errors,
    newProjectName,
    handleNewProjectNameChange,
    onPictureChange,
    onAttachmentChange,
    handleLastDate,
    handleNextDate,
    attachmentNr,
    pictureNr,
    project,
    lastServiceDate,
    nextServiceDate,
    showLeaveWarning,
    handleDeleteLastDate,
    handleDeleteNextDate,
    instrument,
    handleCancelUploadCrop,
    handleUploadCrop,
    uploadCropImage,
    uploadCropperRef,
    isModalOpen,
    toggleModal,
    handleModalConfirm,
    handleNewTunerNameChange,
    handleTunerMatch,
    newTunerName,
    tuner,
  } = props;
  const { btnWrapper, title, errorText, indented } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslation();
  const isTuner = useMemo(() => professionChecker(), []);

  return (
    <form onSubmit={handleSubmit}>
      {showLeaveWarning && <LeaveModal />}
      <Title title={translations.serviceFormTitleTitle} />
      <div className={indented}>
        {errors?.title && <p className={errorText}>{translations.mandatoryField}</p>}
        <input
          className={`primary ${title}`}
          type="text"
          ref={register}
          name="title"
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
      </div>
      <Title title={translations.serviceFormTitleDescription} />
      <div className={indented}>
        <textarea rows={4} ref={register} name="description" placeholder={translations.serviceFormDescription} />
      </div>
      {isTuner && (
        <>
          <Title title={translations.serviceFormTitleTuner} />
          <div className={indented}>
            <ChooseTuner
              isEditing={true}
              onMatch={handleTunerMatch}
              tuner={tuner}
              handleNewTunerChange={handleNewTunerNameChange}
              newTunerName={newTunerName}
            />
            {errors.tuner && <p className={errorText}>{translations.serviceFormTunerError}</p>}
          </div>
        </>
      )}
      <Title title={translations.serviceFormTitleProject} />
      <div className={indented}>
        <ChooseProject
          onMatch={onMatch}
          isEditing
          instrumentId={service?.instrumentId ? service.instrumentId : instrument?.id}
          projectId={service?.projectId}
          project={project}
          newProjectName={newProjectName}
          handleNewProjectNameChange={handleNewProjectNameChange}
          service={service}
        />
      </div>
      <ServiceDate
        errors={errors}
        register={register}
        isEditing
        lastDate={service?.lastDate}
        nextDate={service?.nextDate}
        lastDateOriginalValue={lastServiceDate}
        nextDateOriginalValue={nextServiceDate}
        handleLastValue={handleLastDate}
        handleNextValue={handleNextDate}
        handleDeleteLastDate={handleDeleteLastDate}
        handleDeleteNextDate={handleDeleteNextDate}
        isModalOpen={isModalOpen}
        handleModalConfirm={handleModalConfirm}
        toggleModal={toggleModal}
      />
      <LatestInfo
        isEditing
        register={register}
        temperature={service?.temperature}
        humidity={service?.humidity}
        frequency={service?.frequency}
      />
      <TimeAndAmount
        price={service?.price}
        time={service?.duration}
        isEditing
        register={register}
        timeUnit={translations.timeAmountHours}
      />
      <Title title={translations.editAttachments} />
      <div className={indented}>
        <AttachmentIcons
          onAttachmentChange={onAttachmentChange}
          onPictureChange={onPictureChange}
          instrumentId={service?.instrumentId}
          pictureNr={pictureNr}
          attachmentNr={attachmentNr}
          cropperRef={uploadCropperRef}
          cropImage={uploadCropImage}
          handleFinishCrop={handleUploadCrop}
          handleCancelCrop={handleCancelUploadCrop}
          cropText={translations.crop}
          cancelText={translations.cancel}
        />
      </div>
      <Title title={translations.serviceFormTitleNote} />
      <div className={indented}>
        <textarea rows={8} ref={register} name="note" placeholder={translations.serviceFormNote} />
      </div>
      <div className={btnWrapper}>
        <button type="submit" className="blue">
          {translations.serviceFormSaveService}
        </button>
      </div>
      <div className="last-element" />
    </form>
  );
}

export default ServiceForm;
