import { upperFirst } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CATEGORY_TYPES } from '../constants/filter';

import { IUseFilterElement } from '../interfaces/components/Hooks';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';
import { IFilter, IReduxStore } from '../interfaces/IGeneral';

function useFilterElement(
  key: string,
  isNumber: boolean,
  addFilterRedux: string,
  setFilterRedux: string,
  resetActualFilterRedux: string,
  // eslint-disable-next-line prettier/prettier
  resetFilterRedux: string
): IUseFilterElement {
  const dispatch = useDispatch();
  const { filter, hamburgerRef } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [category, setCategory] = useState<string>(CATEGORY_TYPES[0].code);

  useEffect(() => {
    // On hamburger close the selected checkbox by default is the All.
    if (hamburgerRef === null) {
      setIsSelected(false);
      setCategory(CATEGORY_TYPES[0].code);
      dispatch({ type: resetActualFilterRedux });
      dispatch({ type: resetFilterRedux });
    }
  }, [hamburgerRef]);

  const toggleSelected = (value: boolean) => {
    setIsSelected(value);
    if (!value) {
      dispatch({
        type: addFilterRedux,
        payload: { [key]: isNumber ? 0 : '' },
      });
    }
  };

  const onMatch = (dat?: ISuggestion) => {
    if (!isSelected && !!dat) {
      toggleSelected(true);
    }
    console.log(dat, key);

    if (dat?.name) {
      dispatch({
        type: addFilterRedux,
        payload: { [key]: dat },
      });
      return;
    }

    const restFilters = filter;
    const filterToDelete = key as keyof IFilter;
    delete restFilters[filterToDelete];

    dispatch({ type: setFilterRedux, payload: restFilters });
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
    dispatch({
      type: addFilterRedux,
      payload: { category: { id: e.target.value, name: upperFirst(e.target.value) } },
    });
  };

  return { isSelected, toggleSelected, onMatch, handleCategoryChange, category };
}

export default useFilterElement;
