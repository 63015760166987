import { useApi } from './useApi';
import { API_METHOD_GET, API_METHOD_POST } from '../constants/api';
import { MY_ACCOUNT_API, UPLOAD_IMAGE } from '../constants/routes';
import { getBase64 } from '../helpers/baseHelper';
import { IUseProfileCard } from '../interfaces/components/Hooks';
import { useEffect, useState } from 'react';

function useProfileCard(): IUseProfileCard {
  const [uploadImage, setUploadImage] = useState<{ file: string; name: string }>();
  const { handleApi } = useApi();

  useEffect(() => {
    setDefaultImage();
  }, []);

  async function setDefaultImage() {
    const { data } = await handleApi(API_METHOD_GET, MY_ACCOUNT_API);
    setUploadImage({ file: data.profilePicture, name: 'thumbnail' });
  }

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      getBase64(file, onLoad);
    }
  };

  const onLoad = (fileString: string | ArrayBuffer | null, name: string) => {
    setUploadImage({ file: fileString as string, name: name });
    handleApi(API_METHOD_POST, UPLOAD_IMAGE, { profile_picture: fileString, profile_picture_name: name });
  };

  return { onImageChange, uploadImage: uploadImage?.file };
}

export default useProfileCard;
