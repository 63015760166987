import SwitcherComponent from '../SwitcherComponent';
import ProjectCard from '../ProjectCard';
import ServiceCard from '../ServiceCard';
import AdSection from '../AdSection';
import useGetTranslation from '../../hooks/useGetTranslations';
import {
  PROJECT_DETAIL,
  SERVICE_DETAIL,
  CLIENTS_PAGE,
  INSTRUMENT_DETAILS,
  HOME_PAGE,
  NEW_INSTRUMENT,
  CLIENT_DETAILS,
  ATTACHMENTS_PAGE,
} from '../../constants/routes';
import { ISimpleProject } from '../../interfaces/components/Project';
import { ISimpleService } from '../../interfaces/components/Service';
import UserIdComponent from '../UserIdComponent';
import useClientsServices from '../../hooks/useClientsServices';
import ClientsCard from '../ClientsCard';
import useGeneral from '../../hooks/useGeneral';
import useSetHeader from '../../hooks/useSetHeader';
import { ServiceCardTypes } from '../../constants/ServiceCard';
import { ProjectCardTypes } from '../../constants/projects';
import FilterElement from '../FilterElement';
import { useDispatch } from 'react-redux';
import {
  ADD_ACTUAL_FILTER,
  ADD_FILTER,
  RESET_ACTUAL_FILTER,
  SET_ATTACHMENTS_PAGE_SWITCHER,
  SET_OPEN_ATTACHMENT_INSTEAD,
} from '../../constants/reduxActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { convertKeysToCamelCase, filterDuplicateNames } from '../../helpers/baseHelper';
import useMobile from '../../hooks/useMobile';
import ClientServicesHeader from '../ClientServicesHeader';
import ClientReminderDetailCard from '../ClientsReminderDetailCard';
import { MenuBar } from '../MenuBar';
import { MdPiano } from 'react-icons/md';
import { BsCameraFill, BsFillPersonFill } from 'react-icons/bs';
import { ImAttachment } from 'react-icons/im';
import { toast } from 'react-toastify';
import LimitExceededNotification, { limitExceededOptions } from '../LimitExceededNotification';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import { AiFillInfoCircle } from 'react-icons/ai';
import style from '../../assets/scss/components/InstrumentServices.module.scss';

/*eslint-disable*/
function ClientsServices(): JSX.Element {
  const {
    client,
    switcherPage,
    toggleSwitcherPage,
    filteredServices,
    instruments,
    filteredProjects,
    nextPage,
    total,
    allProjects,
    allServices,
    reminders,
    onReminderClick,
    setFilteredServices,
  } = useClientsServices();
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslation();
  const { isMobile } = useMobile();
  const dispatch = useDispatch();
  const { canCreateNewInstrument } = useMySubscriptionPlan();
  const { infoContainer, infoDescription, infoImage } = convertKeysToCamelCase(style);

  const title = () => {
    switch (switcherPage) {
      case 0:
        return translations.clientServicesTitle;
      case 1:
        return translations.clientProjectsTitle;
      case 2:
        return translations.clientRemindersTitle;
      default:
        return '';
    }
  };

  //prettier-ignore
  useSetHeader(
    () => {
      handleRedirect(CLIENTS_PAGE);
      dispatch({ type: RESET_ACTUAL_FILTER });
    },
    title(),
    undefined,
    true
  );

  const handleChange = (newData: any) => {
    const changedData = filteredServices.map((fs) => {
      return newData !== undefined && fs.id === newData.id ? { ...fs, isActive: newData.isActive } : fs;
    });

    return setFilteredServices(changedData);
  };

  return (
    <main>
      <ClientServicesHeader
        switcherPage={switcherPage}
        client={client}
        instruments={instruments}
        sortField={switcherPage === 1 ? 'brand' : undefined}
        sortTitle={
          switcherPage === 1
            ? {
                ascending: translations.sortInstrumentsAscending,
                descending: translations.sortInstrumentsDescending,
              }
            : undefined
        }
        filters={
          <>
            {switcherPage === 0 && (
              <>
                <FilterElement
                  name={translations.byTitle}
                  data={allServices.map((service: ISimpleService) => {
                    return { id: service.id, name: service.title };
                  })}
                  field="title"
                  isNumber={false}
                />
                <FilterElement
                  name={translations.byInstrument}
                  data={filterDuplicateNames(
                    instruments.filter((instr) => {
                      return allServices.some((service) => service.instrumentId === instr.id);
                    })
                  )}
                  field="instruments"
                  isNumber={false}
                />
              </>
            )}
            {switcherPage === 1 && (
              <>
                <FilterElement
                  name={translations.byTitle}
                  data={allProjects.map((project: ISimpleProject) => {
                    return { id: project.id, name: project.title };
                  })}
                  field="projectTitle"
                  isNumber={false}
                />
                <FilterElement
                  name={translations.byInstrument}
                  data={filterDuplicateNames(
                    instruments.filter((instr) => {
                      return allProjects.some((project) => project.instrumentId === instr.id);
                    })
                  )}
                  field="instruments"
                  isNumber={false}
                />
              </>
            )}
          </>
        }
      />
      <AdSection length={total} topPadding>
        <UserIdComponent clientId={client?.id} />
        <ClientsCard client={client} selectedImage={client?.thumbnail ?? ''} isSimple={true} />
        <MenuBar
          items={[
            {
              icon: <MdPiano />,
              onClick: () => {
                if (!client) {
                  return;
                }
                if (instruments.length === 0) {
                  if (!canCreateNewInstrument()) {
                    toast.error(<LimitExceededNotification />, limitExceededOptions);
                    return;
                  }
                  handleRedirect(NEW_INSTRUMENT + `?clientId=${client.id}`);
                  return;
                }
                if (instruments.length === 1) {
                  handleRedirect(INSTRUMENT_DETAILS.replace(':id', instruments[0].id.toString()));
                  return;
                }

                dispatch({
                  type: ADD_FILTER,
                  payload: { clients: { id: client.id, name: `${client.firstName} ${client.lastName}` } },
                });
                dispatch({ type: ADD_ACTUAL_FILTER });
                handleRedirect(HOME_PAGE);
              },
            },
            {
              icon: <BsFillPersonFill />,
              onClick: () => {
                if (!client || !client.id) {
                  return;
                }

                handleRedirect(CLIENT_DETAILS.replace(':id', client.id.toString()));
              },
            },
            {
              icon: <BsCameraFill />,
              onClick: () => {
                if (!client) {
                  return;
                }
                if (instruments.length === 1) {
                  dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'image' });
                  handleRedirect(
                    // eslint-disable-next-line prettier/prettier
                    ATTACHMENTS_PAGE.replace(':id', instruments[0].id.toString()).replace(':type', 'instrument')
                  );
                  return;
                }
                if (instruments.length > 1) {
                  //hanlde here the redirect to files & images. prevent default action
                  dispatch({
                    type: ADD_FILTER,
                    payload: { clients: { id: client.id, name: `${client.firstName} ${client.lastName}` } },
                  });
                  dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'image' });
                  dispatch({ type: ADD_ACTUAL_FILTER });
                  dispatch({ type: SET_OPEN_ATTACHMENT_INSTEAD, payload: true });
                  handleRedirect(HOME_PAGE);
                  return;
                }
              },
            },
            {
              icon: <ImAttachment />,
              onClick: () => {
                if (!client) {
                  return;
                }
                if (instruments.length === 1) {
                  //hanlde here the redirect to files & images. prevent default action
                  dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'file' });
                  handleRedirect(
                    // eslint-disable-next-line prettier/prettier
                    ATTACHMENTS_PAGE.replace(':id', instruments[0].id.toString()).replace(':type', 'instrument')
                  );
                  return;
                }
                if (instruments.length > 1) {
                  //hanlde here the redirect to files & images. prevent default action
                  dispatch({
                    type: ADD_FILTER,
                    payload: { clients: { id: client.id, name: `${client.firstName} ${client.lastName}` } },
                  });
                  dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'file' });
                  dispatch({ type: ADD_ACTUAL_FILTER });
                  dispatch({ type: SET_OPEN_ATTACHMENT_INSTEAD, payload: true });
                  handleRedirect(HOME_PAGE);
                  return;
                }
              },
            },
          ]}
        />
        <SwitcherComponent
          switcherPage={switcherPage}
          toggleSwitcherPage={toggleSwitcherPage}
          titles={[translations.servicesTitle, translations.projectsTitle, translations.remindersLeftTitle]}
          elements={[
            <>
              <div className={infoContainer}>
                <AiFillInfoCircle className={infoImage} />
                <p className={infoDescription}>{translations.activeStatusServiceInfo}</p>
              </div>
              {isMobile ? (
                <InfiniteScroll dataLength={total} next={nextPage} hasMore={true} loader={<></>}>
                  {filteredServices?.map((service: ISimpleService) => (
                    <ServiceCard
                      key={service.id}
                      service={service}
                      type={ServiceCardTypes.CLIENT_SERVICE}
                      onClick={() => {
                        handleRedirect(SERVICE_DETAIL.replace(':id', service.id.toString()));
                      }}
                      onActiveChangeCallback={handleChange}
                      showProjectIcon={service?.projectId !== undefined && service.projectId !== null}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                filteredServices?.map((service: ISimpleService) => (
                  <ServiceCard
                    key={service.id}
                    service={service}
                    type={ServiceCardTypes.CLIENT_SERVICE}
                    onClick={() => {
                      handleRedirect(SERVICE_DETAIL.replace(':id', service.id.toString()));
                    }}
                    onActiveChangeCallback={handleChange}
                    showProjectIcon={service?.projectId !== undefined && service.projectId !== null}
                  />
                ))
              )}
            </>,
            <>
              <div className={infoContainer}>
                <AiFillInfoCircle className={infoImage} />
                <p className={infoDescription}>{translations.activeStatusProjectInfo}</p>
              </div>
              {isMobile ? (
                <InfiniteScroll dataLength={total} next={nextPage} hasMore={true} loader={<></>}>
                  {filteredProjects?.map((project: ISimpleProject) => (
                    <ProjectCard
                      project={project}
                      key={project.id}
                      type={ProjectCardTypes.CLIENT_PROJECT}
                      onClick={() => {
                        handleRedirect(PROJECT_DETAIL.replace(':id', project.id.toString()));
                      }}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                filteredProjects?.map((project: ISimpleProject) => (
                  <ProjectCard
                    project={project}
                    key={project.id}
                    type={ProjectCardTypes.CLIENT_PROJECT}
                    onClick={() => {
                      handleRedirect(PROJECT_DETAIL.replace(':id', project.id.toString()));
                    }}
                  />
                ))
              )}
            </>,
            <>
              {isMobile ? (
                <InfiniteScroll dataLength={total} next={nextPage} hasMore={true} loader={<></>}>
                  {reminders?.map((reminder) => (
                    <ClientReminderDetailCard key={reminder.id} reminder={{ ...reminder }} onClick={onReminderClick} />
                  ))}
                </InfiniteScroll>
              ) : (
                reminders?.map((reminder) => (
                  <ClientReminderDetailCard key={reminder.id} reminder={{ ...reminder }} onClick={onReminderClick} />
                ))
              )}
            </>,
          ]}
        />
      </AdSection>
    </main>
  );
}

export default ClientsServices;
