import { IInstrumentCard } from '../interfaces/components/Instrument';
import completed from '../assets/images/completed.svg';
import scheduled from '../assets/images/scheduled.svg';
import project_start from '../assets/images/project_start.svg';
import { ReactComponent as UnfinishedService } from '../assets/images/scheduled.svg';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/InstrumentServiceCard.module.scss';
import { MdLocationPin } from 'react-icons/md';
import { BiUser } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';
import useGetTranslations from '../hooks/useGetTranslations';
import {
  SET_GLOBAL_SEARCH_ON_BACK,
  SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER,
  SET_OPEN_ATTACHMENT_INSTEAD,
  SET_OPEN_NEW_PROJECT_INSTEAD,
  SET_OPEN_NEW_REMINDER_INSTEAD,
  SET_OPEN_NEW_SERVICE_INSTEAD,
} from '../constants/reduxActions';
import {
  ATTACHMENTS_PAGE,
  INSTRUMENT_DETAILS,
  NEW_PROJECT_PAGE,
  NEW_REMINDER_PAGE,
  NEW_SERVICE_PAGE,
} from '../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import useGeneral from '../hooks/useGeneral';
import { IReduxStore } from '../interfaces/IGeneral';
import { BsLink } from 'react-icons/bs';
import { professionTranslation } from '../utils/roleChecker';

export const InstrumentServiceCard = (props: IInstrumentCard) => {
  const {
    brand,
    to,
    serviceType,
    title: projectTitle,
    isSimple,
    fromGlobalSearch,
    client,
    id,
    model,
    date,
    endDate,
    showProjectIcon,
    hasUnfinishedService,
  } = props;

  const {
    wrapper,
    bubble,
    bubbleOut,
    bubbleOutRed,
    bubbleContainer,
    shortLine,
    longLine,
    imageRow,
    logo,
    lighter,
    redIcon,
    box,
    title,
    subtitle,
    descriptionContainer,
    dateSection,
    dateText,
    dateIcon,
    linkContainer,
    linkIcon,
    flex,
    infoRed,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const dispatch = useDispatch();
  const { handleRedirect } = useGeneral();
  //prettier-ignore
  const { openAttachmentInstead, openProjectInstead, openReminderInstead, openServiceInstead } = useSelector(
    (store: IReduxStore) => store.defaultReducer
  );

  const nameBuilder = (name?: string, firstName?: string, lastName?: string) => {
    if (name) {
      return name;
    }

    if (firstName && lastName && lastName !== null) {
      return `${firstName} ${lastName}`;
    }

    if (firstName) {
      return firstName;
    }

    return '';
  };

  return (
    <div
      className={wrapper}
      onClick={() => {
        if (openAttachmentInstead && id) {
          handleRedirect(ATTACHMENTS_PAGE.replace(':id', id.toString()).replace(':type', 'instrument'));
          dispatch({ type: SET_OPEN_ATTACHMENT_INSTEAD, payload: false });
          return;
        }
        if (openServiceInstead && id) {
          handleRedirect(NEW_SERVICE_PAGE.replace(':id', id.toString()));
          dispatch({ type: SET_OPEN_NEW_SERVICE_INSTEAD, payload: false });
          return;
        }
        if (openProjectInstead && id) {
          handleRedirect(NEW_PROJECT_PAGE.replace(':id', id.toString()));
          dispatch({ type: SET_OPEN_NEW_PROJECT_INSTEAD, payload: false });
          return;
        }
        if (openReminderInstead && id) {
          handleRedirect(NEW_REMINDER_PAGE.replace(':id', id.toString()));
          dispatch({ type: SET_OPEN_NEW_REMINDER_INSTEAD, payload: false });
          return;
        }
        if (to) {
          if (serviceType === 'service') {
            dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 0 });
            handleRedirect(to);
            return;
          }
          dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 1 });
          handleRedirect(to);
        } else if (id && !isSimple) {
          handleRedirect(INSTRUMENT_DETAILS.replace(':id', id.toString()));
        }

        if (fromGlobalSearch) {
          dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: true });
        }
      }}
    >
      <div className={bubbleContainer}>
        <div className={shortLine} />
        <div className={hasUnfinishedService ? bubbleOutRed : bubbleOut}>
          <div className={bubble} />
        </div>
        <div className={longLine} />
      </div>
      <div className={descriptionContainer}>
        <p className={title}>{[brand, model].filter((e) => e !== undefined && e !== '').join(', ')}</p>
        {projectTitle && <p className={subtitle}>{projectTitle}</p>}
        <div className={dateSection}>
          <div className={imageRow}>
            {!hasUnfinishedService ? (
              <>
                {serviceType === 'project' ? (
                  <img src={project_start} alt="project_start_date" className={dateIcon} />
                ) : (
                  <img src={scheduled} alt="scheduled" className={dateIcon} />
                )}
                <p className={dateText}>{endDate ?? '-'}</p>
              </>
            ) : (
              <>
                <UnfinishedService className={infoRed} />
                <p className={dateText}>{endDate ?? '-'}</p>
              </>
            )}
          </div>
          <div className={imageRow}>
            <img src={completed} alt="completed" className={dateIcon} /> <p className={dateText}>{date ?? '-'}</p>
          </div>
        </div>
        <div className={box}>
          <div className={flex}>
            {client?.name ? (
              <div className={imageRow}>
                <BiUser className={logo} />
                <p className={lighter}>{nameBuilder(client.name, client.firstName, client.lastName)}</p>
              </div>
            ) : client?.firstName ? (
              <div className={imageRow}>
                <BiUser className={logo} />
                <p className={lighter}>{nameBuilder(client.name, client.firstName, client.lastName)}</p>
              </div>
            ) : (
              <div className={imageRow}>
                <FaUser className={redIcon} />
                <p className={lighter}>{translations[professionTranslation('noClient')]}</p>
              </div>
            )}
            {showProjectIcon && (
              <div className={linkContainer}>
                <BsLink className={linkIcon} />
              </div>
            )}
          </div>
          {client?.region && (
            <div className={imageRow}>
              <MdLocationPin className={logo} />
              <p className={lighter}>
                {client?.city}
                {client.city && client.postcode && ', '} {client?.postcode}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
