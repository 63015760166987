import { IServiceCard } from '../interfaces/components/Service';
import style from '../assets/scss/components/ActiveServiceCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import { BsLink } from 'react-icons/bs';
import scheduled from '../assets/images/scheduled.svg';
import { BiUser } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import { AiOutlineClockCircle } from 'react-icons/ai';
import tuner from '../assets/images/tuner.svg';
import { professionTranslation } from '../utils/roleChecker';

const ActiveServiceCard = (props: IServiceCard): JSX.Element => {
  const { service, onClick, showProjectIcon } = props;
  const {
    wrapper,
    bubble,
    bubbleOut,
    bubbleOutRed,
    bubbleContainer,
    shortLine,
    midLine,
    longLine,
    right,
    greenBubble,
    detailWrapper,
    description,
    title,
    box,
    flex,
    linkIcon,
    linkContainer,
    instrumentTitle,
    icon,
    rowCenter,
    dateTitle,
    logo,
    imageRow,
    redIcon,
    lighter,
    dateTimeInfoWrapper,
    clockIcon,
    tunerBox,
    tunerImage,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  const checkPast = (date?: string) => {
    if (!date) return false;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() - 1);
    const providedDate = new Date(date);
    return providedDate < tomorrow;
  };

  return (
    <div className={wrapper}>
      <div className={bubbleContainer}>
        <div className={shortLine} />
        <div className={checkPast(service.nextDate) ? bubbleOutRed : bubbleOut}>
          {' '}
          <div
            className={service?.isActive ? greenBubble : bubble}
            onClick={() => {
              onClick(service.id);
            }}
          >
            {service.nextDateOriginal &&
              service.nextDateOriginal !== null &&
              new Date(service.nextDateOriginal).getDate()}
          </div>
        </div>
        {service.dateTimeInfo !== null ? (
          <>
            <div className={longLine} />
            <AiOutlineClockCircle className={clockIcon} />
            <div className={midLine} />
          </>
        ) : (
          <div className={longLine} />
        )}
      </div>
      <div
        className={right}
        onClick={() => {
          onClick(service.id);
        }}
      >
        <div className={detailWrapper}>
          <div className={description}>
            <div className={rowCenter}>
              {service.nextDate !== null ? (
                <p className={dateTitle}>
                  {service.nextDate.split(' ')[1]}, {service.nextDate.split(' ')[2]}
                </p>
              ) : (
                <p className={dateTitle}>{translations.noScheduledDate}</p>
              )}
              <img src={scheduled} alt="scheduled_date" className={icon} />
            </div>
            <p className={instrumentTitle}>
              {service.instrument?.brand}
              {service.instrument?.model ? `, ${service.instrument.model}` : ''}
            </p>
            <p className={title}>{service.title}</p>
            <div className={box}>
              <div className={flex}>
                {service.instrument?.client?.name ? (
                  <div className={imageRow}>
                    <BiUser className={logo} />
                    <p className={lighter}>{service.instrument?.client.name}</p>
                  </div>
                ) : (
                  <div className={imageRow}>
                    <FaUser className={redIcon} />
                    <p className={lighter}>{translations[professionTranslation('noClient')]}</p>
                  </div>
                )}
                {showProjectIcon && (
                  <div className={linkContainer}>
                    <BsLink className={linkIcon} />
                  </div>
                )}
              </div>
              {service.instrument?.client?.region && (
                <div className={imageRow}>
                  <MdLocationPin className={logo} />
                  <p className={lighter}>
                    {service.instrument?.client?.city}
                    {service.instrument?.client.city && service.instrument?.client.postcode && ', '}{' '}
                    {service.instrument?.client?.postcode}
                  </p>
                </div>
              )}
            </div>
            {service.tuner && service.tuner !== null && (
              <div className={`${box} ${tunerBox}`}>
                <div className={imageRow}>
                  <img src={tuner} alt="tuner" className={tunerImage} />
                  <p className={lighter}>{service.tuner.name}</p>
                </div>
              </div>
            )}
            {service.dateTimeInfo && (
              <div className={dateTimeInfoWrapper}>
                <p>{service.dateTimeInfo}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveServiceCard;
