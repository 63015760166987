import { AiOutlineClose } from 'react-icons/ai';

import BlueHeader from './BlueHeader';
import SortIcon from './SortIcon';
import style from '../assets/scss/components/ActiveFilterHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useActiveFilterHeader from '../hooks/useActiveFilterHeader';
import { IFilter } from '../interfaces/IGeneral';
import useGetTranslations from '../hooks/useGetTranslations';
import { IActiveFilterHeader } from '../interfaces/components/GreyModal';
import { RESET_ACTUAL_FILTER, UPDATE_ACTUAL_FILTER } from '../constants/reduxActions';

function ActiveFilterHeader({
  title,
  field,
  isService,
  parentPage,
  actualFilter,
  updateActualFilterRedux = UPDATE_ACTUAL_FILTER,
  resetActualFilterRedux = RESET_ACTUAL_FILTER,
}: IActiveFilterHeader): JSX.Element {
  const { resetContainer, filterContainer, filter, filterText, wrapper, btn, link } = convertKeysToCamelCase(style);
  const { onReset, onDelete } = useActiveFilterHeader(actualFilter, resetActualFilterRedux, updateActualFilterRedux);
  const { translations } = useGetTranslations();
  const filterKeys = Object.keys(actualFilter) as Array<keyof IFilter | 'myInstruments'>;

  return (
    <div className={wrapper}>
      <BlueHeader
        element={
          <>
            <div className={resetContainer}>
              <button className={`secondary ${btn}`} onClick={onReset}>
                {translations.resetFilter}
              </button>
            </div>
            <SortIcon title={title} field={field} isService={isService} parentPage={parentPage} />
          </>
        }
      />
      <div className={filterContainer}>
        {filterKeys.map((fil) => (
          <div
            className={`${filter} bubble`}
            key={fil}
            onClick={() => {
              onDelete(fil);
            }}
          >
            <p className={filterText}>
              {fil === 'myInstruments' ? translations.filterMyInstruments : actualFilter[fil]?.name}
              {fil === 'noService' && translations.filterNoService}
              {fil === 'hasUnfinishedService' && translations.filterHasUnfinishedService}
            </p>
            <AiOutlineClose className={link} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ActiveFilterHeader;
