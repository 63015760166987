import { useEffect } from 'react';

import ClientsCard from '../ClientsCard';
import ClientsForm from '../ClientsForm';
import useNewClient from '../../hooks/useNewClient';
import useGetTranslations from '../../hooks/useGetTranslations';
import AdSection from '../AdSection';
import useSetHeader from '../../hooks/useSetHeader';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import { IReduxStore } from '../../interfaces/IGeneral';
import { useSelector } from 'react-redux';
import { ImageCropper } from '../ImageCropper';
import { professionTranslation } from '../../utils/roleChecker';

function NewClient(): JSX.Element {
  const {
    selectedImage,
    handleImageChange,
    register,
    handleSubmit,
    errors,
    onMatchInstrument,
    selectedInstruments,
    handleDeleteClick,
    control,
    showLeaveWarning,
    setInstrument,
    handleProfileImageDelete,
    cropImage,
    cropperRef,
    handleCancelCrop,
    handleFinishCrop,
    clientType,
    handleClientType,
  } = useNewClient();
  const { translations } = useGetTranslations();
  const { noClientsLeft, showPlanExceededToast } = useMySubscriptionPlan();
  const { instruments } = useSelector((store: IReduxStore) => store.defaultReducer);

  useEffect(() => {
    if (noClientsLeft) showPlanExceededToast();
  }, [noClientsLeft, showPlanExceededToast]);

  useEffect(() => {
    const id = window.location.href.split('=')[1];
    setInstrument(Number(id));
  }, [window.location.href, instruments]);

  useSetHeader(() => {}, translations[professionTranslation('newClientTitle')]);

  return (
    <main>
      <AdSection>
        <ClientsCard
          isSimple={true}
          selectedImage={selectedImage}
          handleImageChange={handleImageChange}
          handleProfileImageDelete={handleProfileImageDelete}
          isNew={true}
          isEditing={true}
        />
        <>
          {cropImage !== '' && (
            <ImageCropper
              cropperRef={cropperRef}
              cropImage={cropImage}
              handleFinishCrop={handleFinishCrop}
              cropText={translations.crop}
              cancelText={translations.cancel}
              handleCancel={handleCancelCrop}
            />
          )}
        </>

        <ClientsForm
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          selectedInstruments={selectedInstruments}
          onMatchInstrument={onMatchInstrument}
          onDeleteClick={handleDeleteClick}
          control={control}
          disableSave={noClientsLeft}
          showLeaveWarning={showLeaveWarning}
          onTypeChange={handleClientType}
          clientType={clientType}
        />
      </AdSection>
    </main>
  );
}

export default NewClient;
