import { useSelector } from 'react-redux';

import HomeSearchHeader from '../HomeSearchHeader';
import ClientsCard from '../ClientsCard';
import useClientsList from '../../hooks/useClientsList';
import NewHeader from '../NewHeader';
import Adsection from '../AdSection';
import FilterElement from '../FilterElement';
import { IReduxStore } from '../../interfaces/IGeneral';
import { NEW_CLIENT_PAGE } from '../../constants/routes';
import useGetTranslations from '../../hooks/useGetTranslations';
import GlobalSearchElements from '../GlobalSearchElements';
import { filterDuplicateNames } from '../../helpers/baseHelper';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMobile from '../../hooks/useMobile';
import { paddingForInfiniteScroll } from '../../constants/infiniteScrollStyle';
import { professionChecker, professionTranslation } from '../../utils/roleChecker';
import { useMemo } from 'react';

function ClientsPage(): JSX.Element {
  const { clients, allClients, handleOnClick, total, nextPage } = useClientsList();
  const { translations } = useGetTranslations();
  const { isMobile } = useMobile();
  const store = useSelector((store: IReduxStore) => store.defaultReducer);
  const isTuner = useMemo(() => professionChecker(), []);

  return (
    <main>
      <HomeSearchHeader
        hideFilters={!isTuner}
        hideSort={!isTuner}
        sortField="first_name"
        parentPage="clientList"
        sortTitle={{ ascending: translations.sortClientsAscending, descending: translations.sortClientsDescending }}
        filters={
          <>
            <FilterElement
              name={translations.byClients}
              data={allClients.map((cli) => ({
                id: cli.id,
                name: `${cli.firstName} ${cli.lastName}, ID: ${cli.id}`,
              }))}
              field="clientName"
              isNumber={true}
              needsSpiltAtValues
            />
            <FilterElement
              name={translations.byCity}
              data={filterDuplicateNames(allClients.map((cli) => ({ id: cli.id, name: cli.city })))}
              field="city"
              isNumber={true}
            />
            <FilterElement
              name={translations.byRegion}
              data={filterDuplicateNames(allClients.map((cli) => ({ id: cli.id, name: cli.region })))}
              field="region"
              isNumber={true}
            />
            <FilterElement
              name={translations.byPostcode}
              data={filterDuplicateNames(allClients.map((cli) => ({ id: cli.id, name: cli.postcode })))}
              field="postcode"
              isNumber={true}
            />
            <FilterElement
              name={translations.byCountry}
              data={filterDuplicateNames(allClients.map((cli) => ({ id: cli.id, name: cli.country })))}
              field="country"
              isNumber={true}
            />
          </>
        }
      />
      {Object.keys(store.globalSearch).length > 0 ? (
        <GlobalSearchElements />
      ) : (
        <>
          {Object.keys(store.actualFilter).length === 0 && (
            <NewHeader
              leftTitle={`${translations[professionTranslation('clientPageLeftTitle')]} (${total})`}
              rightTitle={translations[professionTranslation('clientPageRightTitle')]}
              to={NEW_CLIENT_PAGE}
              type="client"
            />
          )}
          <Adsection length={total} noPadding>
            {isMobile ? (
              <InfiniteScroll
                dataLength={clients.length}
                next={nextPage}
                hasMore={true}
                loader={<></>}
                style={paddingForInfiniteScroll}
              >
                {clients?.map((client) => (
                  <ClientsCard key={client.id} client={client} onClick={handleOnClick} />
                ))}
              </InfiniteScroll>
            ) : (
              <>
                {clients?.map((client) => (
                  <ClientsCard key={client.id} client={client} onClick={handleOnClick} />
                ))}
              </>
            )}
          </Adsection>
        </>
      )}
    </main>
  );
}

export default ClientsPage;
