import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from './useApi';
import { TOKEN_DATA_KEY } from '../constants/login';
import { API_METHOD_GET } from '../constants/api';
import { GET_CLIENTS } from '../constants/routes';
import { SET_CLIENTS } from '../constants/reduxActions';
import _ from 'lodash';
import { IReduxStore } from '../interfaces/IGeneral';

function useGetClients(): void {
  const dispatch = useDispatch();
  const { handleApi } = useApi();
  const { clientListRefresh } = useSelector((store: IReduxStore) => store.defaultReducer);

  useEffect(() => {
    getAllClients();
  }, [clientListRefresh]);

  const getTotal = async () => {
    const { data, code } = await handleApi(API_METHOD_GET, GET_CLIENTS);
    if (code !== 200) {
      return;
    }
    return data.total;
  };

  const getAllClients = async () => {
    if (!localStorage.getItem(TOKEN_DATA_KEY)) {
      return;
    }

    const total = await getTotal();
    const URL = GET_CLIENTS + `?page=1&perPage=${total}`;

    const { data, code } = await handleApi(API_METHOD_GET, URL);
    if (code !== 200) {
      return;
    }
    dispatch({ type: SET_CLIENTS, payload: _.uniqBy(data.data, 'id') });
  };
}

export default useGetClients;
