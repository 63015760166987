import { useDispatch, useSelector } from 'react-redux';
import TimeAndAmount from '../TimeAndAmount';
import ServiceCard from '../ServiceCard';
import Title from '../Title';
import AdSection from '../AdSection';
import ProjectHeader from '../ProjectHeader';
import ProjectForm from '../ProjectForm';
import useSetHeader from '../../hooks/useSetHeader';
import useGetTranslations from '../../hooks/useGetTranslations';
import useGeneral from '../../hooks/useGeneral';
import InstrumentCard from '../InstrumentCard';
import CollapseElement from '../CollapseElement';
import useProjectServices from '../../hooks/useProjectServices';
import AttachmentIcons from '../AttachmentIcons';
import {
  CLIENT_DETAILS,
  INSTRUMENT_DETAILS,
  INSTRUMENT_SERVICES,
  NEW_CLIENT_PAGE,
  NEW_SERVICE_PAGE_WITH_PROJECT,
  SERVICE_DETAIL,
} from '../../constants/routes';
import { IReduxStore } from '../../interfaces/IGeneral';
import { ServiceCardTypes } from '../../constants/ServiceCard';
import IdComponent from '../IdComponent';
import { MdPiano } from 'react-icons/md';
import { MenuBar } from '../MenuBar';
import { BsFillPersonFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import LimitExceededNotification, { limitExceededOptions } from '../LimitExceededNotification';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import { GiAutoRepair } from 'react-icons/gi';
import { SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER } from '../../constants/reduxActions';
import { BiCalendar } from 'react-icons/bi';

function ProjectDetail(): JSX.Element {
  const {
    projectService,
    toggleEditing,
    handleSubmit,
    register,
    errors,
    handleDelete,
    handleStartingDate,
    startingDate,
    handleDeleteDate,
    fetchProjectDetails,
    endDate,
    handleDeleteEndDate,
    handleEndDate,
    tuner,
    newTunerName,
    handleTunerChange,
    handleNewTunerNameChange,
  } = useProjectServices();
  const { translations } = useGetTranslations();
  const { handleRedirect } = useGeneral();
  const { instruments } = useSelector((store: IReduxStore) => store.defaultReducer);
  useSetHeader(() => {}, translations.projectServicesTitle);
  const { canCreateNewClient } = useMySubscriptionPlan();
  const dispatch = useDispatch();

  const handleServiceClick = (id: number) => {
    handleRedirect(SERVICE_DETAIL.replace(':id', id.toString()));
  };

  return (
    <main>
      <ProjectHeader
        isEditing={false}
        toggleEditing={toggleEditing}
        handleDelete={handleDelete}
        deleteTitle={translations.deleteProjectDescription}
        redirectToService={() => {
          if (projectService?.instrumentId)
            //prettier-ignore
            handleRedirect(
              NEW_SERVICE_PAGE_WITH_PROJECT.replace(':id', projectService.instrumentId.toString()).replace(
                ':projectId',
                projectService.id.toString()
              )
            );
        }}
      />
      <AdSection topPadding>
        <IdComponent id={projectService?.id} text={translations.projectId} />
        <InstrumentCard isSimple={true} {...instruments.find((instr) => instr.id === projectService?.instrumentId)} />
        <MenuBar
          items={[
            {
              icon: <MdPiano />,
              onClick: () => {
                if (!projectService?.instrumentId) {
                  return;
                }
                handleRedirect(INSTRUMENT_DETAILS.replace(':id', projectService.instrumentId.toString()));
              },
            },
            {
              icon: <BsFillPersonFill />,
              onClick: () => {
                const instrument = instruments.find((instr) => instr.id === projectService?.instrumentId);
                if (!instrument || !instrument.id) {
                  return;
                }
                if (!instrument?.client) {
                  if (!canCreateNewClient()) {
                    toast.error(<LimitExceededNotification />, limitExceededOptions);
                    return;
                  }
                  handleRedirect(NEW_CLIENT_PAGE + `?instrumentId=${instrument.id}`);
                  return;
                } else {
                  handleRedirect(CLIENT_DETAILS.replace(':id', instrument.client?.id.toString()));
                }
              },
            },
            {
              icon: <GiAutoRepair />,
              onClick: () => {
                const instrument = instruments.find((instr) => instr.id === projectService?.instrumentId);
                if (!instrument || !instrument.id) {
                  return;
                }
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 1 });
                handleRedirect(INSTRUMENT_SERVICES.replace(':id', instrument.id.toString()));
              },
            },
            {
              icon: <BiCalendar />,
              onClick: () => {
                const instrument = instruments.find((instr) => instr.id === projectService?.instrumentId);
                if (!instrument || !instrument.id) {
                  return;
                }
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 2 });
                handleRedirect(INSTRUMENT_SERVICES.replace(':id', instrument.id.toString()));
              },
            },
          ]}
        />
        <ProjectForm
          isEditing={false}
          {...projectService}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          startingDateOriginalValue={startingDate}
          handleStartingDate={handleStartingDate}
          handleDeleteDate={handleDeleteDate}
          endingDateOriginalValue={endDate}
          handleDeleteEndingDate={handleDeleteEndDate}
          handleEndingDate={handleEndDate}
          handleNewTunerNameChange={handleNewTunerNameChange}
          handleTunerMatch={handleTunerChange}
          newTunerName={newTunerName}
          tuner={tuner}
        />

        <TimeAndAmount
          time={projectService?.sumDuration}
          price={projectService?.sumPrice}
          timeUnit={translations.timeAmountHours}
        />
        <CollapseElement
          headerElement={translations.projectServicesListTitle}
          defaultVisible={true}
          collapseElement={
            <>
              {projectService?.services.map((service) => (
                <ServiceCard
                  key={service.id}
                  service={service}
                  type={ServiceCardTypes.INSTRUMENT_SERVICE}
                  onClick={() => {
                    handleServiceClick(service.id);
                  }}
                  onActiveChangeCallback={async () => {
                    await fetchProjectDetails();
                  }}
                  disableActivate
                />
              ))}
            </>
          }
        />
        <Title title={translations.attachmentDetailTitle} />
        <AttachmentIcons
          pictureNr={projectService?.imagesCount}
          attachmentNr={projectService?.filesCount}
          type="project"
          instrumentId={projectService?.id}
        />
      </AdSection>
    </main>
  );
}

export default ProjectDetail;
