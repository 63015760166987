import { BsCheck } from 'react-icons/bs';

import style from '../assets/scss/components/MyInstrumentsFilter.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import { IMyIstrumentsFilter } from '../interfaces/components/FilterElement';

const UnfinishedServiceFilter = (props: IMyIstrumentsFilter): JSX.Element => {
  const { toggle, handleToggle } = props;
  const { wrapper, toggleIcon, toggleIconInactive } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  return (
    <div className={` ${wrapper} radio`}>
      <div className="radio-container">
        <BsCheck
          className={`radio-item ${toggle && 'radio-item-selected'} ${toggle ? toggleIcon : toggleIconInactive}`}
          onClick={handleToggle}
        />
      </div>
      <p>{translations.filterHasUnfinishedService}</p>
    </div>
  );
};

export default UnfinishedServiceFilter;
