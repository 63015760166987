import EditHeader from '../EditHeader';
import useServiceDetail from '../../hooks/useServiceDetail';
import InstrumentCard from '../InstrumentCard';
import ServiceForm from '../ServiceForm';
import AdSection from '../AdSection';
import useSetHeader from '../../hooks/useSetHeader';
import useGetTranslation from '../../hooks/useGetTranslations';
import IdComponent from '../IdComponent';
import { convertKeysToCamelCase, projectToSugesstion } from '../../helpers/baseHelper';
import ReactToggle from 'react-toggle';
import style from '../../assets/scss/components/Toggle.module.scss';

function EditService(): JSX.Element {
  const {
    instrument,
    service,
    register,
    handleSubmit,
    toggleEditing,
    onPictureChange,
    onAttachmentChange,
    handleDelete,
    errors,
    attachmentNr,
    pictureNr,
    handleLastDate,
    handleNextDate,
    lastServiceDate,
    nextServiceDate,
    handleDeleteLastDate,
    handleDeleteNextDate,
    handleNewProjectNameChange,
    newProjectName,
    handleExistingProjectChange,
    project,
    shouldLeaveWarning,
    cropUploadImage,
    handleCancelUploadCrop,
    handleFinishUploadCrop,
    uploadCropperRef,
    handleServiceActivate,
    isActive,
    confirmDateModal,
    isDateModalOpen,
    toggleDateModal,
    handleNewTunerNameChange,
    handleTunerChange,
    newTunerName,
    tuner,
  } = useServiceDetail();
  const { translations } = useGetTranslation();

  //prettier-ignore
  useSetHeader(
    () => {},
    project ? translations.serviceEditDetailTitleWithProject : translations.serviceEditDetailTitleWithoutProject
  );
  const { custom, flex, activeText, activeContainer } = convertKeysToCamelCase(style);

  return (
    <main>
      <EditHeader
        isEditing
        toggleEditing={toggleEditing}
        handleDelete={handleDelete}
        deleteTitle={translations.deleteServiceDescription}
        deleteButtonText={translations.deleteService}
      />
      <AdSection topPadding>
        <IdComponent id={service?.id} text={translations.serviceId} />
        <div className={flex}>
          <InstrumentCard {...instrument} isSimple={true} noMargin />
          <div className={activeContainer}>
            <p className={activeText}>{translations.active}</p>
            <ReactToggle className={custom} onChange={handleServiceActivate} checked={isActive === 1} />
          </div>
        </div>

        <ServiceForm
          register={register}
          handleSubmit={handleSubmit}
          isEditing
          service={service}
          onMatch={handleExistingProjectChange}
          onAttachmentChange={onAttachmentChange}
          onPictureChange={onPictureChange}
          errors={errors}
          pictureNr={pictureNr}
          attachmentNr={attachmentNr}
          lastServiceDate={lastServiceDate}
          nextServiceDate={nextServiceDate}
          handleLastDate={handleLastDate}
          handleNextDate={handleNextDate}
          showLeaveWarning={shouldLeaveWarning}
          handleDeleteLastDate={handleDeleteLastDate}
          handleDeleteNextDate={handleDeleteNextDate}
          handleNewProjectNameChange={handleNewProjectNameChange}
          newProjectName={newProjectName}
          project={projectToSugesstion(project)}
          uploadCropperRef={uploadCropperRef}
          uploadCropImage={cropUploadImage}
          handleUploadCrop={handleFinishUploadCrop}
          handleCancelUploadCrop={handleCancelUploadCrop}
          isModalOpen={isDateModalOpen}
          handleModalConfirm={confirmDateModal}
          toggleModal={toggleDateModal}
          handleNewTunerNameChange={handleNewTunerNameChange}
          handleTunerMatch={handleTunerChange}
          newTunerName={newTunerName}
          tuner={tuner}
        />
      </AdSection>
    </main>
  );
}

export default EditService;
