import { useState } from 'react';
import { Collapse } from 'react-collapse';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';

import style from '../assets/scss/components/CollapseElement.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { ICollapseElement } from '../interfaces/components/CollapseElement';

function CollapseElement(props: ICollapseElement): JSX.Element {
  const { headerElement, collapseElement, defaultVisible, notBoldHeader } = props;
  const { collapse, header, logo, logoContainer, line, wrapper, headerNotBold } = convertKeysToCamelCase(style);
  const [isVisible, setVisible] = useState(defaultVisible);

  return (
    <div className={wrapper}>
      <div
        onClick={() => {
          setVisible(!isVisible);
        }}
        className={notBoldHeader ? headerNotBold : header}
      >
        <p>{headerElement}</p>
        <div className={logoContainer}>
          {isVisible ? <MdOutlineKeyboardArrowUp className={logo} /> : <MdOutlineKeyboardArrowDown className={logo} />}
        </div>
      </div>
      <div className={line} />
      <Collapse isOpened={isVisible} theme={{ collapse }}>
        {collapseElement}
      </Collapse>
    </div>
  );
}

export default CollapseElement;
