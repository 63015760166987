import { IProfileRow } from '../interfaces/components/ProfileRow';
import style from '../assets/scss/components/ProfileRow.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGeneral from '../hooks/useGeneral';

function ProfileRow(props: IProfileRow): JSX.Element {
  const { text, to, showLine, navigate, onClick } = props;
  const { wrapper, line } = convertKeysToCamelCase(style);
  const { handleRedirect } = useGeneral();

  return (
    /* prettier-ignore */
    <div
      className={wrapper}
      onClick={onClick ? onClick : () => {
        navigate ? window.open(to, '_self') : handleRedirect(to);
      }}
    >
      <p>{text}</p>
      {showLine && <div className={line}/>}
    </div>
  );
}

export default ProfileRow;
