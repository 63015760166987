export const countryDropdownStyle = {
  container: (base: any) => ({
    ...base,
    border: '1px solid #e5e5e5',
    borderRadius: 15,
    width: '100%',
  }),
  control: (base: any, { isDisabled }: any) => ({
    ...base,
    minHeight: 30,
    border: 0,
    borderRadius: 15,
    width: '100%',
    boxShadow: 'none',
    backgroundColor: isDisabled ? 'white !important' : 'white',
    flexWrap: 'nowrap',
  }),
  dropdownIndicator: (base: any, { isDisabled }: any) => ({
    ...base,
    padding: 4,
    cursor: 'pointer',
    color: 'black',
    display: isDisabled ? 'none' : 'flex',
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: window.screen.width < 876 ? '4px 10px' : '0px 10px',
    whiteSpace: 'nowrap',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: 'black',
    whiteSpace: 'nowrap',
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isSelected && '#266e6a',
    cursor: 'pointer',
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#a9a9a9',
  }),
};

export const countryDropdownStyleWithWrap = {
  container: (base: any) => ({
    ...base,
    border: '1px solid #e5e5e5',
    borderRadius: 15,
    width: '100%',
  }),
  control: (base: any, { isDisabled }: any) => ({
    ...base,
    minHeight: 30,
    border: 0,
    borderRadius: 15,
    width: '100%',
    boxShadow: 'none',
    backgroundColor: isDisabled ? 'white !important' : 'white',
  }),
  dropdownIndicator: (base: any, { isDisabled }: any) => ({
    ...base,
    padding: 4,
    cursor: 'pointer',
    color: 'black',
    display: isDisabled ? 'none' : 'flex',
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '4px 10px',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: 'black',
    whiteSpace: 'normal',
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isSelected && '#266e6a',
    cursor: 'pointer',
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#a9a9a9',
  }),
};

export const filterDropdownStyle = {
  container: (base: any) => ({
    ...base,
    border: '1px solid #e5e5e5',
    borderRadius: 15,
    width: '100%',
    pointerEvents: 'none',
  }),
  control: (base: any, { isDisabled }: any) => ({
    ...base,
    minHeight: 30,
    border: 0,
    borderRadius: 15,
    width: '100%',
    boxShadow: 'none',
    backgroundColor: isDisabled ? 'white !important' : 'white',
    flexWrap: 'nowrap',
  }),
  dropdownIndicator: (base: any, { isDisabled }: any) => ({
    ...base,
    padding: 4,
    cursor: 'pointer',
    color: 'black',
    display: isDisabled ? 'none' : 'flex',
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: window.screen.width < 876 ? '4px 10px' : '0px 10px',
    whiteSpace: 'nowrap',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: 'black',
    whiteSpace: 'nowrap',
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isSelected && '#266e6a',
    cursor: 'pointer',
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#a9a9a9',
  }),
};
