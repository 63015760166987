import { useState, useEffect, createRef, RefObject } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Resizer from 'react-image-file-resizer';

import { useApi } from './useApi';
import { API_METHOD_DELETE, API_METHOD_GET, API_METHOD_POST, API_METHOD_PUT } from '../constants/api';
import {
  FETCH_INSTRUMENT,
  INSTRUMENTS,
  HOME_PAGE,
  GET_CLIENT,
  INSTRUMENT_DETAILS,
  NEW_BRAND,
} from '../constants/routes';
import { PIANO_TYPES } from '../constants/instruments';
import { ADD_INSTRUMENT, SET_INTRUMENT_LIST_REFRESH, SET_IS_LOADING, SET_PAGINATION } from '../constants/reduxActions';
import { getInstrumentValidationSchema } from '../helpers/validationSchemaHelper';
import useYupValidationResolver from './useYupValidationResolver';
import { INewInstrument, IInstrumentDetail } from '../interfaces/components/Instrument';
import { getBase64, multiUploadFilesAndCheckLimit, toCamelCase } from '../helpers/baseHelper';
import useGeneral from './useGeneral';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';
import { IClient } from '../interfaces/components/Clients';
import { IReduxStore } from '../interfaces/IGeneral';
import useMySubscriptionPlan from './useMySubscriptionPlan';
import useGetTranslations from './useGetTranslations';
import NoActiveSubscriptionNotification, {
  noActiveSubscriptionOptions,
} from '../components/NoActiveSubscriptionNotification';
import useGetClients from './useGetClients';
import { ReactCropperElement } from 'react-cropper';
import { nanoid } from 'nanoid';

function useInstrumnent() {
  const { handleApi } = useApi();
  const resolver = useYupValidationResolver(getInstrumentValidationSchema());
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setInstrumentValue,
  } = useForm<INewInstrument>({ resolver, shouldUnregister: false });
  const { handleRedirect } = useGeneral();
  const [selectedType, setSelectedType] = useState<number>(1);
  const [uploadImage, setUploadImage] = useState<{ file: string; name: string; didUpdate: boolean }>();
  const [uploadImages = [], setUploadImages] = useState<Array<{ content: string; name: string }>>();
  const [uploadAttachments = [], setUploadAttachments] = useState<Array<{ content: string; name: string }>>();
  const [instrument, setInstrument] = useState<IInstrumentDetail>();
  const [clientId, setClientId] = useState<number | undefined>(instrument?.clientId);
  const [client, setClient] = useState<IClient | undefined>();
  const [canLeave, setCanLeave] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [deleteProfileImage, setDeleteProfileImage] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { pagination } = useSelector((store: IReduxStore) => store.defaultReducer);
  const dispatch = useDispatch();
  const { imagesLeft, filesLeft, showPlanExceededToast } = useMySubscriptionPlan();
  const { goBack } = useGeneral();
  const history = useHistory();
  const { translations } = useGetTranslations();
  const [cropImage, setCropImage] = useState<string>(''); // profile pic cropped
  const cropperRef = createRef<ReactCropperElement>();
  const [cropUploadImage, setCropUploadImage] = useState<string>(''); // single uploaded image on attachments cropped
  const uploadCropperRef = createRef<ReactCropperElement>();
  const [newBrandName, setNewBrandName] = useState<string>('');
  const [brand, setBrand] = useState<ISuggestion>({ id: -1, name: '' });
  useGetClients();

  useEffect(() => {
    fetchInstrument();
  }, []);

  useEffect(() => {
    fetchClient(clientId);
  }, [clientId]);

  async function fetchClient(clientId: number | undefined) {
    if (clientId) {
      const { data } = await handleApi(API_METHOD_GET, GET_CLIENT.replace(':id', clientId.toString()));
      setClient(data);
    }
  }

  async function fetchInstrument() {
    const queryParam = window.location.href.split('=');
    if (queryParam.length > 1) {
      setClientId(Number(queryParam[1]));
      return;
    }
    if (id) {
      const { data } = await handleApi(API_METHOD_GET, FETCH_INSTRUMENT.replace(':id', id.toString()));

      if (data === null) {
        //if instrument is null, then redirect to homepage ~ if is deleted
        setCanLeave(true);
        handleRedirect(HOME_PAGE);
        return;
      }

      setInstrument(data);
      setInstrumentValue('brand', data.brand);
      setInstrumentValue('size', data.size);
      setInstrumentValue('color', data.color);
      setInstrumentValue('age', data.age);
      setInstrumentValue('model', data.model);
      setInstrumentValue('serial_number', data.serialNumber);
      setInstrumentValue('note', data.note);
      setInstrumentValue('dateOfPurchase', data.dateOfPurchase);
      setInstrumentValue('room', data.room);
      setInstrumentValue('warranty', data.warranty);
      setUploadImage({ file: data.thumbnail, name: 'thumbnail', didUpdate: false });
      const type = PIANO_TYPES.find((piano) => piano.name === data.category)!.id;
      setSelectedType(type);
      setClientId(data.clientId);
      setBrand({ id: data.brandId, name: data.brand });
    }
  }

  const handleSuggestionChange = (suggestion?: ISuggestion) => {
    setClientId(suggestion?.id);
  };

  async function submitForm(formData: INewInstrument) {
    let brandIdOnSubmit = -1;
    let brandNameOnSubmit = '';

    if (newBrandName !== '') {
      const { data, code } = await handleApi(API_METHOD_POST, NEW_BRAND, {
        name: newBrandName,
      });

      if (Number(code) >= 400) {
        toast.error(translations[toCamelCase(data.name.replace('api.', ''))] ?? data.name);
        return;
      }

      brandIdOnSubmit = data.id;
      brandNameOnSubmit = data.name;
    }

    if (newBrandName === '') {
      brandIdOnSubmit = brand.id;
      brandNameOnSubmit = brand.name;
    }

    //existing instrument, update ( PUT ) part
    if (id && window.location.href.split('=').length === 1) {
      let form = {
        ...formData,
        images: uploadImages,
        files: uploadAttachments,
        category: PIANO_TYPES.find((piano) => piano.id === selectedType)?.name,
        deleteThumbnail: deleteProfileImage ? '1' : '0',
        brand_id: brandIdOnSubmit,
        brand: brandNameOnSubmit,
      };
      if (uploadImage?.didUpdate) form = { ...form, thumbnail: uploadImage?.file };
      if (clientId) form = { ...form, client_id: clientId };
      const { message, code, data } = await handleApi(API_METHOD_PUT, FETCH_INSTRUMENT.replace(':id', id), form);
      dispatch({ type: SET_PAGINATION, payload: pagination });
      dispatch({ type: SET_INTRUMENT_LIST_REFRESH, payload: true });

      if (code !== 200) {
        toast.error(translations[toCamelCase(message)]);
        const errors = Object.keys(data);
        errors.forEach((err) => toast.error(data[err]));
        return;
      }

      toast.success(translations.savedSuccessfully);
      setCanLeave(true);
      goBack();
    } else {
      //this is a new instrument, POST part
      let form = formData;

      if (clientId) form = { ...form, client_id: clientId };

      const { message, code, data } = await handleApi(API_METHOD_POST, INSTRUMENTS, {
        ...form,
        thumbnail: uploadImage?.file === '' ? null : uploadImage?.file,
        category: PIANO_TYPES.find((piano) => piano.id === selectedType)?.name,
        images: uploadImages,
        files: uploadAttachments,
        brand_id: brandIdOnSubmit,
        brand: brandNameOnSubmit,
      });

      if (code === 200) {
        dispatch({ type: SET_IS_LOADING, payload: true });
        setCanLeave(true);
        history.goBack();
        history.replace(HOME_PAGE);
        setTimeout(() => {
          handleRedirect(INSTRUMENT_DETAILS.replace(':id', String(data.id)));
        }, 100);
        dispatch({ type: SET_IS_LOADING, payload: false });
        dispatch({ type: ADD_INSTRUMENT, payload: data });
        toast.success(translations.savedSuccessfully);
        return;
      }

      if (message === 'no_active_subscription') {
        toast.error(<NoActiveSubscriptionNotification />, noActiveSubscriptionOptions);
        return;
      }

      if (data) {
        Object.values(data).map((err: any) => {
          toast.error(err);
        });
      }
    }
  }

  const isPlanLimitExceeded = (amountOfFiles: number) => {
    return amountOfFiles > imagesLeft;
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const amountOfFiles = files.length;
      if (isPlanLimitExceeded(amountOfFiles)) return showPlanExceededToast();

      getBase64(files[0], handleCropImage);
    }
    // multiUploadFilesAndCheckLimit(files, onLoadProfile);
  };

  const handleCropImage = (fileString: string | ArrayBuffer | null, name: string) => {
    name;
    if (typeof fileString === 'string') {
      setCropImage(fileString);
    }
  };
  const handleCancelCrop = () => {
    setCropImage('');
  };

  const finishCrop = async (cropperRef: RefObject<ReactCropperElement>): Promise<File | null> => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const croppedImageDataURL = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      const byteString = atob(croppedImageDataURL.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: 'image/png' });

      // Create a File object from the Blob
      const file = new File([blob], 'cropped_image.png', { type: 'image/png' });

      const resized = await resizeFile(file);

      return resized;
    }

    return null;
  };

  const handleFinishCrop = async () => {
    const resized = await finishCrop(cropperRef);
    if (resized) {
      setDeleteProfileImage(false);
      onLoadProfile(resized as any, `${nanoid()}}.png`);
      setCropImage('');
    }
  };

  const handleUploadCropImage = (fileString: string | ArrayBuffer | null, name: string) => {
    name;
    if (typeof fileString === 'string') {
      setCropUploadImage(fileString);
    }
  };
  const handleCancelUploadCrop = () => {
    setCropUploadImage('');
  };

  const handleFinishUploadCrop = async () => {
    const resized = await finishCrop(uploadCropperRef);

    if (resized) {
      onLoadPictures(resized as any, `${nanoid()}}.png`);
      setCropUploadImage('');
    }
  };

  /*prettier-ignore*/
  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1020,
        1020,
        'JPEG',
        200,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'base64'
      );
    });

  const onPictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const amountOfFiles = files.length;
      // Prevent adding files if limit exceeded
      if (isPlanLimitExceeded(amountOfFiles)) return showPlanExceededToast();
      for (let i = 0; i < amountOfFiles; i++) {
        if (files.length === 1) {
          getBase64(files[i], handleUploadCropImage);
          return;
        }
        const image = await resizeFile(files[i]);
        onLoadPictures(image as any, `resized${nanoid()}.png`);
      }
    }
    // multiUploadFilesAndCheckLimit(files, onLoadPictures, imagesLeft, showPlanExceededToast);
  };

  const onAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    multiUploadFilesAndCheckLimit(files, onLoadAttachments, filesLeft, showPlanExceededToast);
  };

  const onLoadProfile = (fileString: string | ArrayBuffer | null, name: string) => {
    if (fileString) setUploadImage({ file: fileString as string, name: name, didUpdate: true });
  };

  const onLoadPictures = (fileString: string | ArrayBuffer | null, name: string) => {
    if (fileString)
      setUploadImages((old) => {
        return old
          ? [...old, { content: fileString as string, name: name }]
          : [{ content: fileString as string, name: name }];
      });
  };

  const onLoadAttachments = (fileString: string | ArrayBuffer | null, name: string) => {
    if (fileString)
      setUploadAttachments((old) => {
        return old
          ? [...old, { content: fileString as string, name: name }]
          : [{ content: fileString as string, name: name }];
      });
  };

  const handleDeleteSelected = () => {
    setClient(undefined);
    setClientId(undefined);
  };

  async function handleDelete() {
    setCanLeave(true);
    await handleApi(API_METHOD_DELETE, FETCH_INSTRUMENT.replace(':id', id));
    setTimeout(() => {
      handleRedirect(HOME_PAGE);
    }, 100);
  }

  const handleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleDeleteProfileImage = () => {
    setDeleteProfileImage(true);
    setUploadImage({ file: '', name: '', didUpdate: false });
    setCropImage('');
  };

  const handleNewBrandNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewBrandName(e.target.value);
    setInstrumentValue('brand', e.target.value);
  };

  const handleBrandChange = (brand: ISuggestion | undefined) => {
    brand && setBrand(brand);
    setInstrumentValue('brand', brand?.name);
  };

  return {
    register,
    handleSubmitForm: handleSubmit(submitForm),
    errors,
    selectedType,
    setSelectedType,
    handleImageChange,
    uploadImage: uploadImage?.file,
    onPictureChange: onPictureChange,
    onAttachmentChange: onAttachmentChange,
    instrument,
    handleSuggestionChange,
    selectedClient: client,
    handleDeleteSelected,
    handleDelete,
    pictureNr: uploadImages.length + (instrument?.imagesCount || 0),
    attachmentNr: uploadAttachments.length + (instrument?.filesCount || 0),
    canLeave,
    isEditing,
    handleIsEditing,
    handleDeleteProfileImage,
    cropImage,
    handleFinishCrop,
    cropperRef,
    handleCancelCrop,
    cropUploadImage,
    handleFinishUploadCrop,
    uploadCropperRef,
    handleCancelUploadCrop,
    handleNewBrandNameChange,
    newBrandName,
    handleBrandChange,
    brand,
  };
}

export default useInstrumnent;
