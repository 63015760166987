import { useSelector } from 'react-redux';

import AdSection from '../AdSection';
import ProjectHeader from '../ProjectHeader';
import ProjectForm from '../ProjectForm';
import useSetHeader from '../../hooks/useSetHeader';
import useGetTranslations from '../../hooks/useGetTranslations';
import useGeneral from '../../hooks/useGeneral';
import InstrumentCard from '../InstrumentCard';
import useProjectServices from '../../hooks/useProjectServices';
import { NEW_SERVICE_PAGE_WITH_PROJECT } from '../../constants/routes';
import { IReduxStore } from '../../interfaces/IGeneral';
import IdComponent from '../IdComponent';

function EditProject(): JSX.Element {
  const {
    projectService,
    toggleEditing,
    handleSubmit,
    register,
    errors,
    handleDelete,
    handleStartingDate,
    startingDate,
    handleDeleteDate,
    shouldLeaveWarning,
    endDate,
    handleDeleteEndDate,
    handleEndDate,
    handleNewTunerNameChange,
    handleTunerChange,
    newTunerName,
    tuner,
  } = useProjectServices();
  const { translations } = useGetTranslations();
  const { handleRedirect } = useGeneral();
  const { instruments } = useSelector((store: IReduxStore) => store.defaultReducer);
  useSetHeader(() => {}, translations.projectEditServicesTitle);

  return (
    <main>
      <ProjectHeader
        isEditing
        toggleEditing={toggleEditing}
        handleDelete={handleDelete}
        deleteTitle={translations.deleteProjectDescription}
        redirectToService={() => {
          if (projectService?.instrumentId)
            //prettier-ignore
            handleRedirect(
              NEW_SERVICE_PAGE_WITH_PROJECT.replace(':id', projectService.instrumentId.toString()).replace(
                ':projectId',
                projectService.id.toString()
              )
            );
        }}
      />
      <AdSection topPadding>
        <IdComponent id={projectService?.id} text={translations.projectId} />
        <InstrumentCard isSimple={true} {...instruments.find((instr) => instr.id === projectService?.instrumentId)} />
        <ProjectForm
          isEditing
          {...projectService}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          startingDateOriginalValue={startingDate}
          handleStartingDate={handleStartingDate}
          handleDeleteDate={handleDeleteDate}
          shouldLeaveWarning={shouldLeaveWarning}
          endingDateOriginalValue={endDate}
          handleDeleteEndingDate={handleDeleteEndDate}
          handleEndingDate={handleEndDate}
          handleNewTunerNameChange={handleNewTunerNameChange}
          handleTunerMatch={handleTunerChange}
          newTunerName={newTunerName}
          tuner={tuner}
        />
      </AdSection>
    </main>
  );
}

export default EditProject;
