import { MdAutoDelete, MdDateRange } from 'react-icons/md';

import Title from './Title';
import style from '../assets/scss/components/ProjectForm.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import CollapseElement from './CollapseElement';
import { IProjectForm } from '../interfaces/components/Project';
import LeaveModal from './LeaveModal';
import useGetTranslations from '../hooks/useGetTranslations';
import DatePicker from 'react-date-picker';
import ChooseTuner from './ChooseTuner';
import { useMemo, useRef } from 'react';
import { professionChecker } from '../utils/roleChecker';

function ProjectForm(props: IProjectForm): JSX.Element {
  const { row, name, inp, btnContainer, errorText, subtitle, text, minWidth, deleteIcon, inputWrapper } =
    convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const {
    register,
    handleSubmit,
    title,
    description,
    startingDate,
    isEditing,
    errors,
    shouldLeaveWarning,
    startingDateOriginalValue,
    handleStartingDate,
    handleDeleteDate,
    endingDate,
    endingDateOriginalValue,
    handleEndingDate,
    handleDeleteEndingDate,
    handleNewTunerNameChange,
    handleTunerMatch,
    newTunerName,
    tuner,
  } = props;
  const isTuner = useMemo(() => professionChecker(), []);

  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleScrollToDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {isEditing && shouldLeaveWarning && <LeaveModal />}
      {errors?.title && <p className={errorText}>{translations.mandatoryField}</p>}
      <div className={isEditing ? row : subtitle}>
        <p className={name}>{translations.projectFormProject}:*</p>
        {isEditing ? (
          <input
            name="title"
            ref={register}
            type="text"
            className={`primary ${inp}`}
            placeholder={translations.projectFormTitlePlaceholder}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <p className={text}>{title}</p>
        )}
      </div>
      {errors.tuner && <p className={errorText}>{translations.serviceFormTunerError}</p>}
      <div className={row} ref={datePickerRef}>
        <p className={minWidth}>{translations.projectFormStartDate}</p>
        {isEditing ? (
          <div className={`${row} ${inputWrapper}`}>
            <p className="bubble-date">{startingDateOriginalValue}</p>
            <DatePicker
              onChange={handleStartingDate}
              calendarIcon={<MdDateRange className={deleteIcon} onClick={handleScrollToDatePicker} />}
              clearIcon={<MdAutoDelete className={deleteIcon} onClick={handleDeleteDate} />}
              value={startingDateOriginalValue}
              format="dd/MM/yyyy"
            />
          </div>
        ) : (
          <p className="bubble-date">{startingDate}</p>
        )}
      </div>
      <div className={row}>
        <p className={minWidth}>{translations.projectFormEndDate}</p>
        {isEditing ? (
          <div className={`${row} ${inputWrapper}`}>
            <p className="bubble-date">{endingDateOriginalValue}</p>
            <DatePicker
              onChange={handleEndingDate}
              calendarIcon={<MdDateRange className={deleteIcon} onClick={handleScrollToDatePicker} />}
              clearIcon={<MdAutoDelete className={deleteIcon} onClick={handleDeleteEndingDate} />}
              value={endingDateOriginalValue}
              format="dd/MM/yyyy"
            />
          </div>
        ) : (
          <p className="bubble-date">{endingDate}</p>
        )}
      </div>

      {isEditing ? (
        <>
          <Title title={translations.projectFormDescriptionTitle} />
          <textarea rows={20} name="description" ref={register} />
        </>
      ) : (
        <CollapseElement
          headerElement={translations.projectFormDescriptionTitle}
          defaultVisible={false}
          collapseElement={<textarea rows={20} value={description} disabled />}
        />
      )}

      {isTuner && (
        <CollapseElement
          headerElement={translations.serviceFormTitleTuner}
          defaultVisible={isEditing}
          collapseElement={
            <ChooseTuner
              isEditing={isEditing}
              onMatch={handleTunerMatch}
              tuner={tuner}
              handleNewTunerChange={handleNewTunerNameChange}
              newTunerName={newTunerName}
            />
          }
        />
      )}

      {isEditing && (
        <div className={btnContainer}>
          <button className="blue" type="submit">
            {translations.projectFormSaveProject}
          </button>
        </div>
      )}
    </form>
  );
}

export default ProjectForm;
