import { AiOutlinePlus } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';

import BlueaHeader from './BlueHeader';
import SortIcon from './SortIcon';
import EditHeader from './EditHeader';
import style from '../assets/scss/components/ProjectHeader.module.scss';
import { IProjectHeader } from '../interfaces/components/Project';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';

function ProjectHeader(props: IProjectHeader): JSX.Element {
  const { wrapperLeft, icon, line, wrapper, flex, link } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { toggleEditing, redirectToService, isEditing, handleDelete, deleteTitle } = props;

  return !isEditing ? (
    <BlueaHeader
      element={
        <div className={wrapper}>
          <SortIcon
            field="title"
            title={{
              ascending: translations.sortServiceNameAsc,
              descending: translations.sortServiceNameDesc,
            }}
          />
          <div className={wrapperLeft}>
            <div className={`${flex} ${link}`}>
              <p onClick={redirectToService}>{translations.projectHeaderNewService}</p>
              <AiOutlinePlus className="plus-circle" onClick={redirectToService} />
            </div>
            <div className={line} />
            <div className={`${flex} ${link}`}>
              <p onClick={toggleEditing}>{translations.edit}</p>
              <MdModeEditOutline className={icon} onClick={toggleEditing} />
            </div>
          </div>
        </div>
      }
    />
  ) : (
    <EditHeader
      isEditing={isEditing}
      toggleEditing={toggleEditing}
      handleDelete={handleDelete}
      deleteTitle={deleteTitle}
      deleteButtonText={translations.deleteProject}
    />
  );
}

export default ProjectHeader;
