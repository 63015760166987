import { useRef } from 'react';
import { MdLocationPin, MdEmail, MdOutlineTextsms, MdModeEditOutline, MdDeleteOutline } from 'react-icons/md';
import { IoEarthSharp } from 'react-icons/io5';
import { AiFillPhone } from 'react-icons/ai';

import style from '../assets/scss/components/ClientsCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useClientsCard from '../hooks/useClientsCard';
import userPlaceholder from '../assets/images/userplaceholder.jpg';
import { IClientsCard } from '../interfaces/components/Clients';
import useMobile from '../hooks/useMobile';

function ClientsCard(props: IClientsCard): JSX.Element {
  const {
    wrapper,
    image,
    title,
    line,
    description,
    location,
    descriptionContainer,
    logoContainer,
    logo,
    infoWrapper,
    contactWrapper,
    contactIcon,
    contactIconDisabled,
    contactIconWrapper,
    contactIconWrapperDisabled,
    wrapperSimple,
    imageContainer,
    contactIconCircled,
    leftMargin,
    countryText,
  } = convertKeysToCamelCase(style);

  const { isSimple, selectedImage, handleImageChange, isNew, client, onClick, isEditing, handleProfileImageDelete } =
    props;
  const { handleSMSOpen, handlePhoneOpen, handleEmailOpen } = useClientsCard();
  const imageRef = useRef<HTMLImageElement>(null);
  const { isMobile } = useMobile();

  return (
    <div className={isSimple ? wrapperSimple : wrapper}>
      <div
        className={infoWrapper}
        onClick={() => {
          if (onClick) onClick(client?.id);
        }}
      >
        <input type="file" name="file" id="file" accept="image/*" onChange={handleImageChange} disabled={!isEditing} />
        <label htmlFor="file" className={imageContainer}>
          <img
            src={!isSimple ? client?.thumbnail : selectedImage ? selectedImage : userPlaceholder}
            className={image}
            ref={imageRef}
            onError={() => {
              if (imageRef && imageRef.current) imageRef.current.src = userPlaceholder;
            }}
          />
          {isEditing && <MdModeEditOutline className="image-edit-icon" />}
        </label>
        {isEditing && <MdDeleteOutline onClick={handleProfileImageDelete} className="image-delete-icon" />}
        {!isNew && (
          <div className={descriptionContainer}>
            <div>
              <p className={title}>
                {client?.firstName} {client?.lastName}
              </p>
              <div>{!isSimple && <p className={description}>{client?.nick}</p>}</div>
            </div>
            <div>
              {(client?.city !== '' || client?.postcode !== '') && (
                <div className={logoContainer}>
                  <MdLocationPin className={logo} />
                  <p className={location}>{client?.city}</p>
                  {client?.city && client?.postcode && <p className={location}>,</p>}
                  <p className={`${location} ${leftMargin}`}>{client?.postcode}</p>
                </div>
              )}
              {client?.country !== '' && (
                <div className={`${logoContainer} ${countryText}`}>
                  <IoEarthSharp className={logo} />
                  <p>{client?.country}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!isSimple && (
        <>
          <div className={line} />
          <div className={contactWrapper}>
            <div className={client?.email !== '' ? contactIconWrapper : contactIconWrapperDisabled}>
              <MdEmail
                className={client?.email !== '' ? contactIconCircled : contactIconDisabled}
                onClick={() => {
                  client?.email !== '' && handleEmailOpen(client?.email);
                }}
              />
            </div>
            <div className={isMobile && client?.phone1 !== '' ? contactIconWrapper : contactIconWrapperDisabled}>
              <MdOutlineTextsms
                className={isMobile && client?.phone1 !== '' ? contactIcon : contactIconDisabled}
                onClick={() => {
                  client?.phone1 !== '' && handleSMSOpen(client?.phone1);
                }}
              />
            </div>
            <div className={client?.phone1 !== '' ? contactIconWrapper : contactIconWrapperDisabled}>
              <AiFillPhone
                className={client?.phone1 !== '' ? contactIconCircled : contactIconDisabled}
                onClick={() => {
                  client?.phone1 !== '' && handlePhoneOpen(client?.phone1);
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ClientsCard;
