import { BsCheck } from 'react-icons/bs';
import { FaUser } from 'react-icons/fa';

import style from '../assets/scss/components/MyInstrumentsFilter.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslations from '../hooks/useGetTranslations';
import { IMyIstrumentsFilter } from '../interfaces/components/FilterElement';

function MyInstrumentsFilter(props: IMyIstrumentsFilter): JSX.Element {
  const { toggle, handleToggle } = props;
  const { wrapper, logo, logoWrapper, toggleIcon, toggleIconInactive } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();

  return (
    <div className={` ${wrapper} radio`}>
      <div className="radio-container">
        <BsCheck
          className={`radio-item ${toggle && 'radio-item-selected'} ${toggle ? toggleIcon : toggleIconInactive}`}
          onClick={handleToggle}
        />
      </div>
      <div className={logoWrapper}>
        <FaUser className={logo} size={20} />
      </div>
      <p>{translations.filterMyInstruments}</p>
    </div>
  );
}

export default MyInstrumentsFilter;
