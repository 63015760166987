import { useState, useEffect, useMemo } from 'react';

import { useApi } from './useApi';
import { API_METHOD_DELETE } from '../constants/api';
import {
  DELETE_CURRENT_PLAN,
  SUBSCRIPTIONS_CHANGE_PAYMENT_METHOD_STATUS,
  SUBSCRIPTIONS_PAYMENT_STATUS,
} from '../constants/routes';
import { IUseMySubscriptionDetails } from '../interfaces/components/Hooks';
import useQuery from './useQuery';
import { PaymentStatus } from './useStripeWebCheckout';
import { toast } from 'react-toastify';
import useGetTranslations from './useGetTranslations';
import useMySubscriptionPlan from './useMySubscriptionPlan';
import useGeneral from './useGeneral';
import { useHistory } from 'react-router';

function useMySubscriptionDetails(): IUseMySubscriptionDetails {
  const { subscription } = useMySubscriptionPlan();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { handleApi } = useApi();
  const { translations } = useGetTranslations();
  const { removeSearchParams } = useGeneral();
  const query = useQuery();
  const history = useHistory();

  const toggleCancelModal = () => {
    setIsCancelModalOpen(!isCancelModalOpen);
  };

  const cancelSubscription = async () => {
    await handleApi(API_METHOD_DELETE, DELETE_CURRENT_PLAN);
    setIsCancelModalOpen(!isCancelModalOpen);
    history.go(0);
  };

  const { paymentStatus, paymentMethodStatus } = useMemo(() => {
    const paymentStatus = query.get(SUBSCRIPTIONS_PAYMENT_STATUS);
    const paymentMethodStatus = query.get(SUBSCRIPTIONS_CHANGE_PAYMENT_METHOD_STATUS);
    return {
      paymentStatus,
      paymentMethodStatus,
    };
  }, [query]);

  useEffect(() => {
    if (paymentStatus && Object.keys(translations).length > 0) {
      if (paymentStatus === PaymentStatus.SUCCESS) {
        toast.success(translations.subscriptionPaymentSuccess);
        removeSearchParams();
      }
      if (paymentStatus === PaymentStatus.CANCELED) {
        toast.error(translations.subscriptionPaymentCanceled);
        removeSearchParams();
      }
    }
    if (paymentMethodStatus && Object.keys(translations).length > 0) {
      if (paymentMethodStatus === PaymentStatus.SUCCESS) {
        toast.success(translations.paymentMethodUpdateSuccess);
        removeSearchParams();
      }
      if (paymentMethodStatus === PaymentStatus.CANCELED) {
        toast.error(translations.subscriptionPaymentCanceled);
        removeSearchParams();
      }
    }
  }, [paymentStatus, paymentMethodStatus, translations]);

  return { isCancelModalOpen, toggleCancelModal, cancelSubscription, subscription };
}

export default useMySubscriptionDetails;
