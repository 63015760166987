import { TiLocationArrowOutline } from 'react-icons/ti';
import { AiFillPhone } from 'react-icons/ai';
import { MdEmail, MdOutlineTextsms } from 'react-icons/md';
import { GiRotaryPhone } from 'react-icons/gi';

import Title from './Title';
import style from '../assets/scss/components/ClientsEditForm.module.scss';
import ClientsInstruments from './ClientsInstruments';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import googleIcon from '../assets/images/google_maps.svg';
import { IClientsEditForm } from '../interfaces/components/Clients';
import useGetTranslations from '../hooks/useGetTranslations';
import useGeneral from '../hooks/useGeneral';
import { INSTRUMENT_DETAILS } from '../constants/routes';
import { professionChecker } from '../utils/roleChecker';

function ClientsEditForm(props: IClientsEditForm): JSX.Element {
  const { row, icon, rowSimple, address, logos, link } = convertKeysToCamelCase(style);
  const { handleAddressClick, client, selectedInstruments, handleEmailOpen, handlePhoneOpen, handleSmsOpen } = props;
  const { translations } = useGetTranslations();
  const { handleRedirect } = useGeneral();

  return (
    <div>
      <Title title={client?.type === 'company' ? translations.contactName : translations.clientsEditFormName} />
      <p className="bubble">{client?.nick}</p>
      {professionChecker() && (
        <>
          <Title title={translations.clientsEditFormAddress} />
          <div className={row}>
            <div className={rowSimple}>
              <div className={icon}>
                <TiLocationArrowOutline className={icon} onClick={handleAddressClick} />
              </div>
              <p className={address}>
                {client?.postcode} {client?.street} {client?.city} {client?.region} {client?.country}
              </p>
            </div>
            <img src={googleIcon} className={link} onClick={handleAddressClick} />
          </div>
        </>
      )}
      <hr />
      <div className={rowSimple}>
        <AiFillPhone
          className={`${logos} ${link}`}
          onClick={() => client && client.phone1 && handlePhoneOpen(client.phone1)}
        />
        <p className="bubble-client">{client?.phone1}</p>
      </div>
      <div className={rowSimple}>
        <GiRotaryPhone
          className={`${logos} ${link}`}
          onClick={() => client && client.phone2 && handlePhoneOpen(client.phone2)}
        />
        <p className="bubble-client">{client?.phone2}</p>
      </div>
      <div className={rowSimple}>
        <MdOutlineTextsms
          className={`${logos} ${link}`}
          onClick={() => client && client.phone1 && handleSmsOpen(client.phone1)}
        />
        <p className="bubble-client">{client?.phone1}</p>
      </div>
      <hr />
      <div className={rowSimple}>
        <MdEmail
          className={`${logos} ${link}`}
          onClick={() => client && client.email && handleEmailOpen(client.email)}
        />
        <p className="bubble-client">{client?.email}</p>
      </div>
      <Title title={translations.clientsEditFormInstruments} />
      <ClientsInstruments
        isEditing={false}
        selectedInstruments={selectedInstruments}
        onClick={(id: number) => handleRedirect(INSTRUMENT_DETAILS.replace(':id', String(id)))}
      />
      <Title title={translations.clientsEditFormNotes} />
      <textarea rows={8} value={client?.note} disabled />
      <div className="last-element" />
    </div>
  );
}

export default ClientsEditForm;
