import { MdModeEditOutline } from 'react-icons/md';
import { BsTrash } from 'react-icons/bs';

import style from '../assets/scss/components/MyAccountHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import AlertModal from './AlertModal';
import { IMyAccountHeader } from '../interfaces/components/MyAccountHeader';
import useGetTranslations from '../hooks/useGetTranslations';

function MyAccountHeader(props: IMyAccountHeader): JSX.Element {
  const { wrapperEdit, icon, wrapperDelete } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { toggleEditing, isEditing, toggleModal, isModalVisible, deleteAccount } = props;

  return (
    <div>
      <AlertModal
        onClose={toggleModal}
        isOpen={isModalVisible}
        onLeftButtonClick={toggleModal}
        onRightButtonClick={deleteAccount}
        header={translations.myAccountHeaderAlertHeader}
        description={translations.myAccountHeaderAlertDescription}
        leftButton={translations.cancel}
        rightButton={translations.delete}
      />
      {isEditing && (
        <div onClick={toggleModal} className={wrapperDelete}>
          <p>{translations.myAccountHeaderDeleteAccount}</p>
          <BsTrash className={icon} />
        </div>
      )}
      {!isEditing && (
        <div onClick={toggleEditing} className={wrapperEdit}>
          <p>{translations.edit}</p>
          <MdModeEditOutline className="edit-icon" />
        </div>
      )}
    </div>
  );
}

export default MyAccountHeader;
