import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IReduxStore } from '../interfaces/IGeneral';
import { SET_PAGINATION } from '../constants/reduxActions';
import style from '../assets/scss/components/AdSection.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { arrayPagination } from '../constants/pagination';

interface PaginationElementsProps {
  length: number;
  perPage?: number;
}

function PaginationElements(props: PaginationElementsProps): JSX.Element {
  const { length, perPage } = props;
  const { pagination, paginationContainer, paginationSelected } = convertKeysToCamelCase(style);
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const { pagination: paginationRedux } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [display, setDisplay] = useState('');

  const onResize = () => {
    if (window.innerWidth < 768) {
      setDisplay('none');
    } else {
      setDisplay('flex');
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (length) {
      if (display !== 'none') {
        dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: perPage ?? 10 } });
      }
    }
  }, [display, length]);

  return (
    <div className={paginationContainer} ref={divRef} id="Heeey" style={{ display: display }}>
      {arrayPagination(Math.ceil(length / (perPage ?? 10)), paginationRedux.page).map((pagi) => (
        <div
          className={`${pagination} ${paginationRedux.page === pagi && paginationSelected}`}
          key={pagi}
          onClick={() => {
            dispatch({ type: SET_PAGINATION, payload: { ...paginationRedux, page: pagi } });
          }}
        >
          <p>{pagi}</p>
        </div>
      ))}
    </div>
  );
}
export default PaginationElements;
