import ReactToggle, { ToggleProps as ReactToggleProps } from 'react-toggle';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/Toggle.module.scss';

interface ToggleProps extends ReactToggleProps {
  defaultChecked?: boolean;
  checked: boolean;
  onChange: () => void;
}

const Toggle = ({ defaultChecked, checked, onChange }: ToggleProps) => {
  const { custom } = convertKeysToCamelCase(style);

  return (
    <label>
      <ReactToggle defaultChecked={defaultChecked} className={custom} onChange={onChange} checked={checked} />
    </label>
  );
};

export default Toggle;
