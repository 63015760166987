import { Link } from 'react-router-dom';
import { ToastOptions } from 'react-toastify/dist/types';
import useGetTranslations from '../hooks/useGetTranslations';
import { MY_SUBSCRIPTION } from '../constants/routes';

export const noActiveSubscriptionOptions: ToastOptions = {
  toastId: 'no-active-subscription',
  autoClose: 6000,
};

const NoActiveSubscriptionNotification = (): JSX.Element => {
  const { translations } = useGetTranslations();

  return translations.noActiveSubscription && translations.here ? (
    <div>
      <p>
        {translations.noActiveSubscription} <Link to={MY_SUBSCRIPTION}>{translations.here}</Link>.
      </p>
    </div>
  ) : (
    <div>
      <p>No active subscription.</p>
    </div>
  );
};

export default NoActiveSubscriptionNotification;
