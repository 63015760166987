import Title from './Title';
import LeaveModal from './LeaveModal';
import style from '../assets/scss/components/RemindersForm.module.scss';
import { capitalize, convertKeysToCamelCase } from '../helpers/baseHelper';
import { IRemindersForm } from '../interfaces/components/Reminders';
import useGetTranslations from '../hooks/useGetTranslations';
import { AiFillInfoCircle } from 'react-icons/ai';
import { BsCheck } from 'react-icons/bs';
import { MdAutoDelete, MdDateRange } from 'react-icons/md';
import DatePicker from 'react-date-picker';
import { useRef } from 'react';
import { professionTranslation } from '../utils/roleChecker';

function RemindersForm(props: IRemindersForm): JSX.Element {
  const {
    input,
    inputContainer,
    dateText,
    bubble,
    btnContainer,
    errorMessage,
    infoContainer,
    infoImage,
    infoDescription,
    flex,
    padding,
    btnContainerSecondary,
    indented,
    repeatValue,
    value,
    noMargin,
    deleteIcon,
    row,
    inputWrapper,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const {
    reminder,
    isEditing,
    isSendMail,
    register,
    handleSubmit,
    errors,
    handleSendEmail,
    saveReminderTemplate,
    showLeaveWarning,
    handleReminderDateValue,
    reminderDateValue,
    handleReminderRepeatValue,
    reminderRepeatValue,
    handleDeleteReminderDate,
    showSendEmail,
    profile,
  } = props;
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleScrollToDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {isEditing && showLeaveWarning && <LeaveModal />}
      <Title title={translations.remindersFormTitleTitle} />
      <div className={indented}>
        {errors?.title && <p className={errorMessage}>{translations.mandatoryField}</p>}
        {isEditing ? (
          <input
            type="text"
            className={input}
            ref={register}
            name="title"
            placeholder={translations.remindersFormTitlePlaceholder}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <p className={`bubble ${noMargin}`}>{reminder?.title}</p>
        )}
      </div>
      <Title title={translations.remindersFormDateTitle} />
      <div className={indented}>
        {isEditing && (
          <div className={infoContainer}>
            <AiFillInfoCircle className={infoImage} />
            <p className={infoDescription}>{translations.remindersFormSetReminderInfo}</p>
          </div>
        )}
        {errors?.date && <p className={errorMessage}>{translations.mandatoryField}</p>}
        <div className={inputContainer}>
          <div className={dateText}>
            <p>{translations.remindersFormSendingDate}:*</p>
          </div>
          {isEditing ? (
            <div className={`${row} ${inputWrapper}`} ref={datePickerRef}>
              <p className="bubble-date">{reminderDateValue}</p>
              <DatePicker
                onChange={handleReminderDateValue}
                calendarIcon={<MdDateRange className={deleteIcon} onClick={handleScrollToDatePicker} />}
                clearIcon={<MdAutoDelete className={deleteIcon} onClick={handleDeleteReminderDate} />}
                value={reminderDateValue}
                format="dd/MM/yyyy"
              />
            </div>
          ) : (
            <p className="bubble-date">{reminder?.date}</p>
          )}
        </div>
        <div className={inputContainer}>
          <div className={dateText}>
            <p>{translations.remindersFormRepeatEvery}:</p>
          </div>
          <div className={value}>
            {isEditing ? (
              <input
                type="text"
                className={`${input} ${repeatValue}`}
                value={reminderRepeatValue !== '0' ? reminderRepeatValue : ''}
                onChange={handleReminderRepeatValue}
                name="repeat_in_days"
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              />
            ) : (
              <p className="bubble-center">{reminder?.repeatInDays === 0 ? '-' : reminder?.repeatInDays}</p>
            )}
            <p>{translations.remindersFormDays}</p>
          </div>
        </div>
        <div className={flex}>
          <p className={padding}>{translations[professionTranslation('remindersFormSendMailToClient')]}</p>
          {isEditing ? (
            <div className="radio-container" onClick={showSendEmail ? handleSendEmail : () => {}}>
              <BsCheck
                className={`radio-item ${isSendMail && 'radio-item-selected'} ${
                  !showSendEmail && 'radio-item-disabled'
                }`}
              />
            </div>
          ) : (
            <div className="radio-container">
              <BsCheck
                className={`radio-item ${isSendMail && 'radio-item-selected'} ${
                  !showSendEmail && 'radio-item-disabled'
                }`}
              />
            </div>
          )}
        </div>
      </div>
      <Title title={translations.remindersFormMessageTitle} />
      <div className={indented}>
        <p>{translations.remindersFormSubject}</p>
        {errors?.subject && <p className={errorMessage}>{translations.mandatoryField}</p>}
        {isEditing ? (
          <div className={inputContainer}>
            <input
              type="text"
              className={input}
              ref={register}
              name="subject"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </div>
        ) : (
          <p className={`bubble ${bubble}`}>{reminder?.subject}</p>
        )}

        <div className={infoContainer}>
          <p>{translations.remindersFormText}</p>
        </div>
        {isEditing && profile !== null && (
          <div className={infoContainer}>
            <AiFillInfoCircle className={infoImage} />
            <p className={infoDescription}>{translations[`reminderFormTextHelp${capitalize(profile.userType)}`]}</p>
          </div>
        )}
        {errors?.text && <p className={errorMessage}>{translations.mandatoryField}</p>}
        {isEditing ? (
          <div className={inputContainer}>
            <textarea rows={8} ref={register} name="text" />
          </div>
        ) : (
          <textarea className={`bubble ${bubble}`} rows={8} disabled value={reminder?.text} />
        )}
      </div>
      {isEditing && saveReminderTemplate && (
        <div className={btnContainerSecondary}>
          <button type="button" onClick={saveReminderTemplate} className="grey">
            {translations.remindersFormSaveTemplate}
          </button>
        </div>
      )}
      {isEditing && <Title title="" />}
      {isEditing && (
        <div className={btnContainer}>
          <button type="submit" className="blue">
            {translations.remindersFormSaveReminder}
          </button>
        </div>
      )}
      <div className="last-element" />
    </form>
  );
}

export default RemindersForm;
