import { useState } from 'react';
import { IUseEditHeader } from '../interfaces/components/Hooks';

function useEditHeader(): IUseEditHeader {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisible = () => {
    setIsVisible(!isVisible);
  };

  return { isVisible, toggleVisible };
}

export default useEditHeader;
