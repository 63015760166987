import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { API_METHOD_GET } from '../constants/api';
import { useApi } from './useApi';
import { FETCH_INSTRUMENT } from '../constants/routes';
import { IInstrumentDetail } from '../interfaces/components/Instrument';
import { IUseInstrumentDetails } from '../interfaces/components/Hooks';

function useInstrumentDetails(): IUseInstrumentDetails {
  const { handleApi } = useApi();
  const { id } = useParams<{ id: string }>();
  const [instrument, setInstrument] = useState<IInstrumentDetail>();

  async function fetchDetails() {
    if (id) {
      const { data } = await handleApi(API_METHOD_GET, FETCH_INSTRUMENT.replace(':id', id.toString()));
      setInstrument(data);
    }
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  return { instrument };
}

export default useInstrumentDetails;
