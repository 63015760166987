import InstrumentDetailHeader from '../InstrumentDetailHeader';
import InstrumentCard from '../InstrumentCard';
import InstrumentDetailForm from '../InstrumentDetailForm';
import useInstrumentDetails from '../../hooks/useInstrumentDetails';
import useSetHeader from '../../hooks/useSetHeader';
import useGetTranslations from '../../hooks/useGetTranslations';
import useGeneral from '../../hooks/useGeneral';
import AdSection from '../AdSection';
import UserIdComponent from '../UserIdComponent';
import {
  ATTACHMENTS_PAGE,
  CLIENT_DETAILS,
  EDIT_INSTRUMENT,
  HOME_PAGE,
  INSTRUMENT_SERVICES,
  NEW_CLIENT_PAGE,
} from '../../constants/routes';
import { MenuBar } from '../MenuBar';
import { BsFillPersonFill } from 'react-icons/bs';
import { GiAutoRepair } from 'react-icons/gi';
import { BiCalendar } from 'react-icons/bi';
import { BsCameraFill } from 'react-icons/bs';
import { ImAttachment } from 'react-icons/im';
import { SET_ATTACHMENTS_PAGE_SWITCHER, SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER } from '../../constants/reduxActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import LimitExceededNotification, { limitExceededOptions } from '../LimitExceededNotification';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import AlertModal from '../AlertModal';
import { useState } from 'react';
import { professionTranslation } from '../../utils/roleChecker';

function InstrumentDetails(): JSX.Element {
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();
  const { instrument } = useInstrumentDetails();
  const dispatch = useDispatch();
  const { canCreateNewClient } = useMySubscriptionPlan();
  const [isModalOpen, setModalOpen] = useState(false);

  useSetHeader(() => {
    handleRedirect(HOME_PAGE);
  }, translations.instrumentDetailTitle);

  //prettier-ignore
  return (
    <main>
      <InstrumentDetailHeader />
      <AdSection topPadding>
        {<UserIdComponent isInstrument={true} clientId={instrument?.id} />}
        <InstrumentCard isSimple={true} {...instrument} noMarginBottom />
        <MenuBar
          items={[
            {
              icon: <BsFillPersonFill />,
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                if (!instrument.clientId) {
                  setModalOpen(true);
                  return;
                } else {
                  handleRedirect(CLIENT_DETAILS.replace(':id', instrument.clientId.toString()));
                }
              },
            },
            {
              icon: <GiAutoRepair />,
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 0 });
                handleRedirect(INSTRUMENT_SERVICES.replace(':id', instrument.id.toString()));
              },
            },
            {
              icon: <BiCalendar />,
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 2 });
                handleRedirect(INSTRUMENT_SERVICES.replace(':id', instrument.id.toString()));
              },
            },
            {
              icon: <BsCameraFill />,
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'image' });
                handleRedirect(
                  ATTACHMENTS_PAGE.replace(':id', instrument.id.toString()).replace(':type', 'instrument')
                );
              },
            },
            {
              icon: <ImAttachment />,
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'file' });
                handleRedirect(
                  ATTACHMENTS_PAGE.replace(':id', instrument.id.toString()).replace(':type', 'instrument')
                );
              },
            },
          ]}
        />
        <AlertModal
          isOpen={isModalOpen ?? false}
          onClose={() => {
            setModalOpen(false);
          }}
          header={translations[professionTranslation('newClientQuestionTitle')]}
          description={translations[professionTranslation('newClientQuestionDescription')]}
          leftButton={translations[professionTranslation('newClientQuestionLeftButton')]}
          rightButton={translations[professionTranslation('newClientQuestionRightButton')]}
          onLeftButtonClick={() => {
            if (!instrument || !instrument.id) {
              return;
            }
          
            setModalOpen(false);
            handleRedirect(EDIT_INSTRUMENT.replace(':id', instrument.id.toString()));
          }}
          onRightButtonClick={() => {
            if (!instrument || !instrument.id) {
              return;
            }

            if (!canCreateNewClient()) {
              toast.error(<LimitExceededNotification />, limitExceededOptions);
              return;
            }
            setModalOpen(false);
            handleRedirect(NEW_CLIENT_PAGE + `?instrumentId=${instrument.id}`);
          }}
        />

        <InstrumentDetailForm {...instrument} />
      </AdSection>
    </main>
  );
}

export default InstrumentDetails;
