import { animateScroll } from './animateScroll';

const logError = () => console.error(`Invalid element, are you sure you've provided element id or react ref?`);

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

const getElementPosition = (element: any) => (iOS ? element.offsetTop - 150 : element.offsetTop - 20);

export const smoothScrollTo = ({ id, ref = null, duration = 3000, parentRef = null, onFinished = () => {} }: any) => {
  // the position of the scroll bar before the user clicks the button
  const initialPosition = parentRef.scrollTop;

  // decide what type of reference that is
  // if neither ref or id is provided  set element to null
  const element = ref ? ref.current : id ? document.getElementById(id) : null;

  if (!element) {
    // log error if the reference passed is invalid
    logError();
    return;
  }

  animateScroll({
    targetPosition: getElementPosition(element),
    initialPosition,
    duration,
    parentElement: parentRef,
    onFinished,
  });
};
