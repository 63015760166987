import _ from 'lodash';

import useMobile from './useMobile';

function usePagination() {
  const { isMobile } = useMobile();

  const paginate = (callback: any, newData: any) => {
    if (isMobile) {
      callback((prev: any) => _.uniqBy([...prev, ...newData], 'id'));
      return;
    }

    callback(newData);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return { paginate };
}

export default usePagination;
