import { FiDownload } from 'react-icons/fi';
import { BsCheck } from 'react-icons/bs';

import Title from './Title';
import style from '../assets/scss/components/CurrencyLanguageForm.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { LANGUAGE_TYPES } from '../constants/languages';
import { ICurrencyForm } from '../interfaces/components/Currency';
import useGetTranslations from '../hooks/useGetTranslations';
import { useCallback } from 'react';
import LeaveModal from './LeaveModal';
import { AiFillInfoCircle } from 'react-icons/ai';

function CurrencyLanguageForm(props: ICurrencyForm): JSX.Element {
  const {
    row,
    languageContainer,
    centerRow,
    blueTitle,
    icon,
    downloadRow,
    currency,
    unitRow,
    unit,
    unitTitle,
    indented,
    lastElement,
    unitText,
    left,
    wrapper,
    line,
    infoContainer,
    infoImage,
    infoDescription,
  } = convertKeysToCamelCase(style);
  const { onDownloadFiles, onDownloadPictures, onDownloadUserDb, profile, isEditing, onChange, onSubmit } = props;
  const { translations } = useGetTranslations();

  //prettier-ignore
  const handleCurrencyChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange('currency', e.target.value);
    },
    [onChange]
  );

  //prettier-ignore
  const handleLanguageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange('languageCode', e.target.value);
    },
    [onChange]
  );

  return (
    <div className={wrapper}>
      {isEditing && <LeaveModal />}
      <Title title={translations.currencyFormCurrencyTitle} />
      <div className={indented}>
        <div className={row}>
          <p>{translations.currencyFormCurrency}</p>
          <input
            type="text"
            disabled={!isEditing}
            value={profile?.currency}
            className={`primary ${currency}`}
            onChange={handleCurrencyChange}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        </div>
        <div className={unitRow}>
          <p className={unitText}>{translations.currencyFormUnit}</p>
          <div>
            <div className={unit}>
              <div
                className={`radio ${left}`}
                onClick={() => {
                  isEditing && onChange('lengthUnit', 'cm');
                }}
              >
                <p className={unitTitle}>cm</p>
                <div className="radio-container">
                  <BsCheck className={`radio-item ${profile?.lengthUnit === 'cm' && 'radio-item-selected'}`} />
                </div>
              </div>
              <div
                className="radio"
                onClick={() => {
                  isEditing && onChange('lengthUnit', 'inch');
                }}
              >
                <p className={unitTitle}>inch</p>
                <div className="radio-container">
                  <BsCheck className={`radio-item ${profile?.lengthUnit === 'inch' && 'radio-item-selected'}`} />
                </div>
              </div>
            </div>
            <div className={unit}>
              <div
                className={`radio ${left}`}
                onClick={() => {
                  isEditing && onChange('temperatureUnit', 'C');
                }}
              >
                <p className={unitTitle}>C</p>
                <div className="radio-container">
                  <BsCheck className={`radio-item ${profile?.temperatureUnit === 'C' && 'radio-item-selected'}`} />
                </div>
              </div>
              <div
                className="radio"
                onClick={() => {
                  isEditing && onChange('temperatureUnit', 'F');
                }}
              >
                <p className={unitTitle}>F</p>
                <div className="radio-container">
                  <BsCheck className={`radio-item ${profile?.temperatureUnit === 'F' && 'radio-item-selected'}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Title title={translations.currencyFormLanguageTitle} />
      <div className={indented}>
        <div className={centerRow}>
          <select
            disabled={!isEditing}
            className={`${languageContainer} bubble-no-p`}
            value={profile?.languageCode}
            onChange={handleLanguageChange}
          >
            {LANGUAGE_TYPES.map((language) => (
              <option key={language.code} value={language.code}>
                {language.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <Title title={translations.currencyFormExportImportTitle} />
      <div className={indented}>
        <p className={blueTitle}>{translations.currencyFormImport}</p>
        <p>{translations.currencyFormImportDescription}</p>
        <p className={blueTitle}>{translations.currencyFormExport}</p>
        <p>{translations.currencyFormExportDescription}</p>
        <div className={downloadRow} onClick={() => onDownloadPictures()}>
          <p>{translations.currencyFormDownloadPictures}</p>
          <FiDownload className={icon} />
        </div>
        <div className={line} />
        <div className={downloadRow} onClick={() => onDownloadFiles()}>
          <p>{translations.currencyFormDownloadFiles}</p>
          <FiDownload className={icon} />
        </div>
        <div className={line} />
        <div className={downloadRow} onClick={() => onDownloadUserDb()}>
          <p>{translations.currencyFormDownloadUserDb}</p>
          <FiDownload className={icon} />
        </div>
        <div className={`${lastElement} ${infoContainer}`}>
          <AiFillInfoCircle className={infoImage} />
          <p className={infoDescription}>{translations.currencyFormDownloadUserDbDescription}</p>
        </div>
      </div>
      {isEditing && (
        <div className={` ${centerRow} ${lastElement}`}>
          <button type="submit" className="blue" onClick={onSubmit}>
            {translations.save}
          </button>
        </div>
      )}
    </div>
  );
}

export default CurrencyLanguageForm;
