import { useSelector } from 'react-redux';
import { IReduxStore, ITranslations } from '../interfaces/IGeneral';

function getTranslations(): ITranslations {
  const { translations } = useSelector((store: IReduxStore) => store.defaultReducer);

  return { translations };
}

export default getTranslations;
