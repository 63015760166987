import { useForm } from 'react-hook-form';

import useGeneral from './useGeneral';
import { useApi } from './useApi';
import { API_METHOD_POST } from '../constants/api';
import { FORGOT_PASSWORD, HOME_PAGE, LOGIN_API } from '../constants/routes';
import { TOKEN_DATA_KEY } from '../constants/login';
import { getSignInValidationSchema } from '../helpers/validationSchemaHelper';
import useYupValidationResolver from './useYupValidationResolver';
import { IUseLogin } from '../interfaces/components/Hooks';

function useLogin(): IUseLogin {
  const { handleRedirect } = useGeneral();
  const { handleApi } = useApi();

  const resolver = useYupValidationResolver(getSignInValidationSchema());
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });

  const handleForgotPassword = () => {
    handleRedirect(FORGOT_PASSWORD);
  };

  async function submitSignIn(formData: { email: string; password: string }) {
    const { data, code } = await handleApi(API_METHOD_POST, LOGIN_API, formData);

    if (code !== 200) {
      return;
    }

    if (data != null) {
      const { access_token, language_code } = data;
      localStorage.setItem(TOKEN_DATA_KEY, JSON.stringify({ access_token, language_code }));
      localStorage.setItem('userEmail', formData.email);
    }
    handleRedirect(HOME_PAGE);
    return;
  }

  return {
    register,
    errors,
    handleSubmitForm: handleSubmit(submitSignIn),
    handleForgotPassword,
  };
}

export default useLogin;
