import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from './useApi';
import { INSTRUMENTS } from '../constants/routes';
import { API_METHOD_GET } from '../constants/api';
import { FilterFilter as InstrumentFilter } from '../constants/filter';
import { IReduxStore, IFilter } from '../interfaces/IGeneral';
import { IInstrument } from '../interfaces/components/Instrument';
import { toast } from 'react-toastify';
import NoActiveSubscriptionNotification, {
  noActiveSubscriptionOptions,
} from '../components/NoActiveSubscriptionNotification';
import { RESET_ORDER, SET_PAGINATION } from '../constants/reduxActions';
import usePagination from '../hooks/usePagination';
import { IUseInstruments } from '../interfaces/components/Hooks';

function useInstruments(): IUseInstruments {
  const { order, actualFilter, pagination } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { handleApi } = useApi();
  const [filteredInstruments, setInstruments] = useState<IInstrument[]>([]);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const { paginate } = usePagination();

  useEffect(() => {
    dispatch({ type: SET_PAGINATION, payload: { page: 1, perPage: 10 } });
  }, []);

  useEffect(() => {
    fetchInstruments();
  }, [order, actualFilter]);

  useEffect(() => {
    fetchInstrumentsWithPagination();
  }, [pagination]);

  async function fetchInstruments() {
    const keys = Object.keys(actualFilter) as Array<keyof IFilter>;
    // prettier-ignore
    const answers = keys.map(
      (key: keyof IFilter) => `&${[key]}=${InstrumentFilter[key] ? (actualFilter[key]?.id ? actualFilter[key]?.id : true) : actualFilter[key]?.name}`
    )
      .join('');
    if (order.orderByField === 'last_date') {
      dispatch({ type: RESET_ORDER });
      return;
    }
    const x = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=1&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENTS + x);
    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }
    setInstruments(data.data);
    setTotal(data.total);
  }

  const fetchInstrumentsWithPagination = async () => {
    const keys = Object.keys(actualFilter) as Array<keyof IFilter>;
    // prettier-ignore
    const answers = keys.map(
      (key: keyof IFilter) => `&${[key]}=${InstrumentFilter[key] ? (actualFilter[key]?.id ? actualFilter[key]?.id : true) : actualFilter[key]?.name}`
    )
      .join('');

    if (order.orderByField === 'last_date') {
      return;
    }
    const x = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENTS + x);
    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }
    paginate(setInstruments, data.data);
    setTotal(data.total);
  };

  const nextPage = () => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: pagination.page + 1 } });
  };

  const showNoActiveSubscriptionToast = useCallback(() => {
    toast.error(<NoActiveSubscriptionNotification />, noActiveSubscriptionOptions);
  }, []);

  return { filteredInstruments, total, nextPage };
}

export default useInstruments;
